import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web'
import { Link } from 'react-router-dom'

import { getIndexName, searchClient } from '../../../../config/algoliaConfig'
import { StudentHits } from '../StudentHits'

export const PartnerStudentTeaserHits = () => {
  const { hits } = useHits()

  if (!hits.length) {
    return null
  }

  return (
    <Card>
      <CardHeader
        title={
          <Stack mb={4} spacing={2}>
            <Typography variant="body2Strong" color="colors.nemo">
              New!
            </Typography>

            <Typography variant="h5">Fresh Fish</Typography>
          </Stack>
        }
        subheader={
          <Typography color="colors.grey">
            New and interesting talents for you.
          </Typography>
        }
      />
      <CardContent>
        <Stack spacing={10}>
          <StudentHits hasBorder />

          <Button component={Link} to="/talents">
            Find more people
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export const PartnerStudentTeasers = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('student__created-desc')}
    >
      <Configure hitsPerPage={4} filters="score >= 70" />
      <PartnerStudentTeaserHits />
    </InstantSearch>
  )
}
