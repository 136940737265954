import {
  Card,
  CardContent,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Fragment, ReactNode, useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { MainNavigation } from '../MainNavigation'

const Wrapper = styled('section')(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.palette.colors.water,
  },
}))

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: 32,
  gridTemplateColumns: '1fr',
  alignItems: 'start',
  paddingRight: 24,
  paddingLeft: 24,

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 5fr',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: 32,
    paddingRight: 40,
    paddingBottom: 64,
    paddingLeft: 40,
  },

  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: '1fr 3fr',
    maxWidth: `${theme.breakpoints.values.xl}px`,
  },
}))

export const ContentLayout = ({
  disablePadding = false,
}: {
  disablePadding?: boolean
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  // add wrapper to deprecated components until they hav their own Card
  const OutletWrapper = useMemo(
    () =>
      isMobile || disablePadding
        ? Fragment
        : ({ children }: { children: ReactNode }) => (
            <Card>
              <CardContent sx={{ main: { padding: 8 } }}>
                {children}
              </CardContent>
            </Card>
          ),
    [disablePadding, isMobile]
  )

  return (
    <Wrapper data-cy-post-login="true" data-test="main">
      <Grid sx={disablePadding ? { padding: 0 } : {}}>
        <MainNavigation />
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </Grid>
    </Wrapper>
  )
}
