import { useListBootcamps } from '../../../graphql/bootcamp/listBootcampService'
import { useListPartners } from '../../../graphql/partner/useListPartners'
import { useListPersonTypes } from '../../../graphql/person/useListPersonTypes'
import { useListTrainingProviders } from '../../../graphql/person/useListTrainingProviders'

export default function useStandingInvitePersonData() {
  const today = new Date().toISOString().substring(0, 10)
  const { bootcamps, loading: bootcampsLoading } = useListBootcamps({
    endsAfter: today,
  })

  const { partners, loading: partnersLoading } = useListPartners()
  const {
    personTypes,
    personTypeSelectOptions,
    loading: personTypesLoading,
  } = useListPersonTypes()

  const {
    trainingProviders,
    trainingProvidersSelectOptions,
    loading: trainingProvidersLoading,
  } = useListTrainingProviders()

  const standingDataLoading =
    bootcampsLoading ||
    partnersLoading ||
    personTypesLoading ||
    trainingProvidersLoading

  return {
    bootcamps,
    partners,
    personTypes,
    personTypeSelectOptions,
    trainingProviders,
    trainingProvidersSelectOptions,
    standingDataLoading,
  }
}
