import { gql, useQuery } from '@apollo/client'

import { getPersonTypeSelectOption } from '../../service/person-type-service'
import { PersonTypeEnum } from '../graphqlTypes'

export const LIST_PERSON_TYPES = gql`
  query ListPersons {
    listPersonTypes
  }
`

export const useListPersonTypes = () => {
  const query = useQuery<{ listPersonTypes: PersonTypeEnum[] }>(
    LIST_PERSON_TYPES
  )
  const personTypes = query.data?.listPersonTypes || []
  const personTypeSelectOptions = personTypes.map(getPersonTypeSelectOption)
  const completed = query.called && !query.loading && !query.error
  return { ...query, personTypes, personTypeSelectOptions, completed }
}
