import { Components } from '@mui/material'
import React from 'react'

import { colors } from '../constants/colors'

export const MuiTabs: Components['MuiTabs'] = {
  defaultProps: {
    variant: 'scrollable',
    scrollButtons: false,
    TabIndicatorProps: {
      children: <span className="MuiTabs-indicatorSpan" />,
    },
  },
  styleOverrides: {
    indicator: {
      display: 'flex',
      height: 2,
      bottom: 10,
      justifyContent: 'center',
      backgroundColor: 'unset',
      transition: 'none',
      '.MuiTabs-indicatorSpan': {
        width: 'calc(100% - 2rem)',
        height: '100%',
        backgroundColor: colors.nemo,
      },
    },
  },
}
