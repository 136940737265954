import { styled } from '@mui/material'
import { ReactNode } from 'react'
import { useDrag } from 'react-dnd'

const Draggable = styled('div')`
  cursor: move;
`

type Props = {
  item: any
  type: string
  children: ReactNode | ReactNode[]
}

const DraggableWrapper = ({ item, type, children }: Props) => {
  const [, drag] = useDrag(() => ({
    type,
    item,
  }))
  return <Draggable ref={drag}>{children}</Draggable>
}

export default DraggableWrapper
