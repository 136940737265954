import { Card, CardContent, styled } from '@mui/material'
import { InstantSearch } from 'react-instantsearch-hooks-web'

import { SearchPagination } from '../common/components/molecules/SearchPagination'
import { SearchBar } from '../common/components/organisims/SearchBar/SearchBar'
import { StudentHits } from '../common/components/organisims/StudentHits'
import { getIndexName, searchClient } from '../config/algoliaConfig'

const PageSearchContainer = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.up('lg')]: {
    gap: 32,
  },
}))
export const PageSearch = () => {
  return (
    <PageSearchContainer>
      <InstantSearch
        searchClient={searchClient}
        indexName={getIndexName('student')}
      >
        <Card>
          <CardContent>
            <SearchBar />
          </CardContent>
        </Card>

        <StudentHits />

        <SearchPagination inCard />
      </InstantSearch>
    </PageSearchContainer>
  )
}
