import { Device } from '../../graphql/graphqlTypes'
import ipadLandscapeFrame from '../assets/devices/frame_ipad_landscape.svg'
import ipadPortraitFrame from '../assets/devices/frame_ipad_portrait.svg'
import iphoneFrame from '../assets/devices/frame_iphone.svg'
import macbookFrame from '../assets/devices/frame_macbook.svg'

type FrameInset = {
  top: number
  right: number
  bottom: number
  left: number
}

const frameUrls: Record<Device, string> = {
  [Device.SMARTPHONE]: iphoneFrame,
  [Device.DESKTOP]: macbookFrame,
  [Device.TABLET_PORTRAIT]: ipadPortraitFrame,
  [Device.TABLET_LANDSCAPE]: ipadLandscapeFrame,
}

const frameInsets: Record<Device, FrameInset> = {
  [Device.SMARTPHONE]: {
    top: 2.625,
    right: 6.739,
    bottom: 2.625,
    left: 6.739,
  },
  [Device.TABLET_PORTRAIT]: {
    top: 3.8,
    right: 4.5,
    bottom: 3.4,
    left: 4.3,
  },
  [Device.TABLET_LANDSCAPE]: {
    top: 4.3,
    right: 3.4,
    bottom: 4,
    left: 3.5,
  },
  [Device.DESKTOP]: {
    top: 4.75,
    right: 8.75,
    bottom: 8.5,
    left: 9,
  },
}

export const useDeviceFrame = (
  device: Device
): {
  frameInset: FrameInset
  frameUrl: string
} => {
  const frameUrl = frameUrls[device]
  const frameInset = frameInsets[device]

  return {
    frameUrl,
    frameInset,
  }
}
