import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import {
  MutationSetPartnerBannerImageArgs,
  MutationSetPartnerProfileImageArgs,
} from '../graphqlTypes'
import { GET_PARTNER_DETAILS } from '../partner/usePartnerDetails'

const SET_PARTNER_PROFILE_IMAGE = gql`
  mutation SetPartnerProfileImage($partnerId: String!, $imageId: String!) {
    setPartnerProfileImage(partnerId: $partnerId, imageId: $imageId) {
      profileImage {
        id
        urls {
          s
          m
          l
          xl
        }
      }
    }
  }
`

export const useSetPartnerProfileImage = (options?: BaseMutationOptions) => {
  const [updateImage, result] = useMutation(SET_PARTNER_PROFILE_IMAGE, options)

  const setPartnerProfileImage = (
    variables: MutationSetPartnerProfileImageArgs
  ) =>
    updateImage({
      variables,
      refetchQueries: [GET_PARTNER_DETAILS],
    })

  return { ...result, setPartnerProfileImage }
}

const SET_PARTNER_BANNER_IMAGE = gql`
  mutation SetPartnerBannerImage($partnerId: String!, $imageId: String!) {
    setPartnerBannerImage(partnerId: $partnerId, imageId: $imageId) {
      profileImage {
        id
        urls {
          s
          m
          l
          xl
        }
      }
    }
  }
`

export const useSetPartnerBannerImage = (options?: BaseMutationOptions) => {
  const [updateImage, result] = useMutation(SET_PARTNER_BANNER_IMAGE, options)

  const setPartnerBannerImage = (
    variables: MutationSetPartnerBannerImageArgs
  ) =>
    updateImage({
      variables,
      refetchQueries: [GET_PARTNER_DETAILS],
    })

  return { ...result, setPartnerBannerImage }
}
