import { Typography } from '@mui/material'
import { ReactNode } from 'react'

import { colors } from '../../../theme/constants/colors'

export interface Props {
  children: ReactNode
}

export const NotificationBox = ({ children }: Props) => {
  return (
    <Typography
      variant="caption"
      sx={theme => ({
        [theme.breakpoints.down('lg')]: {
          marginLeft: 6,
          marginRight: 6,
          marginBottom: 10,
        },
        marginBottom: 6,
        textAlign: 'center',
        padding: 3,
        backgroundColor: colors.almostGranite,
        borderRadius: 2,
        color: colors.foam,
      })}
    >
      {children}
    </Typography>
  )
}
