import { BootcampParticipation } from '../../graphql/graphqlTypes'

const isHeadCoach = (participant: BootcampParticipation) =>
  participant.role.id === 'head-coach'
const isAssistantCoach = (participant: BootcampParticipation) =>
  participant.role.id === 'coach'
export const isCoach = (participant: BootcampParticipation) =>
  isHeadCoach(participant) || isAssistantCoach(participant)
export const isStudent = (participant: BootcampParticipation) =>
  participant.role.id === 'student'
export const compareRole = (
  a: BootcampParticipation,
  b: BootcampParticipation
) => {
  if (
    (isHeadCoach(a) && isHeadCoach(b)) ||
    (isAssistantCoach(a) && isAssistantCoach(b))
  ) {
    return 0
  }
  if (isHeadCoach(a) && isAssistantCoach(b)) {
    return -1
  } else return 1
}
