import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'
import { typography } from './typography'

export const MuiCardHeader: Components['MuiCardHeader'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      alignItems: 'flex-start',
      gap: 24,
      padding: 24,
      [muiDefaultTheme.breakpoints.up('lg')]: {
        padding: 32,
      },
      '.MuiCardHeader-avatar': {
        marginRight: 0,
      },
      '.MuiCardHeader-title': {
        ...typography.h3,
        marginBottom: 6,
      },
      '.MuiCardHeader-subheader': {
        ...typography.body2,
        color: colors.granite,
      },
    },
  },
}
