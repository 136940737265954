import { gql, useQuery } from '@apollo/client'

import { Geolocation } from '../graphqlTypes'

const GET_GEOLOCATION_SUGGESTIONS = gql`
  query GetGeolocationSuggestion($text: String!) {
    getGeolocationSuggestions(text: $text) {
      placeId
      text
    }
  }
`

export const useGetGeolocationSuggestions = (input: string) => {
  const query = useQuery<{ getGeolocationSuggestions: Geolocation[] }>(
    GET_GEOLOCATION_SUGGESTIONS,
    {
      variables: {
        text: input,
      },
      skip: input.length < 1,
    }
  )

  const suggestions =
    query.data?.getGeolocationSuggestions || ([] as Geolocation[])

  return { ...query, suggestions }
}
