import { Button, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { Partner } from '../../../../graphql/graphqlTypes'
import useDeletePartner from '../../../../graphql/partner/useDeletePartner'
import { ConfirmModal } from '../../molecules/ConfirmModal'

export const DeletePartner = ({
  id,
  name,
  onSuccess,
}: Pick<Partner, 'id' | 'name'> & { onSuccess: () => void }) => {
  const { deletePartner, loading } = useDeletePartner({
    onError: error => toast.error(`${error.message} 🙀`),
    onCompleted: () => {
      toast.success(`Successfully deleted ${name}`)
      onSuccess()
    },
  })

  return (
    <ConfirmModal
      title="Delete partner?"
      text={
        <>
          Permanently delete{' '}
          <Typography variant="body2Strong" component="span">
            {name}
          </Typography>{' '}
          and all of its members?
        </>
      }
      onConfirm={() => deletePartner(id)}
      loading={loading}
    >
      <Button variant="text" component="div" color="error" fullWidth>
        Delete partner
      </Button>
    </ConfirmModal>
  )
}
