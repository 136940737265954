import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { typography } from './typography'

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
    disableElevation: true,
    variant: 'contained',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      padding: '14px 22px',
      flexShrink: 0,
    },
    text: {
      '&:active': {
        color: colors.darkNemo,
      },
    },
    outlined: {
      borderWidth: 2,
      padding: '18px 30px',

      '&:hover, &:active': {
        borderWidth: 2,
      },
      '&.Mui-disabled': {
        borderWidth: 2,
      },
    },
    containedPrimary: {
      '&.Mui-disabled': {
        background: colors.lightGrey,
        color: colors.foam,
      },
      '.MuiCircularProgress-root': {
        color: colors.foam,
      },
    },
    containedSecondary: {
      background: colors.foam,
      color: colors.granite,
      '&:hover, &:active': {
        background: colors.foam,
        color: colors.nemo,
      },
      '&.Mui-disabled': {
        background: colors.foam,
        color: colors.lightGrey,
      },
      '.MuiCircularProgress-root': {
        color: colors.nemo,
      },
    },
    outlinedPrimary: {
      borderColor: colors.nemo,
      '&:hover, &:active': {
        background: colors.nemo,
        color: colors.foam,
      },
      '&.MuiLoadingButton-root': {
        background: colors.nemo,
        color: colors.foam,
        '&.Mui-disabled': {
          borderColor: colors.nemo,
        },
        '.MuiCircularProgress-root': {
          color: colors.foam,
        },
      },
      '&.Mui-disabled': {
        borderColor: colors.lightNemo,
        color: colors.lightNemo,
      },
    },
    outlinedSecondary: {
      borderColor: colors.foam,
      color: colors.foam,
      '&:hover, &:active': {
        background: colors.foam,
        color: colors.nemo,
        borderColor: colors.foam,
      },
      '&.MuiLoadingButton-root': {
        background: colors.foam,
        color: colors.nemo,
        '&.Mui-disabled': {
          borderColor: colors.foam,
          opacity: 1,
        },
        '.MuiCircularProgress-root': {
          color: colors.nemo,
        },
      },
      '&.Mui-disabled': {
        borderColor: colors.foam,
        color: colors.foam,
        opacity: 0.5,
      },
    },
    sizeSmall: {
      fontSize: 14,
      lineHeight: '20px',
      padding: '12px 20px',
      borderRadius: 4,
    },
    sizeMedium: {
      fontSize: 14,
      lineHeight: '20px',
      padding: '14px 22px',
      borderRadius: 4,
    },
    outlinedSizeSmall: {
      padding: '10px 18px',
      lineHeight: '18px',
    },
    outlinedSizeMedium: {
      padding: '12px 20px',
      lineHeight: '18px',
    },
    sizeRounded: {
      fontSize: 14,
      padding: '14px 16px',
      borderRadius: 8,
      ...typography.body2Strong,
      lineHeight: '20px',

      '&.MuiButton-outlined': {
        borderWidth: 1,
        paddingTop: 13,
        paddingBottom: 13,
        paddingRight: 15,
        paddingLeft: 15,
        '&:hover, &:active': {
          background: 'transparent',
          borderWidth: 1,
        },
        '&:hover': {
          color: colors.darkNemo,
          borderColor: colors.darkNemo,
        },
        '&:active': {
          color: colors.nemo,
          borderColor: colors.nemo,
        },
        '&.Mui-disabled': {
          borderWidth: 1,
          borderColor: colors.lightGrey,
          color: colors.lightGrey,
        },
        '&.MuiLoadingButton-root': {
          background: 'transparent',
          color: 'transparent',
          '&.Mui-disabled': {
            borderColor: colors.nemo,
          },
          '.MuiCircularProgress-root': {
            color: colors.nemo,
          },
        },
      },
    },
    outlinedSizeRounded: {
      padding: '13px 15px',
    },
    sizeBanner: {
      borderRadius: 0,
    },
  },
}
