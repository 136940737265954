import { Divider, Stack } from '@mui/material'
import { Fragment } from 'react'
import { useHits } from 'react-instantsearch-hooks-web'

import type { AlgoliaPartnerHit } from '../../../../types/algolia.d'
import { PartnerSearchHit } from '../../molecules/PartnerSearchHit'

export const PartnerSearchHits = () => {
  const { hits } = useHits<AlgoliaPartnerHit>()

  if (!hits.length) {
    return <p>No partners yet. Maybe create one?</p>
  }

  return (
    <Stack spacing={8}>
      {hits.map(hit => (
        <Fragment key={hit.objectID}>
          <PartnerSearchHit {...hit} />
          <Divider light />
        </Fragment>
      ))}
    </Stack>
  )
}
