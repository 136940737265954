import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationAddParticipantToBootcampArgs } from '../graphqlTypes'

const ADD_PARTICIPANT_TO_BOOTCAMP = gql`
  mutation AddBootcampDetails(
    $personId: String!
    $bootcampId: String!
    $roleId: String!
  ) {
    addParticipantToBootcamp(
      personId: $personId
      bootcampId: $bootcampId
      roleId: $roleId
    ) {
      id
      startDate
      endDate
      location {
        id
        name
      }
      track {
        id
        name
      }
      participants {
        role {
          id
          name
        }
        person {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const useAddParticipantToBootcamp = (options: BaseMutationOptions) => {
  const [addParticipantMutation, status] = useMutation(
    ADD_PARTICIPANT_TO_BOOTCAMP,
    options
  )

  const addParticipantToBootcamp = (
    variables: MutationAddParticipantToBootcampArgs
  ) =>
    addParticipantMutation({
      variables,
    })
  return { addParticipantToBootcamp, ...status }
}
