import { Button } from '@neuefische/design-system'
import { useState } from 'react'
import { toast } from 'react-toastify'
import InputWithLabel from '../../../common-deprecated/components/InputWithLabel'
import useUpdatePartner from '../../../graphql/partner/useUpdatePartner'
import { Partner } from '../../../graphql/graphqlTypes'
import { toLocaleDateString } from '../../../service/date-service'
import { styled } from '@mui/material'

type Props = {
  partner: Partner
}

export default function EditPartnerForm({ partner }: Props) {
  const [partnerData, setPartnerData] = useState(partner)

  const { updatePartner, loading } = useUpdatePartner({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      toast.success('Partner successfully updated 🎉')
    },
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!partnerData.name) {
      toast.warn('Company name is required')
      return
    }
    updatePartner(partnerData)
  }

  const handleChange = (event: { target: HTMLInputElement }) => {
    setPartnerData({
      ...partnerData,
      [event.target.name]: event.target.value,
    })
  }

  const hasUnsavedChanges =
    partner.name !== partnerData.name ||
    partner.description !== partnerData.description

  return (
    <section>
      <Form onSubmit={handleSubmit}>
        <PartnerInput
          labelText="Company name"
          onChange={handleChange}
          value={partnerData.name}
          type="text"
          name="name"
          disabled={loading}
          placeholder="Company name, e.g. ACME Corp."
        />
        <PartnerInput
          labelText="Company description"
          onChange={handleChange}
          value={partnerData.description}
          type="text"
          name="description"
          disabled={loading}
          placeholder="Optional description, comment"
        />
        <PartnerInput
          labelText="Created"
          value={toLocaleDateString(partnerData.created)}
          type="text"
          name="description"
          readOnly
          disabled
        />
        <ButtonGroup>
          <Button
            variant="secondary"
            label="Cancel"
            disabled={loading || !hasUnsavedChanges}
            onClick={() => setPartnerData(partner)}
            type="button"
          />
          <Button
            disabled={loading || !hasUnsavedChanges}
            variant="primary"
            label="Save"
          />
        </ButtonGroup>
      </Form>
    </section>
  )
}

const ButtonGroup = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: end;
`

const PartnerInput = styled(InputWithLabel)`
  width: 100%;
`

const Form = styled('form')`
  display: grid;
  gap: var(--spacing-m);
`
