import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import { ChevronDown as IconChevronDown } from '../../../../common/assets/icons'
import { Bootcamp } from '../../../../graphql/graphqlTypes'
import {
  compareRole,
  isCoach,
  isStudent,
} from '../../../../service/profile/user-dashboard-service'
import { BootcampCoachesList } from '../../molecules/BootcampCoachesList'
import { BootcampParticipantsList } from '../../molecules/BootcampParticipantsList'
import { EmptySection } from '../../molecules/EmptySection'

type BootcampCardProps = {
  bootcamp: Bootcamp
  personId: string | undefined
  expandedDefault: boolean
}

export const BootcampCard = ({
  bootcamp,
  personId,
  expandedDefault,
}: BootcampCardProps) => {
  const [expanded, setExpanded] = useState(expandedDefault)
  const coaches = bootcamp.participants?.filter(isCoach).sort(compareRole)
  const courseMates = bootcamp?.participants
    ?.filter(isStudent)
    .filter(student => student.person.id !== personId)

  return (
    <Card
      sx={{
        borderBottom: '1px solid',
        borderColor: { xs: 'colors.water', lg: 'transparent' },
      }}
    >
      <CardHeader
        sx={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label="Show more"
        action={
          <IconButton
            sx={{
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: theme => theme.transitions.create('transform'),
            }}
          >
            <IconChevronDown />
          </IconButton>
        }
        title={
          <>
            <Typography variant="body2Strong" color="colors.nemo" mb={2}>
              Your Bootcamp
            </Typography>
            <Typography variant="h5">
              {bootcamp.track?.name ? `${bootcamp.track.name}: ` : null}
              {bootcamp.id}
            </Typography>
          </>
        }
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0 }}>
          <>
            {coaches && coaches.length > 0 ? (
              <>
                <BootcampCoachesList coaches={coaches} />
                <Divider sx={{ paddingTop: 4, marginBottom: 4 }} light />
              </>
            ) : null}

            {courseMates && courseMates.length > 0 ? (
              <BootcampParticipantsList participants={courseMates} />
            ) : (
              <EmptySection
                icon={false}
                text="You're the only one in this course right now. 🤔"
              />
            )}
          </>
        </CardContent>
      </Collapse>
    </Card>
  )
}
