import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
    color: 'primary',
    SelectProps: {
      MenuProps: {
        PaperProps: {
          variant: 'light',
        },
      },
    },
  },
  styleOverrides: {
    root: {
      '.MuiFilledInput-input': {
        paddingLeft: 20,
      },
      '.MuiSvgIcon-root': {
        color: 'currentcolor',
      },
      '.MuiInputBase-input:focus': {
        backgroundColor: 'transparent',
      },
      '.MuiInputBase-multiline': {
        paddingBottom: 16,
      },
    },
  },
  variants: [
    {
      props: {
        color: 'primary',
      },
      style: {
        '.MuiFilledInput-root': {
          backgroundColor: colors.water,
          color: colors.granite,
          border: `1px solid ${colors.water}`,
          '&.Mui-focused': {
            backgroundColor: colors.water10,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.water,
            borderColor: colors.water,
            '.MuiInputBase-input': {
              WebkitTextFillColor: colors.lightGrey,
            },
          },
        },
        '&:hover': {
          '& .MuiFilledInput-root': {
            backgroundColor: colors.water10,
            borderColor: colors.water10,
          },
        },
      },
    },
    {
      props: {
        color: 'secondary', // dark mode
      },
      style: {
        '.MuiFilledInput-root': {
          backgroundColor: colors.granite,
          color: colors.foam,
          border: `1px solid ${colors.lightGrey}`,
          '&.Mui-focused': {
            backgroundColor: colors.darkGrey,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.granite,
            borderColor: colors.grey,
            '.MuiInputBase-input': {
              WebkitTextFillColor: colors.grey,
            },
          },
        },
        '&:hover': {
          '& .MuiFilledInput-root': {
            backgroundColor: colors.darkGrey,
            borderColor: colors.lightGrey,
          },
        },
        '.MuiInputLabel-root': {
          color: colors.lightGrey,
          '&.Mui-focused': {
            color: colors.foam,
          },
          '&.Mui-disabled': {
            color: colors.grey,
          },
        },
      },
    },
  ],
}
