export const colors = {
  nemo: '#ff4a11',
  granite: '#252629',

  darkGrey: '#3f4145',
  grey: '#6f727b',
  lightGrey: '#9fa1a8',
  darkNemo: '#e5430f',
  midNemo: '#ff5c29',
  lightNemo: '#ffb7a0',

  error: '#ff0000',
  success: '#00c149',

  water: '#f3f5f9',
  water10: '#e4e8f0',
  foam: '#fff',
  sand: '#efece7',
  sand10: '#e9e4de',
  almostGranite: '#323437',

  purple: '#4200FF',
}

export type colorName = keyof typeof colors

export type Colors = Record<colorName, string>
