import { BaseMutationOptions, gql, useQuery } from '@apollo/client'

import { EventTeaserProps } from '../../common/components/organisims/EventTeaser'

const GET_UPCOMING_EVENT = gql`
  query GetUpcomingEvents {
    getUpcomingEvents {
      id
      description
      onlineEvent
      bootcamp {
        id
        track {
          name
        }
      }
      date
      time
      token
    }
  }
`

export const useUpcomingEvents = (options?: BaseMutationOptions) => {
  const query = useQuery<{ getUpcomingEvents: EventTeaserProps[] }>(
    GET_UPCOMING_EVENT,
    options
  )
  const upcomingEvents = query.data?.getUpcomingEvents
  return { ...query, upcomingEvents }
}
