import { styled } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import { shuffle } from 'lodash'
import { useHits } from 'react-instantsearch-hooks-web'

import { ProfileCard } from '../../molecules/ProfileCard'

const StyledStudentList = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 24,
  gridTemplateColumns: '1fr',

  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.colors.water,
    padding: theme.spacing(6),
  },

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(370px, 1fr))`,
  },
}))

type AlgoliaStudent = Hit<{
  tracks: string[]
  availability: string[]
  firstName: string
  lastName: string
  avatarUrl: string
  teaser: string
  jobPreferences: string[]
  languages: string[]
  lastEmployment: { title: string; id: string; companyName: string }
  lastEducation: { title: string; id: string; companyName: string }
  trainingProvider: string
}>

export const StudentHits = ({ hasBorder }: { hasBorder?: boolean }) => {
  const { hits } = useHits<AlgoliaStudent>({ transformItems: shuffle })

  return (
    <StyledStudentList>
      {hits.map(student => (
        <ProfileCard
          key={student.objectID}
          id={student.objectID}
          {...student}
          availability={student.availability.includes('Available')}
          hasBorder={hasBorder}
        />
      ))}
    </StyledStudentList>
  )
}
