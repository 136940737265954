import { Components } from '@mui/material'

export const MuiGrid: Components['MuiGrid'] = {
  defaultProps: {
    spacing: {
      xs: 3,
      md: 4,
      lg: 6,
    },
    columns: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 10,
      xl: 10,
    },
  },
}
