import { css, styled } from '@mui/material'
import { DropTargetHookSpec, useDrop } from 'react-dnd'

type DropableProps = {
  isOver: boolean
}

const Dropable = styled('div')<DropableProps>`
  ${props =>
    props.isOver &&
    css`
      opacity: 0.3;
    `}
`

type Props<DROPPED, TARGET> = {
  item: TARGET
  accept: string
  onDrop?: (dropped: DROPPED, item: TARGET) => void
  children: any
}

const DropableWrapper = <DROPPED, TARGET>({
  item,
  accept,
  onDrop,
  children,
}: Props<DROPPED, TARGET>) => {
  const dropOptions: DropTargetHookSpec<DROPPED, any, any> = {
    accept,
    drop: dropped => onDrop && onDrop(dropped, item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }
  const [{ isOver }, drop] = useDrop(() => dropOptions)
  return (
    <Dropable isOver={isOver} ref={drop}>
      {children}
    </Dropable>
  )
}

export default DropableWrapper
