import { ChangeEvent, useRef, useState } from 'react'
import { styled } from '@mui/material'
import { toast } from 'react-toastify'
import { LoadingButton } from '@mui/lab'
import { AddDocumentIcon } from '../../common/assets/icons'
import ImportantNotes from '../../common/components/atoms/ImportantNotes/ImportantNotes'
import ExampleCsv from './components/ExampleCsv'
import { batchInviteStudents } from './service/batch-invite-students-service'

export function BatchInviteStudentsForm() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!selectedFile) {
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('file', selectedFile)
    batchInviteStudents(formData)
      .then(data => {
        const total = data.successfulInvites + data.failedInvites
        toast.success(
          `${data.successfulInvites} of ${total} students have been invited`
        )
      })
      .finally(() => setLoading(false))
      .catch(error => toast.error(`Error uploading file: ${error.message}`))
  }
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    hiddenFileInput?.current?.click()
  }

  const importantNotes = [
    'The .csv file is exclusively for inviting students.',
    'Ensure that the first line of the .csv file follows the provided example above.',
    'Make sure that the bootcamp has been created before inviting students to it',
    'Be aware that uploading larger files may require more time to process.',
    'You will receive a status email after the upload.',
  ]

  return (
    <>
      <ExampleCsv />
      <ImportantNotes notes={importantNotes} />
      <CenteredForm onSubmit={handleSubmit}>
        <StyledUpload onClick={handleClick}>
          <AddDocumentIconStyled /> <h3>Upload CSV file</h3>
        </StyledUpload>
        {selectedFile && (
          <div>
            <SelectedText>selected file:</SelectedText>
            <p> {selectedFile.name} </p>
          </div>
        )}
        <input
          hidden
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          ref={hiddenFileInput}
        />
        <LoadingButton
          type="submit"
          disabled={selectedFile == null}
          loading={loading}
        >
          Invite students
        </LoadingButton>
      </CenteredForm>
    </>
  )
}

const StyledUpload = styled('div')`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`

const CenteredForm = styled('form')`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  p {
    font-size: var(--font-s);
  }
`

const SelectedText = styled('p')`
  font-weight: bold;
`
const AddDocumentIconStyled = styled(AddDocumentIcon)`
  font-size: 48px;
  color: var(--primaryOne);
`
