import { Chip, ChipProps, styled } from '@mui/material'

export const TagsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 12,
})

export interface TagsProps extends Pick<ChipProps, 'color' | 'variant'> {
  tags: string[]
  onDelete?: (id: string) => void
}

export const Tags = ({ tags, color, variant, onDelete }: TagsProps) => {
  if (tags.length === 0) return null

  return (
    <TagsContainer>
      {tags.map(tag => (
        <Chip
          key={tag}
          label={tag}
          color={color}
          variant={variant}
          onDelete={onDelete ? () => onDelete(tag) : undefined}
        />
      ))}
    </TagsContainer>
  )
}
