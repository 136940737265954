import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material'

import type { AlgoliaPartnerHit } from '../../../../types/algolia.d'

export const CompanySearchHit = ({
  objectID,
  name,
  industry,
  imageUrl,
}: AlgoliaPartnerHit) => {
  return (
    <Card component="a" href={`/company/${objectID}`}>
      <CardContent sx={{ alignItems: 'center' }}>
        <Stack spacing={8} sx={{ alignItems: 'center' }}>
          <Avatar variant="rounded-big" src={imageUrl || undefined} />
          <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <Typography variant="body2Strong">{name}</Typography>
            {industry && (
              <Typography variant="body2" color="colors.grey">
                {industry}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
