import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '.MuiFilledInput-root': {
        paddingLeft: 0,

        '.MuiAutocomplete-input': {
          paddingLeft: 20,
        },

        '.MuiAutocomplete-clearIndicator': {
          marginRight: 4,
        },
      },
    },
    paper: {
      borderRadius: '10px',
      border: '1px solid' + colors.water10,
    },
    option: {
      borderBottom: '1px solid' + colors.water10,
      ':last-of-type': {
        borderBottom: 'none',
      },
      ':hover': {
        backgroundColor: colors.water10,
      },
      ':selected': {
        backgroundColor: colors.water10,
      },
    },
  },
}
