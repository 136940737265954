import { css, GlobalStyles as MuiGlobalStyles } from '@mui/material'
import { GlobalStyles as NeueFischeGlobalStyles } from '@neuefische/design-system'

import { colors } from './common/theme/constants/colors'

const deprecatedStyles = css`
  :root {
    --primary: #193251;
    --accent: #ff5a36;
    --secondaryOne: #7589a2;
    --secondaryTwo: #e0e4e8;
    --secondaryThree: #f8f8f8;

    --primaryShadow: 0 2px 11px 0 rgba(25, 50, 81, 0.2);

    /* Sizes for padding, margin, etc. */
    --spacing-xs: 4px;
    --spacing-s: 8px;
    --spacing-m: 12px;
    --spacing-l: 16px;
    --spacing-xl: 24px;

    /* Sizes for fonts */
    --font-xs: 0.75rem; /* around 12px */
    --font-s: 1rem; /* around 16px */
    --font-m: 1.25rem; /* around 20px */
    --font-l: 1.5rem; /* around 24px */
    --font-xl: 2rem; /* around 32px */
    --font-xxl: 2.5rem; /* around 40px */
  }

  button,
  input,
  select,
  textarea {
    font-size: var(--font-s);
  }
`

const fonts = css`
  @font-face {
    font-family: PolySans;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/poly-sans/PolySans-Median.woff2') format('woff2'),
      url('/fonts/poly-sans/PolySans-Median.woff') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('/fonts/inter/Inter-Thin.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Thin.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('/fonts/inter/Inter-ThinItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-ThinItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('/fonts/inter/Inter-ExtraLight.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-ExtraLight.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/inter/Inter-Light.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Light.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/inter/Inter-LightItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-LightItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/inter/Inter-Regular.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Regular.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/inter/Inter-Italic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Italic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/inter/Inter-Medium.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Medium.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/inter/Inter-MediumItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-MediumItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/inter/Inter-SemiBold.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-SemiBold.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/inter/Inter-Bold.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Bold.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/inter/Inter-BoldItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-BoldItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('/fonts/inter/Inter-ExtraBold.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-ExtraBold.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/inter/Inter-Black.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-Black.woff?v=3.19') format('woff');
  }

  @font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/inter/Inter-BlackItalic.woff2?v=3.19') format('woff2'),
      url('/fonts/inter/Inter-BlackItalic.woff?v=3.19') format('woff');
  }

  /* ----------------------- variable ----------------------- */
  /* stylelint-disable property-no-unknown */

  @font-face {
    font-family: 'Inter var';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('/fonts/inter/Inter-roman.var.woff2?v=3.19') format('woff2');
    font-named-instance: 'Regular';
  }

  @font-face {
    font-family: 'Inter var';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url('/fonts/inter/Inter-italic.var.woff2?v=3.19') format('woff2');
    font-named-instance: 'Italic';
  }
`

const globalStyles = css`
  /*
  CSS reset based on Josh W. Comeaus Blog:
  https://www.joshwcomeau.com/css/custom-css-reset/
  */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    margin: 0;
  }

  html {
    font-family: Inter, sans-serif;
    color: ${colors.granite};
  }

  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter var', sans-serif;
    }
  }

  body {
    font-size: 14px;
    line-height: 1.5;
    color: ${colors.granite};
    -webkit-font-smoothing: antialiased;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  a {
    text-decoration: none;
  }

  #root,
  #__next {
    isolation: isolate;
    height: 100%;
  }
`

const toastStyles = css`
  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 20px 28px;

    @media (min-width: 481px) {
      border-radius: 8px;
    }

    background-color: ${colors.granite} !important;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 8px;
      background-color: ${colors.grey};
    }

    &--success::before {
      background-color: ${colors.success};
    }
    &--error::before {
      background-color: ${colors.error};
    }
  }

  .Toastify__progress-bar {
    position: relative;
    height: 4px;
    border-radius: 32px;
    background-color: ${colors.grey};
  }

  .Toastify__toast-body {
    padding: 0;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: ${colors.foam};
  }
`

export const GlobalStyles = () => (
  <>
    <MuiGlobalStyles styles={fonts} />
    <MuiGlobalStyles styles={globalStyles} />
    <MuiGlobalStyles styles={toastStyles} />
  </>
)

export const DeprecatedGlobalStyles = () => (
  <>
    <MuiGlobalStyles styles={deprecatedStyles} />
    <NeueFischeGlobalStyles />
  </>
)
