import { Components } from '@mui/material/styles'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'
import { typography } from './typography'

const { up } = muiDefaultTheme.breakpoints

export const MuiPaginationItem: Components['MuiPaginationItem'] = {
  styleOverrides: {
    page: {
      ...typography.body1Strong,
      padding: 0,
      minWidth: '.8em',
      height: 'auto',
      margin: '0 8px',
      borderRadius: 0,
      background:
        'linear-gradient(0deg, currentColor, currentColor) no-repeat right bottom',
      backgroundSize: '100% 0',
      transition: `background 150ms cubic-bezier(0.4, 0, 0.2, 1)`,

      '&:hover': {
        backgroundColor: 'transparent',
        backgroundSize: '100% 2px',
      },
    },
    previousNext: {
      height: 'auto',
      borderBottom: `2px solid transparent`,
      margin: '0 8px',

      [up('md')]: {
        margin: '0 24px',
      },

      '&:hover': {
        color: colors.nemo,
        backgroundColor: 'transparent',
      },
    },
    text: {
      color: colors.granite,
    },
    root: {
      '&.Mui-selected': {
        color: colors.nemo,
        backgroundSize: '100% 2px',
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}
