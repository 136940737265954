import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import { useGetProfileCompletion } from '../../../../graphql/person/useGetProfileCompletion'
import { ChevronDown as IconChevronDown } from '../../../assets/icons'
import { colors } from '../../../theme/constants/colors'
import { ProfileCompletionBox } from '../../molecules/ProfileCompletionBox/ProfileCompletionBox'

export const ProgressCard: FC<{ personId: string }> = ({ personId }) => {
  const [expanded, setExpanded] = useState(true)
  const { completionData } = useGetProfileCompletion()
  const score = completionData?.completionScore.score || 0
  const scorePieData = [{ value: score, color: colors.nemo }]
  const capstoneConsidered = completionData?.completionScore.capstoneConsidered

  return (
    <Card
      sx={{
        borderBottom: '1px solid',
        borderColor: { xs: 'colors.water', lg: 'transparent' },
      }}
    >
      <CardHeader
        sx={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label="Show more"
        action={
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              alignItems: 'flex-end',
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
                alignItems: 'flex-start',
              },
            })}
          >
            <IconButton
              sx={{
                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: theme => theme.transitions.create('transform'),
              }}
            >
              <IconChevronDown />
            </IconButton>
            <Box maxWidth={80}>
              <PieChart
                data={scorePieData}
                background="#E4E8F0"
                totalValue={100}
                radius={47.5}
                lineWidth={20}
                startAngle={90}
                rounded
                label={({ dataEntry }) => Math.round(dataEntry.value) + '%'}
                labelPosition={0}
                labelStyle={{
                  fontSize: '18px',
                  fontFamily: 'PolySans',
                  fill: '#ff4a11',
                }}
              />
            </Box>
          </Box>
        }
        title={
          <>
            <Typography variant="h5">Complete your profile!</Typography>
            <Stack direction="row" spacing={{ xs: 15, md: 15 }}>
              <Typography mt={4}>
                Your profile will be easier to find as you fill it out more.
              </Typography>
            </Stack>
          </>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={theme => ({
            paddingTop: 0,
            display: 'grid',
            gap: 4,
            [theme.breakpoints.up('md')]: { gridTemplateColumns: '1fr 1fr' },
          })}
        >
          <ProfileCompletionBox
            isComplete={completionData?.isBasicInfoComplete}
            title="Basic info"
            description="Add your basic profile information"
            personId={personId}
          />
          <ProfileCompletionBox
            isComplete={completionData?.isSkillsComplete}
            title="Skills"
            description="Add skills and technologies."
            personId={personId}
          />
          <ProfileCompletionBox
            isComplete={completionData?.isLanguageComplete}
            title="Languages"
            description="Add languages you speak."
            personId={personId}
          />
          <ProfileCompletionBox
            isComplete={completionData?.isExperienceComplete}
            title="Experience"
            description="Add your education and workplaces."
            personId={personId}
          />
          <ProfileCompletionBox
            isComplete={completionData?.isJobPreferencesComplete}
            title="Job Preferences"
            description="Add your employment type and locations."
            personId={personId}
          />
          {capstoneConsidered && (
            <ProfileCompletionBox
              isComplete={completionData?.isCapstoneComplete}
              title="Capstone"
              description="Add your capstone project."
              personId={personId}
            />
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
}
