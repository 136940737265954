import { Components } from '@mui/material'

import defaultUserAvatar from './../../assets/defaultUserAvatar.svg'

export const defaultAvatar = defaultUserAvatar

export const MuiAvatar: Components['MuiAvatar'] = {
  defaultProps: {
    src: defaultAvatar,
  },
  styleOverrides: {
    root: {
      fontFamily: 'PolySans, sans-serif',
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
  },
  variants: [
    {
      props: { variant: 'rounded-big' },
      style: {
        width: 160,
        height: 160,
        borderRadius: 8,
      },
    },
    {
      props: { variant: 'rounded' },
      style: {
        width: 96,
        height: 96,
        borderRadius: 4,
      },
    },
    {
      props: { variant: 'rounded-small' },
      style: {
        width: 60,
        height: 60,
        borderRadius: 4,
      },
    },
  ],
}
