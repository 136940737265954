import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ContactsPage } from '../common/components/organisims/Partner/ContactsPage'
import { KeywordFilterPage } from '../common/components/organisims/Partner/KeywordFilterPage'
import { Page404 } from '../pages/Page404'
import { PageBootcamps } from '../pages/PageBootcamps'
import { PageCompanies } from '../pages/PageCompanies'
import { PageCompanyDetails } from '../pages/PageCompanyDetails'
import { PageContactTracing } from '../pages/PageContactTracing'
import { PageDashboardInternal } from '../pages/PageDashboardInternal'
import { PagePartners } from '../pages/PagePartners'
import { PageSettings } from '../pages/PageSettings'
import EditDefaultCurriculum from '../pages-deprecated/admin-edit-curriculum/EditDefaultCurriculum'
import { ContentLayout } from './../common/components/organisims/ContentLayout'
import { RequirePermissions } from './../common/components/organisims/RequirePermissions'
import { PersonTypeEnum } from './../graphql/graphqlTypes'
import { useUser } from './../graphql/person/useUser'
import { PageDashboardPartner } from './../pages/PageDashboardPartner'
import { PageDashboardStudent } from './../pages/PageDashboardStudent'
import { PageProfile } from './../pages/PageProfile'
import { PageSearch } from './../pages/PageSearch'
import DeprecatedAddParticipantToBootcamp from './../pages-deprecated/admin-add-participant-to-bootcamp/AddParticipantToBootcamp'
import DeprecatedBootcampDetailsPage from './../pages-deprecated/admin-bootcamp-details/BootcampDetailsPage'
import DeprecatedEditCurriculum from './../pages-deprecated/admin-edit-curriculum/EditCurriculum'
import DeprecatedInviteUsers from './../pages-deprecated/admin-invite-users/InviteUsers'
import DeprecatedPartnerDetails from './../pages-deprecated/admin-partner-details/PartnerDetails'

export const AppRoutes = () => {
  const { person } = useUser()

  if (!person) {
    return null
  }

  const { personType } = person

  const getDashboardPage = () => {
    if (personType === PersonTypeEnum.STUDENT) {
      return <PageDashboardStudent />
    }
    if (personType === PersonTypeEnum.PARTNER_MEMBER) {
      return <PageDashboardPartner />
    }
    // remaining group: COACH, PARTNER_MANAGEMENT, STUDENT_SERVICE
    // (some of which may have admin authority)
    return <PageDashboardInternal />
  }

  return (
    <Routes>
      <Route path="/" element={<ContentLayout disablePadding />}>
        <Route index element={getDashboardPage()} />

        <Route path="profile/:id" element={<PageProfile />} />

        <Route path="talents" element={<PageSearch />} />

        <Route path="companies" element={<PageCompanies />} />

        <Route path="company/:id" element={<PageCompanyDetails />} />
      </Route>

      <Route path="/settings" element={<ContentLayout />}>
        <Route index element={<PageSettings />} />
      </Route>

      <Route element={<RequirePermissions roles={['partner-management']} />}>
        <Route element={<ContentLayout />}>
          <Route path="keyword-filter" element={<KeywordFilterPage />} />
        </Route>
      </Route>
      <Route element={<RequirePermissions roles={['admin']} />}>
        <Route path="/admin" element={<ContentLayout />}>
          <Route path="users" element={<DeprecatedInviteUsers />} />
          <Route path="bootcamps" element={<PageBootcamps />} />
          <Route path="edit-curriculum" element={<EditDefaultCurriculum />} />
          <Route path="keyword-filter" element={<KeywordFilterPage />} />

          <Route
            path="bootcamp/:id"
            element={<DeprecatedBootcampDetailsPage />}
          />

          <Route
            path="bootcamp/:id/add-participants"
            element={<DeprecatedAddParticipantToBootcamp />}
          />

          <Route
            path="bootcamp/:id/edit-curriculum"
            element={<DeprecatedEditCurriculum />}
          />

          <Route path="partners" element={<PagePartners />} />

          <Route path="partner/:id" element={<DeprecatedPartnerDetails />} />

          <Route path="partnerActivity" element={<PageContactTracing />} />
        </Route>
      </Route>

      <Route element={<RequirePermissions roles={['partner-member']} />}>
        <Route element={<ContentLayout disablePadding />}>
          <Route path="/contacts" element={<ContactsPage />} />
        </Route>
        <Route element={<ContentLayout disablePadding />}>
          <Route path="/keyword-filter" element={<KeywordFilterPage />} />
        </Route>
      </Route>

      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}
