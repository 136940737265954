import { DotsMenu } from '../../../common-deprecated/icons'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useSetAdmin } from '../../../graphql/person/useSetAdmin'
import { toast } from 'react-toastify'
import { useDeletePerson } from '../../../graphql/person/useDeletePerson'
import { FC, useState } from 'react'
import ConfirmDialog from '../../../common-deprecated/components/ConfirmDialog'
import { Button, styled } from '@mui/material'
import { BootcampParticipation } from '../../../graphql/graphqlTypes'
import { useNavigate } from 'react-router-dom'

type Props = {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  id: string
  showMenu: boolean
  setShowMenu: (isOpen: boolean) => void
  isAdmin?: boolean | null
  participates: BootcampParticipation[]
}

const PersonItemMenu: FC<Props> = ({
  firstName,
  lastName,
  email,
  id,
  showMenu,
  setShowMenu,
  isAdmin,
  participates,
}) => {
  const navigate = useNavigate()
  const { setAdminRights } = useSetAdmin({
    onCompleted: data => {
      toast.success(
        `${firstName} ${lastName} ${
          data.setIsAdmin.isAdmin ? 'is admin now' : 'is no admin anymore'
        }`
      )
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })
  const { deletePerson, loading: deleteLoading } = useDeletePerson({
    onCompleted: () => toast.success(`Person deleted`),
    onError: error => {
      setShowDeletePerson(false)
      toast.error(`${error.message} 🙀`)
    },
  })
  const [showDeletePerson, setShowDeletePerson] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setShowMenu(false) })

  return (
    <>
      <MenuWrapper onClick={() => setShowMenu(!showMenu)} ref={ref}>
        <DotsMenu />
        {showMenu && (
          <Menu>
            {isAdmin ? (
              <ActionButton
                variant="text"
                onClick={() => setAdminRights({ id, isAdmin: false })}
              >
                Remove Admin
              </ActionButton>
            ) : (
              <ActionButton
                variant="text"
                onClick={() => setAdminRights({ id, isAdmin: true })}
              >
                Make Admin
              </ActionButton>
            )}

            <ActionButton
              variant="text"
              onClick={() => setShowDeletePerson(true)}
            >
              Delete User
            </ActionButton>
            {participates.map(p => (
              <ActionButton
                variant="text"
                onClick={() => navigate(`/admin/bootcamp/${p.bootcamp.id}`)}
              >
                Go to {p.bootcamp.id}
              </ActionButton>
            ))}
          </Menu>
        )}
      </MenuWrapper>
      {showDeletePerson && id && (
        <ConfirmDialog
          title="Delete Person?"
          description={`Sure you want to delete Person: ${firstName || '-'} ${
            lastName || '-'
          } ${email}`}
          loading={deleteLoading}
          onConfirm={() => deletePerson(id)}
          onAbort={() => setShowDeletePerson(false)}
        />
      )}
    </>
  )
}

export default PersonItemMenu

const MenuWrapper = styled('div')`
  margin: 0 10px;
  cursor: pointer;
  position: relative;
`

const Menu = styled('div')`
  padding: 10px;
  z-index: 2;
  overflow-y: auto;
  width: 175px;
  top: -10px;
  right: 25px;
  cursor: default;
  position: absolute;
  background-color: white;
  border: 2px solid var(--accent);
  display: grid;
  grid-gap: 10px;
`
const ActionButton = styled(Button)`
  padding: 4px;
  margin-left: var(--spacing-xs);

  :hover {
    color: white;
    background-color: var(--accent);
  }
`
