import { gql } from '@apollo/client'

export const SET_NAME = gql`
  mutation SetName($teamId: String!, $name: String!) {
    setTeamName(teamId: $teamId, name: $name) {
      id
      name
      participants {
        bootcamp {
          id
        }
        person {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const ADD_TEAM = gql`
  mutation AddTeam($bootcampId: String!) {
    addTeam(bootcampId: $bootcampId) {
      id
      name
    }
  }
`

export const DELETE_TEAM = gql`
  mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId)
  }
`

export const ADD_TO_TEAM = gql`
  mutation AddParticipantToTeam(
    $personId: String!
    $bootcampId: String!
    $teamId: String!
  ) {
    addParticipantToTeam(
      personId: $personId
      bootcampId: $bootcampId
      teamId: $teamId
    )
  }
`

export const REMOVE_FROM_TEAM = gql`
  mutation RemoveParticipantFromTeam(
    $personId: String!
    $bootcampId: String!
    $teamId: String!
  ) {
    removeParticipantFromTeam(
      personId: $personId
      bootcampId: $bootcampId
      teamId: $teamId
    )
  }
`
