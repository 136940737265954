import { ReactNode } from 'react'
import create from 'zustand'

export interface ModalStoreModel {
  modal: boolean
  modalContent: ReactNode[]
  setModal(modal: boolean): void
  setModalContent(data: ReactNode): void
}

export const useModalStore = create<ModalStoreModel>(set => ({
  modal: false,
  setModal(modal) {
    set(state => {
      if (!modal) {
        if (state.modalContent.length) {
          return {
            modalContent: state.modalContent.slice(0, -1),
          }
        }
        setTimeout(() => set({ modalContent: [] }), 300)
        return { modal }
      }
      return { modal }
    })
  },
  modalContent: [],
  setModalContent: modalContent => {
    set(state => {
      return {
        modalContent: [...state.modalContent, modalContent],
      }
    })
  },
}))
