import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Person } from '../../../../../graphql/graphqlTypes'
import { useSetPersonSkills } from '../../../../../graphql/person/useSetPersonSkills'
import { useGetSkillsSuggestions } from '../../../../../graphql/skills/useGetSkillsSuggestions'
import { Plus } from '../../../../assets/icons'
import { useModalStore } from '../../../../stores/modalStore'
import { InfoBox } from '../../../atoms/InfoBox'
import { TagsSortable } from '../../../molecules/Tags'
import { ProfileForm } from './ProfileForm'

export interface FormProfileSkillsProps {
  skills: Person['skills']
  id: string
}

export const FormProfileSkills = ({ skills, id }: FormProfileSkillsProps) => {
  const setModal = useModalStore(state => state.setModal)

  const [newSkills, setNewSkills] = useState(skills)

  const { control, handleSubmit, reset, formState } = useForm()
  const { field: fieldSkill, fieldState: fieldSkillState } = useController({
    name: 'skill',
    control,
    rules: {
      required: true,
      validate: value =>
        !newSkills.includes(value) || 'This skill is already in your list.',
    },
    defaultValue: '',
  })

  const handleAddSkill = useCallback(
    async (data: Record<string, string>) => {
      if (!data.skill.trim()) {
        reset()
        return
      }
      setNewSkills(oldSkills => [...oldSkills, data.skill])
      reset()
    },
    [reset]
  )

  const handleDeleteSkill = useCallback((skill: string) => {
    setNewSkills(oldSkills => oldSkills.filter(s => s !== skill))
  }, [])

  const onMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setNewSkills(oldSkills =>
      update(oldSkills, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, oldSkills[dragIndex]],
        ],
      })
    )
  }, [])

  const { setPersonSkills, loading } = useSetPersonSkills({
    onCompleted: () => {
      setModal(false)
      toast.success('Skills updated')
    },
    onError: () => toast.error('Error updating skills'),
  })

  const clear = useCallback(() => {
    reset()
    setModal(false)
  }, [reset, setModal])

  const submit = useCallback(() => {
    setPersonSkills({
      personId: id,
      skills: newSkills,
    })
  }, [id, newSkills, setPersonSkills])

  const { skillsSuggestions } = useGetSkillsSuggestions(
    fieldSkill.value,
    0,
    newSkills
  )

  return (
    <ProfileForm
      title="Skills"
      onCancel={clear}
      onSubmit={submit}
      loading={loading}
      formState={formState}
    >
      <Stack spacing={8} flexGrow={1}>
        <InfoBox>
          Enter your skills, both technical- and soft skills, as well as any
          ability you want to let people know about.
          <br />
          <br />
          They will be shown on your profile.
        </InfoBox>

        <Box
          component="form"
          onSubmit={handleSubmit(handleAddSkill)}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Autocomplete
            freeSolo
            disablePortal
            popupIcon=""
            options={skillsSuggestions}
            getOptionLabel={s => (s.name ? `${s.name} (${s.count})` : s ?? '')}
            inputValue={fieldSkill.value}
            onInputChange={(event, newValue) => {
              if (fieldSkill.value === newValue) {
                return
              }
              fieldSkill.onChange(newValue ?? '')
            }}
            value={fieldSkill.value}
            onChange={(event, newValue) => {
              if (!newValue) {
                return
              }
              handleAddSkill({ skill: newValue.name })
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Add skill"
                {...fieldSkill}
                name="skill"
                error={!!fieldSkillState.error}
                helperText={fieldSkillState.error?.message}
                onKeyUp={e => {
                  if (e.code === 'Enter') {
                    handleSubmit(handleAddSkill)
                  }
                }}
              />
            )}
          />
          <IconButton
            sx={{
              width: 48,
              height: 48,
            }}
            color="inherit"
            type="button"
            onClick={() => handleAddSkill({ skill: fieldSkill.value })}
          >
            <Plus />
          </IconButton>
        </Box>

        <Stack spacing={4}>
          <Typography variant="body2">
            Drag and drop to show your favorite skills first. Or delete unwanted
            skills.
          </Typography>

          <TagsSortable
            tags={newSkills}
            onDelete={handleDeleteSkill}
            onMove={onMove}
          />
        </Stack>
      </Stack>
    </ProfileForm>
  )
}
