import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import { CapstoneProject, Device } from '../../../../graphql/graphqlTypes'
import { getPersonName } from '../../../../service/profile/person-service'
import { RightArrow as IconRightArrow } from '../../../assets/icons'
import { CardLink } from '../../atoms/CardLink'
import { CapstoneImageView } from '../CapstoneImage'

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}))

export type CapstoneTeaserProps = Pick<
  CapstoneProject,
  'name' | 'description' | 'image' | 'device' | 'person'
>

export const CapstoneTeaser = ({
  name,
  description,
  image,
  device = 'SMARTPHONE' as Device,
  person,
}: CapstoneTeaserProps) => {
  return (
    <Grid>
      <Box padding={image ? 4 : 0}>
        <CapstoneImageView
          device={device as Device}
          src={image?.urls.l}
          srcXl={image?.urls.xl}
        />
      </Box>
      <Stack spacing={6}>
        <Stack spacing={4}>
          {name && <Typography variant="body2Strong">{name}</Typography>}
          {description && (
            <Typography variant="body2">{description}</Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center">
          <CardLink href={`/profile/${person.id}`} target="_blank">
            <Card
              sx={[
                {
                  height: '100%',
                  borderRadius: 2,
                },
              ]}
            >
              <CardHeader
                sx={{ padding: 6, gap: 4, alignItems: 'center' }}
                action={<IconRightArrow fontSize={18} />}
                avatar={
                  <Box sx={{ position: 'relative' }}>
                    <Avatar
                      src={person.avatar?.urls.m}
                      variant="rounded-small"
                    />
                  </Box>
                }
                title={
                  <Typography variant="body2Strong">
                    {getPersonName(person)}
                  </Typography>
                }
              />
            </Card>
          </CardLink>
        </Stack>
      </Stack>
    </Grid>
  )
}
