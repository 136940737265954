import { FC } from 'react'
import { Svg, SvgProps } from './Svg'

const Search: FC<SvgProps> = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <title id="title">Search</title>
    <desc id="description">
      Magnifiying glass to indicate searching something.
    </desc>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(4 4)"
    >
      <circle cx="7.083" cy="7.083" r="7.083" />
      <path d="M17 17L12.278 12.278" />
    </g>
  </Svg>
)

export default Search
