import { useState } from 'react'

import {
  PersonTypeEnum,
  QueryListPersonsArgs,
} from '../../graphql/graphqlTypes'
import { useListPrivatePersons } from '../../graphql/person/useListPrivatePersons'

const initialListParameters = {
  filter: {
    search: '',
  },
  pagination: {
    page: 0,
    size: 20,
  },
}

export const usePersons = () => {
  const [requestParams, setRequestParams] = useState<QueryListPersonsArgs>(
    initialListParameters
  )

  const setSize = (size: number) =>
    setRequestParams({
      ...requestParams,
      pagination: { page: 0, size },
    })

  const setPersonType = (personType?: PersonTypeEnum) =>
    setRequestParams({
      ...requestParams,
      filter: { ...requestParams.filter, personType },
      pagination: {
        page: 0,
        size: requestParams.pagination?.size || 20,
      },
    })

  const setBootcampParticipant = (bootcampParticipant?: boolean) =>
    setRequestParams({
      ...requestParams,
      filter: { ...requestParams.filter, bootcampParticipant },
      pagination: {
        page: 0,
        size: requestParams.pagination?.size || 20,
      },
    })

  const response = useListPrivatePersons(requestParams)

  const setSearch = (search: string) => {
    if (requestParams.filter?.search === search) {
      return
    }
    const variables = {
      ...requestParams,
      filter: { ...requestParams.filter, search },
      pagination: {
        page: 0,
        size: requestParams.pagination?.size || 20,
      },
    }
    setRequestParams(variables)
  }

  const setPage = (page: number) => {
    if (page !== response.currentPage) {
      setRequestParams({
        ...requestParams,
        pagination: {
          page,
          size: requestParams.pagination?.size || 20,
        },
      })
    }
  }

  return {
    setSize,
    setPersonType,
    setSearch,
    setBootcampParticipant,
    ...requestParams,
    ...response,
    setPage,
  }
}
