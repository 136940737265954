import createTheme from '@mui/material/styles/createTheme'

import { components } from './mui/components'
import { palette } from './mui/palette'
import { muiDefaultTheme, spacing } from './mui/theme'
import { typography } from './mui/typography'

export const light = createTheme({
  palette,
  breakpoints: {
    values: muiDefaultTheme.breakpoints.values,
  },
  components,
  typography,
  spacing,
})
