import { Button, styled } from '@mui/material'
import { FC } from 'react'

const DialogContainer = styled('section')`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #fff;
  width: 400px;
  margin-left: -200px;
  margin-top: -200px;
  padding: var(--spacing-l);
  border-radius: 20px;
  text-align: center;
  z-index: 15;
`

const BackgroundOverlay = styled('section')`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

const ButtonPanel = styled('section')`
  display: flex;
  justify-content: space-between;
`

type ConfirmDialogProps = {
  title: string
  description: string
  onConfirm: () => void
  onAbort: () => void
  loading?: boolean
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  description,
  onConfirm,
  onAbort,
  loading,
}) => {
  return (
    <>
      <BackgroundOverlay onClick={onAbort} />
      <DialogContainer>
        <h1>{title}</h1>
        <p>{description}</p>
        <ButtonPanel>
          <Button disabled={loading} color="secondary" onClick={onAbort}>
            Abort
          </Button>
          <Button disabled={loading} onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonPanel>
      </DialogContainer>
    </>
  )
}

export default ConfirmDialog
