import { FC } from 'react'
import { Svg, SvgProps } from './Svg'

const Close: FC<SvgProps> = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <defs>
      <filter
        id="prefix__a"
        width="113.5%"
        height="235%"
        x="-6.8%"
        y="-67.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.458823529 0 0 0 0 0.537254902 0 0 0 0 0.635294118 0 0 0 0.4 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      filter="url(#prefix__a)"
      transform="translate(-356 -8)"
    >
      <g>
        <path d="M12 0L0 12M0 0L12 12" transform="translate(362 14)" />
      </g>
    </g>
  </Svg>
)

export default Close
