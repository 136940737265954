import { IconButton, Stack, styled, Typography } from '@mui/material'
import { format } from 'date-fns'

import type { AlgoliaBootcampHit } from '../../../../types/algolia.d'
import { PenEdit } from '../../../assets/icons'

const BootcampSearchHitContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  columnGap: 16,
  rowGap: 24,

  [theme.breakpoints.down('lg')]: {
    '> time': {
      order: 2,
      columnSpan: 'all',
    },
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '5fr 3fr 4fr 5fr 1fr',
  },
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,

  [theme.breakpoints.up('lg')]: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

export const BootcampSearchHit = ({
  objectID,
  name,
  track,
  location,
  startDate,
  endDate,
  language,
  headCoach,
}: AlgoliaBootcampHit) => {
  return (
    <BootcampSearchHitContainer>
      <Stack
        spacing={1}
        sx={theme => ({
          [theme.breakpoints.down('lg')]: {
            gridColumn: '1/-1',
          },
        })}
      >
        <Typography variant="body2Strong" textTransform="uppercase">
          {name}
        </Typography>
        <Typography variant="body2" color="colors.grey">
          {track}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography>{location}</Typography>
        <Typography>{language}</Typography>
      </Stack>

      <Stack spacing={1} textAlign={{ xs: 'left', lg: 'left' }}>
        <Typography
          component="time"
          sx={{
            fontVariantNumeric: 'tabular-nums',
            whiteSpace: 'nowrap',
            '::before': {
              display: 'inline-block',
              content: '"Start: "',
              color: 'colors.grey',
              width: '3.25em',
            },
          }}
        >
          {format(new Date(startDate), 'dd/MM/yyyy')}
        </Typography>
        <Typography
          component="time"
          sx={{
            fontVariantNumeric: 'tabular-nums',
            whiteSpace: 'nowrap',
            '::before': {
              display: 'inline-block',
              content: '"End: "',
              color: 'colors.grey',
              width: '3.25em',
            },
          }}
        >
          {format(new Date(endDate), 'dd/MM/yyyy')}
        </Typography>
      </Stack>

      <Stack
        spacing={1}
        sx={theme => ({
          [theme.breakpoints.down('lg')]: {
            gridColumn: '1/-1',
          },
        })}
      >
        {headCoach && (
          <>
            <Typography color="colors.grey">Lead coach:</Typography>
            <Typography>{headCoach}</Typography>
          </>
        )}
      </Stack>

      <ButtonWrapper>
        <IconButton color="inherit" href={`/admin/bootcamp/${objectID}`}>
          <PenEdit />
        </IconButton>
      </ButtonWrapper>
    </BootcampSearchHitContainer>
  )
}
