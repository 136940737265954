import type {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'
import { useConnector } from 'react-instantsearch-hooks-web'

export type UseStatsProps = StatsConnectorParams

export function useStats(props?: UseStatsProps) {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(
    connectStats,
    props
  )
}
