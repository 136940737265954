import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

const SET_PERSON_EMail = gql`
  mutation SetPersonEMail($id: String!, $email: String!) {
    setPersonEMailAddress(id: $id, email: $email) {
      id
      email
    }
  }
`

export const useSetPersonEMail = (options?: BaseMutationOptions) => {
  const [changeEMail, result] = useMutation(SET_PERSON_EMail, options)

  const setPersonEMail = (id: string, email: string) =>
    changeEMail({
      variables: {
        id,
        email,
      },
    })

  return { ...result, setPersonEMail }
}
