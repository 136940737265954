import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { trimCurriculumEntryTitles } from '../../pages-deprecated/admin-edit-curriculum/curriculum-service'
import {
  CurriculumDtoInput,
  LanguageCode,
  MutationUpdateDefaultCurriculumArgs,
} from '../graphqlTypes'

const UPDATE_DEFAULT_BOOTCAMP_CURRICULUM = gql`
  mutation UpdateDefaultBootcampCurriculum(
    $trackId: String!
    $language: LanguageCode!
    $curriculumDto: CurriculumDtoInput!
  ) {
    updateDefaultCurriculum(
      language: $language
      trackId: $trackId
      curriculumDto: $curriculumDto
    ) {
      id
      curriculumColumns {
        curriculumEntries {
          title
          level
        }
      }
    }
  }
`

export const useUpdateDefaultCurriculum = (options: BaseMutationOptions) => {
  const [updateDefaultCurriculumMutation, status] = useMutation(
    UPDATE_DEFAULT_BOOTCAMP_CURRICULUM,
    options
  )

  const updateDefaultBootcampCurriculum = (
    variables: MutationUpdateDefaultCurriculumArgs
  ) =>
    updateDefaultCurriculumMutation({
      variables,
    })

  const updateDefaultCurriculumEntry = (
    trackId: string,
    language: LanguageCode,
    curriculumDto: CurriculumDtoInput
  ) => {
    const trimmedCurriculum = trimCurriculumEntryTitles(curriculumDto)
    updateDefaultBootcampCurriculum({
      trackId,
      language,
      curriculumDto: trimmedCurriculum,
    })
  }

  return { updateDefaultCurriculumEntry, ...status }
}
