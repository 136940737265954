import { MenuItem, Stack, TextField } from '@mui/material'
import { format, isAfter, isBefore } from 'date-fns'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import { toast } from 'react-toastify'

import { useListBootcampTracks } from '../../../../graphql/bootcamp/listBootcampTracks'
import { useListLocations } from '../../../../graphql/bootcamp/listLocations'
import { useBootcampManagement } from '../../../../graphql/bootcamp/manageBootcampService'
import {
  LanguageCode,
  MutationAddBootcampArgs,
} from '../../../../graphql/graphqlTypes'
import { languageOptions } from '../../../../service/language-service'
import { DatePicker } from '../../atoms/DatePicker'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

type FormValues = MutationAddBootcampArgs

export const FormAddBootcamp = ({
  onCancel,
  onSuccess,
}: {
  onCancel: () => void
  onSuccess: () => void
}) => {
  const { refresh } = useInstantSearch()
  const { tracks } = useListBootcampTracks()
  const { locations } = useListLocations()

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      id: '',
      trackId: '',
      locationId: '',
      language: LanguageCode.DE,
      startDate: '',
      endDate: '',
    },
    mode: 'onBlur',
  })

  const { field: fieldId, fieldState: fieldIdState } = useController({
    name: 'id',
    control,
    rules: {
      required: `Please choose a bootcamp id`,
      validate: {
        pattern: value =>
          !!value.match(/^[a-zA-Z0-9](-?[a-zA-Z0-9])*$/) ||
          'use a slug-like format',
        length: value =>
          value.length >= 6 || 'needs to be at least 6 characters',
      },
    },
  })

  const { field: fieldTrack, fieldState: fieldTrackState } = useController({
    name: 'trackId',
    control,
    rules: {
      required: `Please choose a track`,
    },
  })

  const {
    field: fieldLocation,
    fieldState: fieldLocationState,
  } = useController({
    name: 'locationId',
    control,
    rules: {
      required: `Please choose a location`,
    },
  })

  const {
    field: fieldLanguage,
    fieldState: fieldLanguageState,
  } = useController({
    name: 'language',
    control,
    rules: {
      required: true,
    },
  })

  const {
    field: fieldStartDate,
    fieldState: fieldStartDateState,
  } = useController({
    name: 'startDate',
    control,
    rules: {
      required: true,
    },
  })

  const { field: fieldEndDate, fieldState: fieldEndDateState } = useController({
    name: 'endDate',
    control,
    rules: {
      required: true,
    },
  })

  const { addBootcamp, loading } = useBootcampManagement({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      toast.success('Bootcamp successfully created 🎉')
      onSuccess()
      refresh()
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      if (data) {
        const mappedData = { ...data }
        if (data.startDate) {
          mappedData.startDate = format(new Date(data.startDate), 'yyyy-MM-dd')
        }
        if (data.endDate) {
          mappedData.endDate = format(new Date(data.endDate), 'yyyy-MM-dd')
        }
        addBootcamp(mappedData)
      }
    },
    [addBootcamp]
  )

  return (
    <ProfileForm
      title="Add new bootcamp"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
      submitText="Create"
      loading={loading}
    >
      <Stack component="form" spacing={12}>
        <Stack spacing={4}>
          <TextField
            label="ID (e.g. “cgn-java-21-2”)"
            {...fieldId}
            error={!!fieldIdState.error}
            helperText={fieldIdState.error?.message}
          />

          <TextField
            label="Track"
            {...fieldTrack}
            error={!!fieldTrackState.error}
            helperText={fieldTrackState.error?.message}
            select
          >
            {tracks.map(track => (
              <MenuItem
                key={track.id}
                value={track.id}
                selected={track.id === fieldTrack.value}
              >
                {track.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Location"
            {...fieldLocation}
            error={!!fieldLocationState.error}
            helperText={fieldLocationState.error?.message}
            select
          >
            {locations.map(location => (
              <MenuItem
                key={location.id}
                value={location.id}
                selected={location.id === fieldLocation.value}
              >
                {location.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Language"
            {...fieldLanguage}
            error={!!fieldLanguageState.error}
            helperText={fieldLanguageState.error?.message}
            select
          >
            {languageOptions.map(language => (
              <MenuItem
                key={language.id}
                value={language.id}
                selected={language.id === fieldLanguage.value}
              >
                {language.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack spacing={4}>
          <DatePicker
            label="Start date"
            {...fieldStartDate}
            error={!!fieldEndDateState.error}
            helperText={fieldStartDateState.error?.message}
            shouldDisableDate={day =>
              !!fieldEndDate.value && isAfter(day, new Date(fieldEndDate.value))
            }
          />

          <DatePicker
            label="End date"
            {...fieldEndDate}
            error={!!fieldEndDateState.error}
            helperText={fieldEndDateState.error?.message}
            shouldDisableDate={day =>
              !!fieldStartDate.value &&
              isBefore(day, new Date(fieldStartDate.value))
            }
          />
        </Stack>
      </Stack>
    </ProfileForm>
  )
}
