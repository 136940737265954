import { useState } from 'react'

import {
  LoginForm,
  ResetPasswordForm,
  SetPasswordForm,
} from '../common/components/organisims/LoginForms'

export const PageLogin = () => {
  const [showPage, setShowPage] = useState<'login' | 'forgotten' | 'validate'>(
    'login'
  )
  const [email, setEmail] = useState('')

  if (showPage === 'forgotten') {
    return (
      <ResetPasswordForm
        onRequestSuccess={email => {
          setEmail(email)
          setShowPage('validate')
        }}
        onCancel={() => setShowPage('login')}
      />
    )
  }

  if (showPage === 'validate') {
    return <SetPasswordForm email={email} codeIsRequired />
  }

  return <LoginForm onForget={() => setShowPage('forgotten')} />
}
