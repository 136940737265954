import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider as MuiDivider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, Fragment, useState } from 'react'

import { useGetFilteredStudents } from '../../../../graphql/partner/useGetStudentsByKeyword'

export const KeywordFilterPage = () => {
  const [input, setInput] = useState('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  const { students } = useGetFilteredStudents({
    keyword: input.toLowerCase(),
  })

  return (
    <Card>
      <CardContent sx={{ rowGap: 10 }}>
        <Typography variant="h5">Student Filter</Typography>

        <TextField
          label="Enter keyword"
          value={input}
          onChange={handleChange}
          sx={{ width: '100%' }}
        />
      </CardContent>
      <Stack spacing={6}>
        <MuiDivider light />

        {students.length ? (
          <>
            {students?.map(student => (
              <Box
                key={student.id}
                sx={theme => ({
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  [theme.breakpoints.up('lg')]: {
                    flexDirection: 'row',
                  },
                })}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Avatar
                    src={student.avatar?.urls.s ?? undefined}
                    variant="rounded"
                    sx={{ height: '60px', width: '60px' }}
                  />
                  <Box>
                    <Typography variant="body1Strong">
                      {student.firstName} {student.lastName}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  sx={theme => ({ [theme.breakpoints.down('lg')]: { mt: 4 } })}
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      `/profile/${student.id}`,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  <Typography noWrap variant="body2Strong">
                    Go to profile
                  </Typography>
                </Button>
              </Box>
            ))}
          </>
        ) : (
          <Box sx={{ display: 'grid', gap: 6, justifyItems: 'center' }}>
            <Typography variant="title2">No results</Typography>
          </Box>
        )}
      </Stack>
    </Card>
  )
}
