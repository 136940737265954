import { ReactNode, useEffect, useState } from 'react'

import { graphqlClient } from '../../graphql/GraphqlClient'
import {
  completeNewPassword,
  getAuthenticatedUser,
  getUserData,
  login,
  logout,
} from '../../service/auth/cognito-auth-service'
import type {
  AuthenticatedUser,
  AuthenticationState,
  CognitoUser,
} from '../../types/auth.d'
import { AuthContext } from './AuthContext'

export const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>()
  const [
    authenticationState,
    setAuthenticationState,
  ] = useState<AuthenticationState>({
    pending: true,
  })

  async function mapAuthenticatedUserToState(data?: AuthenticatedUser) {
    let isAdmin = false
    const authenticated = data != null
    let id = undefined
    if (data) {
      const userData = await getUserData()
      isAdmin = userData.groups?.includes('admin')
      id = userData.id
    }

    return {
      authenticated,
      isAdmin,
      pending: false,
      newPasswordRequired: false,
      id,
    }
  }

  async function completeSetPassword(password: string) {
    const userEMail = cognitoUser?.challengeParam.userAttributes.email
    if (cognitoUser && userEMail) {
      await completeNewPassword(cognitoUser, password).then(() =>
        loginUser(userEMail, password)
      )
    }
  }

  async function loginUser(username: string, password: string) {
    const userData = await login(username, password)

    if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setCognitoUser(userData)
      setAuthenticationState({
        ...authenticationState,
        authenticated: true,
        newPasswordRequired: true,
        pending: false,
      })
    } else {
      return getAuthenticatedUser()
        .then(mapAuthenticatedUserToState)
        .then(data => {
          setAuthenticationState(data)
        })
    }
  }

  function logoutUser() {
    return logout()
      .then(() =>
        setAuthenticationState({
          authenticated: false,
          isAdmin: false,
          pending: false,
          id: undefined,
        })
      )
      .then(() => {
        graphqlClient.clearStore()
      })
  }

  useEffect(() => {
    getAuthenticatedUser()
      .then(mapAuthenticatedUserToState)
      .then(setAuthenticationState)
  }, [])

  const isAuthenticated = !!authenticationState.authenticated
  const isAdmin = !!authenticationState.isAdmin
  const isAuthPending = authenticationState.pending
  const isNewPasswordRequired = !!authenticationState.newPasswordRequired
  const authenticatedUserId = authenticationState.id

  return (
    <AuthContext.Provider
      value={{
        cognitoUser,
        loginUser,
        logoutUser,
        completeSetPassword,
        isAuthenticated,
        isAdmin,
        isAuthPending,
        isNewPasswordRequired,
        authenticatedUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
