import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      marginLeft: 14,

      '&.Mui-error': {
        color: colors.error,
      },
    },
  },
}
