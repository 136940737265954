import { FC, FormEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackLink from '../../common-deprecated/components/BackLink'
import LoadingIndicator from '../../common-deprecated/components/LoadingIndicator'
import { useGetBootcampCurriculum } from '../../graphql/curriculum/getBootcampCurriculumService'
import { toast } from 'react-toastify'
import TextAreaWithLabel from '../../common-deprecated/components/TextAreaWithLabel'
import { CurriculumColumn } from '../../graphql/graphqlTypes'
import { useUpdateCurriculum } from '../../graphql/curriculum/updateCurriculumService'
import {
  mapInputToDto,
  mapToText,
  removeTypenameFromCurriculumColumn,
} from './curriculum-service'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { Button, styled } from '@mui/material'

const EditCurriculum: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { curriculum } = useGetBootcampCurriculum(id!)
  const [curriculumContent, setCurriculumContent] = useState<
    CurriculumColumn[]
  >([])

  const { updateCurriculumEntry, loading } = useUpdateCurriculum({
    onCompleted: () => toast.success(`Bootcamp edited`),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  useEffect(() => {
    if (curriculum) {
      setCurriculumContent(
        curriculum.curriculumColumns.map(removeTypenameFromCurriculumColumn)
      )
    }
  }, [curriculum])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    updateCurriculumEntry(curriculum!.id, {
      curriculumColumns: curriculumContent,
    })
  }

  const handleChange = (
    event: {
      target: HTMLTextAreaElement
    },
    toUpdateIndex: number
  ) => {
    const lines = event.target.value.split(/\r?\n|\r/)
    const updatedEntries = lines.map(input => mapInputToDto(input))

    const updatedCurriculum = curriculumContent.map((curriculum, index) =>
      index === toUpdateIndex
        ? { curriculumEntries: updatedEntries }
        : curriculum
    )
    setCurriculumContent(updatedCurriculum)
  }

  return (
    <Wrapper>
      <DeprecatedGlobalStyles />
      {!curriculum ? (
        <LoadingIndicator />
      ) : (
        <>
          <BackLink
            to={`/admin/bootcamp/${curriculum.id}`}
            title={`Back to ${curriculum.id} overview`}
          />
          <h1>Edit curriculum of {curriculum.id}</h1>
          <Grid>
            {curriculumContent?.map((curriculumColumn, index) => (
              <TextAreaWithLabel
                key={index}
                onChange={event => handleChange(event, index)}
                value={mapToText(curriculumColumn.curriculumEntries)}
                rows={25}
                labelText={`Column ${index + 1}`}
                disabled={loading}
              />
            ))}
          </Grid>
        </>
      )}

      <ButtonElement onClick={handleSubmit} type="submit" disabled={loading}>
        Save
      </ButtonElement>
    </Wrapper>
  )
}

const ButtonElement = styled(Button)`
  justify-self: end;
`

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
`

const Wrapper = styled('form')`
  display: grid;
  align-content: start;
  grid-gap: var(--spacing-m);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);
  overflow-y: auto;

  h1 {
    text-align: center;
    margin: 0;
  }
`

export default EditCurriculum
