import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiCard: Components['MuiCard'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      backgroundColor: colors.foam,
      borderRadius: `${12 / 16}rem`,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      '.MuiCardContent-root:last-child': {
        flexGrow: '1',
      },
    },
  },
}
