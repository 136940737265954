import { useState } from 'react'
import { MutationAddBootcampArgs } from '../../../graphql/graphqlTypes'

type BootcampValidation = {
  idErrorMessage: string
  dateErrorMessage: string
}

const initialFormValidation: BootcampValidation = {
  idErrorMessage: '',
  dateErrorMessage: '',
}

export default function useBootcampValidation(
  bootcampToAdd: MutationAddBootcampArgs
) {
  const [formValidation, setFormValidation] = useState(initialFormValidation)
  const isValidInput =
    !formValidation.idErrorMessage &&
    !formValidation.dateErrorMessage &&
    bootcampToAdd.id !== ''

  const handleValidation = (event: {
    target: HTMLInputElement | HTMLSelectElement
  }) => {
    switch (event.target.name) {
      case 'id':
        setFormValidation({
          ...formValidation,
          idErrorMessage:
            bootcampToAdd.id.length > 5
              ? ''
              : 'bootcamp ID must have a minimum of 6 characters',
        })
        break
      case 'startDate':
        setFormValidation({
          ...formValidation,
          dateErrorMessage:
            bootcampToAdd.endDate.length < 1 ||
            bootcampToAdd.startDate < bootcampToAdd.endDate
              ? ''
              : 'start date must be before end date',
        })
        break
      case 'endDate':
        setFormValidation({
          ...formValidation,
          dateErrorMessage:
            bootcampToAdd.startDate.length < 1 ||
            bootcampToAdd.startDate < bootcampToAdd.endDate
              ? ''
              : 'start date must be before end date',
        })
    }
  }

  return { formValidation, handleValidation, isValidInput }
}
