import { Button } from '@neuefische/design-system'
import { Partner } from '../../../graphql/graphqlTypes'
import { useState } from 'react'
import ConfirmDialog from '../../../common-deprecated/components/ConfirmDialog'
import useDeletePartner from '../../../graphql/partner/useDeletePartner'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

type Props = {
  partner: Partner
}

export default function DeletePartner({ partner }: Props) {
  const navigate = useNavigate()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const toggleConfirmDialog = () =>
    setConfirmDialogOpen(confirmDialogOpen => !confirmDialogOpen)

  const { deletePartner, loading } = useDeletePartner({
    onError: error => toast.error(`${error.message} 🙀`),
    onCompleted: () => toast.success(`Successfully deleted ${partner.name}`),
  })

  const handleDelete = () => {
    deletePartner(partner.id).then(() => navigate('/admin/partners'))
  }

  const title = `Delete ${partner.name}`

  return (
    <section>
      <Button variant="primary" label={title} onClick={toggleConfirmDialog} />
      {confirmDialogOpen && (
        <ConfirmDialog
          title={title}
          description={`Permanently delete "${partner.name}" and all of its members?`}
          loading={loading}
          onConfirm={handleDelete}
          onAbort={toggleConfirmDialog}
        />
      )}
    </section>
  )
}
