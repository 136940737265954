import {
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  MutationUpdatePartnerArgs,
  Partner,
} from '../../../../graphql/graphqlTypes'
import usePartnerDetails from '../../../../graphql/partner/usePartnerDetails'
import useUpdatePartner from '../../../../graphql/partner/useUpdatePartner'
import {
  PartnerRevenueCategories,
  PartnerSizes,
} from '../../../../types/graphql.d'
import { useModalStore } from '../../../stores/modalStore'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

type FormValues = Pick<
  MutationUpdatePartnerArgs,
  | 'yearOfEstablishment'
  | 'industry'
  | 'size'
  | 'revenueCategory'
  | 'address'
  | 'city'
  | 'postalCode'
>

type FormPartnerType = {
  onCancel: () => void
  onSuccess: () => void
}

const FormPartnerInfoForm = ({
  onCancel,
  onSuccess,
  partner,
}: FormPartnerType & {
  partner: Partner
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      industry: partner.industry || '',
      yearOfEstablishment: partner.yearOfEstablishment || null,
      size: partner.size || '',
      revenueCategory: partner.revenueCategory || '',
      address: partner.address || '',
      city: partner.city || '',
      postalCode: partner.postalCode || '',
    },
  })

  const {
    field: fieldYearOfEstablishment,
    fieldState: fieldYearOfEstablishmentState,
  } = useController({
    name: 'yearOfEstablishment',
    control,
  })

  const {
    field: fieldIndustry,
    fieldState: fieldIndustryState,
  } = useController({
    name: 'industry',
    control,
  })

  const { field: fieldSize, fieldState: fieldSizeState } = useController({
    name: 'size',
    control,
  })

  const {
    field: fieldRevenueCategory,
    fieldState: fieldRevenueCategoryState,
  } = useController({
    name: 'revenueCategory',
    control,
  })

  const { field: fieldAddress, fieldState: fieldAddressState } = useController({
    name: 'address',
    control,
  })

  const { field: fieldCity, fieldState: fieldCityState } = useController({
    name: 'city',
    control,
  })

  const {
    field: fieldPostalCode,
    fieldState: fieldPostalCodeState,
  } = useController({
    name: 'postalCode',
    control,
    rules: {
      validate: value =>
        value ? /([0-9]){5}/.test(value) || 'Requires a number of 5' : true,
    },
  })

  const { updatePartner, loading } = useUpdatePartner({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      toast.success('Partner successfully updated 🎉')
      onSuccess()
    },
  })

  const handleCancel = () => {
    onCancel()
  }

  const submit = useCallback(
    (data: FormValues) => {
      if (data) {
        updatePartner({ ...partner, ...data })
      }
    },
    [partner, updatePartner]
  )

  return (
    <ProfileForm
      title="More information"
      onCancel={handleCancel}
      onSubmit={handleSubmit(submit)}
      loading={loading}
    >
      <Stack component="form" spacing={8}>
        <Stack spacing={4}>
          <TextField
            label="Year founded"
            {...fieldYearOfEstablishment}
            value={fieldYearOfEstablishment.value || ''}
            error={!!fieldYearOfEstablishmentState.error}
            helperText={fieldYearOfEstablishmentState.error?.message}
          />

          <TextField
            label="Industry"
            {...fieldIndustry}
            error={!!fieldIndustryState.error}
            helperText={fieldIndustryState.error?.message}
          />

          <TextField
            label="Size"
            {...fieldSize}
            error={!!fieldSizeState.error}
            helperText={fieldSizeState.error?.message}
            select
          >
            <MenuItem value="" selected={fieldSize.value === ''}>
              &nbsp;
            </MenuItem>

            {PartnerSizes.map(size => (
              <MenuItem
                key={size}
                value={size}
                selected={size === fieldSize.value}
              >
                {size}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Revenue"
            {...fieldRevenueCategory}
            error={!!fieldRevenueCategoryState.error}
            helperText={fieldRevenueCategoryState.error?.message}
            select
          >
            <MenuItem value="" selected={fieldRevenueCategory.value === ''}>
              &nbsp;
            </MenuItem>

            {PartnerRevenueCategories.map(category => (
              <MenuItem
                key={category}
                value={category}
                selected={category === fieldRevenueCategory.value}
              >
                {category}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack spacing={4}>
          <Typography color="colors.grey">Location details</Typography>

          <TextField
            label="Address"
            {...fieldAddress}
            error={!!fieldAddressState.error}
            helperText={fieldAddressState.error?.message}
          />

          <TextField
            label="City"
            {...fieldCity}
            error={!!fieldCityState.error}
            helperText={fieldCityState.error?.message}
          />

          <TextField
            label="Postal code"
            {...fieldPostalCode}
            error={!!fieldPostalCodeState.error}
            helperText={fieldPostalCodeState.error?.message}
          />
        </Stack>
      </Stack>
    </ProfileForm>
  )
}

export const FormEditPartnerMoreInfo = ({ id }: { id: string }) => {
  const { partner, loading: partnerLoading } = usePartnerDetails(id)
  const setModal = useModalStore(state => state.setModal)

  if (!id || partnerLoading || !partner?.id) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <FormPartnerInfoForm
      onCancel={() => setModal(false)}
      onSuccess={() => setModal(false)}
      partner={partner}
    />
  )
}
