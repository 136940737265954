import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'

export const MuiSwitch: Components['MuiSwitch'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: `${48 / 16}rem`,
      height: `${24 / 16}rem`,
      borderRadius: `${30 / 16}rem`,
      padding: 0,
      display: 'flex',
      '& .MuiSwitch-switchBase': {
        padding: 4,
        width: `${24 / 16}rem`,
        height: `${24 / 16}rem`,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          '.MuiSwitch-thumb': {
            opacity: 0.6,
            backgroundColor: colors.lightGrey,
          },
          '& + .MuiSwitch-track': {
            opacity: 0.6,
            backgroundColor: colors.water10,
          },
        },
        '&.Mui-checked': {
          transform: `translateX(${24 / 16}rem)`,
          '.MuiSwitch-thumb': {
            backgroundColor: colors.foam,
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: colors.nemo,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        width: `${16 / 16}rem`,
        height: `${16 / 16}rem`,
        backgroundColor: colors.lightGrey,
        borderRadius: '100%',
        boxShadow: 'none',
        transition: muiDefaultTheme.transitions.create('background-color'),
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.water,
      },
      '&:hover .MuiSwitch-track': {
        backgroundColor: colors.water10,
      },
    },
  },
}
