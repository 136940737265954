import { ChangeEvent, useEffect, useState } from 'react'
import { PersonTypeEnum } from '../../../graphql/graphqlTypes'
import Select from '../../../common-deprecated/components/Select'
import InputWithLabel from '../../../common-deprecated/components/InputWithLabel'
import LoadingIndicator from '../../../common-deprecated/components/LoadingIndicator'
import { initialFormData } from '../service/initialFormData'
import { checkIsFormValid } from '../service/checkFormIsValid'
import { prepareInvitePersonVariables } from '../service/prepareInvitePersonVariables'
import { prepareFormResetData } from '../service/prepareFormResetData'
import useStandingInvitePersonData from '../hooks/useStandingInvitePersonData'
import Message from '../../../common-deprecated/components/Message'
import { useLocation } from 'react-router-dom'
import { Button, styled } from '@mui/material'
import { useInvitePerson } from '../../../graphql/person/useInvitePerson'
import { toast } from 'react-toastify'

export default function InvitePersonForm() {
  const query = new URLSearchParams(useLocation().search)
  const preselectedPartnerId = query.get('partner-id')
  const {
    standingDataLoading,
    partners,
    bootcamps,
    personTypeSelectOptions,
    trainingProvidersSelectOptions,
  } = useStandingInvitePersonData()

  const [formData, setFormData] = useState(initialFormData)

  const { invitePerson, loading: inviteLoading } = useInvitePerson({
    onCompleted: data =>
      toast.success(
        `${data?.invitePerson.firstName} ${data?.invitePerson.lastName} (${data?.invitePerson.email}) invited 🎉`
      ),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  useEffect(() => {
    if (standingDataLoading) {
      return
    }
    setFormData(formData => ({
      ...formData,
      partnerId:
        formData.personType === PersonTypeEnum.PARTNER_MEMBER
          ? partners[0]?.id
          : '',
      bootcampId:
        formData.personType === PersonTypeEnum.STUDENT ? bootcamps[0]?.id : '',
    }))
  }, [formData.personType, standingDataLoading, partners, bootcamps])

  useEffect(() => {
    if (preselectedPartnerId) {
      setFormData({
        ...formData,
        personType: PersonTypeEnum.PARTNER_MEMBER,
        partnerId: preselectedPartnerId,
      })
    }
  }, [preselectedPartnerId])

  if (standingDataLoading) {
    return <LoadingIndicator />
  }

  const isFormValid = checkIsFormValid(formData)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!isFormValid) {
      return
    }
    const dto = prepareInvitePersonVariables(formData)
    invitePerson(dto).then(() => {
      const newFormData = prepareFormResetData(formData)
      setFormData(newFormData)
    })
  }

  const inviteUserDisabled = inviteLoading || !isFormValid

  const handleChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => setFormData({ ...formData, [event.target.name]: event.target.value })

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <InputWithLabel
          srOnly
          labelText="Firstname"
          type="text"
          name="firstName"
          value={formData.firstName}
          placeholder="Enter firstname"
          onChange={handleChange}
        />
        <InputWithLabel
          srOnly
          labelText="Lastname"
          type="text"
          name="lastName"
          value={formData.lastName}
          placeholder="Enter lastname"
          onChange={handleChange}
        />
        <InputWithLabel
          srOnly
          labelText="E-Mail"
          type="text"
          name="email"
          value={formData.email}
          placeholder="Enter E-Mail"
          onChange={handleChange}
        />
        <Select
          srOnly
          labelText="Person Type"
          name="personType"
          onChange={handleChange}
          value={formData.personType}
          options={personTypeSelectOptions}
        />
        {formData.personType === PersonTypeEnum.STUDENT &&
          formData.bootcampId && (
            <Select
              srOnly
              labelText="Bootcamp"
              name="bootcampId"
              onChange={handleChange}
              value={formData.bootcampId}
              options={bootcamps}
            />
          )}
        {formData.personType === PersonTypeEnum.STUDENT &&
          formData.trainingProvider && (
            <Select
              srOnly
              labelText="Training Provider"
              name="trainingProvider"
              onChange={handleChange}
              value={formData.trainingProvider}
              options={trainingProvidersSelectOptions}
            />
          )}

        {formData.personType === PersonTypeEnum.PARTNER_MEMBER &&
          formData.partnerId && (
            <Select
              srOnly
              labelText="Partner"
              name="partnerId"
              onChange={handleChange}
              value={formData.partnerId}
              options={partners}
            />
          )}
      </InputWrapper>
      <Button type="submit" disabled={inviteUserDisabled}>
        Invite User
      </Button>
      {formData.personType === PersonTypeEnum.STUDENT &&
        !formData.bootcampId && (
          <Message variant="error">
            You must create at least one bootcamp before inviting students
          </Message>
        )}
      {formData.personType === PersonTypeEnum.PARTNER_MEMBER &&
        !formData.partnerId && (
          <Message variant="error">
            You must create at least one partner organisation before inviting
            members
          </Message>
        )}
    </Form>
  )
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  min-width: 520px;

  button {
    margin: 10px 0;
    align-self: flex-end;
  }
`

const InputWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-m);

  input {
    width: 100%;
  }

  select {
    width: 100%;
    height: 100%;
  }
`
