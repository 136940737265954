import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiFilledInput: Components['MuiFilledInput'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      paddingLeft: 0,
      borderRadius: 8,
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&.Mui-focused': {
        borderColor: `1px solid ${colors.darkGrey}`,
      },
      '&.Mui-error': {
        border: `1px solid ${colors.error}`,
        color: colors.error,
      },
      '&.Mui-disabled': {
        fill: 'currentcolor',
      },
      '.MuiFilledInput-input': {
        paddingLeft: 20,
      },
      '.MuiInputLabel-root': {
        lineHeight: '1.75em',
        paddingLeft: 12,
        '&.Mui-error': {
          color: colors.error,
        },
      },
      '.MuiSvgIcon-root': {
        color: 'currentcolor',
      },
      '.MuiInputBase-input:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  variants: [
    {
      props: {
        color: 'primary',
      },
      style: {
        backgroundColor: colors.water,
        color: colors.granite,
        border: `1px solid ${colors.water}`,
        '&.Mui-focused': {
          backgroundColor: colors.water10,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.water,
          borderColor: colors.water,
          '.MuiInputBase-input': {
            WebkitTextFillColor: colors.lightGrey,
          },
        },
        '&:hover': {
          backgroundColor: colors.water10,
          borderColor: colors.water10,
        },
        '.MuiInputLabel-root': {
          color: colors.grey,
          '&.Mui-focused': {
            color: colors.granite,
          },
          '&.Mui-disabled': {
            color: colors.lightGrey,
          },
        },
      },
    },
    {
      props: {
        color: 'secondary', // dark mode
      },
      style: {
        backgroundColor: colors.granite,
        color: colors.foam,
        border: `1px solid ${colors.lightGrey}`,
        '&.Mui-focused': {
          backgroundColor: colors.darkGrey,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.granite,
          borderColor: colors.grey,
          '.MuiInputBase-input': {
            WebkitTextFillColor: colors.grey,
          },
        },
        '&:hover': {
          '& .MuiFilledInput-root': {
            backgroundColor: colors.darkGrey,
            borderColor: colors.lightGrey,
          },
        },
        '.MuiInputLabel-root': {
          color: colors.lightGrey,
          '&.Mui-focused': {
            color: colors.foam,
          },
          '&.Mui-disabled': {
            color: colors.grey,
          },
        },
      },
    },
  ],
}
