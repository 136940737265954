import { Box, Stack } from '@mui/material'
import { useMemo } from 'react'

import { TrainingProviderEnum } from '../../../../graphql/graphqlTypes'
import { ReactComponent as IconFish } from '../../../theme/icons/iconFish.svg'
import { ReactComponent as IconSpiced } from '../../../theme/icons/iconSpiced.svg'

export interface ProfileParticipationProps {
  items: string[]
  trainingProvider?: string | null
}

export const ProfileParticipation = ({
  items,
  trainingProvider,
}: ProfileParticipationProps) => {
  const uniqueItems = useMemo(() => [...new Set(items)], [items])

  return (
    <Box marginTop={2}>
      {uniqueItems.map(item => (
        <Stack
          key={item}
          component="span"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {trainingProvider === TrainingProviderEnum.SPICED_ACADEMY ? (
            <Box component={IconSpiced} flexShrink={0} />
          ) : (
            <Box component={IconFish} flexShrink={0} />
          )}
          <span>{item}</span>
        </Stack>
      ))}
    </Box>
  )
}
