import { styled, TextareaAutosize } from '@mui/material'

export default function ExampleCsv() {
  return (
    <>
      <h3>Example CSV file:</h3>
      <ExampleText>
        <StyledTextareaAutosize
          minRows={3}
          value={`firstName,lastName,email,bootcampId,trainingProvider\nJohn,Doe,john.doe@gmail.com,test-bootcamp,NEUE_FISCHE\nJane,Smith,jane.smith@web.de,other-bootcamp,SPICED_ACADEMY`}
          disabled
        />
      </ExampleText>
    </>
  )
}

const ExampleText = styled('div')`
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
`
const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  resize: none;
`
