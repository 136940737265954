import { Dialog, DialogProps } from '@mui/material'

import { useModalStore } from '../../../stores/modalStore'

type ModalProps = Omit<DialogProps, 'open' | 'children' | 'sx'>

export const ModalGlobal = (props: ModalProps) => {
  const modal = useModalStore(state => state.modal)
  const modalContent = useModalStore(state => state.modalContent)

  if (!modalContent.length) {
    return null
  }

  return (
    <>
      {modalContent.map((content, key) => (
        <Dialog open={modal} {...props} key={`modal-${key}`}>
          {content}
        </Dialog>
      ))}
    </>
  )
}
