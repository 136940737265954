import { gql, useQuery } from '@apollo/client'

import { SkillSuggestion } from '../graphqlTypes'

export const SEARCH_FOR_SKILL_SUGGESTIONS = gql`
  query SearchForSuggestions($text: String!, $minCount: Int!) {
    searchForSuggestions(text: $text, minCount: $minCount) {
      name
      count
    }
  }
`

export const useGetSkillsSuggestions = (
  text: string,
  minCount: number,
  selectedSkills: string[]
) => {
  const query = useQuery<{ searchForSuggestions: SkillSuggestion[] }>(
    SEARCH_FOR_SKILL_SUGGESTIONS,
    {
      variables: { text, minCount },
      skip: text.length < 2,
    }
  )

  const suggestions = query.data?.searchForSuggestions || []

  const skillsSuggestions = suggestions.filter(
    skill => !selectedSkills.includes(skill.name)
  )

  return { ...query, skillsSuggestions }
}
