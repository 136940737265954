import { gql, useQuery } from '@apollo/client'

import { CurriculumDto } from '../graphqlTypes'

export const GET_BOOTCAMP_CURRICULUM = gql`
  query GetBootcampCurriculum($id: String!) {
    getBootcampCurriculum(bootcampId: $id) {
      id
      curriculumColumns {
        curriculumEntries {
          title
          level
        }
      }
    }
  }
`

export const useGetBootcampCurriculum = (id: string) => {
  const query = useQuery<{ getBootcampCurriculum: CurriculumDto }>(
    GET_BOOTCAMP_CURRICULUM,
    { variables: { id } }
  )
  const curriculum = query.data?.getBootcampCurriculum
  return { ...query, curriculum }
}
