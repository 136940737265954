import { FC } from 'react'
import { ArrowLeft } from '../icons'
import { Button, styled } from '@mui/material'
import { Link } from 'react-router-dom'

type BackLinkProps = {
  to: string
  title: string
}

const BackLink: FC<BackLinkProps> = ({ to, title }) => (
  <Button variant="text" component={Link} to={to}>
    <BackArrow /> {title}
  </Button>
)

const BackArrow = styled(ArrowLeft)`
  font-size: var(--font-l);
  margin-top: -2px;
`

export default BackLink
