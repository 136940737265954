import {
  Box,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { UseFormSetValue } from 'react-hook-form'
import { toast } from 'react-toastify'

import { CapstoneProject, Device } from '../../../../../graphql/graphqlTypes'
import { uploadImage } from '../../../../../service/image/image-upload-service'
import {
  Ipad,
  Laptop,
  MobileDevices,
  PenEdit,
  TrashDeleteBin,
} from '../../../../assets/icons'
import { UploadOverlay } from '../../../atoms/UploadOverlay'
import { CapstoneImageView } from '../../../molecules/CapstoneImage'
import { CapstoneFormValues } from './FormProfileCapstone'
import { ProfileForm } from './ProfileForm'

const devices: Record<
  Device,
  { label: string; ratio: string; resolution: string; icon: ReactNode }
> = {
  [Device.SMARTPHONE]: {
    label: 'Smartphone',
    ratio: '9∶20',
    resolution: '796 x 1732 / 398 x 867',
    icon: <MobileDevices fontSize={32} />,
  },
  [Device.TABLET_PORTRAIT]: {
    label: 'Tablet (Portrait)',
    ratio: '4:3',
    resolution: '1536 x 2048 / 768 x 1024',
    icon: <Ipad fontSize={32} />,
  },
  [Device.TABLET_LANDSCAPE]: {
    label: 'Tablet (Landscape)',
    ratio: '3:4',
    resolution: '2048 x 1536 / 1024 x 768',
    icon: <Ipad fontSize={32} transform="rotate(-90)" />,
  },
  [Device.DESKTOP]: {
    label: 'Desktop/Laptop',
    ratio: '16∶10',
    resolution: '1680 x 1050 / 840 x 525',
    icon: <Laptop fontSize={32} />,
  },
}

export type FormProfileCapstoneImageProps = Pick<
  CapstoneProject,
  'image' | 'device'
> & {
  setValue: UseFormSetValue<CapstoneFormValues>
  deleteImage: () => void
}

export const FormProfileCapstoneImage = ({
  image,
  device = Device.SMARTPHONE,
  setValue,
  deleteImage,
}: FormProfileCapstoneImageProps) => {
  const [file, setFile] = useState<File>()
  const [modalOpen, setModalOpen] = useState(false)
  const [newDevice, setNewDevice] = useState<Device>(
    device || Device.SMARTPHONE
  )
  const [imageId, setImageId] = useState(image?.id)
  const [savedImage, setSavedImage] = useState<string>()

  const uploadNewImage = useCallback((newImg: File) => {
    if (newImg) {
      uploadImage(newImg)
        .then(({ id: imageId }) => {
          return setImageId(imageId)
        })
        .then(() => {
          setFile(newImg)
        })
        .catch(error => {
          toast.error(error.response.data)
        })
    }
  }, [])

  const onDropAccepted = useCallback(
    (files: File[]) => {
      uploadNewImage(files[0])
      setModalOpen(true)
    },
    [uploadNewImage]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxFiles: 1,
    multiple: false,
  })

  const fileUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : image?.urls.l),
    [file, image?.urls.l]
  )

  const onDelete = useCallback(() => {
    deleteImage()
    setSavedImage(undefined)
    setFile(undefined)
  }, [deleteImage])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 6,
          margin: 3,
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            position: 'relative',
            padding: 8,
          }}
        >
          <CapstoneImageView
            src={savedImage ?? image?.urls.l}
            device={device || Device.SMARTPHONE}
            disableModal
          />

          <UploadOverlay sx={isDragActive ? { opacity: 1 } : {}} />

          <input {...getInputProps()} />
        </Box>

        <Stack flexShrink={0}>
          {(image?.urls.l ?? savedImage) && (
            <Stack spacing={4}>
              <IconButton color="inherit" onClick={() => setModalOpen(true)}>
                <PenEdit />
              </IconButton>
              <IconButton color="inherit" onClick={onDelete}>
                <TrashDeleteBin />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Box>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <ProfileForm
          title="Edit Image"
          onCancel={() => {
            setModalOpen(false)
          }}
          onSubmit={() => {
            setModalOpen(false)
            if (fileUrl) {
              setSavedImage(fileUrl)
            }
            setValue('imageId', imageId)
            setValue('device', newDevice)
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 8,
              padding: 8,
            }}
          >
            <CapstoneImageView src={fileUrl} device={newDevice} disableModal />
          </Box>

          <ToggleButtonGroup
            orientation="vertical"
            value={newDevice}
            exclusive
            onChange={(_, nextView: Device) => {
              if (nextView) {
                setNewDevice(nextView)
              } else {
                setNewDevice(device as Device)
              }
            }}
          >
            {Object.entries(devices).map(
              ([key, { label, resolution, ratio, icon }]) => (
                <ToggleButton value={key} key={key} sx={{ gap: 5 }}>
                  {icon}
                  <Stack flex={1} alignItems="flex-start" spacing={1}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="caption">{resolution}</Typography>
                  </Stack>
                  <Typography variant="caption">{ratio}</Typography>
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </ProfileForm>
      </Dialog>
    </>
  )
}
