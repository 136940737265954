import { Components } from '@mui/material'

export const MuiTypography: Components['MuiTypography'] = {
  defaultProps: {
    variant: 'body2',
    variantMapping: {
      h0: 'h2',
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      title1: 'h4',
      title2: 'h5',
      caption: 'div',
      captionStrong: 'div',
      button: 'span',
      body1: 'p',
      body1Strong: 'p',
      body2: 'p',
      body2Strong: 'p',
      intro: 'div',
      introStrong: 'div',
      menu: 'div',
    },
  },
}
