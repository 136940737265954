/* eslint-disable react/display-name */
import { TextField, TextFieldProps } from '@mui/material'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ForwardedRef, forwardRef } from 'react'

import { CalendarSchedule11 } from '../../../assets/icons'

export interface DatePickerProps extends MuiDatePickerProps<string, Date> {
  name?: TextFieldProps['name']
  onBlur?: TextFieldProps['onBlur']
  helperText?: TextFieldProps['helperText']
  error?: TextFieldProps['error']
}

export const DatePicker = forwardRef(
  (
    {
      name,
      onBlur,
      helperText,
      ...props
    }: Omit<DatePickerProps, 'renderInput'>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          inputRef={ref}
          {...props}
          DialogProps={{
            sx: {
              '.MuiPickersCalendarHeader-root .MuiIconButton-root': {
                backgroundColor: 'transparent !important',
              },
            },
          }}
          components={{ OpenPickerIcon: CalendarSchedule11 }}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              onBlur={onBlur}
              error={props.error}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
    )
  }
)
