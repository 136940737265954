import { Box, Button, Divider, Stack } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import {
  useClearRefinements,
  useRefinementList,
} from 'react-instantsearch-hooks-web'

import { Geolocation } from '../../../../graphql/graphqlTypes'
import { useGetLatLng } from '../../../../graphql/useGetLatLng'
import { DEFAULT_RADIUS } from '../../../../service/geolocation-service'
import type { AlgoliaRefinementListItem } from '../../../../types/algolia'
import { ProfileForm } from '../Profile/Forms/ProfileForm'
import { useStats } from './hooks/useStats'
import { LocationFilter } from './LocationFilter'
import { RefinementListCheckboxes } from './RefinementListCheckboxes'
import { RefinementListCallback } from './RefinementListItem'
import { SkillFilter } from './SkillFilter'

type Props = {
  onSubmit: RefinementListCallback
  onClose: () => void
  tracks: AlgoliaRefinementListItem[]
  jobPreferences: AlgoliaRefinementListItem[]
  status: AlgoliaRefinementListItem[]
  availability: AlgoliaRefinementListItem[]
  locations: AlgoliaRefinementListItem[]
  languages: AlgoliaRefinementListItem[]
  skills: AlgoliaRefinementListItem[]
  geolocation: Geolocation | null
  radius: number
}

export const StudentFilterModalContent: FC<Props> = ({
  onSubmit,
  onClose,
  tracks,
  jobPreferences,
  status,
  availability,
  locations,
  languages,
  skills,
  geolocation,
  radius,
}) => {
  const { items: trackItems, refine: refineTracks } = useRefinementList({
    attribute: 'tracks',
  })
  const {
    items: jobPreferencesItems,
    refine: refineJobPreferences,
  } = useRefinementList({
    attribute: 'jobPreferences',
  })
  const {
    items: availabilityItems,
    refine: refineAvailability,
  } = useRefinementList({
    attribute: 'availability',
  })
  const { items: statusItems, refine: refineStatus } = useRefinementList({
    attribute: 'status',
  })
  const { items: locationItems, refine: refineLocations } = useRefinementList({
    attribute: 'locationPreferences',
  })

  const [
    selectedGeolocation,
    setSelectedGeolocation,
  ] = useState<Geolocation | null>(null)

  const [selectedRadius, setSelectedRadius] = useState(DEFAULT_RADIUS)

  const { latLng } = useGetLatLng(selectedGeolocation?.placeId)
  const {
    items: languageItems,
    refine: refineLanguages,
    toggleShowMore,
    isShowingMore,
  } = useRefinementList({
    attribute: 'languages',
    limit: 6,
    showMore: true,
    showMoreLimit: 100,
  })

  const { items: skillItems, refine: refineSkills } = useRefinementList({
    attribute: 'skills',
    operator: 'and',
  })

  const { nbHits } = useStats()
  const { refine: clearAllRefinements } = useClearRefinements()

  const clearSelectedGeolocation = useCallback(() => {
    setSelectedGeolocation(null)
    setSelectedRadius(DEFAULT_RADIUS)
  }, [])

  const clearAll = useCallback(() => {
    clearAllRefinements()
    clearSelectedGeolocation()
  }, [clearAllRefinements, clearSelectedGeolocation])

  useEffect(() => {
    clearAll()
    tracks.forEach(item => item.isRefined && refineTracks(item.value))
    jobPreferences.forEach(
      item => item.isRefined && refineJobPreferences(item.value)
    )
    status.forEach(item => item.isRefined && refineStatus(item.value))
    availability.forEach(
      item => item.isRefined && refineAvailability(item.value)
    )
    locations.forEach(item => item.isRefined && refineLocations(item.value))
    languages.forEach(item => item.isRefined && refineLanguages(item.value))
    skills.forEach(item => item.isRefined && refineSkills(item.value))
    setSelectedGeolocation(geolocation)
    setSelectedRadius(radius)
  }, [
    tracks,
    refineTracks,
    jobPreferences,
    refineJobPreferences,
    status,
    refineStatus,
    availability,
    refineAvailability,
    locations,
    languages,
    skills,
    refineLocations,
    refineLanguages,
    refineSkills,
    clearAll,
    geolocation,
    radius,
  ])

  const handleSubmit = () => {
    onSubmit(
      trackItems,
      jobPreferencesItems,
      availabilityItems,
      statusItems,
      locationItems,
      languageItems,
      skillItems,
      selectedGeolocation,
      selectedRadius
    )
  }

  return (
    <>
      <ProfileForm title="Filters" onCancel={onClose} hideCardActions>
        <Stack spacing={6}>
          <RefinementListCheckboxes
            title="Track"
            items={trackItems}
            refine={refineTracks}
          />
          <Divider light />
          <RefinementListCheckboxes
            title="Employment type"
            items={jobPreferencesItems}
            refine={refineJobPreferences}
          />
          <Divider light />
          <RefinementListCheckboxes
            title="Availability"
            items={availabilityItems}
            refine={refineAvailability}
          />
          <Divider light />
          <RefinementListCheckboxes
            title="Type"
            items={statusItems}
            refine={refineStatus}
          />
          <Divider light />
          <LocationFilter
            items={locationItems}
            refine={refineLocations}
            latLng={latLng}
            selectedGeolocation={selectedGeolocation}
            onSelectGeolocation={setSelectedGeolocation}
            selectedRadius={selectedRadius}
            onSelectRadius={setSelectedRadius}
          />
          <Divider light />
          <SkillFilter refine={refineSkills} items={skillItems} />
          <Divider light />
          <RefinementListCheckboxes
            title="Language"
            items={languageItems}
            refine={refineLanguages}
            toggleShowMore={toggleShowMore}
            isShowingMore={isShowingMore}
          />
        </Stack>
      </ProfileForm>
      <Divider light />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
          gap: 4,
          margin: 4,
        }}
      >
        <Button variant="outlined" onClick={clearAll}>
          Clear all
        </Button>

        <Button disabled={nbHits === 0} type="submit" onClick={handleSubmit}>
          View {nbHits} results
        </Button>
      </Box>
    </>
  )
}
