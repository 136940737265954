import { Card, CardContent, CardMedia, styled } from '@mui/material'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import awsCloud from '../assets/aws-cloud.webp'
import { FooterMinimal } from '../components/organisims/Footer'

const CenteredBoxContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.palette.colors.water,
  },

  '> *': {
    margin: 'auto',
  },
}))

const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  paddingTop: 24,
  paddingBottom: 24,

  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-end',
  },
}))

export const LayoutCenteredBox = ({
  children,
  img = awsCloud,
  disableImage = false,
}: {
  children: ReactNode
  img?: string
  disableImage?: boolean
}) => {
  return (
    <CenteredBoxContainer
      sx={theme => ({
        [theme.breakpoints.down('lg')]: {
          backgroundColor: '#0009',
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: '90%',
          backgroundBlendMode: 'darken',
        },
      })}
    >
      <CardWrapper>
        <Card
          sx={theme => ({
            width: '95vw',
            maxWidth: 570,

            [theme.breakpoints.up('lg')]: {
              display: 'grid',
              gridTemplateColumns: disableImage ? '1fr' : '1fr 2fr',
              maxWidth: disableImage ? 920 : 1130,
            },
          })}
        >
          {!disableImage && (
            <CardMedia
              component="img"
              image={img}
              sx={theme => ({
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
                height: '100%',
                objectPosition: '90%',
              })}
            />
          )}
          <CardContent
            sx={{
              padding: { xs: 6, md: 8, lg: 12 },
              ':last-child': {
                padding: { xs: 6, md: 8, lg: 12 },
              },
            }}
          >
            {children}
          </CardContent>
        </Card>

        <FooterMinimal />
      </CardWrapper>
    </CenteredBoxContainer>
  )
}

export const LayoutCenteredBoxOutlet = ({
  disableImage = false,
}: {
  disableImage?: boolean
}) => (
  <LayoutCenteredBox disableImage={disableImage}>
    <Outlet />
  </LayoutCenteredBox>
)
