/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'lodash'
import { useRef } from 'react'

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  timeout: number
) => {
  const ref = useRef(debounce(func, timeout))
  return ref.current
}
