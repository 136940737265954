import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiToggleButtonGroup: Components['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      '& .MuiToggleButtonGroup-groupedVertical': {
        margin: `0 0 ${muiDefaultTheme.spacing(4)} 0`,
        minHeight: 75,
      },
      '& .MuiToggleButtonGroup-groupedHorizontal': {
        margin: `0 ${muiDefaultTheme.spacing(4)} ${muiDefaultTheme.spacing(
          4
        )} 0`,
      },
    },
    groupedHorizontal: {
      margin: muiDefaultTheme.spacing(3),
    },
  },
}
