import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Person } from '../../../../graphql/graphqlTypes'
import { useSetPersonEMail } from '../../../../graphql/person/useSetPersonEMail'

type FormValues = {
  email: string
  emailConfirm: string
}
export const EMailSettings = (person: Person) => {
  const defaultValues = useMemo(
    () => ({
      email: '',
      emailConfirm: '',
    }),
    []
  )

  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues,
  })

  const watchEmail = watch('email', '')

  const { field: fieldEMail, fieldState: fieldEMailState } = useController({
    name: 'email',
    control,
    rules: {
      required: 'E-Mail can not be empty',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Invalid E-Mail address',
      },
    },
  })

  const {
    field: fieldEMailConfirm,
    fieldState: fieldEMailConfirmState,
  } = useController({
    name: 'emailConfirm',
    control,
    rules: {
      validate: value =>
        value === watchEmail || 'The E-Mail addresses do not match',
    },
  })

  const { setPersonEMail, loading: personEMailLoading } = useSetPersonEMail({
    onCompleted: () => {
      toast.success('E-Mail updated')
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    ({ email }: FormValues) => {
      person && setPersonEMail(person.id, email).finally(() => reset({ email }))
    },
    [person, reset, setPersonEMail]
  )

  return (
    <Stack component="form" spacing={6} onSubmit={handleSubmit(submit)}>
      <Typography variant="title2">E-Mail</Typography>
      <Stack spacing={1}>
        <TextField
          label="New E-Mail"
          {...fieldEMail}
          error={!!fieldEMailState.error}
          helperText={fieldEMailState.error?.message || ' '}
        />
        <TextField
          label="Confirm new E-Mail"
          {...fieldEMailConfirm}
          error={!!fieldEMailConfirmState.error}
          helperText={fieldEMailConfirmState.error?.message || ' '}
          onPaste={e => e.preventDefault()}
        />
      </Stack>
      <Box>
        <LoadingButton
          disabled={!formState.isDirty}
          loading={personEMailLoading}
          type="submit"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Stack>
  )
}
