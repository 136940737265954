import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'

export const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      // !important required to override :not(:first-child) styles from MUI
      border: `1px solid ${colors.water10} !important`,
      padding: muiDefaultTheme.spacing(4),
      borderRadius: `4px !important`,
      marginTop: `0 !important`,
      color: colors.granite,
      transition: muiDefaultTheme.transitions.create('border-color'),
      '&.Mui-selected': {
        border: `1px solid ${colors.nemo} !important`,
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        color: colors.lightGrey,
        border: `1px solid ${colors.water10} !important`,
        '.MuiTypography-root': {
          color: colors.lightGrey,
        },
      },
      '&:hover': {
        border: `1px solid ${colors.granite} !important`,
        backgroundColor: 'transparent',
      },
      '&:focus-visible': {
        border: `1px solid ${colors.purple} !important`,
        backgroundColor: 'transparent',
      },
      '&.Mui-selected:hover': {
        borderColor: colors.nemo,
        backgroundColor: 'transparent',
      },
      '&.MuiToggleButtonGroup-groupedVertical': {
        justifyContent: 'flex-start',
      },
      '&.MuiToggleButtonGroup-groupedHorizontal': {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: muiDefaultTheme.spacing(7),
      },
      '.MuiTypography-caption': {
        color: colors.grey,
      },
      '.MuiSvgIcon-root': {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        marginRight: muiDefaultTheme.spacing(3),
      },
    },
  },
}
