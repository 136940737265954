import {
  BottomNavigation,
  BottomNavigationAction,
  MenuItem,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useUser } from '../../../../graphql/person/useUser'
import { useAuth } from '../../../contexts/AuthContext'
import { CustomDrawer } from '../../atoms/Drawer'
import { useNavItems } from './useNavItems'

const SideNavigation = styled(BottomNavigation)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  height: 'auto',
  border: 0,
  gap: 16,
  backgroundColor: 'transparent',

  [theme.breakpoints.up('xl')]: {
    alignItems: 'stretch',
  },

  '> .MuiBottomNavigationAction-root': {
    padding: 8,
    borderRadius: 4,
    transition: theme.transitions.create('background-color'),

    [theme.breakpoints.up('xl')]: {
      maxWidth: '240',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: 16,
    },

    ':hover': {
      backgroundColor: theme.palette.colors.water10,
    },
  },
}))

export const MainNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { person } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const { isAdmin } = useAuth()

  const { navItems, drawerItems, hasDrawer } = useNavItems({
    personType: person?.personType,
    moreAction: () => setDrawerOpen(true),
    id: person?.id,
    isAdmin,
  })

  const [drawerOpen, setDrawerOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const [value, setValue] = useState<string>(location.pathname)

  const handleChange = useCallback(
    (url: string, isAction = false) => {
      setValue(url)
      if (!isAction) {
        navigate(url)
      }
    },
    [navigate]
  )

  const handleDrawerItemClick = useCallback(
    (url: string) => {
      setDrawerOpen(false)
      navigate(url)
    },
    [navigate]
  )

  useEffect(() => {
    if (location.pathname.includes(`profile/${person?.id}`)) {
      const profileItem = navItems.find(i => i.url?.includes('profile/'))
      profileItem?.url && setValue(profileItem.url)
    }
    if (location.pathname.includes('compan')) {
      const companyItem = navItems.find(i => i.url?.includes('compan'))
      companyItem?.url && setValue(companyItem.url)
    }
  }, [location.pathname, navItems, person?.id])

  if (isMobile) {
    return (
      <>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === 'function') {
              newValue()
              handleChange(newValue.name, true)
            } else {
              handleChange(newValue)
            }
          }}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9000,
          }}
        >
          {navItems.map(item => (
            <BottomNavigationAction
              label={item.label}
              icon={item.icon}
              value={item.url || item.action}
              key={item.url || item.label}
            />
          ))}
        </BottomNavigation>

        {hasDrawer && (
          <CustomDrawer
            open={drawerOpen}
            anchor="bottom"
            onClose={() => setDrawerOpen(false)}
          >
            {drawerItems
              ?.filter(i => i.url)
              .map(item => (
                <MenuItem
                  divider={false}
                  key={item.label}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  onClick={() => handleDrawerItemClick(item.url!)}
                >
                  {item.label}
                </MenuItem>
              ))}
          </CustomDrawer>
        )}
      </>
    )
  }

  return (
    <SideNavigation
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue)
      }}
    >
      {[...navItems, ...drawerItems].map((item, key) => (
        <BottomNavigationAction
          label={item.label}
          icon={item.icon}
          value={item.url || item.action}
          key={item.url || item.label}
          showLabel
          sx={
            key === navItems.length - 1 && !!drawerItems.length
              ? {
                  marginBottom: 8,
                }
              : {}
          }
        />
      ))}
    </SideNavigation>
  )
}
