import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationUpdateEntryFromJobExperienceFromPersonArgs } from '../graphqlTypes'
import { GET_PROFILE_COMPLETION } from './useGetProfileCompletion'
import { GET_USER } from './useUser'

const UPDATE_JOB_EXPERIENCE = gql`
  mutation UpdateJobExperience(
    $id: String!
    $personId: String!
    $title: String!
    $companyName: String!
    $startDate: String!
    $endDate: String!
    $type: String!
    $description: String
    $location: String
    $employmentType: EmploymentType
  ) {
    updateEntryFromJobExperienceFromPerson(
      id: $id
      personId: $personId
      title: $title
      companyName: $companyName
      startDate: $startDate
      endDate: $endDate
      type: $type
      description: $description
      location: $location
      employmentType: $employmentType
    )
  }
`

export const useUpdateJobExperience = (options?: BaseMutationOptions) => {
  const [updateEntry, result] = useMutation(UPDATE_JOB_EXPERIENCE, options)

  const updateJobExperienceEntry = (
    variables: MutationUpdateEntryFromJobExperienceFromPersonArgs
  ) =>
    updateEntry({
      variables,
      refetchQueries: [GET_USER, GET_PROFILE_COMPLETION],
    })

  return { ...result, updateJobExperienceEntry }
}
