import { Auth } from '@aws-amplify/auth'

import { authConfig } from '../../config/authConfig'
import type { AuthenticatedUser, CognitoUser } from '../../types/auth.d'

Auth.configure({
  // REQUIRED - Amazon Cognito Region
  region: authConfig.region,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: authConfig.UserPoolId,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: authConfig.ClientId,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
})

export const login = (username: string, password: string) =>
  Auth.signIn(username.toLocaleLowerCase(), password)

export const getUserIdToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

export const logout = () => Auth.signOut()

export const getAuthenticatedUser: () => Promise<AuthenticatedUser> = () =>
  Auth.currentUserInfo()

export const getUserData = async () => {
  const session = await Auth.currentSession()
  const groups = session.getIdToken().payload['cognito:groups']
  const id = session.getIdToken().payload['sub']
  return { groups, id }
}

export const requestNewPassword = async (username: string) => {
  return await Auth.forgotPassword(username.toLocaleLowerCase())
}

export const completeNewPassword = async (
  user: CognitoUser,
  newPassword: string
) => {
  await Auth.completeNewPassword(user, newPassword, [])
}

export const setNewPassword = async (
  username: string,
  password: string,
  code: string
) => {
  return await Auth.forgotPasswordSubmit(
    username.toLocaleLowerCase(),
    code,
    password
  )
}
