import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  EmploymentType,
  JobExperience,
  JobExperienceType,
} from '../../../../../graphql/graphqlTypes'
import { useAddJobExperience } from '../../../../../graphql/person/useAddJobExperience'
import { useDeleteJobExperience } from '../../../../../graphql/person/useDeleteJobExperience'
import { useUpdateJobExperience } from '../../../../../graphql/person/useUpdateJobExperience'
import {
  GraduatePerson,
  MarkerPin2,
  SuitcasePortfolio1,
} from '../../../../assets/icons'
import { useModalStore } from '../../../../stores/modalStore'
import { ProfileForm } from './ProfileForm'
import { FromProfileCareerDate } from './ProfileForm/FromProfileCareerDate'

const labels: Record<
  JobExperience['type'],
  {
    companyName: string
    title: string
  }
> = {
  education: {
    title: 'Degree',
    companyName: 'School / University',
  },
  employment: {
    title: 'Title',
    companyName: 'Company',
  },
}

type FormValues = Pick<
  JobExperience,
  | 'id'
  | 'companyName'
  | 'title'
  | 'type'
  | 'location'
  | 'startDate'
  | 'endDate'
  | 'description'
  | 'employmentType'
>

export interface FormProfileCareerProps extends Partial<FormValues> {
  personId?: string
  type: JobExperience['type']
}

export const FormProfileCareer = ({
  personId,
  id,
  companyName,
  title,
  type,
  location,
  startDate,
  endDate,
  description,
  employmentType,
}: FormProfileCareerProps) => {
  const setModal = useModalStore(state => state.setModal)

  const { control, handleSubmit, setValue, formState } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      id,
      companyName: companyName || '',
      title: title || '',
      type: type || '',
      location: location || '',
      startDate: startDate || '',
      endDate: endDate || '',
      description: description || '',
      employmentType: employmentType || EmploymentType.FULLTIME,
    },
  })

  const { field: fieldType } = useController({
    name: 'type',
    control,
    rules: {
      required: `Please select a type`,
    },
  })

  const { field: fieldTitle, fieldState: fieldTitleState } = useController({
    name: 'title',
    control,
    rules: {
      required: `Please fill ${labels[fieldType.value]?.title ?? ''}`,
    },
  })

  const {
    field: fieldEmploymentType,
    fieldState: fieldEmploymentTypeState,
  } = useController({
    name: 'employmentType',
    control,
  })

  const {
    field: fieldCompanyName,
    fieldState: fieldCompanyNameState,
  } = useController({
    name: 'companyName',
    control,
  })

  const {
    field: fieldLocation,
    fieldState: fieldLocationState,
  } = useController({
    name: 'location',
    control,
  })

  const {
    field: fieldStartDate,
    fieldState: fieldStartDateState,
  } = useController({
    name: 'startDate',
    control,
    rules: { required: 'Please fill start date' },
  })

  const { field: fieldEndDate, fieldState: fieldEndDateState } = useController({
    name: 'endDate',
    control,
  })

  const {
    field: fieldDescription,
    fieldState: fieldDescriptionState,
  } = useController({
    name: 'description',
    control,
    rules: {
      validate: value =>
        (value?.length || 0) <= 250 ||
        `(${value?.length || 0}/250 characters) Your text is too long`,
    },
  })

  const {
    updateJobExperienceEntry,
    loading: updateJobExperienceEntryLoading,
  } = useUpdateJobExperience({
    onCompleted: () => {
      toast.success(`Job experience updated`)
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const {
    addEntryToJobExperience,
    loading: addEntryToJobExperienceLoading,
  } = useAddJobExperience({
    onCompleted: () => {
      toast.success(`Job experience added`)
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const {
    deleteJobExperienceEntry,
    loading: deleteJobExperienceEntryLoading,
  } = useDeleteJobExperience({
    onCompleted: () => {
      toast.success(`Job experience deleted`)
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      if (personId) {
        if (id) {
          updateJobExperienceEntry({
            personId,
            ...data,
            employmentType: data.employmentType as EmploymentType,
            endDate: data.endDate ?? '',
          })
        } else {
          addEntryToJobExperience({
            personId,
            ...data,
            endDate: data.endDate ?? '',
          })
        }
      }
    },
    [personId, id, updateJobExperienceEntry, addEntryToJobExperience]
  )

  const onCancel = useCallback(() => {
    setModal(false)
  }, [setModal])

  const onDelete = useCallback(() => {
    if (personId && id) {
      deleteJobExperienceEntry({ id, personId })
    }
  }, [deleteJobExperienceEntry, id, personId])

  const loading =
    updateJobExperienceEntryLoading ||
    addEntryToJobExperienceLoading ||
    deleteJobExperienceEntryLoading

  return (
    <ProfileForm
      title={id ? 'Edit entry' : 'Add new entry'}
      onCancel={onCancel}
      loading={loading}
      onSubmit={handleSubmit(submit)}
      formState={formState}
    >
      <Box component="form" paddingBottom={8}>
        <ToggleButtonGroup
          orientation="horizontal"
          value={fieldType.value}
          exclusive
          sx={{ width: '100%' }}
          onChange={(_, value: JobExperienceType) => {
            if (value !== null) {
              setValue('type', value)
            }
          }}
        >
          <ToggleButton value={JobExperienceType.EMPLOYMENT}>
            <SuitcasePortfolio1 fontSize={20} />
            <Typography variant="body2">Employment</Typography>
          </ToggleButton>
          <ToggleButton value={JobExperienceType.EDUCATION}>
            <GraduatePerson fontSize={20} />
            <Typography variant="body2">Education</Typography>
          </ToggleButton>
        </ToggleButtonGroup>

        <Stack spacing={10}>
          <Stack spacing={4}>
            <TextField
              label={labels[fieldType.value]?.title ?? ''}
              {...fieldTitle}
              error={!!fieldTitleState.error}
              helperText={fieldTitleState.error?.message}
            />

            {fieldType.value === 'employment' && (
              <TextField
                select
                label="Employment type"
                {...fieldEmploymentType}
                onChange={e =>
                  setValue('employmentType', e.target.value as EmploymentType)
                }
                error={!!fieldEmploymentTypeState.error}
                defaultValue={EmploymentType.FULLTIME}
              >
                <MenuItem value={EmploymentType.FULLTIME}>Full-time</MenuItem>
                <MenuItem value={EmploymentType.PARTTIME}>Part-time</MenuItem>
                <MenuItem value={EmploymentType.FREELANCE}>Freelance</MenuItem>
                <MenuItem value={EmploymentType.INTERNSHIP}>
                  Internship
                </MenuItem>
              </TextField>
            )}

            <TextField
              label={labels[fieldType.value]?.companyName ?? ''}
              {...fieldCompanyName}
              error={!!fieldCompanyNameState.error}
              helperText={fieldCompanyNameState.error?.message}
            />

            <TextField
              label="Location"
              {...fieldLocation}
              error={!!fieldLocationState.error}
              helperText={fieldLocationState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component={MarkerPin2} fontSize={32} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack spacing={4}>
            <FromProfileCareerDate
              label="Start date"
              date={fieldStartDate.value}
              onChange={newDate => setValue('startDate', newDate)}
              before={fieldEndDate.value as string | undefined}
              error={fieldStartDateState.error?.message}
            />

            {fieldEndDate.value && (
              <FromProfileCareerDate
                label="End date"
                date={fieldEndDate.value}
                onChange={newDate => setValue('endDate', newDate)}
                after={fieldStartDate.value}
                error={fieldEndDateState.error?.message}
              />
            )}

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
            >
              <Typography variant="body2">Until now</Typography>
              <Switch
                checked={!fieldEndDate.value}
                onChange={(_, checked) => {
                  if (checked) {
                    setValue('endDate', null)
                  } else {
                    setValue('endDate', new Date().toISOString())
                  }
                }}
              />
            </Box>
          </Stack>

          <TextField
            multiline
            minRows={3}
            label="Description"
            {...fieldDescription}
            error={!!fieldDescriptionState.error}
            helperText={
              fieldDescriptionState.error?.message ||
              `(${fieldDescription?.value?.length || 0}/250 characters)`
            }
          />

          {id && (
            <Button variant="text" fullWidth color="error" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Stack>
      </Box>
    </ProfileForm>
  )
}
