import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { MutationSetEventDetailsArgs } from '../graphqlTypes'

const SET_EVENT_DETAILS = gql`
  mutation SetEventDetails(
    $id: String!
    $description: String
    $techStack: [String!]!
    $date: String!
    $time: String!
    $published: Boolean
    $location: String
  ) {
    setEventDetails(
      id: $id
      description: $description
      techStack: $techStack
      date: $date
      time: $time
      published: $published
      location: $location
    ) {
      id
      description
      techStack
      published
      date
      time
      token
      location
    }
  }
`

export const useSetEventDetails = (options: BaseMutationOptions) => {
  const [setEventDetailsMutation, status] = useMutation(
    SET_EVENT_DETAILS,
    options
  )

  const setEventDetails = (event: MutationSetEventDetailsArgs) =>
    setEventDetailsMutation({
      variables: {
        ...event,
      },
    })
  return { setEventDetails, ...status }
}
