import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { Person } from '../graphqlTypes'

const ADD_PERSON_BASIC_INFO = gql`
  mutation SetPersonBasicInfo(
    $id: String!
    $firstName: String!
    $lastName: String!
    $teaser: String!
    $location: String!
    $shortDescription: String!
    $gitHubUrl: String!
    $videoUrl: String!
  ) {
    setPersonName(id: $id, firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
    setPersonTeaser(id: $id, teaser: $teaser) {
      id
      teaser
    }
    setPersonLocation(id: $id, location: $location) {
      id
      location
    }
    setPersonShortDescription(id: $id, shortDescription: $shortDescription) {
      id
      shortDescription
    }
    setPersonGitHubUrl(id: $id, gitHubUrl: $gitHubUrl) {
      id
      gitHubUrl
    }
    setPersonVideoUrl(id: $id, videoUrl: $videoUrl) {
      id
      videoUrl
    }
  }
`

type MutationSetPersonBasicInfoArgs = Pick<
  Person,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'shortDescription'
  | 'teaser'
  | 'location'
  | 'gitHubUrl'
>

export const useAddPersonBasicInfo = (options?: BaseMutationOptions) => {
  const [addBasicInfo, result] = useMutation(ADD_PERSON_BASIC_INFO, options)

  const addPersonBasicInfo = (variables: MutationSetPersonBasicInfoArgs) =>
    addBasicInfo({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, addPersonBasicInfo }
}
