import { BaseQueryOptions, gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'

import { DefaultCurriculumDto, LanguageCode } from '../graphqlTypes'

export const GET_DEFAULT_BOOTCAMP_CURRICULUM = gql`
  query GetDefaultBootcampCurriculum(
    $trackId: String!
    $language: LanguageCode!
  ) {
    getDefaultBootcampCurriculum(trackId: $trackId, language: $language) {
      editor
      created
      language
      track {
        id
        name
      }
      curriculumDto {
        id
        curriculumColumns {
          curriculumEntries {
            title
            level
          }
        }
      }
    }
  }
`

export const useGetDefaultBootcampCurriculum = (
  trackId: string,
  language: LanguageCode,
  options?: BaseQueryOptions
) => {
  const query = useQuery<{
    getDefaultBootcampCurriculum: DefaultCurriculumDto
  }>(GET_DEFAULT_BOOTCAMP_CURRICULUM, {
    variables: { trackId, language },
    ...options,
  })

  useEffect(() => {
    query.refetch()
  }, [trackId, language, query])
  const curriculum = query.data?.getDefaultBootcampCurriculum.curriculumDto
  const editor = query.data?.getDefaultBootcampCurriculum.editor
  const created = query.data?.getDefaultBootcampCurriculum.created
  return { ...query, curriculum, editor, created }
}
