import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationSetPersonAvatarArgs } from '../graphqlTypes'
import { GET_PERSON } from './useGetPerson'
import { GET_USER } from './useUser'

const SET_PERSON_AVATAR = gql`
  mutation SetPersonAvatar($personId: String!, $imageId: String!) {
    setPersonAvatar(personId: $personId, imageId: $imageId) {
      email
      firstName
      lastName
      personType
      avatar {
        id
        urls {
          s
          m
          l
          xl
        }
      }
    }
  }
`

export const useSetPersonAvatar = (options?: BaseMutationOptions) => {
  const [updateAvatar, result] = useMutation(SET_PERSON_AVATAR, options)

  const setPersonAvatar = (variables: MutationSetPersonAvatarArgs) =>
    updateAvatar({
      variables,
      refetchQueries: [GET_USER, GET_PERSON],
    })

  return { ...result, setPersonAvatar }
}
