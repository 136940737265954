import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

const DELETE_PARTNER = gql`
  mutation DeletePartner($id: String!) {
    deletePartner(id: $id)
  }
`

export default function useDeletePartner(options: BaseMutationOptions) {
  const [deletePartnerMutation, status] = useMutation(DELETE_PARTNER, options)

  const deletePartner = (id: string) =>
    deletePartnerMutation({ variables: { id } })

  return { deletePartner, ...status }
}
