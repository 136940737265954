import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiDivider: Components['MuiDivider'] = {
  styleOverrides: {
    root: {
      borderBottomWidth: '1px',
      borderBottomColor: colors.darkGrey,
    },
    light: {
      borderBottomColor: colors.water10,
    },
  },
}
