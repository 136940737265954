import { Drawer, DrawerProps, IconButton } from '@mui/material'

import { DeleteDisabled } from '../../../assets/icons'

export enum DrawerAnchor {
  top = 'top',
  bottom = 'bottom',
}

export interface CustomDrawerProps extends Omit<DrawerProps, 'anchor'> {
  anchor: 'top' | 'bottom'
}

export const CustomDrawer = ({
  children,
  onClose,
  ...props
}: CustomDrawerProps) => {
  return (
    <Drawer {...props} onClose={onClose}>
      {onClose && (
        <IconButton
          color="inherit"
          aria-label="close"
          sx={{
            background: 'none',
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 1000, // TODO: config
          }}
          size="tiny"
          onClick={e => onClose(e, 'backdropClick')}
        >
          <DeleteDisabled />
        </IconButton>
      )}
      {children}
    </Drawer>
  )
}
