import { styled } from '@mui/material'
import React from 'react'

const BadgeContainer = styled('sup')({
  backgroundColor: '#ff5a36',
  color: 'white',
  borderRadius: '4px',
  padding: '0.1em 0.4em',
  fontSize: '0.55em',
  fontWeight: 'bold',
  lineHeight: 'normal',
  verticalAlign: 'super',
  marginLeft: '0.2em',
})

const BetaBadge: React.FC = () => {
  return <BadgeContainer>Beta</BadgeContainer>
}

export default BetaBadge
