import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiMenu: Components['MuiMenu'] = {
  defaultProps: {
    color: 'primary',
    BackdropProps: {
      sx: {
        backgroundColor: 'transparent',
      },
    },
  },
  styleOverrides: {
    paper: {
      border: `1px solid ${colors.water10}`,
    },
  },
}
