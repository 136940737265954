import {
  Autocomplete,
  Box,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import algoliasearch from 'algoliasearch'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Configure,
  InstantSearch,
  useHits,
  useMenu,
} from 'react-instantsearch-hooks-web'

import { LogGrid, LogItem } from '../common/components/atoms/LogItem'
import { SearchPagination } from '../common/components/molecules/SearchPagination'
import { useStats } from '../common/components/organisims/SearchBar/hooks/useStats'
import { colors } from '../common/theme/constants/colors'
import {
  algoliaConfig,
  getIndexName,
  searchClient,
} from '../config/algoliaConfig'
import type {
  AlgoliaPartnerLog,
  AlgoliaRefinementListItem,
} from '../types/algolia.d'

const client = algoliasearch(algoliaConfig.project, algoliaConfig.key)
const index = client.initIndex('partnerLog')

const Wrapper = styled('div')({
  border: '1px solid' + colors.water10,
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
  padding: '16px 24px',
  borderRadius: 8,
})

const ContactTracing = () => {
  const [
    refinementItem,
    setRefinementItem,
  ] = useState<AlgoliaRefinementListItem | null>(null)
  const { nbHits: totalHits } = useStats()
  const { items, refine } = useMenu({ attribute: 'partnerName' })
  const { hits } = useHits<AlgoliaPartnerLog>()
  const [hitsCurrentMonth, setHitsCurrentMonth] = useState(0)

  const time = useMemo(() => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1).getTime()
  }, [])

  const getCurrentMonthHits = useCallback(
    async (time: number, label?: string) => {
      if (time) {
        await index
          .search('', {
            filters: `created_timestamp > ${time}${
              label ? ` AND partnerName: '${label}'` : ''
            }`,
          })
          .then(({ nbHits }) => {
            setHitsCurrentMonth(nbHits)
          })
      }
    },
    []
  )

  useEffect(() => {
    if (items.length) {
      getCurrentMonthHits(time, refinementItem?.label)
    }
  }, [getCurrentMonthHits, items.length, refinementItem?.label, time])

  return (
    <>
      <Stack spacing={8}>
        <Typography variant="h5">Activity Log</Typography>

        <Stack spacing={6}>
          <Autocomplete
            value={refinementItem}
            onChange={(event, data) => {
              setRefinementItem(data)
              if (!data) items.map(item => item.isRefined && refine(item.value))
              else {
                refine(data.value)
              }
            }}
            options={items as AlgoliaRefinementListItem[]}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="Partner"
                placeholder="Enter a partner name"
              />
            )}
          />

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 6 }}>
            <Wrapper>
              <Typography variant="body2">This month</Typography>
              <Typography variant="body2Strong">{hitsCurrentMonth}</Typography>
            </Wrapper>
            <Wrapper>
              <Typography variant="body2">Total</Typography>
              <Typography variant="body2">{totalHits}</Typography>
            </Wrapper>
          </Box>
        </Stack>

        <LogGrid>
          {hits.map((log, index) => (
            <LogItem key={index} {...log} tracing />
          ))}
        </LogGrid>
      </Stack>
      <SearchPagination />
    </>
  )
}

export const PageContactTracing = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('partnerLog')}
    >
      <Configure hitsPerPage={16} filters="type:CONTACT" />
      <ContactTracing />
    </InstantSearch>
  )
}
