import { Typography } from '@mui/material'
import { ReactNode } from 'react'

export interface InfoBoxProps {
  children: ReactNode
}

export const InfoBox = ({ children }: InfoBoxProps) => {
  return (
    <Typography
      variant="caption"
      sx={{
        padding: 6,
        backgroundColor: 'colors.sand',
        borderRadius: 1,
      }}
    >
      {children}
    </Typography>
  )
}
