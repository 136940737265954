import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { MutationSetPersonSkillsArgs } from '../graphqlTypes'

const SET_PERSON_SKILLS = gql`
  mutation SetPersonSkills($personId: String!, $skills: [String!]!) {
    setPersonSkills(personId: $personId, skills: $skills) {
      id
      skills
    }
  }
`

export const useSetPersonSkills = (options?: BaseMutationOptions) => {
  const [updateSkills, result] = useMutation(SET_PERSON_SKILLS, options)

  const setPersonSkills = (variables: MutationSetPersonSkillsArgs) =>
    updateSkills({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, setPersonSkills }
}
