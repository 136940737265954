import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { ReactNode } from 'react'

import {
  EmploymentType,
  JobExperience,
  JobExperienceType,
} from '../../../../../graphql/graphqlTypes'
import { EditButton } from '../../../molecules/EditButton'

export interface ProfileCareerItemProps extends JobExperience {
  isOwnProfile?: boolean
  editModal?: ReactNode
}

const employmentTypeStrings = {
  [EmploymentType.FULLTIME]: 'Full time',
  [EmploymentType.PARTTIME]: 'Part time',
  [EmploymentType.FREELANCE]: 'Freelance',
  [EmploymentType.INTERNSHIP]: 'Internship',
}

export const ProfileCareerItem = ({
  title,
  companyName,
  startDate,
  endDate,
  location,
  description,
  isOwnProfile = false,
  editModal,
  employmentType,
  type,
}: ProfileCareerItemProps) => {
  return (
    <Box position="relative">
      <Typography variant="body2Strong" color="colors.nemo">
        {title}
      </Typography>
      <Typography variant="captionStrong" sx={{ mb: 2 }}>
        {companyName}
      </Typography>
      <Typography variant="caption" color="colors.grey" sx={{ mb: 2 }}>
        <span>
          {type == JobExperienceType.EMPLOYMENT &&
            employmentType &&
            employmentTypeStrings[employmentType] + ' / '}
          {format(new Date(startDate), 'MMM yyyy')} -{' '}
          {endDate ? format(new Date(endDate), 'MMM yyyy') : 'now'}
        </span>
        {location && <div>{location}</div>}
      </Typography>
      {description && !editModal && <Typography>{description}</Typography>}

      {isOwnProfile && editModal && (
        <EditButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          modalContent={editModal}
        />
      )}
    </Box>
  )
}
