import { gql, useQuery } from '@apollo/client'

import { Person, QueryFindAllByKeywordArgs } from '../graphqlTypes'

export const GET_FILTERED_STUDENTS = gql`
  query GetFilteredStudents($keyword: String!) {
    findAllByKeyword(keyword: $keyword) {
      id
      firstName
      lastName
      avatar {
        urls {
          s
        }
      }
    }
  }
`

export const useGetFilteredStudents = (
  variables: QueryFindAllByKeywordArgs
) => {
  const query = useQuery<{
    findAllByKeyword: Person[]
  }>(GET_FILTERED_STUDENTS, { variables, skip: variables.keyword.length < 3 })
  const students = query.data?.findAllByKeyword || []

  return {
    ...query,
    students,
  }
}
