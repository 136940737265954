import {
  Box,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { usePagination } from 'react-instantsearch-hooks-web'

import { LeftArrow, RightArrow } from '../../../assets/icons'
import { useStats } from '../../organisims/SearchBar/hooks/useStats'

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (v, k) => k + start)

export const SearchPagination = ({ inCard = false }: { inCard?: boolean }) => {
  const { hitsPerPage = 1 } = useStats()
  const { nbHits, nbPages, refine } = usePagination()
  const [currentPage, setCurrentPage] = useState(1)
  const numberOfPages = nbPages > 0 ? nbPages : 1

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value)
    setCurrentPage(number)
    handleRefine(number)
  }

  const handleRefine = (input: number) => {
    const fixedInput = input - 1
    refine(fixedInput)
  }
  const pages = range(0, numberOfPages)

  const { resStart, resEnd } = useMemo(() => {
    const resEnd = currentPage * hitsPerPage
    return {
      resStart: resEnd - hitsPerPage + 1,
      resEnd: resEnd > nbHits ? nbHits : resEnd,
    }
  }, [currentPage, nbHits, hitsPerPage])

  const Wrapper = useCallback(
    ({ children }: { children: ReactNode }) =>
      inCard ? (
        <Stack
          spacing={{ xs: 0, lg: 4 }}
          sx={{
            alignItems: 'center',
            paddingBottom: 4,
          }}
        >
          <Card sx={{ width: '100%' }}>
            <CardContent
              sx={{
                paddingY: { xs: 4, lg: 4 },
                ':last-child': {
                  paddingY: { xs: 4, lg: 4 },
                },
              }}
            >
              {children}
            </CardContent>
          </Card>
          <Typography variant="caption" color="colors.grey">
            Showing {resStart}-{resEnd} of {nbHits} results
          </Typography>
        </Stack>
      ) : (
        <Stack
          spacing={4}
          sx={{
            alignItems: 'center',
            paddingBottom: { xs: 4, lg: 0 },
          }}
        >
          {children}
          <Typography variant="caption" color="colors.grey">
            Showing {resStart}-{resEnd} of {nbHits} results
          </Typography>
        </Stack>
      ),
    [inCard, nbHits, resEnd, resStart]
  )

  if (hitsPerPage > nbHits) {
    return null
  }

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 2, md: 8 },
        }}
      >
        <IconButton
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            handleRefine(currentPage - 1)
          }}
        >
          <LeftArrow />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant="body2">Page:</Typography>
          <TextField
            id="page-number"
            value={currentPage}
            select
            onChange={handleChange}
            sx={{
              '& .MuiInputBase-input': {
                padding: '16px 24px',
                textAlign: 'center',
              },
              width: '110px',
            }}
          >
            {pages.map(page => (
              <MenuItem
                key={page}
                value={page + 1}
                disabled={currentPage === page + 1}
              >
                {page + 1}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="body2">of {numberOfPages}</Typography>
        </Box>
        <IconButton
          disabled={currentPage === numberOfPages}
          onClick={() => {
            setCurrentPage(currentPage + 1)
            handleRefine(currentPage + 1)
          }}
        >
          <RightArrow />
        </IconButton>
      </Box>
    </Wrapper>
  )
}
