import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationRemoveParticipantFromBootcampArgs } from '../graphqlTypes'
import { GET_BOOTCAMP_DETAILS } from './getBootcampDetailsService'

const REMOVE_PARTICIPANT_FROM_BOOTCAMP = gql`
  mutation RemoveFromBootcamp($personId: String!, $bootcampId: String!) {
    removeParticipantFromBootcamp(personId: $personId, bootcampId: $bootcampId)
  }
`

export const useRemoveParticipantFromBootcamp = (
  options?: BaseMutationOptions
) => {
  const [deleteEntry, result] = useMutation(
    REMOVE_PARTICIPANT_FROM_BOOTCAMP,
    options
  )

  const removeParticipantFromBootcamp = (
    variables: MutationRemoveParticipantFromBootcampArgs
  ) =>
    deleteEntry({
      variables,
      refetchQueries: [GET_BOOTCAMP_DETAILS],
    })

  return { ...result, removeParticipantFromBootcamp }
}
