import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiDialogActions: Components['MuiDialogActions'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
      gap: 16,
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,

      '>:not(:first-of-type)': {
        marginLeft: 0, //reset default
      },

      [muiDefaultTheme.breakpoints.up('lg')]: {
        paddingRight: 32,
        paddingLeft: 32,
      },
    },
  },
}
