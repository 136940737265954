import { toast } from 'react-toastify'

import { Person } from '../../../../../graphql/graphqlTypes'
import { useDeletePartnerLogo } from '../../../../../graphql/partner/useDeletePartnerImage'
import { useSetPartnerProfileImage } from '../../../../../graphql/person/useSetPartnerImage'
import { uploadImage } from '../../../../../service/image/image-upload-service'
import { FormProfileImage } from './FormProfileImage'

export interface PartnerFormProfileImageProps extends Pick<Person, 'id'> {
  avatar?: string
}

export const PartnerFormProfileImage = ({
  id,
  avatar,
}: PartnerFormProfileImageProps) => {
  const { setPartnerProfileImage } = useSetPartnerProfileImage({
    onCompleted: () => {
      toast.success('Company logo saved')
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const uploadImageToBucket = (image: File) => {
    return uploadImage(image, id)
  }

  const { deletePartnerLogo } = useDeletePartnerLogo({
    onError: error => toast.error(`${error.message} 🙀`),
  })

  const deletePartnerAvatar = async (partnerId: string) => {
    deletePartnerLogo(partnerId)
  }

  const setAvatar = async (imageId: string, partnerId: string) => {
    setPartnerProfileImage({ imageId, partnerId })
  }

  return (
    <FormProfileImage
      id={id}
      image={avatar}
      deleteImage={deletePartnerAvatar}
      setNewImage={setAvatar}
      uploadImage={uploadImageToBucket}
      padded
    />
  )
}
