import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      gap: muiDefaultTheme.spacing(3),
    },
  },
}
