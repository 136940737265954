import { Box, styled, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { InstantSearch } from 'react-instantsearch-hooks-web'

import { getIndexName, searchClient } from '../../../../config/algoliaConfig'
import { Geolocation } from '../../../../graphql/graphqlTypes'
import type { AlgoliaRefinementListItem } from '../../../../types/algolia.d'
import { SettingsFilter } from '../../../assets/icons'
import { useModalStore } from '../../../stores/modalStore'
import { colors } from '../../../theme/constants/colors'
import { RefinementListCallback } from './RefinementListItem'
import { StudentFilterModalContent } from './StudentFilterModalContent'

const Button = styled('button')(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: 8,
  padding: '16px 24px',
  lineHeight: 1,
  borderRadius: 4,
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: theme.palette.colors.foam,
  transition: theme.transitions.create('background-color'),

  ':hover': {
    backgroundColor: theme.palette.colors.water10,
  },
}))

type Props = {
  onRefine: RefinementListCallback
  tracks: AlgoliaRefinementListItem[]
  jobPreferences: AlgoliaRefinementListItem[]
  status: AlgoliaRefinementListItem[]
  availability: AlgoliaRefinementListItem[]
  locations: AlgoliaRefinementListItem[]
  languages: AlgoliaRefinementListItem[]
  skills: AlgoliaRefinementListItem[]
  setNumberOfRefinements: (count: number) => void
  numberOfRefinements: number
  geolocation: Geolocation | null
  radius: number
}

export const StudentFilterModalButton: FC<Props> = ({
  onRefine,
  tracks,
  jobPreferences,
  status,
  availability,
  locations,
  setNumberOfRefinements,
  numberOfRefinements,
  languages,
  skills,
  geolocation,
  radius,
}) => {
  const setModal = useModalStore(state => state.setModal)
  const setModalContent = useModalStore(state => state.setModalContent)

  const applyRefinements: RefinementListCallback = useCallback(
    (
      tracks,
      jobPreferences,
      availability,
      status,
      locations,
      languages,
      skills,
      geolocation,
      radius
    ) => {
      let count = 0
      setModal(false)
      tracks.map(item => item.isRefined && count++)
      jobPreferences.map(item => item.isRefined && count++)
      availability.map(item => item.isRefined && count++)
      status.map(item => item.isRefined && count++)
      locations.map(item => item.isRefined && count++)
      languages.map(item => item.isRefined && count++)
      skills.map(item => item.isRefined && count++)
      if (geolocation) {
        count++
      }
      setNumberOfRefinements(count)
      onRefine(
        tracks,
        jobPreferences,
        availability,
        status,
        locations,
        languages,
        skills,
        geolocation,
        radius
      )
    },
    [setModal, onRefine, setNumberOfRefinements]
  )

  const handleOpenModal = useCallback(() => {
    setModalContent(
      <InstantSearch
        searchClient={searchClient}
        indexName={getIndexName('student')}
      >
        <StudentFilterModalContent
          onSubmit={applyRefinements}
          onClose={() => setModal(false)}
          tracks={tracks}
          jobPreferences={jobPreferences}
          status={status}
          availability={availability}
          locations={locations}
          languages={languages}
          skills={skills}
          geolocation={geolocation}
          radius={radius}
        />
      </InstantSearch>
    )
    setModal(true)
  }, [
    setModal,
    setModalContent,
    applyRefinements,
    tracks,
    jobPreferences,
    status,
    availability,
    locations,
    languages,
    skills,
    geolocation,
    radius,
  ])

  return (
    <Button
      onClick={handleOpenModal}
      sx={{
        border:
          '1px solid' + (numberOfRefinements > 0 ? colors.nemo : colors.foam),
      }}
    >
      <Box component={SettingsFilter} sx={{ flexShrink: 0 }} />
      <Typography variant="body2Strong">Filters</Typography>
      {numberOfRefinements > 0 && (
        <Typography
          sx={{
            background: colors.nemo,
            position: 'absolute',
            top: -10,
            right: -10,
            padding: '4px 8px',
            borderRadius: '10px',
            border: '5px solid' + colors.foam,
          }}
          variant="body2Strong"
          color="colors.foam"
        >
          {numberOfRefinements}
        </Typography>
      )}
    </Button>
  )
}
