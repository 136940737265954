import { styled } from '@mui/material'
import url from '../icons/loading.gif'

const Img = styled('img')`
  margin: 0 auto;
`

const LoadingIndicator = () => <Img src={url} alt="loading"></Img>

export default LoadingIndicator
