export const toLocaleDateString = (date: string | number | Date) =>
  new Date(date).toLocaleDateString()

export function getPastYears(number: number, date = new Date()) {
  const currentYear = date.getUTCFullYear()
  return Array(currentYear - (currentYear - number))
    .fill('')
    .map((_, index) => `${currentYear - index}`)
}

export const formatToDateTimeString = (isoString: string) => {
  if (!isoString) return ''

  const date = new Date(isoString)

  const day = date.getUTCDate()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = monthNames[date.getUTCMonth()]
  const year = date.getUTCFullYear()

  function getOrdinalSuffix(num: number) {
    if (num > 3 && num < 21) return 'th'
    switch (num % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const time = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date)

  return `${day}${getOrdinalSuffix(day)} of ${month} ${year} at ${time}`
}
