import { styled } from '@mui/material'
import DraggableWrapper from '../../../common-deprecated/components/DraggableWrapper'
import { BASE_URL } from '../../../config/backendConfig'
import { Bootcamp } from '../../../graphql/graphqlTypes'
import { useRemoveParticipantFromBootcamp } from '../../../graphql/bootcamp/removeParticipantFromBootcamp'
import { toast } from 'react-toastify'
import BootcampPerson from './BootcampPerson'

type BootcampPersonListProps = {
  bootcamp: Bootcamp
  roleId: string
  showCertificateLink?: boolean
  canDrag?: boolean
}

export default function BootcampPersonList({
  bootcamp,
  roleId,
  showCertificateLink,
  canDrag,
}: BootcampPersonListProps) {
  const persons = bootcamp.participants?.filter(
    participant => participant.role.id === roleId
  )

  const handleRemove = (personId: string, bootcampId: string) => {
    removeParticipantFromBootcamp({ personId, bootcampId })
  }

  const { removeParticipantFromBootcamp } = useRemoveParticipantFromBootcamp({
    onCompleted: () => toast.success('Person removed from bootcamp 🎉'),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const navigateToCertificate = (personId: string) => {
    const url = `${BASE_URL}/certificates/${bootcamp.id}/${personId}.pdf`

    window.open(url, '_blank')
  }

  const getConfirmDialogMessage = (
    bootcampId: string,
    firstName?: string | null,
    lastName?: string | null
  ) => {
    const profileName =
      firstName && lastName ? `${firstName} ${lastName}` : 'this profile'
    return `Are you sure you want to remove ${profileName} from ${bootcampId}?`
  }

  return (
    <section>
      <ScrollContainer>
        {persons?.map(participant => {
          const Person = (
            <BootcampPerson
              key={participant.person.id}
              participant={participant}
              handleRemove={handleRemove}
              showCertificateLink={showCertificateLink}
              navigateToCertificate={navigateToCertificate}
              getConfirmDialogMessage={getConfirmDialogMessage}
              bootcamp={bootcamp}
            />
          )
          if (!canDrag) {
            return Person
          }
          return (
            <DraggableWrapper
              key={participant.person.id}
              item={participant}
              type={roleId}
            >
              {Person}
            </DraggableWrapper>
          )
        })}
        {(!persons || persons.length === 0) && <p>No one there</p>}
      </ScrollContainer>
    </section>
  )
}

const ScrollContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 200px));
  overflow-y: auto;
`
