import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { FormEvent, useState } from 'react'
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web'

import { DeleteDisabled, Search } from '../../../assets/icons'
import { colors } from '../../../theme/constants/colors'

type Props = UseSearchBoxProps & {
  placeholder?: string
  sx?: BoxProps['sx']
}

export const SearchBox = (props: Props) => {
  const { query, refine, clear } = useSearchBox(props)
  const [value, setValue] = useState('')

  return (
    <Box
      component="form"
      onSubmit={(e: FormEvent) => e.preventDefault()}
      sx={{ flexGrow: 1, ...(props.sx || {}) }}
    >
      <TextField
        label={props.placeholder || 'Search'}
        onChange={event => {
          refine(event.currentTarget.value)
          setValue(event.currentTarget.value)
        }}
        value={value}
        sx={{ width: '100%' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {query ? (
                <IconButton
                  onClick={() => {
                    clear()
                    setValue('')
                  }}
                >
                  <DeleteDisabled />
                </IconButton>
              ) : (
                <Box component={Search} fontSize={24} color={colors.granite} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
