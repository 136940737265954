import React, { FC, FormEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import TextAreaWithLabel from '../../common-deprecated/components/TextAreaWithLabel'
import { CurriculumColumn, LanguageCode } from '../../graphql/graphqlTypes'
import {
  mapInputToDto,
  mapToText,
  removeTypenameFromCurriculumColumn,
} from './curriculum-service'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { Button, styled, Typography } from '@mui/material'
import { useGetDefaultBootcampCurriculum } from '../../graphql/curriculum/getDefaultBootcampCurriculumService'
import { useUpdateDefaultCurriculum } from '../../graphql/curriculum/updateDefaultCurriculumService'
import Select from '../../common-deprecated/components/Select'
import { useListBootcampTracks } from '../../graphql/bootcamp/listBootcampTracks'
import { languageOptions } from '../../service/language-service'
import { BASE_URL } from '../../config/backendConfig'
import { getClient } from '../../service/auth/auth-client'
import { formatToDateTimeString } from '../../service/date-service'
import ImportantNotes from '../../common/components/atoms/ImportantNotes/ImportantNotes'
import BetaBadge from '../../common/components/atoms/BetaBadge/BetaBadge'

const EditDefaultCurriculum: FC = () => {
  const [trackId, setTrackId] = useState('java')
  const [language, setLanguage] = useState(LanguageCode.DE)
  const [curriculumContent, setCurriculumContent] = useState<
    CurriculumColumn[]
  >([])
  const { tracks } = useListBootcampTracks()
  const { curriculum, editor, created } = useGetDefaultBootcampCurriculum(
    trackId,
    language
  )
  const initialCurriculumContentRef = useRef<CurriculumColumn[]>([])

  const { updateDefaultCurriculumEntry, loading } = useUpdateDefaultCurriculum({
    onCompleted: () => toast.success(`Default curriculum updated`),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    updateDefaultCurriculumEntry(trackId, language, {
      curriculumColumns: curriculumContent,
    })
    setIsPreviewClicked(false)
  }
  const [isPreviewClicked, setIsPreviewClicked] = useState(false)

  const navigateToPreview = async () => {
    setIsPreviewClicked(true)
    const url = `${BASE_URL}/curriculum/preview/${trackId}/${language}`
    const client = await getClient()

    await client
      .post(url, {
        curriculumColumns: curriculumContent,
      })
      .then(() =>
        window.open(
          `${BASE_URL}/curriculum/preview/${trackId}/${language}.pdf`,
          '_blank'
        )
      )
      .catch(e => console.error(e.message))
  }

  const handleChange = (
    event: {
      target: HTMLTextAreaElement
    },
    toUpdateIndex: number
  ) => {
    const lines = event.target.value.split(/\r?\n|\r/)
    const updatedEntries = lines.map(input => mapInputToDto(input))

    const updatedCurriculum = curriculumContent.map((curriculum, index) =>
      index === toUpdateIndex
        ? { curriculumEntries: updatedEntries }
        : curriculum
    )
    setCurriculumContent(updatedCurriculum)
  }

  useEffect(() => {
    if (curriculum) {
      const curriculumWithoutTypeNames = curriculum.curriculumColumns.map(
        removeTypenameFromCurriculumColumn
      )
      setCurriculumContent(curriculumWithoutTypeNames)
      initialCurriculumContentRef.current = curriculumWithoutTypeNames
    }
  }, [curriculum])

  const hasChanges =
    JSON.stringify(initialCurriculumContentRef.current) !==
    JSON.stringify(curriculumContent)

  const handleSelectTrack = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIsPreviewClicked(false)

    setTrackId(event.target.value)
  }

  const handleSelectLanguage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsPreviewClicked(false)
    setLanguage(event.target.value as LanguageCode)
  }

  const notes = [
    'this curriculum will be shown on the second page of the certificate.',
    'changing the default curriculum will not affect already created bootcamps.',
    'please review your changes carefully by creating a preview before saving',
  ]

  return (
    <Wrapper>
      <DeprecatedGlobalStyles />
      <>
        <Typography variant="h5">
          Default Curriculum
          <BetaBadge />
        </Typography>
        <SelectContainer>
          <Select
            srOnly
            labelText="Tracks"
            name="trackId"
            onChange={handleSelectTrack}
            value={trackId}
            options={tracks}
          />
          <Select
            srOnly
            labelText="Languages"
            name="LanguageCode"
            onChange={handleSelectLanguage}
            value={language}
            options={languageOptions}
          />
        </SelectContainer>
        {curriculumContent && (
          <Grid>
            <TextAreaWithLabel
              onChange={event => handleChange(event, 0)}
              value={mapToText(curriculumContent[0]?.curriculumEntries)}
              rows={25}
              labelText="Column 1"
              disabled={loading}
            />
            <TextAreaWithLabel
              onChange={event => handleChange(event, 1)}
              value={mapToText(curriculumContent[1]?.curriculumEntries)}
              rows={25}
              labelText="Column 2"
              disabled={loading}
            />
            <TextAreaWithLabel
              onChange={event => handleChange(event, 2)}
              value={mapToText(curriculumContent[2]?.curriculumEntries)}
              rows={25}
              labelText="Column 3"
              disabled={loading}
            />
            <TextAreaWithLabel
              onChange={event => handleChange(event, 3)}
              value={mapToText(curriculumContent[3]?.curriculumEntries)}
              rows={25}
              labelText="Column 4"
              disabled={loading}
            />
          </Grid>
        )}
      </>
      {editor && created && (
        <p>
          last update by {editor} ({formatToDateTimeString(created)})
        </p>
      )}
      <ImportantNotes notes={notes} />
      <ButtonElement disabled={!hasChanges} onClick={() => navigateToPreview()}>
        Preview
      </ButtonElement>
      <ButtonElement
        onClick={handleSubmit}
        type="submit"
        disabled={loading || !isPreviewClicked}
      >
        {isPreviewClicked ? 'Save' : 'Please check Preview before saving'}
      </ButtonElement>
    </Wrapper>
  )
}

const ButtonElement = styled(Button)`
  justify-self: end;
`

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
`

const Wrapper = styled('form')`
  display: grid;
  align-content: start;
  grid-gap: var(--spacing-m);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);
  overflow-y: auto;

  h1 {
    text-align: center;
    margin: 0;
  }
`

const SelectContainer = styled('div')`
  display: flex;
  gap: 1rem;
`

export default EditDefaultCurriculum
