import { gql, useQuery } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { useAuth } from '../../common/contexts/AuthContext'
import { personFragment } from '../fragments/personFragment'
import { graphqlClient } from '../GraphqlClient'
import { Person } from '../graphqlTypes'

export const GET_USER = gql`
  ${personFragment}
  query GetUser {
    user {
      ...Person
    }
  }
`

export const useUser = (options?: BaseMutationOptions) => {
  const { isAuthenticated } = useAuth()
  const query = useQuery<{ user: Person | undefined }>(GET_USER, {
    ...options,
    skip: !isAuthenticated,
  })
  const person = query.data?.user

  return { ...query, person }
}

export const refetchUser = async () => {
  await graphqlClient.refetchQueries({
    include: [GET_USER],
  })
}
