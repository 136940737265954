import { Stack, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  MutationInvitePersonArgs,
  Partner,
  PersonTypeEnum,
} from '../../../../graphql/graphqlTypes'
import { refetchPartner } from '../../../../graphql/partner/usePartnerDetails'
import { useInvitePerson } from '../../../../graphql/person/useInvitePerson'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

type FormValues = Pick<
  MutationInvitePersonArgs,
  'email' | 'firstName' | 'lastName'
>

type FormPartnerType = {
  onCancel: () => void
  onSuccess: () => void
}

export const FormPartnerMember = ({
  partnerId,
  onCancel,
  onSuccess,
}: FormPartnerType & {
  partnerId: string
  partner?: Partner
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  const {
    field: fieldFirstName,
    fieldState: fieldFirstNameState,
  } = useController({
    name: 'firstName',
    control,
    rules: {
      required: `Please enter your first name`,
    },
  })

  const {
    field: fieldLastName,
    fieldState: fieldLastNameState,
  } = useController({
    name: 'lastName',
    control,
    rules: {
      required: `Please enter your last name`,
    },
  })

  const { field: fieldEmail, fieldState: fieldEmailState } = useController({
    name: 'email',
    control,
    rules: {
      required: `Please enter your email`,
    },
  })

  const { invitePerson, loading } = useInvitePerson({
    onCompleted: data => {
      toast.success(
        `${data?.invitePerson.firstName} ${data?.invitePerson.lastName} (${data?.invitePerson.email}) invited 🎉`
      )
      refetchPartner()
      onSuccess()
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      if (data) {
        invitePerson({
          partnerId,
          personType: PersonTypeEnum.PARTNER_MEMBER,
          ...data,
        })
      }
    },
    [invitePerson, partnerId]
  )

  return (
    <ProfileForm
      title="Invite partner member"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
      loading={loading}
      submitText="Invite member"
    >
      <Stack component="form" spacing={8}>
        <Stack spacing={4}>
          <Stack spacing={4} direction={{ sm: 'column', md: 'row' }}>
            <TextField
              label="First name"
              {...fieldFirstName}
              error={!!fieldFirstNameState.error}
              helperText={fieldFirstNameState.error?.message}
              fullWidth
            />
            <TextField
              label="Last name"
              {...fieldLastName}
              error={!!fieldLastNameState.error}
              helperText={fieldLastNameState.error?.message}
              fullWidth
            />
          </Stack>
          <TextField
            label="Email"
            {...fieldEmail}
            error={!!fieldEmailState.error}
            helperText={fieldEmailState.error?.message}
          />
        </Stack>
      </Stack>
    </ProfileForm>
  )
}
