import { Card, CardContent, Stack, Typography } from '@mui/material'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'

import { SearchPagination } from '../common/components/molecules/SearchPagination'
import { CompanySearchHits } from '../common/components/organisims/Company/CompanySearchHits'
import { getIndexName, searchClient } from '../config/algoliaConfig'

const HITS_PER_PAGE = 6

export const PageCompanies = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('partner')}
    >
      <Configure hitsPerPage={HITS_PER_PAGE} filters="disabled:false" />
      <Stack spacing={8}>
        <Card>
          <CardContent>
            <Stack spacing={4}>
              <Typography variant="h5">Companies</Typography>
              <Typography>
                Learn about all the companies you can reach on the Talent App.
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <CompanySearchHits />

        <SearchPagination inCard />
      </Stack>
    </InstantSearch>
  )
}
