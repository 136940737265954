import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Shape04 } from '../common/assets/svgs'
import { NotificationBox } from '../common/components/atoms/NotificationBox/NotificationBox'
import { ContentList } from '../common/components/molecules/ContentList'
import { ProfileParticipation } from '../common/components/molecules/ProfileParticipation'
import { ProfileSection } from '../common/components/molecules/ProfileSection'
import { Tags } from '../common/components/molecules/Tags'
import { Contact } from '../common/components/organisims/Contact'
import { ButtonModal } from '../common/components/organisims/Contact/ButtonModal'
import { FormProfileCapstone } from '../common/components/organisims/Profile/Forms/FormProfileCapstone'
import { FormProfileInfo } from '../common/components/organisims/Profile/Forms/FormProfileInfo'
import { FormProfileJobPreferences } from '../common/components/organisims/Profile/Forms/FormProfileJobPreferences'
import { FormProfileSkills } from '../common/components/organisims/Profile/Forms/FormProfileSkills'
import { ModalProfileLanguages } from '../common/components/organisims/Profile/ModalProfileLanguages'
import { ProfileCareer } from '../common/components/organisims/Profile/ProfileCareer'
import { ProfileCareerModal } from '../common/components/organisims/Profile/ProfileCareer/ProfileCareerModal'
import { ProfileCurrently } from '../common/components/organisims/Profile/ProfileCareer/ProfileCurrently'
import { ProfileJobPreferences } from '../common/components/organisims/Profile/ProfileJobPreferences'
import { ProfileCapstoneProjects } from '../common/components/organisims/ProfileCapstoneProjects'
import { ReactComponent as IconGitHub } from '../common/theme/icons/iconGithub.svg'
import { ReactComponent as IconPlay } from '../common/theme/icons/iconPlay.svg'
import { PersonTypeEnum } from '../graphql/graphqlTypes'
import { useGetOwnPartnerMessages } from '../graphql/partner/useGetOwnPartnerMessages'
import { useGetPerson } from '../graphql/person/useGetPerson'
import { useUser } from '../graphql/person/useUser'
import {
  getPersonName,
  getPersonNameMultiline,
} from '../service/profile/person-service'

const Main = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.down('lg')]: {
    '.MuiCard-root': {
      borderRadius: 0,
    },
  },

  [theme.breakpoints.up('lg')]: {
    gap: 20,
  },
}))

const Divider = ({ inCard = false }: { inCard?: boolean }) => {
  if (inCard) {
    return (
      <CardContent sx={{ paddingY: { xs: 4, lg: 2 } }}>
        <MuiDivider light />
      </CardContent>
    )
  }
  return (
    <Grid item xs={12} sx={{ display: { lg: 'none' } }}>
      <CardContent
        sx={{
          paddingY: { xs: 4, lg: 2 },
          ':last-child': { paddingBottom: { xs: 4, lg: 2 } },
        }}
      >
        <MuiDivider light />
      </CardContent>
    </Grid>
  )
}

export const PageProfile = () => {
  const { id } = useParams<{ id: string }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const { person } = useGetPerson({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: id!,
    },
  })

  const { person: user } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })
  const { hash } = useLocation()
  const hashId = hash.substring(1)
  const hashElement = document.getElementById(hashId)

  useEffect(() => {
    hashElement?.scrollIntoView()
  }, [hashElement])

  const isPartnerMember = user?.personType === 'PARTNER_MEMBER'
  const { studentMessages } = useGetOwnPartnerMessages(isPartnerMember)
  const isOwnProfile = useMemo(() => Boolean(id && user?.id === id), [id, user])

  const participationWithCap = useMemo(
    () =>
      person?.participates?.filter(
        p => p.bootcamp.track.hasCapstone && p.capstoneProject
      ) || [],
    [person]
  )

  const latestMessage = studentMessages.find(
    message => message.student.id === person.id
  )

  const getSenderOfMessage = () => {
    if (latestMessage?.partnerMember.id === user?.id) {
      return 'you'
    } else {
      const sender = latestMessage?.partnerMember
      return `${sender?.firstName} ${sender?.lastName}`
    }
  }

  const getContactDate = () => {
    return new Intl.DateTimeFormat('en-EN', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(
      latestMessage?.created ? new Date(latestMessage?.created) : new Date()
    )
  }

  const [currentId, setCurrentId] = useState<string>()

  const current = useMemo(
    () =>
      participationWithCap.find(p => p.id === currentId) ||
      participationWithCap.find(p => p.capstoneProject.name) ||
      participationWithCap[0],
    [currentId, participationWithCap]
  )

  const introActions = useMemo(() => {
    const actions = []
    if (person.gitHubUrl) {
      actions.push(
        <Button
          href={person.gitHubUrl}
          size="medium"
          target="_blank"
          variant="outlined"
          rel="noreferrer noopener"
          startIcon={<IconGitHub />}
          key="githubButton"
        >
          GitHub
        </Button>
      )
    }
    if (person.videoUrl) {
      actions.push(
        <Button
          href={person.videoUrl}
          size="medium"
          target="_blank"
          variant="outlined"
          rel="noreferrer noopener"
          startIcon={<IconPlay />}
          key="videoButton"
        >
          Video
        </Button>
      )
    }

    if (
      user &&
      user.personType === PersonTypeEnum.PARTNER_MEMBER &&
      !isOwnProfile
    ) {
      actions.push(
        <ButtonModal
          title={
            person.jobPreferences?.available ? 'Make contact' : 'Not available'
          }
          modalContent={<Contact person={person} />}
          disabled={!person.jobPreferences?.available}
          key="contactButton"
        />
      )
    }
    return actions
  }, [isOwnProfile, person, user])

  const profileIsEmpty = useMemo(
    () =>
      !isOwnProfile &&
      !current?.capstoneProject?.name &&
      !person?.jobExperiences?.length &&
      !person?.jobPreferences?.available &&
      !person?.skills?.length &&
      !person?.languageSkills?.length,
    [current, isOwnProfile, person]
  )

  if (!person?.id) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <Main>
      <Grid container spacing={{ lg: 6 }}>
        {/* Info section */}
        <Grid item xs={12}>
          {latestMessage && (
            <NotificationBox>
              Contacted by <strong>{getSenderOfMessage()}</strong> on
              <strong> {getContactDate()}</strong>
            </NotificationBox>
          )}
          <Card>
            <ProfileSection
              isIntro
              avatar={
                <Avatar
                  src={person?.avatar?.urls.s}
                  alt={getPersonName(person)}
                  variant={isMobile ? 'rounded' : 'rounded-big'}
                />
              }
              title={getPersonNameMultiline(person)}
              subheader={
                <ProfileParticipation
                  items={person?.participates.map(p => p.bootcamp.track.name)}
                  trainingProvider={person.trainingProvider}
                />
              }
              isOwnProfile={isOwnProfile}
              content={
                person.location || person.shortDescription ? (
                  <>
                    {person.location && (
                      <Typography variant="body2Strong">
                        {person.location}
                      </Typography>
                    )}
                    {person.shortDescription && (
                      <Typography variant="body2">
                        {person.shortDescription}
                      </Typography>
                    )}
                  </>
                ) : null
              }
              actions={introActions.length ? introActions : null}
              editModal={<FormProfileInfo personId={person.id} />}
            />
          </Card>
        </Grid>

        {profileIsEmpty && (
          <Grid
            item
            xs={12}
            sx={{ [theme.breakpoints.down('xl')]: { order: 2 } }}
          >
            <Card>
              <CardContent>
                <Stack spacing={6} textAlign="center" paddingY={20}>
                  <Typography variant="title2" fontWeight={600}>
                    Profile is empty.
                  </Typography>
                  <Typography>
                    This person hasn’t filled out their profile.
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          xl={5}
          sx={{ [theme.breakpoints.down('xl')]: { order: 2 } }}
        >
          <Card>
            {current?.bootcamp.track.hasCapstone &&
              (isOwnProfile || current.capstoneProject.name) && (
                <>
                  <ProfileSection
                    title="Capstone Project"
                    isOwnProfile={isOwnProfile}
                    content={
                      <ProfileCapstoneProjects
                        projects={participationWithCap}
                        current={current}
                        setCurrent={setCurrentId}
                        withNamesOnly={!isOwnProfile}
                      />
                    }
                    editModal={
                      current ? (
                        <FormProfileCapstone
                          personId={person.id}
                          {...current.capstoneProject}
                          bootcamp={current.bootcamp}
                        />
                      ) : null
                    }
                  />
                  <Divider inCard />
                </>
              )}

            <ProfileSection
              title="Career"
              isOwnProfile={isOwnProfile}
              content={
                person.jobExperiences.length ? (
                  <ProfileCareer
                    jobExperiences={person.jobExperiences}
                    personId={id}
                    isOwnProfile={isOwnProfile}
                  />
                ) : null
              }
              editModal={
                id ? (
                  <ProfileCareerModal
                    personId={id}
                    isOwnProfile={isOwnProfile}
                  />
                ) : null
              }
            />
          </Card>
        </Grid>

        {!profileIsEmpty && <Divider />}

        <Grid item xs={12} xl={5}>
          <Card>
            {!!person.jobExperiences.length && (
              <>
                <ProfileSection
                  isOwnProfile={isOwnProfile}
                  title="Latest"
                  content={
                    <ProfileCurrently jobExperiences={person.jobExperiences} />
                  }
                />

                <Divider inCard />
              </>
            )}

            <ProfileSection
              title="Job Preferences"
              isOwnProfile={isOwnProfile}
              content={
                person.jobPreferences.available ? (
                  <ProfileJobPreferences
                    jobPreferences={person.jobPreferences}
                  />
                ) : null
              }
              editModal={
                <FormProfileJobPreferences
                  jobPreferences={person.jobPreferences}
                  id={person.id}
                />
              }
            />

            {(person.jobPreferences.available || isOwnProfile) && (
              <Divider inCard />
            )}

            <ProfileSection
              title="Skills"
              isOwnProfile={isOwnProfile}
              content={
                person.skills.length ? <Tags tags={person.skills} /> : null
              }
              editModal={
                <FormProfileSkills id={person.id} skills={person.skills} />
              }
            />

            {(!!person.skills.length || isOwnProfile) && <Divider inCard />}

            <ProfileSection
              title="Languages"
              isOwnProfile={isOwnProfile}
              content={
                person.languageSkills.length ? (
                  <ContentList
                    items={person.languageSkills.map(l => ({
                      headline: capitalize(l.language),
                      subline: capitalize(l.level),
                    }))}
                    icon={
                      <Box component={Shape04} fontSize={10} marginTop={1} />
                    }
                  />
                ) : null
              }
              editModal={
                <ModalProfileLanguages
                  personId={id}
                  isOwnProfile={isOwnProfile}
                />
              }
            />
          </Card>

          <Divider />
        </Grid>
      </Grid>
    </Main>
  )
}
