import { Box, Typography } from '@mui/material'

import { JobPreferences } from '../../../../../graphql/graphqlTypes'
import { geolocationDtoToShortName } from '../../../../../service/geolocation-service'

export interface ProfileJobPreferencesProps {
  jobPreferences: JobPreferences
}

function mapPreferences(
  preferences: JobPreferences
): {
  timePreferences?: string
  locationPreferences?: string
} {
  const {
    remote,
    partTime,
    fullTime,
    freelance,
    internship,
    flexible,
    geolocationPreferences,
  } = preferences

  const workTime = []

  if (partTime) {
    workTime.push('Part-Time')
  }
  if (fullTime) {
    workTime.push('Full-Time')
  }
  if (freelance) {
    workTime.push('Freelance')
  }
  if (internship) {
    workTime.push('Internship')
  }

  const allLocations = geolocationPreferences.map(geolocationDtoToShortName)

  if (remote) {
    allLocations.unshift('Remote')
  }
  if (flexible) {
    allLocations.unshift('Flexible')
  }

  return {
    timePreferences: workTime.length ? workTime.join(' / ') : undefined,
    locationPreferences: allLocations.length
      ? allLocations.join(' / ')
      : undefined,
  }
}

export const ProfileJobPreferences = ({
  jobPreferences,
}: ProfileJobPreferencesProps) => {
  const { timePreferences, locationPreferences } = mapPreferences(
    jobPreferences
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: 8,
        rowGap: 4,
      }}
    >
      {timePreferences && (
        <>
          <Typography color="colors.grey">How</Typography>
          <Typography variant="body2Strong">{timePreferences}</Typography>
        </>
      )}
      {locationPreferences && (
        <>
          <Typography color="colors.grey">Where</Typography>
          <Typography variant="body2Strong">{locationPreferences}</Typography>
        </>
      )}
    </Box>
  )
}
