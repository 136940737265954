import { CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { useAuth } from '../common/contexts/AuthContext'
import { LayoutCenteredBox } from '../common/layouts/LayoutCenteredBox'
import { LayoutPage } from '../common/layouts/LayoutPage'
import { PageLogin } from '../pages/PageLogin'
import { PageSetNewPassword } from '../pages/PageSetNewPassword'
import { AppRoutes } from './AppRoutes'
import { EventRoutes } from './EventRoutes'
import { PublicRoutes } from './PublicRoutes'

const SignupFlow = (props: { children: JSX.Element }) => {
  const { isAuthenticated, isNewPasswordRequired, isAuthPending } = useAuth()

  if (isAuthPending) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  if (!isAuthenticated) {
    return (
      <LayoutCenteredBox>
        <PageLogin />
      </LayoutCenteredBox>
    )
  } else if (isNewPasswordRequired) {
    return (
      <LayoutCenteredBox>
        <PageSetNewPassword />
      </LayoutCenteredBox>
    )
  }

  return <LayoutPage>{props.children}</LayoutPage>
}

export const RoutesResolver = () => {
  const location = useLocation()
  const onEventPage = location.pathname.startsWith('/event')
  const onPublicPage = location.pathname.startsWith('/meta')

  if (onEventPage) {
    return (
      <LayoutPage>
        <EventRoutes />
      </LayoutPage>
    )
  }

  if (onPublicPage) {
    return <PublicRoutes />
  }

  return (
    <SignupFlow>
      <AppRoutes />
    </SignupFlow>
  )
}
