import { Grid } from '@mui/material'

import { BootcampParticipation } from '../../../../graphql/graphqlTypes'
import { ProfileLink } from '../ProfileLink'

export interface BootcampParticipantsListProps {
  participants: BootcampParticipation[]
}

export const BootcampParticipantsList = ({
  participants,
}: BootcampParticipantsListProps) => {
  if (participants.length === 0) return null

  return (
    <Grid
      container
      columns={{
        xs: 1,
        md: 2,
        xl: 3,
      }}
      spacing={6}
    >
      {participants?.map(participant => (
        <Grid item xs={1} key={participant.person.id}>
          <ProfileLink
            id={participant.person.id}
            firstName={participant.person.firstName ?? ''}
            lastName={participant.person.lastName ?? ''}
            avatarUrl={participant.person?.avatar?.urls?.m}
          />
        </Grid>
      ))}
    </Grid>
  )
}
