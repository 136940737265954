import { Components, SvgIcon } from '@mui/material'

import { colors } from '../constants/colors'
import { ReactComponent as IconCheck } from '../icons/iconCheck.svg'
import { muiDefaultTheme } from './theme'

export const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    icon: <SvgIcon />,
    checkedIcon: <SvgIcon component={IconCheck} inheritViewBox />,
  },
  styleOverrides: {
    root: {
      marginTop: -9,
      marginBottom: -9,
      padding: 9,
      marginLeft: 2,
      marginRight: 2,

      '&.Mui-focusVisible > .MuiSvgIcon-root': {
        outline: '1px solid',
        outlineColor: colors.purple,
      },

      '& > .MuiSvgIcon-root': {
        borderRadius: 4,
        backgroundColor: colors.water,
      },

      '&.Mui-checked > .MuiSvgIcon-root': {
        backgroundColor: colors.nemo,
        color: colors.foam,
      },

      '&.Mui-disabled.Mui-checked > .MuiSvgIcon-root': {
        backgroundColor: colors.lightNemo,
      },

      '&.Mui-error:not(.Mui-checked) > .MuiSvgIcon-root': {
        boxShadow: `inset 0 0 0 2px ${muiDefaultTheme.palette.error.main}`,
      },
    },
  },
}
