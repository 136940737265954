import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useController, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Person } from '../../../../graphql/graphqlTypes'
import { useDeletePerson } from '../../../../graphql/person/useDeletePerson'
import { useAuth } from '../../../contexts/AuthContext'

export const SettingsDeletePerson = (person: Person) => {
  const { logoutUser } = useAuth()
  const navigate = useNavigate()

  const fullname = `${person.firstName} ${person.lastName}`

  const { control, handleSubmit, formState } = useForm<{ confirm: string }>({
    defaultValues: {
      confirm: '',
    },
    mode: 'onChange',
  })

  const { field: fieldConfirm, fieldState: fieldConfirmState } = useController({
    name: 'confirm',
    control,
    rules: {
      required: 'Can not be empty',
      validate: value => value === fullname || 'not correct',
    },
  })

  const { deletePerson, loading: deleteLoading } = useDeletePerson({
    onCompleted: () => {
      toast.success('Account deleted')
      logoutUser().then(() => navigate('/'))
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  if (!person?.id) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <Stack
      component="form"
      spacing={6}
      marginBottom={10}
      onSubmit={handleSubmit(() => deletePerson(person.id))}
    >
      <Typography variant="title2">Delete account</Typography>

      <Typography variant="body2">
        Your profile and your data will be completely deleted. This cannot be
        undone.
      </Typography>

      <Typography variant="body2Strong">
        Confirm by typing in your full name: <br />“{fullname}”
      </Typography>

      <TextField
        label="Confirm"
        {...fieldConfirm}
        error={!!fieldConfirmState.error}
        helperText={fieldConfirmState.error?.message || ' '}
      />

      <Box>
        <LoadingButton
          disabled={!formState.isValid}
          loading={deleteLoading}
          type="submit"
        >
          Delete account
        </LoadingButton>
      </Box>
    </Stack>
  )
}
