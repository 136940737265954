import React, { FC, useState } from 'react'
import { styled } from '@mui/material'
import ConfirmDialog from '../../../common-deprecated/components/ConfirmDialog'
import { Bootcamp, BootcampParticipation } from '../../../graphql/graphqlTypes'
import Avatar from '../../../common-deprecated/components/avatar/Avatar'
import Card from '../../../common-deprecated/components/Card'
import { Block, Download, RemoveCircle } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const BootcampPerson: FC<{
  participant: BootcampParticipation
  showCertificateLink?: boolean
  navigateToCertificate: (personId: string) => void
  getConfirmDialogMessage: (
    id: string,
    firstname?: string | null,
    lastName?: string | null
  ) => string
  bootcamp: Bootcamp
  handleRemove: (personId: string, bootcampId: string) => void
}> = ({
  participant,
  showCertificateLink,
  navigateToCertificate,
  getConfirmDialogMessage,
  bootcamp,
  handleRemove,
}) => {
  const [showDeleteProfile, setShowDeleteProfile] = useState(false)

  return (
    <StyledCard>
      <Avatar src={participant.person.avatar?.urls.l} size={80} />
      <NameLink to={`/profile/${participant.person.id}`} target="_blank">
        <h4>
          {participant.person.firstName || '-'} <br />
          {participant.person.lastName || '-'}
        </h4>
      </NameLink>
      {showCertificateLink && (
        <ButtonStyled
          className={'download-button'}
          onClick={() => navigateToCertificate(participant.person.id)}
        >
          {participant.certificateReady ? <Download /> : <Block />}
          Certificate
        </ButtonStyled>
      )}
      <ButtonStyled
        className={'remove-button'}
        aria-label="Delete participant"
        onClick={() => setShowDeleteProfile(true)}
      >
        <RemoveCircle /> Remove
      </ButtonStyled>

      {showDeleteProfile && (
        <ConfirmDialog
          title="Remove from Bootcamp?"
          description={getConfirmDialogMessage(
            participant.bootcamp.id,
            participant.person.firstName,
            participant.person.lastName
          )}
          onConfirm={() => {
            handleRemove(participant.person.id, bootcamp.id)
            setShowDeleteProfile(false)
          }}
          onAbort={() => setShowDeleteProfile(false)}
        />
      )}
    </StyledCard>
  )
}

export default BootcampPerson

const NameLink = styled(Link)`
  color: inherit;
`
const ButtonStyled = styled('button')`
  background-color: transparent;
  border: none;
  justify-self: end;
  display: grid;
  grid-template-columns: auto auto;
  color: var(--accent);
  grid-gap: 8px;
  align-items: center;
`
const StyledCard = styled(Card)`
  position: relative;
  text-align: center;
  padding: var(--spacing-m);
  border-radius: var(--spacing-xs);
  margin: 10px;
  h3 {
    margin-bottom: 0;
  }
  h4 {
    margin-bottom: 15px;
  }
`
