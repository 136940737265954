import { Button } from '@mui/material'
import { ReactNode, useCallback } from 'react'

import { useModalStore } from '../../../stores/modalStore'

export interface ProfileSectionProps {
  title: string
  modalContent?: ReactNode
  disabled?: boolean
}

export const ButtonModal = ({
  title,
  modalContent,
  disabled,
}: ProfileSectionProps) => {
  const modal = useModalStore(state => state.modal)
  const setModal = useModalStore(state => state.setModal)
  const setModalContent = useModalStore(state => state.setModalContent)

  const openModal = useCallback(() => {
    if (!modal && modalContent) {
      setModalContent(modalContent)
      setModal(true)
    }
  }, [modalContent, modal, setModal, setModalContent])

  return (
    <Button
      variant="contained"
      size="medium"
      onClick={openModal}
      disabled={disabled}
    >
      {title}
    </Button>
  )
}
