import { Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PersonTypeEnum } from '../../../../graphql/graphqlTypes'
import { useUser } from '../../../../graphql/person/useUser'
import { useAuth } from '../../../contexts/AuthContext'
import { CustomDrawer } from '../../atoms/Drawer'
import { ProfileButton } from '../../molecules/ProfileButton'

export const TopNavigation = () => {
  const { logoutUser, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  const { person } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const logoutAndRedirectToLogin = useCallback(
    () => logoutUser().then(() => navigate('/login')),
    [logoutUser, navigate]
  )

  const menuItems = useMemo(() => {
    const items: { text: string; url: string; onClick?: () => void }[] = []

    if (person?.id) {
      if (person.personType === PersonTypeEnum.STUDENT) {
        items.push({
          text: 'Profile',
          url: `/profile/${person.id}`,
        })
      }

      items.push({
        text: 'Settings',
        url: `/settings`,
      })
    }

    if (isAuthenticated) {
      items.push({
        text: 'Logout',
        url: '/logout',
        onClick: logoutAndRedirectToLogin,
      })
    } else {
      items.push({
        text: 'Login',
        url: '/login',
      })
    }

    return items
  }, [isAuthenticated, logoutAndRedirectToLogin, person])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (url: string) => {
    setAnchorEl(null)
    navigate(url)
  }

  return (
    <>
      <ProfileButton onClick={handleClick} />

      {/* {isAuthenticated && <MyProfile />} */}

      {isMobile ? (
        <CustomDrawer open={open} anchor="top" onClose={handleClose}>
          {menuItems?.map(item => (
            <MenuItem
              divider={false}
              onClick={
                item.onClick ? item.onClick : () => handleClose(item.url)
              }
              key={item.text}
            >
              {item.text}
            </MenuItem>
          ))}
        </CustomDrawer>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            variant: 'light-padded',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            marginTop: 1,
          }}
        >
          {menuItems?.map(item => (
            <MenuItem
              divider={false}
              onClick={
                item.onClick ? item.onClick : () => handleClose(item.url)
              }
              key={item.text}
            >
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
