import 'react-toastify/dist/ReactToastify.min.css'

import { ApolloProvider } from '@apollo/client'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { toast } from 'react-toastify'

import { GlobalStylesContainer } from './common/components/organisims/GlobalStylesContainer'
import { ThemeProvider } from './common/contexts/ThemeProvider'
import { graphqlClient } from './graphql/GraphqlClient'
import { MaintenancePage } from './pages/MaintenancePage'
import { RoutesResolver } from './routing/RoutesResolver'
import { useInitGrowthBook } from './service/growthbook/useInitGrowthBook'
import { useMaintenance } from './service/maintenance/useMaintenance'

toast.configure()

function App() {
  const { growthbookLoading, growthbook } = useInitGrowthBook()
  const { maintenanceLoading, isMaintenance } = useMaintenance()

  if (growthbookLoading || maintenanceLoading) {
    return null
  }

  if (isMaintenance) {
    return <MaintenancePage />
  }

  return (
    <>
      <ThemeProvider>
        <ApolloProvider client={graphqlClient}>
          <GrowthBookProvider growthbook={growthbook}>
            <GlobalStylesContainer>
              <RoutesResolver />
            </GlobalStylesContainer>
          </GrowthBookProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  )
}

export default App
