import ReactMarkdown from 'react-markdown'
import { Route, Routes } from 'react-router-dom'

import { useMarkdown } from '../common/hooks/useMarkdown'
import { LayoutCenteredBoxOutlet } from '../common/layouts/LayoutCenteredBox'
import privacyPolicyPath from '../markdown/privacy-policy.md'
import { PageTermsOfUse } from '../pages/PageTermsOfUse'

export const PublicRoutes = () => {
  const privacyPolicy = useMarkdown(privacyPolicyPath)

  return (
    <Routes>
      <Route path="/meta" element={<LayoutCenteredBoxOutlet disableImage />}>
        <Route
          path="privacy-policy"
          element={
            <div>
              <ReactMarkdown>{privacyPolicy}</ReactMarkdown>
            </div>
          }
        />
        <Route path="terms-of-use" element={<PageTermsOfUse />} />
      </Route>
    </Routes>
  )
}
