import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { colors } from '../common/theme/constants/colors'

export const MaintenancePage = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={theme => ({
        height: '100%',
        backgroundColor: colors.water,
        [theme.breakpoints.down('lg')]: { margin: 4 },
      })}
      spacing={10}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="h3" mb={6}>
          🛠️ Maintenance 🛠️
        </Typography>
        <Typography textAlign="center">
          The Talent App is unavailable but will be back soon with a newer,
          fresher look and feel! We will let you know once it is back.
        </Typography>
      </Box>
    </Stack>
  )
}
