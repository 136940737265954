import { useMediaQuery, useTheme } from '@mui/material'
import { ReactNode, useMemo } from 'react'

import { PersonTypeEnum } from '../../../../graphql/graphqlTypes'
import {
  AddressBookEmail,
  Blackboard,
  EmployeeSearchLoupe,
  FilterMinimal,
  GraduateHat,
  GroupUser1,
  HighRiseBuilding,
  Home,
  ShieldUserProtection,
  UserProfile2,
} from '../../../assets/icons'

type NavItem = {
  label: string
  icon?: ReactNode
  url?: string
  action?: () => void
  secondary?: boolean
} & ({ url: string } | { action: () => void })

const home: NavItem = {
  label: 'Home',
  url: '/',
  icon: <Home />,
}

const profile = (id: string): NavItem => ({
  label: 'My Profile',
  url: `/profile/${id}`,
  icon: <UserProfile2 />,
})

const companies: NavItem = {
  label: 'Companies',
  url: '/companies',
  icon: <HighRiseBuilding />,
}

const talents: NavItem = {
  label: 'Talents',
  url: '/talents',
  icon: <EmployeeSearchLoupe />,
}

const contacts: NavItem = {
  label: 'Contacts',
  url: '/contacts',
  icon: <AddressBookEmail />,
}

const studentFilter: NavItem = {
  label: 'Filter',
  url: '/keyword-filter',
  icon: <FilterMinimal />,
  secondary: true,
}

const adminItem = (action: () => void): NavItem => ({
  label: 'Admin',
  action,
  icon: <ShieldUserProtection />,
})

const users: NavItem = {
  label: 'Users',
  url: '/admin/users',
  icon: <EmployeeSearchLoupe />,
  secondary: true,
}

const bootcamps: NavItem = {
  label: 'Bootcamps',
  url: '/admin/bootcamps',
  icon: <Blackboard />,
  secondary: true,
}
const curriculum: NavItem = {
  label: 'Curriculum',
  url: '/admin/edit-curriculum',
  icon: <GraduateHat />,
  secondary: true,
}

const partners: NavItem = {
  label: 'Partners',
  url: '/admin/partners',
  icon: <GroupUser1 />,
  secondary: true,
}
const partnerActivity: NavItem = {
  label: 'Partner Activity',
  url: '/admin/partnerActivity',
  icon: <GroupUser1 />,
  secondary: true,
}

export const useNavItems = ({
  personType,
  moreAction,
  id,
  isAdmin,
}: {
  personType?: PersonTypeEnum
  moreAction: () => void
  id?: string
  isAdmin: boolean
}): { navItems: NavItem[]; drawerItems: NavItem[]; hasDrawer: boolean } => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const isPartner = personType === 'PARTNER_MEMBER'
  const isStudent = personType === 'STUDENT'
  const isB2B = personType === 'PARTNER_MANAGEMENT'

  const allNavItems = useMemo(() => {
    if (isPartner) {
      return [home, talents, contacts].filter(Boolean) as NavItem[]
    }

    if (isStudent) {
      return [home, id && profile(id), talents, companies].filter(
        Boolean
      ) as NavItem[]
    }

    if (isB2B && !isAdmin) {
      return [home, talents, companies, studentFilter]
    }

    if (isB2B && isAdmin) {
      return [
        home,
        talents,
        companies,
        studentFilter,
        users,
        bootcamps,
        curriculum,
        partnerActivity,
        partners,
      ]
    }

    const items = [home, talents, companies]

    if (isAdmin) {
      items.push(users, bootcamps, curriculum, partnerActivity, partners)
    }

    return items.filter(Boolean) as NavItem[]
  }, [id, isAdmin, isPartner, isStudent, isB2B])

  const drawerItems = useMemo(() => {
    if (allNavItems.filter(i => i.secondary).length) {
      return allNavItems.filter(i => i.secondary)
    }

    return []
  }, [allNavItems])

  const navItems = useMemo(() => {
    const items = allNavItems.filter(i => !i.secondary)

    if (isMobile && drawerItems.length) {
      items.push(adminItem(moreAction))
    }

    return items
  }, [allNavItems, drawerItems.length, isMobile, moreAction])

  return {
    navItems,
    drawerItems,
    hasDrawer: !!drawerItems.length,
  }
}
