import { Button } from '@neuefische/design-system'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputWithLabel from '../../../common-deprecated/components/InputWithLabel'
import Select from '../../../common-deprecated/components/Select'
import {
  DefaultLanguage,
  languageOptions,
} from '../../../service/language-service'
import { useListBootcampTracks } from '../../../graphql/bootcamp/listBootcampTracks'
import { useListLocations } from '../../../graphql/bootcamp/listLocations'
import { useBootcampManagement } from '../../../graphql/bootcamp/manageBootcampService'
import {
  LanguageCode,
  MutationAddBootcampArgs,
} from '../../../graphql/graphqlTypes'
import useBootcampValidation from '../hooks/useBootcampValidation'
import InputValidationMessages from './InputValidationMessages'
import { styled } from '@mui/material'

type Props = {
  bootcamp?: AddBootcampFormData
}

type AddBootcampFormData = MutationAddBootcampArgs & {
  language: LanguageCode
}

const initialBootcamp: AddBootcampFormData = {
  id: '',
  trackId: 'web-development',
  startDate: '',
  endDate: '',
  locationId: 'hamburg',
  language: DefaultLanguage,
}

export default function CreateBootcampForm(props: Props) {
  const [bootcampToEdit, setBootcampToEdit] = useState(
    props.bootcamp || initialBootcamp
  )
  const {
    handleValidation,
    formValidation,
    isValidInput,
  } = useBootcampValidation(bootcampToEdit)

  const { tracks } = useListBootcampTracks()
  const { locations } = useListLocations()
  const navigate = useNavigate()

  const { addBootcamp, updateBootcamp, loading } = useBootcampManagement({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      if (!props.bootcamp) {
        setBootcampToEdit(initialBootcamp)
        navigate(`/admin/bootcamp/${bootcampToEdit.id}`)
        toast.success('Bootcamp successfully created 🎉')
      } else {
        toast.success('Bootcamp successfully updated 🎉')
      }
    },
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!props.bootcamp) {
      addBootcamp(bootcampToEdit)
    } else {
      updateBootcamp(bootcampToEdit)
    }
  }

  const handleChange = (event: {
    target: HTMLInputElement | HTMLSelectElement
  }) => {
    setBootcampToEdit({
      ...bootcampToEdit,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <section>
      <Form onSubmit={handleSubmit}>
        <InputWithLabel
          labelText="Bootcamp Abbreviation"
          srOnly
          onBlur={handleValidation}
          onChange={handleChange}
          value={bootcampToEdit.id}
          type="text"
          name="id"
          disabled={!!props.bootcamp}
          placeholder="Bootcamp Abbreviation, e.g. cgn-java-21-2"
        />

        <Select
          srOnly
          labelText="Tracks"
          name="trackId"
          onChange={handleChange}
          value={bootcampToEdit.trackId}
          options={tracks}
        />
        <Select
          srOnly
          labelText="Language"
          name="language"
          options={languageOptions}
          onChange={handleChange}
          value={bootcampToEdit.language}
        />
        <InputWithLabel
          labelText="Start:"
          onBlur={handleValidation}
          type="date"
          name="startDate"
          onChange={handleChange}
          value={bootcampToEdit.startDate}
        />
        <InputWithLabel
          labelText="End:"
          onBlur={handleValidation}
          type="date"
          name="endDate"
          onChange={handleChange}
          value={bootcampToEdit.endDate}
        />
        <Select
          srOnly
          labelText="Location"
          name="locationId"
          onChange={handleChange}
          value={bootcampToEdit.locationId}
          options={locations}
        />
        <Button
          disabled={loading || !isValidInput}
          variant="primary"
          label="Save"
        />
      </Form>
      <InputValidationMessages formValidation={formValidation} />
    </section>
  )
}

const Form = styled('form')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-m);

  button {
    grid-column: 3;
    justify-self: end;
    text-align: center;
  }

  select,
  input {
    height: 100%;
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
  }
`
