import { Box, Button, Stack, Typography } from '@mui/material'
import { FC } from 'react'

import {
  CapstoneProject as CapstoneProjectType,
  Device,
} from '../../../graphql/graphqlTypes'
import { RightArrow } from '../../assets/icons'
import { CapstoneImageView } from '../molecules/CapstoneImage'
import { Tags } from '../molecules/Tags'

type Props = {
  capstoneProject: CapstoneProjectType
}

export const CapstoneProject: FC<Props> = ({
  capstoneProject: {
    name,
    description,
    techStack,
    repositoryUrl,
    youtubeUrl,
    appUrl,
    image,
    device,
  },
}) => {
  return (
    <>
      {image?.urls.l && (
        <Box padding={8}>
          <CapstoneImageView
            src={image.urls.l}
            srcXl={image.urls.xl}
            device={device ?? Device.SMARTPHONE}
          />
        </Box>
      )}

      {name && (
        <Typography variant="body2Strong" mb={4}>
          {name}
        </Typography>
      )}

      {description && <Typography mb={6}>{description}</Typography>}

      {techStack.length > 0 && (
        <Box mb={8}>
          <Typography mb={4} color="colors.grey">
            Technologies used
          </Typography>
          <Tags tags={techStack} />
        </Box>
      )}

      {(repositoryUrl || youtubeUrl || appUrl) && (
        <Stack direction="row" flexWrap="wrap">
          {repositoryUrl && (
            <Button
              href={repositoryUrl}
              target="_blank"
              variant="contained"
              size="small"
              rel="noreferrer noopener"
            >
              Repository
            </Button>
          )}

          {youtubeUrl && (
            <Button
              href={youtubeUrl}
              target="_blank"
              variant="contained"
              color="secondary"
              size="small"
              rel="noreferrer noopener"
              endIcon={<RightArrow />}
            >
              Watch Video
            </Button>
          )}

          {appUrl && (
            <Button
              href={appUrl}
              target="_blank"
              variant="contained"
              color="secondary"
              size="small"
              rel="noreferrer noopener"
              endIcon={<RightArrow />}
            >
              Visit App
            </Button>
          )}
        </Stack>
      )}
    </>
  )
}
