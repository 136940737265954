import {
  Autocomplete,
  Button,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { capitalize } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  LanguageLevel,
  LanguageSkill,
  LanguageSkillInput,
} from '../../../../../graphql/graphqlTypes'
import { useSetPersonLanguageSkill } from '../../../../../graphql/person/useSetPersonLanguageSkill'
import { ProfileForm } from '../Forms/ProfileForm'

export interface FormProfileLanguage extends Partial<LanguageSkillInput> {
  title: string
  personId: string
  currentLanguageSkills: LanguageSkill[]
  onCancel?: () => void
  onSubmit?: () => void
  allLanguages: string[]
}

export const FormProfileLanguage = ({
  title,
  language,
  level,
  personId,
  currentLanguageSkills,
  onCancel,
  onSubmit,
  allLanguages,
}: FormProfileLanguage) => {
  const languageOptions = useMemo(() => {
    const options = Object.values(allLanguages).filter(
      l => !currentLanguageSkills.some(c => c.language === l)
    )

    if (language) {
      options.push(language)
      options.sort()
    }

    return options
  }, [currentLanguageSkills, language, allLanguages])

  const levelOptions = [
    LanguageLevel.BASIC,
    LanguageLevel.GOOD,
    LanguageLevel.FLUENT,
    LanguageLevel.PROFESSIONAL,
    LanguageLevel.NATIVE,
  ]

  const { control, handleSubmit, formState } = useForm<
    Partial<LanguageSkillInput>
  >({
    mode: 'onChange',
    defaultValues: {
      language: language,
      level: level,
    },
  })

  const {
    field: fieldLanguage,
    fieldState: fieldLanguageState,
  } = useController({
    name: 'language',
    control,
    rules: {
      required: `Please select a language`,
    },
  })

  const { field: fieldLevel, fieldState: fieldLevelState } = useController({
    name: 'level',
    control,
    rules: {
      required: 'Please choose a proficiency',
    },
  })

  const {
    setPersonLanguageSkill,
    deletePersonLanguageSkill,
  } = useSetPersonLanguageSkill(currentLanguageSkills, {
    onCompleted: () => {
      if (onSubmit) {
        onSubmit()
      }
      toast.success('Language updated')
    },
    onError: () => toast.error('Error updating language'),
  })

  const submit = useCallback(
    (data: Partial<LanguageSkillInput>) => {
      if (data) {
        setPersonLanguageSkill({
          personId,
          languageSkill: data as LanguageSkillInput,
        })
      }
    },
    [personId, setPersonLanguageSkill]
  )

  return (
    <ProfileForm
      title={title}
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
      formState={formState}
    >
      <Stack component="form" spacing={4}>
        <Stack>
          <Autocomplete
            {...fieldLanguage}
            value={fieldLanguage.value || ''}
            onChange={(event, data) => fieldLanguage.onChange(data)}
            options={languageOptions}
            getOptionLabel={option => (option ? capitalize(option) : option)}
            isOptionEqualToValue={(option, value) =>
              option.toUpperCase() === value
            }
            disabled={!!language}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="Language"
                placeholder="Enter language"
                error={!!fieldLanguageState.error}
              />
            )}
          />
          {!!fieldLanguageState.error && (
            <FormHelperText error>
              {fieldLanguageState.error.message}
            </FormHelperText>
          )}
        </Stack>

        <TextField
          select
          {...fieldLevel}
          value={fieldLevel.value || ''}
          label="Proficiency"
          placeholder="Choose proficiency"
          error={!!fieldLevelState.error}
          helperText={fieldLevelState.error?.message}
        >
          {levelOptions.map(l => (
            <MenuItem key={l} value={l}>
              {capitalize(l)}
            </MenuItem>
          ))}
        </TextField>

        {language && (
          <Button
            variant="text"
            onClick={() => deletePersonLanguageSkill({ personId, language })}
          >
            Delete language
          </Button>
        )}
      </Stack>
    </ProfileForm>
  )
}
