import { Box } from '@mui/material'
import { useHits } from 'react-instantsearch-hooks-web'

import type { AlgoliaPartnerHit } from '../../../../types/algolia.d'
import { CompanySearchHit } from '../../molecules/CompanySearchHit'

export const CompanySearchHits = () => {
  const { hits } = useHits<AlgoliaPartnerHit>()

  if (!hits.length) {
    return <p>No partners yet. Maybe create one?</p>
  }

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 6,

        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: `repeat(auto-fill, minmax(280px, 1fr))`,
        },
      })}
    >
      {hits.map(hit => (
        <CompanySearchHit {...hit} key={hit.objectID} />
      ))}
    </Box>
  )
}
