import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import { ChevronDown, ChevronUp } from '../../../assets/icons'
import { colors } from '../../../theme/constants/colors'

type Props = {
  isShowingMore: boolean
  toggleShowMore: () => void
}

const BoxStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
}

export const ExtendAndCollapse: FC<Props> = ({
  toggleShowMore,
  isShowingMore,
}) => {
  return (
    <Box sx={BoxStyle} onClick={toggleShowMore}>
      <Typography variant="captionStrong" color={colors.grey}>
        {isShowingMore ? 'Show less' : 'Show more'}
      </Typography>
      {isShowingMore ? (
        <ChevronUp color={colors.grey} width="8px" />
      ) : (
        <ChevronDown color={colors.grey} width="8px" />
      )}
    </Box>
  )
}
