import {
  MutationInvitePersonArgs,
  PersonTypeEnum,
} from '../../../graphql/graphqlTypes'

export const checkIsFormValid = (formData: MutationInvitePersonArgs) => {
  switch (formData.personType) {
    case PersonTypeEnum.STUDENT:
      if (!formData.bootcampId || formData.partnerId) {
        return false
      }
      break
    case PersonTypeEnum.PARTNER_MEMBER:
      if (!formData.partnerId || formData.bootcampId) {
        return false
      }
      break
    default:
      if (formData.bootcampId || formData.partnerId) {
        return false
      }
  }
  return !!(formData.firstName && formData.lastName && formData.email)
}
