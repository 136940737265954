import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiBottomNavigation: Components['MuiBottomNavigation'] = {
  defaultProps: {
    showLabels: true,
  },
  styleOverrides: {
    root: {
      backgroundColor: colors.foam,
      borderTop: `1px solid ${colors.water10}`,
    },
  },
}
