import { Box, Card, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { CheckmarkCircle1, Circle } from '../../../assets/icons'

type ProfileCompletionBoxProps = {
  isComplete: boolean | undefined
  title: string
  description: string
  personId: string
}

export const ProfileCompletionBox = ({
  isComplete,
  title,
  description,
  personId,
}: ProfileCompletionBoxProps) => {
  const titleColor = isComplete ? 'colors.lightGrey' : 'primary.granite'
  const descriptionColor = isComplete ? 'colors.lightGrey' : 'primary.grey'
  const CheckIcon = isComplete ? CheckmarkCircle1 : Circle

  return (
    <Link
      to={{
        pathname: `/profile/${personId}`,
        hash: title,
      }}
    >
      <Card
        sx={theme => ({
          border: `1px solid ${theme.palette.colors.water10}`,
          borderRadius: '5px',
          cursor: isComplete ? 'default' : 'pointer',
          padding: 6,
          transition: theme.transitions.create(['border-color', 'outline']),

          '&:hover': !isComplete
            ? {
                borderColor: theme.palette.colors.nemo,
                outline: `1px solid ${theme.palette.colors.nemo}`,
              }
            : {},
        })}
      >
        <Stack direction="row" spacing={{ xs: 6, md: 6 }} alignItems="center">
          <Box
            component={CheckIcon}
            sx={{
              color: isComplete ? 'colors.nemo' : 'colors.water10',
              flexShrink: 0,
              fontSize: 40,
            }}
          />
          <div>
            <Typography variant="button" color={titleColor} fontSize={16}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color={descriptionColor}
              fontSize={14}
              mt={2}
            >
              {description}
            </Typography>
          </div>
        </Stack>
      </Card>
    </Link>
  )
}
