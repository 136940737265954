import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiDialogContent: Components['MuiDialogContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: 24,
      [muiDefaultTheme.breakpoints.up('lg')]: {
        padding: 32,
      },
    },
  },
}
