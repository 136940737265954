import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material'

import { useUpcomingEvents } from '../../../../graphql/events/useUpcomingEvents'
import { Carousel } from '../../molecules/Carousel'
import { EventTeaser } from '../EventTeaser'

export const UpcomingEvents = () => {
  const theme = useTheme()
  const { upcomingEvents } = useUpcomingEvents()

  const responsive = {
    xl: {
      breakpoint: {
        min: theme.breakpoints.values.xl,
        max: 5200,
      },
      items: 3,
    },
    lg: {
      breakpoint: {
        min: theme.breakpoints.values.lg,
        max: theme.breakpoints.values.xl,
      },
      items: 2,
    },
    sm: {
      breakpoint: {
        min: theme.breakpoints.values.sm,
        max: theme.breakpoints.values.lg,
      },
      items: 1,
    },
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={10}>
          <Stack spacing={4}>
            <Typography variant="h5">Upcoming Events</Typography>
            <Typography variant="body2">
              Upcoming events that feature the newest graduates.
            </Typography>
          </Stack>
          {!!upcomingEvents?.length && (
            // to prevent stack to mess with carousel margins
            <div>
              <Carousel responsive={responsive} gap={1.5} autoPlaySpeed={8000}>
                {upcomingEvents.map(event => (
                  <EventTeaser {...event} key={event.id} />
                ))}
              </Carousel>
            </div>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
