import { Partner } from '../graphql/graphqlTypes'

interface Location {
  line: number
  column: number
  sourceName: unknown
}

export interface Error {
  message: string
  locations: Location[]
  errorType: string
  path: unknown
  extensions: unknown
}

declare module '@apollo/client' {
  export interface QueryResult {
    errors?: Error[]
  }
}

export type PartnerSize =
  | '1-10'
  | '11-50'
  | '51-100'
  | '101-1000'
  | '1001-10000'
  | '10000+'

export const PartnerSizes: PartnerSize[] = [
  '1-10',
  '11-50',
  '51-100',
  '101-1000',
  '1001-10000',
  '10000+',
]

export type PartnerRevenueCategory =
  | '0-250k€'
  | '251-1.000k€'
  | '1-5Mio.€'
  | '6-50Mio.€'
  | '51-250Mio.€'
  | '251+Mio.€'

export const PartnerRevenueCategories: PartnerRevenueCategory[] = [
  '0-250k€',
  '251-1.000k€',
  '1-5Mio.€',
  '6-50Mio.€',
  '51-250Mio.€',
  '251+Mio.€',
]

export type CompanyTeaser = Pick<
  Partner,
  'id' | 'profileImage' | 'name' | 'industry'
>
