import { Components, Interpolation, Theme } from '@mui/material'

import { colors } from '../constants/colors'
import { typography } from './typography'

const lightStyles = {
  border: '1px solid',
  borderRadius: 8,
  boxSizing: 'content-box',
  '.MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
    '& > .MuiMenuItem-root': {
      ...typography.body2Strong,
      padding: '14px 16px',
      borderBottomColor: colors.water10,
      '&.Mui-focusVisible': {
        backgroundColor: colors.water,
      },
      '&:not([aria-selected="true"]):hover': {
        color: colors.nemo,
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
    },
  },
  backgroundColor: colors.foam,
  color: colors.granite,
  borderColor: colors.water10,
} as Interpolation<{
  theme: Theme
}>

const darkStyles = {
  border: '1px solid',
  borderRadius: 8,
  boxSizing: 'content-box',
  '.MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
    '& > .MuiMenuItem-root': {
      ...typography.body2Strong,
      padding: '14px 16px',
      borderBottomColor: colors.lightGrey,
      '&.Mui-focusVisible': {
        backgroundColor: colors.darkGrey,
      },
      '&:not([aria-selected="true"]):hover': {
        color: colors.nemo,
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
    },
  },
  backgroundColor: colors.granite,
  color: colors.foam,
  borderColor: colors.lightGrey,
} as Interpolation<{
  theme: Theme
}>

const paddedStyles = {
  padding: 24,
  '> .MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
} as Interpolation<{
  theme: Theme
}>

export const MuiPaper: Components['MuiPaper'] = {
  defaultProps: {
    elevation: 0,
    variant: 'elevation',
  },
  variants: [
    {
      props: {
        variant: 'light',
      },
      style: {
        ...(lightStyles as Record<string, unknown>),
        '> .MuiList-root > .MuiMenuItem-root': {
          borderRadius: 0,
        },
      } as Interpolation<{
        theme: Theme
      }>,
    },
    {
      props: {
        variant: 'light-padded',
      },
      style: {
        ...(lightStyles as Record<string, unknown>),
        ...(paddedStyles as Record<string, unknown>),
      } as Interpolation<{
        theme: Theme
      }>,
    },
    {
      props: {
        variant: 'dark',
      },
      style: {
        ...(darkStyles as Record<string, unknown>),
        '> .MuiList-root > .MuiMenuItem-root': {
          borderRadius: 0,
        },
      } as Interpolation<{
        theme: Theme
      }>,
    },
    {
      props: {
        variant: 'dark-padded',
      },
      style: {
        ...(darkStyles as Record<string, unknown>),
        ...(paddedStyles as Record<string, unknown>),
      } as Interpolation<{
        theme: Theme
      }>,
    },
  ],
}
