import { CircularProgress, Divider, Stack, Typography } from '@mui/material'

import { SettingsDeletePerson } from '../common/components/molecules/SettingsPerson'
import { EMailSettings } from '../common/components/molecules/SettingsPerson/EMailSettings'
import { NameSettings } from '../common/components/molecules/SettingsPerson/NameSettings'
import { PersonFormProfileAvatar } from '../common/components/organisims/Profile/Forms/PersonFormProfileAvatar'
import { PersonTypeEnum } from '../graphql/graphqlTypes'
import { useUser } from '../graphql/person/useUser'

export const PageSettings = () => {
  const { person } = useUser()
  return (
    <Stack spacing={10}>
      <Typography variant="h3">Settings</Typography>

      {!person?.id ? (
        <CircularProgress sx={{ margin: 'auto' }} />
      ) : (
        <>
          <Divider light />

          {/* Hide for types that have own profile page where they edit this */}
          {person.personType !== PersonTypeEnum.STUDENT && (
            <>
              <Stack spacing={8}>
                <Typography variant="title2">Picture</Typography>
                <PersonFormProfileAvatar
                  id={person.id}
                  avatar={person.avatar?.urls.s}
                />
              </Stack>
              <Divider light />
              <NameSettings {...person} />
              <Divider light />
            </>
          )}

          <EMailSettings {...person} />
          <Divider light />
          <SettingsDeletePerson {...person} />
        </>
      )}
    </Stack>
  )
}
