import { toast } from 'react-toastify'

import { Person } from '../../../../../graphql/graphqlTypes'
import useDeletePersonAvatar from '../../../../../graphql/person/useDeletePersonAvatar'
import { useSetPersonAvatar } from '../../../../../graphql/person/useSetPersonAvatar'
import { uploadImage } from '../../../../../service/image/image-upload-service'
import { FormProfileImage } from './FormProfileImage'

export interface StudentFormProfileAvatarProps extends Pick<Person, 'id'> {
  avatar?: string
}

export const PersonFormProfileAvatar = ({
  id,
  avatar,
}: StudentFormProfileAvatarProps) => {
  const { setPersonAvatar } = useSetPersonAvatar({
    onCompleted: () => {
      toast.success('Profile picture saved')
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const uploadImageToBucket = (image: File) => {
    return uploadImage(image)
  }

  const { deletePersonAvatar } = useDeletePersonAvatar({
    onError: error => toast.error(`${error.message} 🙀`),
  })

  const deletePersonImage = async () => {
    deletePersonAvatar()
  }

  const setAvatar = async (imageId: string, personId: string) => {
    setPersonAvatar({ imageId, personId })
  }

  return (
    <FormProfileImage
      id={id}
      image={avatar}
      deleteImage={deletePersonImage}
      setNewImage={setAvatar}
      uploadImage={uploadImageToBucket}
    />
  )
}
