import { MenuItem, Stack, TextField } from '@mui/material'

import type { BootcampParticipation } from '../../../../graphql/graphqlTypes'
import { EmptySection } from '../../molecules/EmptySection'
import { CapstoneProject } from '../CapstoneProject'

export interface ProfileCapstoneProjectsProps {
  projects: BootcampParticipation[]
  current: BootcampParticipation
  setCurrent: (id: string) => void
  withNamesOnly?: boolean
}

export const ProfileCapstoneProjects = ({
  projects,
  current,
  setCurrent,
  withNamesOnly,
}: ProfileCapstoneProjectsProps) => {
  const projectsWithName = withNamesOnly
    ? projects.filter(p => p.capstoneProject.name)
    : projects
  return (
    <Stack spacing={4}>
      {projectsWithName.length > 1 && (
        <TextField
          select
          onChange={e => setCurrent(e.target.value)}
          value={current.id}
          label="Select project"
        >
          {projectsWithName.map(project => (
            <MenuItem
              key={project.id}
              value={project.id}
              disabled={current.id === project.id}
            >
              {project.bootcamp.track.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {current.capstoneProject.name ? (
        <CapstoneProject capstoneProject={current.capstoneProject} />
      ) : (
        <EmptySection />
      )}
    </Stack>
  )
}
