import { gql, useQuery } from '@apollo/client'

import { PartnerStudentMessage } from '../graphqlTypes'

export const GET_OWN_PARTNER_MESSAGES = gql`
  query GetOwnPartnerMessages {
    getOwnPartnerMessages {
      student {
        id
      }
      partnerMember {
        id
        firstName
        lastName
      }
      created
    }
  }
`

export const useGetOwnPartnerMessages = (isPartnerMember: boolean) => {
  const query = useQuery<{ getOwnPartnerMessages: PartnerStudentMessage[] }>(
    GET_OWN_PARTNER_MESSAGES,
    { skip: !isPartnerMember }
  )
  const studentMessages = query.data?.getOwnPartnerMessages || []
  return {
    ...query,
    studentMessages,
  }
}
