import { Pagination } from '@neuefische/design-system'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { styled } from '@mui/material'
import BackLink from '../../common-deprecated/components/BackLink'
import LoadingIndicator from '../../common-deprecated/components/LoadingIndicator'
import SearchBar from '../../common-deprecated/components/SearchBar'
import { usePersons } from '../../common/hooks/usePersons'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { useAddParticipantToBootcamp } from '../../graphql/bootcamp/addParticipantToBootcampService'
import { useGetBootcampDetails } from '../../graphql/bootcamp/getBootcampDetailsService'
import PersonsSearchResult from './components/PersonsSearchResult'

export default function AddParticipantToBootcamp() {
  const { id } = useParams<{ id: string }>()

  const { bootcamp } = useGetBootcampDetails(id!)

  const {
    persons,
    currentPage,
    totalElements,
    pageSize,
    setSearch,
    setBootcampParticipant,
    setPersonType,
    completed,
    setPage,
  } = usePersons()

  const { addParticipantToBootcamp } = useAddParticipantToBootcamp({
    onCompleted: () => toast.success('Person added to Bootcamp 🎉'),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  function addToBootcamp(id: string, roleId: string) {
    return addParticipantToBootcamp({
      bootcampId: bootcamp!.id,
      roleId,
      personId: id,
    })
  }

  const filteredPersons = persons.filter(
    person =>
      !bootcamp!.participants?.find(
        participant => participant.person.id === person.id
      )
  )

  const getPagination = () => (
    <PaginationWrapper>
      <Pagination
        totalPages={Math.ceil(totalElements / pageSize)}
        page={currentPage + 1}
        onChange={page => setPage(page - 1)}
      />
    </PaginationWrapper>
  )

  return (
    <Wrapper>
      <DeprecatedGlobalStyles />
      {bootcamp && (
        <>
          <BackLink
            to={`/admin/bootcamp/${bootcamp.id}`}
            title={`Back to ${bootcamp.id} overview`}
          />
          <h1>Add participants to {bootcamp.id}</h1>
        </>
      )}
      <>
        <SearchBar
          placeholder="Search"
          onTypeChange={setPersonType}
          onInputChange={setSearch}
          onParticipatesBootcampChange={setBootcampParticipant}
        />
        {bootcamp && completed && totalElements > 0 ? (
          <>
            {getPagination()}
            <PersonsSearchResult
              persons={filteredPersons}
              onAddToBootcamp={addToBootcamp}
            />
            {getPagination()}
          </>
        ) : completed ? (
          <div>No one found</div>
        ) : (
          <LoadingIndicator />
        )}
      </>
    </Wrapper>
  )
}

const Wrapper = styled('main')`
  display: grid;
  grid-gap: var(--spacing-m);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);
  overflow-y: auto;

  h1 {
    text-align: center;
    margin: 0;
  }
`
const PaginationWrapper = styled('div')`
  justify-self: center;
  margin-top: 10px;
`
