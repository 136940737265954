import { Box, Dialog, IconButton } from '@mui/material'
import { capitalize } from 'lodash'
import { useState } from 'react'

import { LanguageSkill } from '../../../../graphql/graphqlTypes'
import { PenEdit } from '../../../assets/icons'
import { ContentListItem } from '../../molecules/ContentList'
import { FormProfileLanguage } from './Forms/FormProfileLanguage'

export const ModalProfileLanguageItem = ({
  personId,
  languageSkills,
  languageSkill,
  allLanguages,
}: {
  personId: string
  languageSkills: LanguageSkill[]
  languageSkill: LanguageSkill
  allLanguages: string[]
}) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 32,
        }}
      >
        <ContentListItem
          headline={capitalize(languageSkill.language)}
          subline={capitalize(languageSkill.level)}
        />
        <IconButton
          aria-label="edit"
          onClick={() => setModal(true)}
          color="inherit"
        >
          <PenEdit />
        </IconButton>
      </Box>

      <Dialog open={modal} onClose={() => setModal(false)}>
        <FormProfileLanguage
          allLanguages={allLanguages}
          personId={personId}
          currentLanguageSkills={languageSkills}
          {...languageSkill}
          title="Edit language"
          onCancel={() => setModal(false)}
          onSubmit={() => setModal(false)}
        />
      </Dialog>
    </>
  )
}
