import { Box, Stack, Typography } from '@mui/material'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'

import { SearchBox } from '../common/components/molecules/SearchBox'
import { SearchPagination } from '../common/components/molecules/SearchPagination'
import { AddPartner } from '../common/components/organisims/Partner/AddPartner'
import { PartnerSearchHits } from '../common/components/organisims/PartnerSearchHits'
import { getIndexName, searchClient } from '../config/algoliaConfig'

const HITS_PER_PAGE = 5

export const PagePartners = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('partner')}
    >
      <Configure hitsPerPage={HITS_PER_PAGE} />
      <Stack spacing={8}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Partners</Typography>
          <AddPartner />
        </Box>

        <SearchBox />

        <PartnerSearchHits />
        <SearchPagination />
      </Stack>
    </InstantSearch>
  )
}
