export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Void: void
}

export enum ApplyChannel {
  EMAIL = 'EMAIL',
  WEBSITE = 'WEBSITE',
}

export type Bootcamp = {
  __typename?: 'Bootcamp'
  curriculumDto: CurriculumDto
  endDate: Scalars['String']
  event: Event
  id: Scalars['ID']
  language: LanguageCode
  location: Location
  participants?: Maybe<Array<BootcampParticipation>>
  startDate: Scalars['String']
  teams?: Maybe<Array<Team>>
  track: BootcampTrack
}

export type BootcampParticipation = {
  __typename?: 'BootcampParticipation'
  bootcamp: Bootcamp
  capstoneProject: CapstoneProject
  certificateReady: Scalars['Boolean']
  id: Scalars['ID']
  person: Person
  role: BootcampParticipationRole
}

export type BootcampParticipationRole = {
  __typename?: 'BootcampParticipationRole'
  id: Scalars['ID']
  name: Scalars['String']
}

export type BootcampTrack = {
  __typename?: 'BootcampTrack'
  hasCapstone: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
}

export type CapstoneProject = {
  __typename?: 'CapstoneProject'
  appUrl?: Maybe<Scalars['String']>
  bootcamp: Bootcamp
  description?: Maybe<Scalars['String']>
  device?: Maybe<Device>
  id: Scalars['ID']
  image?: Maybe<Image>
  name?: Maybe<Scalars['String']>
  person: Person
  repositoryUrl?: Maybe<Scalars['String']>
  techStack: Array<Scalars['String']>
  youtubeUrl?: Maybe<Scalars['String']>
}

export type CompletionScore = {
  __typename?: 'CompletionScore'
  capstoneConsidered: Scalars['Boolean']
  score: Scalars['Int']
}

export type ContactedStudent = {
  __typename?: 'ContactedStudent'
  avatar?: Maybe<Image>
  email: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['String']
  lastName: Scalars['String']
  tracks: Array<Scalars['String']>
}

export type ContactedStudentFilter = {
  search?: InputMaybe<Scalars['String']>
}

export type ContactedStudentsResponse = {
  __typename?: 'ContactedStudentsResponse'
  contactedStudents: Array<ContactedStudent>
  current?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  totalElements?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

export type CurriculumColumn = {
  __typename?: 'CurriculumColumn'
  curriculumEntries: Array<CurriculumEntryDto>
}

export type CurriculumColumnInput = {
  curriculumEntries: Array<CurriculumEntryDtoInput>
}

export type CurriculumDto = {
  __typename?: 'CurriculumDto'
  curriculumColumns: Array<CurriculumColumn>
  id: Scalars['ID']
}

export type CurriculumDtoInput = {
  curriculumColumns: Array<CurriculumColumnInput>
}

export type CurriculumEntryDto = {
  __typename?: 'CurriculumEntryDto'
  level: Scalars['Int']
  title: Scalars['String']
}

export type CurriculumEntryDtoInput = {
  level: Scalars['Int']
  title: Scalars['String']
}

export type DefaultCurriculumDto = {
  __typename?: 'DefaultCurriculumDto'
  created?: Maybe<Scalars['String']>
  curriculumDto: CurriculumDto
  editor?: Maybe<Scalars['String']>
  language?: Maybe<LanguageCode>
  track?: Maybe<BootcampTrack>
}

export enum Device {
  DESKTOP = 'DESKTOP',
  SMARTPHONE = 'SMARTPHONE',
  TABLET_LANDSCAPE = 'TABLET_LANDSCAPE',
  TABLET_PORTRAIT = 'TABLET_PORTRAIT',
}

export enum EmploymentType {
  FREELANCE = 'freelance',
  FULLTIME = 'fullTime',
  INTERNSHIP = 'internship',
  PARTTIME = 'partTime',
}

export type Event = {
  __typename?: 'Event'
  bootcamp: Bootcamp
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  location?: Maybe<Scalars['String']>
  onlineEvent: Scalars['Boolean']
  published: Scalars['Boolean']
  techStack: Array<Scalars['String']>
  time?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type Geolocation = {
  __typename?: 'Geolocation'
  placeId: Scalars['String']
  text: Scalars['String']
}

export type GeolocationDto = {
  __typename?: 'GeolocationDto'
  name: Scalars['String']
  placeId: Scalars['String']
}

export type Image = {
  __typename?: 'Image'
  id: Scalars['ID']
  urls: ImageUrls
}

export type ImageUrls = {
  __typename?: 'ImageUrls'
  l: Scalars['String']
  m: Scalars['String']
  s: Scalars['String']
  xl: Scalars['String']
}

export type Job = {
  __typename?: 'Job'
  applyChannel?: Maybe<ApplyChannel>
  created: Scalars['String']
  description: Scalars['String']
  id: Scalars['ID']
  isFreelancer: Scalars['Boolean']
  isFullTime: Scalars['Boolean']
  isPartTime: Scalars['Boolean']
  isRemote: Scalars['Boolean']
  location: Scalars['String']
  title: Scalars['String']
  websiteUrl: Scalars['String']
}

export type JobExperience = {
  __typename?: 'JobExperience'
  companyName: Scalars['String']
  description?: Maybe<Scalars['String']>
  employmentType?: Maybe<EmploymentType>
  endDate?: Maybe<Scalars['String']>
  id: Scalars['ID']
  location?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  title: Scalars['String']
  type: JobExperienceType
}

export enum JobExperienceType {
  EDUCATION = 'education',
  EMPLOYMENT = 'employment',
}

export type JobPreferences = {
  __typename?: 'JobPreferences'
  available: Scalars['Boolean']
  flexible: Scalars['Boolean']
  freelance: Scalars['Boolean']
  fullTime: Scalars['Boolean']
  geolocationPreferences: Array<GeolocationDto>
  id: Scalars['ID']
  internship: Scalars['Boolean']
  partTime: Scalars['Boolean']
  remote: Scalars['Boolean']
}

export enum LanguageCode {
  DE = 'DE',
  EN = 'EN',
}

export enum LanguageLevel {
  BASIC = 'BASIC',
  FLUENT = 'FLUENT',
  GOOD = 'GOOD',
  NATIVE = 'NATIVE',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type LanguageSkill = {
  __typename?: 'LanguageSkill'
  id: Scalars['ID']
  language: Scalars['String']
  level: LanguageLevel
}

export type LanguageSkillInput = {
  language: Scalars['String']
  level: LanguageLevel
}

export type LatLng = {
  __typename?: 'LatLng'
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
}

export type Location = {
  __typename?: 'Location'
  id: Scalars['ID']
  name: Scalars['String']
}

export type MessageRequest = {
  partnerMemberId: Scalars['String']
  studentId: Scalars['String']
  text: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  addBootcamp?: Maybe<Bootcamp>
  addEntryToJobExperienceToPerson?: Maybe<Person>
  addJob?: Maybe<Job>
  addParticipantToBootcamp?: Maybe<Bootcamp>
  addParticipantToTeam?: Maybe<Scalars['Void']>
  addPartner?: Maybe<Partner>
  addTeam?: Maybe<Team>
  deleteBootcamp?: Maybe<Scalars['Void']>
  deleteEntryFromJobExperienceFromPerson?: Maybe<Scalars['Void']>
  deleteJob?: Maybe<Scalars['Void']>
  deleteOwnPersonAvatar?: Maybe<Scalars['Void']>
  deletePartner?: Maybe<Scalars['Void']>
  deletePartnerProfileBanner?: Maybe<Scalars['Void']>
  deletePartnerProfileImage?: Maybe<Scalars['Void']>
  deletePerson?: Maybe<Scalars['Void']>
  deleteTeam?: Maybe<Scalars['Void']>
  invitePerson?: Maybe<Person>
  reinvitePerson?: Maybe<Person>
  removeParticipantFromBootcamp?: Maybe<Scalars['Void']>
  removeParticipantFromTeam?: Maybe<Scalars['Void']>
  sendMessage?: Maybe<Scalars['Void']>
  setCapstoneProject?: Maybe<CapstoneProject>
  setConsentGiven?: Maybe<Person>
  setEventDetails?: Maybe<Event>
  setIsAdmin?: Maybe<Person>
  setPartnerBannerImage?: Maybe<Partner>
  setPartnerDisabled?: Maybe<Partner>
  setPartnerProfileImage?: Maybe<Partner>
  setPersonAvatar?: Maybe<Person>
  setPersonEMailAddress?: Maybe<Person>
  setPersonGitHubUrl?: Maybe<Person>
  setPersonJobPreferences?: Maybe<Person>
  setPersonLanguageSkills: Array<LanguageSkill>
  setPersonLocation?: Maybe<Person>
  setPersonName?: Maybe<Person>
  setPersonShortDescription?: Maybe<Person>
  setPersonSkills?: Maybe<Person>
  setPersonTeaser?: Maybe<Person>
  setPersonVideoUrl?: Maybe<Person>
  setTeamName?: Maybe<Team>
  updateBootcamp?: Maybe<Bootcamp>
  updateBootcampCurriculum: CurriculumDto
  updateDefaultCurriculum: CurriculumDto
  updateEntryFromJobExperienceFromPerson?: Maybe<Scalars['Void']>
  updateJob?: Maybe<Job>
  updatePartner?: Maybe<Partner>
}

export type MutationAddBootcampArgs = {
  endDate: Scalars['String']
  id: Scalars['String']
  language?: InputMaybe<LanguageCode>
  locationId: Scalars['String']
  startDate: Scalars['String']
  trackId: Scalars['String']
}

export type MutationAddEntryToJobExperienceToPersonArgs = {
  companyName: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  employmentType?: InputMaybe<EmploymentType>
  endDate?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['String']>
  personId: Scalars['String']
  startDate: Scalars['String']
  title: Scalars['String']
  type: Scalars['String']
}

export type MutationAddJobArgs = {
  applyChannel?: InputMaybe<ApplyChannel>
  description: Scalars['String']
  isFreelancer: Scalars['Boolean']
  isFullTime: Scalars['Boolean']
  isPartTime: Scalars['Boolean']
  isRemote: Scalars['Boolean']
  location: Scalars['String']
  title: Scalars['String']
  websiteUrl: Scalars['String']
}

export type MutationAddParticipantToBootcampArgs = {
  bootcampId: Scalars['String']
  personId: Scalars['String']
  roleId: Scalars['String']
}

export type MutationAddParticipantToTeamArgs = {
  bootcampId: Scalars['String']
  personId: Scalars['String']
  teamId: Scalars['String']
}

export type MutationAddPartnerArgs = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  homepageUrl?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
  revenueCategory?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  yearOfEstablishment?: InputMaybe<Scalars['Int']>
}

export type MutationAddTeamArgs = {
  bootcampId: Scalars['String']
}

export type MutationDeleteBootcampArgs = {
  id: Scalars['String']
}

export type MutationDeleteEntryFromJobExperienceFromPersonArgs = {
  id: Scalars['String']
  personId: Scalars['String']
}

export type MutationDeleteJobArgs = {
  id: Scalars['String']
}

export type MutationDeletePartnerArgs = {
  id: Scalars['String']
}

export type MutationDeletePartnerProfileBannerArgs = {
  partnerId: Scalars['String']
}

export type MutationDeletePartnerProfileImageArgs = {
  partnerId: Scalars['String']
}

export type MutationDeletePersonArgs = {
  personId: Scalars['String']
}

export type MutationDeleteTeamArgs = {
  teamId: Scalars['String']
}

export type MutationInvitePersonArgs = {
  bootcampId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  partnerId?: InputMaybe<Scalars['String']>
  personType: PersonTypeEnum
  trainingProvider?: InputMaybe<TrainingProviderEnum>
}

export type MutationReinvitePersonArgs = {
  id: Scalars['String']
}

export type MutationRemoveParticipantFromBootcampArgs = {
  bootcampId: Scalars['String']
  personId: Scalars['String']
}

export type MutationRemoveParticipantFromTeamArgs = {
  bootcampId: Scalars['String']
  personId: Scalars['String']
  teamId: Scalars['String']
}

export type MutationSendMessageArgs = {
  messageRequest: MessageRequest
}

export type MutationSetCapstoneProjectArgs = {
  appUrl?: InputMaybe<Scalars['String']>
  bootcampId: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  device?: InputMaybe<Device>
  imageId?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  personId: Scalars['String']
  repositoryUrl?: InputMaybe<Scalars['String']>
  techStack?: InputMaybe<Array<Scalars['String']>>
  youtubeUrl?: InputMaybe<Scalars['String']>
}

export type MutationSetConsentGivenArgs = {
  id: Scalars['String']
}

export type MutationSetEventDetailsArgs = {
  date: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  location?: InputMaybe<Scalars['String']>
  published?: InputMaybe<Scalars['Boolean']>
  techStack: Array<Scalars['String']>
  time: Scalars['String']
}

export type MutationSetIsAdminArgs = {
  id: Scalars['String']
  isAdmin: Scalars['Boolean']
}

export type MutationSetPartnerBannerImageArgs = {
  imageId: Scalars['String']
  partnerId: Scalars['String']
}

export type MutationSetPartnerDisabledArgs = {
  disabled: Scalars['Boolean']
  partnerId: Scalars['String']
}

export type MutationSetPartnerProfileImageArgs = {
  imageId: Scalars['String']
  partnerId: Scalars['String']
}

export type MutationSetPersonAvatarArgs = {
  imageId: Scalars['String']
  personId: Scalars['String']
}

export type MutationSetPersonEMailAddressArgs = {
  email: Scalars['String']
  id: Scalars['String']
}

export type MutationSetPersonGitHubUrlArgs = {
  gitHubUrl?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

export type MutationSetPersonJobPreferencesArgs = {
  available: Scalars['Boolean']
  flexible: Scalars['Boolean']
  freelance: Scalars['Boolean']
  fullTime: Scalars['Boolean']
  internship: Scalars['Boolean']
  partTime: Scalars['Boolean']
  personId: Scalars['String']
  placeIds: Array<Scalars['String']>
  remote: Scalars['Boolean']
}

export type MutationSetPersonLanguageSkillsArgs = {
  languageSkills: Array<LanguageSkillInput>
  personId: Scalars['String']
}

export type MutationSetPersonLocationArgs = {
  id: Scalars['String']
  location: Scalars['String']
}

export type MutationSetPersonNameArgs = {
  firstName: Scalars['String']
  id: Scalars['String']
  lastName: Scalars['String']
}

export type MutationSetPersonShortDescriptionArgs = {
  id: Scalars['String']
  shortDescription: Scalars['String']
}

export type MutationSetPersonSkillsArgs = {
  personId: Scalars['String']
  skills: Array<Scalars['String']>
}

export type MutationSetPersonTeaserArgs = {
  id: Scalars['String']
  teaser: Scalars['String']
}

export type MutationSetPersonVideoUrlArgs = {
  id: Scalars['String']
  videoUrl?: InputMaybe<Scalars['String']>
}

export type MutationSetTeamNameArgs = {
  name: Scalars['String']
  teamId: Scalars['String']
}

export type MutationUpdateBootcampArgs = {
  endDate: Scalars['String']
  id: Scalars['String']
  language: LanguageCode
  locationId: Scalars['String']
  startDate: Scalars['String']
  trackId: Scalars['String']
}

export type MutationUpdateBootcampCurriculumArgs = {
  bootcampId: Scalars['String']
  curriculumDto: CurriculumDtoInput
}

export type MutationUpdateDefaultCurriculumArgs = {
  curriculumDto: CurriculumDtoInput
  language: LanguageCode
  trackId: Scalars['String']
}

export type MutationUpdateEntryFromJobExperienceFromPersonArgs = {
  companyName: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  employmentType?: InputMaybe<EmploymentType>
  endDate?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  location?: InputMaybe<Scalars['String']>
  personId: Scalars['String']
  startDate: Scalars['String']
  title: Scalars['String']
  type: Scalars['String']
}

export type MutationUpdateJobArgs = {
  applyChannel?: InputMaybe<ApplyChannel>
  description: Scalars['String']
  id: Scalars['String']
  isFreelancer: Scalars['Boolean']
  isFullTime: Scalars['Boolean']
  isPartTime: Scalars['Boolean']
  isRemote: Scalars['Boolean']
  location: Scalars['String']
  title: Scalars['String']
  websiteUrl: Scalars['String']
}

export type MutationUpdatePartnerArgs = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  homepageUrl?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  industry?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
  revenueCategory?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  yearOfEstablishment?: InputMaybe<Scalars['Int']>
}

export type PaginationParameter = {
  page: Scalars['Int']
  size: Scalars['Int']
}

export type Partner = {
  __typename?: 'Partner'
  address?: Maybe<Scalars['String']>
  bannerImage?: Maybe<Image>
  city?: Maybe<Scalars['String']>
  created: Scalars['String']
  description: Scalars['String']
  disabled: Scalars['Boolean']
  homepageUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  industry?: Maybe<Scalars['String']>
  members: Array<Person>
  name: Scalars['String']
  postalCode?: Maybe<Scalars['String']>
  profileImage?: Maybe<Image>
  revenueCategory?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  yearOfEstablishment?: Maybe<Scalars['Int']>
}

export type PartnerStudentMessage = {
  __typename?: 'PartnerStudentMessage'
  created: Scalars['String']
  id: Scalars['ID']
  message: Scalars['String']
  partnerMember: Person
  student: Person
}

export type Person = {
  __typename?: 'Person'
  avatar?: Maybe<Image>
  consentGiven?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  gitHubUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAdmin?: Maybe<Scalars['Boolean']>
  jobExperiences: Array<JobExperience>
  jobPreferences: JobPreferences
  languageSkills: Array<LanguageSkill>
  lastName?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  participates: Array<BootcampParticipation>
  partner?: Maybe<Partner>
  personType: PersonTypeEnum
  shortDescription?: Maybe<Scalars['String']>
  skills: Array<Scalars['String']>
  status: Scalars['String']
  teaser?: Maybe<Scalars['String']>
  trainingProvider?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
}

export type PersonFilterInput = {
  bootcampParticipant?: InputMaybe<Scalars['Boolean']>
  personType?: InputMaybe<PersonTypeEnum>
  search?: InputMaybe<Scalars['String']>
}

export enum PersonTypeEnum {
  COACH = 'COACH',
  PARTNER_MANAGEMENT = 'PARTNER_MANAGEMENT',
  PARTNER_MEMBER = 'PARTNER_MEMBER',
  STUDENT = 'STUDENT',
  STUDENT_SERVICE = 'STUDENT_SERVICE',
}

export type PersonsResponse = {
  __typename?: 'PersonsResponse'
  current?: Maybe<Scalars['Int']>
  persons: Array<Maybe<Person>>
  size?: Maybe<Scalars['Int']>
  totalElements?: Maybe<Scalars['Int']>
}

export type ProfileCompletion = {
  __typename?: 'ProfileCompletion'
  completionScore: CompletionScore
  firstName?: Maybe<Scalars['String']>
  isBasicInfoComplete: Scalars['Boolean']
  isCapstoneComplete: Scalars['Boolean']
  isExperienceComplete: Scalars['Boolean']
  isJobPreferencesComplete: Scalars['Boolean']
  isLanguageComplete: Scalars['Boolean']
  isSkillsComplete: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  personId?: Maybe<Scalars['String']>
}

/** The Root Query for the application */
export type Query = {
  __typename?: 'Query'
  findAllByKeyword: Array<Maybe<Person>>
  getAllLanguages: Array<Scalars['String']>
  getBootcamp?: Maybe<Bootcamp>
  getBootcampCurriculum: CurriculumDto
  getContactedStudents?: Maybe<ContactedStudentsResponse>
  getDefaultBootcampCurriculum: DefaultCurriculumDto
  getEvent?: Maybe<Event>
  getGeolocationSuggestions: Array<Geolocation>
  getJob?: Maybe<Job>
  getLatLng: LatLng
  getMessagesForPartner: Array<PartnerStudentMessage>
  getOwnPartnerMessages: Array<PartnerStudentMessage>
  getPartner?: Maybe<Partner>
  getPerson?: Maybe<Person>
  getProfileCompletion: ProfileCompletion
  getProfileCompletionsByBootcamp: Array<Maybe<ProfileCompletion>>
  getUpcomingEvents: Array<Event>
  listBootcampParticipationRoles?: Maybe<
    Array<Maybe<BootcampParticipationRole>>
  >
  listBootcampTracks?: Maybe<Array<Maybe<BootcampTrack>>>
  listBootcamps?: Maybe<Array<Maybe<Bootcamp>>>
  listLocations: Array<Location>
  listPartners?: Maybe<Array<Maybe<Partner>>>
  listPersonTypes: Array<PersonTypeEnum>
  listPersons?: Maybe<PersonsResponse>
  listRandomPartners: Array<Partner>
  listTrainingProviders: Array<TrainingProviderEnum>
  searchForSuggestions: Array<SkillSuggestion>
  user?: Maybe<Person>
}

/** The Root Query for the application */
export type QueryFindAllByKeywordArgs = {
  keyword: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetBootcampArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetBootcampCurriculumArgs = {
  bootcampId: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetContactedStudentsArgs = {
  filter?: InputMaybe<ContactedStudentFilter>
  pagination?: InputMaybe<PaginationParameter>
}

/** The Root Query for the application */
export type QueryGetDefaultBootcampCurriculumArgs = {
  language: LanguageCode
  trackId: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetEventArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetGeolocationSuggestionsArgs = {
  text: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetJobArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetLatLngArgs = {
  placeId: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetMessagesForPartnerArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetPartnerArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetPersonArgs = {
  id: Scalars['String']
}

/** The Root Query for the application */
export type QueryGetProfileCompletionsByBootcampArgs = {
  bootcampId: Scalars['String']
}

/** The Root Query for the application */
export type QueryListBootcampsArgs = {
  endsAfter?: InputMaybe<Scalars['String']>
  endsBefore?: InputMaybe<Scalars['String']>
  startsAfter?: InputMaybe<Scalars['String']>
  startsBefore?: InputMaybe<Scalars['String']>
}

/** The Root Query for the application */
export type QueryListPersonsArgs = {
  filter?: InputMaybe<PersonFilterInput>
  pagination?: InputMaybe<PaginationParameter>
}

/** The Root Query for the application */
export type QueryListRandomPartnersArgs = {
  count?: InputMaybe<Scalars['Int']>
}

/** The Root Query for the application */
export type QuerySearchForSuggestionsArgs = {
  minCount: Scalars['Int']
  text: Scalars['String']
}

export type SkillSuggestion = {
  __typename?: 'SkillSuggestion'
  approved: Scalars['Boolean']
  count: Scalars['Int']
  name: Scalars['String']
}

export type Team = {
  __typename?: 'Team'
  id: Scalars['ID']
  name: Scalars['String']
  participants?: Maybe<Array<BootcampParticipation>>
}

export enum TrainingProviderEnum {
  NEUE_FISCHE = 'NEUE_FISCHE',
  SPICED_ACADEMY = 'SPICED_ACADEMY',
}
