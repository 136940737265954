import { BASE_URL } from '../../config/backendConfig'
import { getClient } from '../auth/auth-client'

export async function uploadImage(file: File, partnerId?: string) {
  const client = await getClient()
  const formData = new FormData()
  formData.append('imageData', file)

  return client
    .post(BASE_URL + '/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        partnerId,
      },
    })
    .then(res =>
      new Promise(resolve => {
        setTimeout(resolve, 1000)
      }).then(
        () =>
          res.data as {
            id: string
          }
      )
    )
}
