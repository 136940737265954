import { Box, Stack, Typography } from '@mui/material'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'

import { SearchPagination } from '../common/components/molecules/SearchPagination'
import { AddBootcamp } from '../common/components/organisims/Bootcamp/AddBootcamp'
import { BootcampSearchHits } from '../common/components/organisims/Bootcamp/BootcampSearchHits'
import { BootcampFilter } from '../common/components/organisims/BootcampFilter'
import { getIndexName, searchClient } from '../config/algoliaConfig'

const HITS_PER_PAGE = 5

export const PageBootcamps = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('bootcamp')}
    >
      <Configure hitsPerPage={HITS_PER_PAGE} />

      <Stack spacing={8}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Bootcamps</Typography>
          <AddBootcamp />
        </Box>

        <BootcampFilter />

        <BootcampSearchHits />

        <SearchPagination />
      </Stack>
    </InstantSearch>
  )
}
