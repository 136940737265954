import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'

import { Event } from '../../../../graphql/graphqlTypes'

export interface EventTeaserProps
  extends Pick<
    Event,
    | 'id'
    | 'description'
    | 'onlineEvent'
    | 'bootcamp'
    | 'date'
    | 'time'
    | 'token'
  > {
  type: 'info' | 'graduation'
}

export const EventTeaser = ({
  type = 'graduation', // TODO: replace with actual type
  ...event
}: EventTeaserProps) => {
  const date = event.date && new Date(event.date)

  return (
    <Card
      sx={{
        backgroundColor: type === 'graduation' ? 'colors.sand' : 'colors.water',
      }}
    >
      <CardContent sx={{ padding: '24px !important' }}>
        <Stack spacing={10} alignItems="flex-start">
          {date && event.date && (
            <time dateTime={event.date}>
              <Typography variant="introStrong">
                {format(date, 'dd')}
              </Typography>
              <Typography variant="body2">{format(date, 'MMMM')}</Typography>
            </time>
          )}

          <Stack spacing={2}>
            <Typography
              variant="captionStrong"
              sx={{
                color: type === 'graduation' ? 'colors.nemo' : 'colors.grey',
              }}
            >
              {type === 'graduation' ? 'Graduation' : 'Info'} Event
            </Typography>

            <div>
              <Typography variant="body2Strong">
                {`${event.bootcamp.track.name}:`}
              </Typography>
              <Typography variant="body2Strong">{event.bootcamp.id}</Typography>
            </div>
          </Stack>

          <Button href={`/event/${event.id}`}>Go to event</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
