import { Button, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { searchClient } from '../../../../config/algoliaConfig'
import { Partner } from '../../../../graphql/graphqlTypes'
import useSetPartnerDisabled from '../../../../graphql/partner/useSetPartnerDisabled'
import { ConfirmModal } from '../../molecules/ConfirmModal'

export const TogglePartnerDisabled = ({
  id,
  name,
  disabled,
  onSuccess,
}: Pick<Partner, 'id' | 'name' | 'disabled'> & { onSuccess: () => void }) => {
  const { setPartnerDisabled, loading } = useSetPartnerDisabled({
    onError: error => toast.error(`${error.message} 🙀`),
    onCompleted: () => {
      toast.success(`Successfully ${disabled ? 'disabled' : 'enabled'} ${name}`)
      onSuccess()
    },
  })

  const disableOrEnable = disabled ? 'Enable partner' : 'Disable partner'

  return (
    <ConfirmModal
      title={disableOrEnable + '?'}
      text={
        <>
          {disableOrEnable + ' '}
          <Typography variant="body2Strong" component="span">
            {name}
          </Typography>{' '}
          and all of its members?
        </>
      }
      onConfirm={() => {
        return setPartnerDisabled(id, !disabled).then(() =>
          searchClient.clearCache()
        )
      }}
      loading={loading}
    >
      <Button variant="text" component="div" color="error" fullWidth>
        {disableOrEnable}
      </Button>
    </ConfirmModal>
  )
}
