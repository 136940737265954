import { Components } from '@mui/material'

export const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    PaperProps: {
      sx: {
        p: 8,
      },
    },
  },
}
