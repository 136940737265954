import { toast } from 'react-toastify'

import { Person } from '../../../../../graphql/graphqlTypes'
import { useDeletePartnerBanner } from '../../../../../graphql/partner/useDeletePartnerImage'
import { useSetPartnerBannerImage } from '../../../../../graphql/person/useSetPartnerImage'
import { uploadImage } from '../../../../../service/image/image-upload-service'
import { FormProfileImage } from './FormProfileImage'

export interface PartnerFormProfileBannerProps extends Pick<Person, 'id'> {
  banner?: string
}

export const PartnerFormProfileBanner = ({
  id,
  banner,
}: PartnerFormProfileBannerProps) => {
  const { setPartnerBannerImage } = useSetPartnerBannerImage({
    onCompleted: () => {
      toast.success('Company logo saved')
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const uploadImageToBucket = (image: File) => {
    return uploadImage(image, id)
  }

  const { deletePartnerBanner } = useDeletePartnerBanner({
    onError: error => toast.error(`${error.message} 🙀`),
  })

  const deleteBanner = async (partnerId: string) => {
    deletePartnerBanner(partnerId)
  }

  const setBanner = async (imageId: string, partnerId: string) => {
    setPartnerBannerImage({ imageId, partnerId })
  }

  return (
    <FormProfileImage
      id={id}
      image={banner}
      deleteImage={deleteBanner}
      setNewImage={setBanner}
      uploadImage={uploadImageToBucket}
      ratio={5}
    />
  )
}
