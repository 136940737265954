import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { Fragment, ReactNode, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { EditButton } from '../common/components/molecules/EditButton'
import { ProfileSection } from '../common/components/molecules/ProfileSection'
import { AddPartnerMember } from '../common/components/organisims/Partner/AddPartnerMember'
import { DeletePartner } from '../common/components/organisims/Partner/DeletePartner'
import { DeletePartnerMember } from '../common/components/organisims/Partner/DeletePartnerMember'
import { FormEditPartnerBasicInfo } from '../common/components/organisims/Partner/FormEditPartnerBasicInfo'
import { FormEditPartnerMoreInfo } from '../common/components/organisims/Partner/FormEditPartnerMoreInfo'
import { TogglePartnerDisabled } from '../common/components/organisims/Partner/TogglePartnerDisabled'
import { useModalStore } from '../common/stores/modalStore'
import usePartnerDetails from '../graphql/partner/usePartnerDetails'
import { useUser } from '../graphql/person/useUser'
import { getPersonName } from '../service/profile/person-service'

const Main = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.up('lg')]: {
    gap: 20,
  },
}))

const InfoGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridAutoRows: '1fr',
  gap: 16,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',

    '> *:last-of-type': {
      gridColumn: 'span 2',
    },
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',

    '> *': {
      gridColumn: 'span 2',
    },

    '> *:nth-of-type(1), > *:nth-of-type(2)': {
      gridColumn: 'span 3',
    },
  },
}))

const InfoCard = ({ title, text }: { title: string; text: ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: 4,
      padding: '16px 20px',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'colors.water10',
    }}
  >
    <Typography color="colors.grey">{title}</Typography>
    <Typography variant="body2Strong">{text}</Typography>
  </Box>
)

export const PageCompanyDetails = () => {
  const { id } = useParams()
  const { loading, partner: company } = usePartnerDetails(id)
  const setModal = useModalStore(state => state.setModal)
  const navigate = useNavigate()
  const { person: user } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const isOwnProfile = useMemo(
    () =>
      Boolean(
        user &&
          (user.isAdmin ||
            company?.members.some(member => member.id === user.id))
      ),
    [company?.members, user]
  )

  const onDelete = useCallback(() => {
    if (user?.isAdmin) {
      navigate('/admin/partners')
    } else {
      navigate('/')
    }
  }, [user?.isAdmin, navigate])

  const companyUrl = useMemo(() => {
    let newUrl = company?.homepageUrl
      ?.replace('https://', '')
      .replace('http://', '')
    if (newUrl?.endsWith('/')) {
      newUrl = newUrl.replace(/.$/, '')
    }
    return newUrl
  }, [company?.homepageUrl])

  if (loading) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  if (!company) {
    return null
  }

  return (
    <Main>
      <Card
        sx={theme => ({
          [theme.breakpoints.down('lg')]: {
            borderRadius: 0,
          },
        })}
      >
        {(company.bannerImage || company.profileImage) && (
          <CardMedia
            component="img"
            sx={{
              aspectRatio: '5',
            }}
            src={
              company.bannerImage?.urls?.xl || company.profileImage?.urls?.xl
            }
          />
        )}
        <CardContent
          sx={theme => ({
            position: 'relative',
            gap: 8,
            [theme.breakpoints.up('lg')]: {
              display: 'grid',
              gridTemplateColumns: '160px 1fr',
            },
          })}
        >
          {isOwnProfile && (
            <EditButton
              sx={{
                position: 'absolute',
                top: 28,
                right: 28,
              }}
              modalContent={
                <FormEditPartnerBasicInfo
                  id={company.id}
                  onCancel={() => setModal(false)}
                  onSuccess={() => setModal(false)}
                  isAdmin={Boolean(user?.isAdmin)}
                />
              }
            />
          )}
          <Avatar src={company.profileImage?.urls?.s} variant="rounded-big" />

          <Stack spacing={4}>
            <Typography variant="h4" paddingRight={10}>
              {company.name}
            </Typography>

            {company.description && (
              <Typography>{company.description}</Typography>
            )}
            {(company.city || company.homepageUrl) && (
              <Box sx={{ display: 'flex', gap: 8 }}>
                {company.city && (
                  <Typography variant="body2Strong">{company.city}</Typography>
                )}
                {company.homepageUrl && (
                  <Typography
                    component="a"
                    href={company.homepageUrl}
                    target="_blank"
                    variant="body2Strong"
                    color="colors.nemo"
                  >
                    {companyUrl}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Divider
        sx={theme => ({ [theme.breakpoints.up('lg')]: { display: 'none' } })}
        light
      />

      <Card>
        <ProfileSection
          title="Info"
          isOwnProfile={isOwnProfile}
          content={
            <InfoGrid>
              <InfoCard title="Founded" text={company.yearOfEstablishment} />
              <InfoCard title="Industry" text={company.industry} />
              <InfoCard title="Employees" text={company.size} />
              <InfoCard title="Revenue" text={company.revenueCategory} />
              <InfoCard
                title="Headquarters"
                text={
                  <Stack component="span">
                    <span>{company.address}</span>
                    <span>
                      {company.postalCode} {company.city}
                    </span>
                  </Stack>
                }
              />
            </InfoGrid>
          }
          editModal={<FormEditPartnerMoreInfo id={company.id} />}
        />
      </Card>

      {isOwnProfile && id && (
        <>
          <Divider
            sx={theme => ({
              [theme.breakpoints.up('lg')]: { display: 'none' },
            })}
            light
          />
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
                mb={8}
              >
                <Typography variant="h5">Members</Typography>
                <AddPartnerMember partnerId={id} />
              </Stack>

              <Stack spacing={4}>
                <Divider light />

                {company?.members.map(member => (
                  <Fragment key={member.id}>
                    <Box
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto 1fr auto',
                        gap: 6,
                      }}
                    >
                      <Avatar
                        variant="rounded-small"
                        src={member.avatar?.urls.s}
                      />

                      <Stack>
                        <Typography variant="body2Strong">
                          {getPersonName(member)}
                        </Typography>
                        <Typography color="colors.grey">
                          {member.email}
                        </Typography>
                      </Stack>

                      <DeletePartnerMember member={member} />
                    </Box>
                    <Divider light />
                  </Fragment>
                ))}
              </Stack>
            </CardContent>
          </Card>
          {user?.isAdmin && (
            <TogglePartnerDisabled
              id={id}
              disabled={company.disabled}
              name={company.name}
              onSuccess={onDelete}
            />
          )}
          {user?.isAdmin && company?.name && (
            <DeletePartner id={id} name={company.name} onSuccess={onDelete} />
          )}
        </>
      )}
    </Main>
  )
}
