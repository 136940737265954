import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { trimCurriculumEntryTitles } from '../../pages-deprecated/admin-edit-curriculum/curriculum-service'
import {
  CurriculumDtoInput,
  MutationUpdateBootcampCurriculumArgs,
} from '../graphqlTypes'

const UPDATE_BOOTCAMP_CURRICULUM = gql`
  mutation UpdateBootcampCurriculum(
    $bootcampId: String!
    $curriculumDto: CurriculumDtoInput!
  ) {
    updateBootcampCurriculum(
      bootcampId: $bootcampId
      curriculumDto: $curriculumDto
    ) {
      id
      curriculumColumns {
        curriculumEntries {
          title
          level
        }
      }
    }
  }
`

export const useUpdateCurriculum = (options: BaseMutationOptions) => {
  const [updateCurriculumMutation, status] = useMutation(
    UPDATE_BOOTCAMP_CURRICULUM,
    options
  )

  const updateBootcampCurriculum = (
    variables: MutationUpdateBootcampCurriculumArgs
  ) =>
    updateCurriculumMutation({
      variables,
    })

  const updateCurriculumEntry = (
    bootcampId: string,
    curriculumDto: CurriculumDtoInput
  ) =>
    updateBootcampCurriculum({
      bootcampId,
      curriculumDto: trimCurriculumEntryTitles(curriculumDto),
    })

  return { updateCurriculumEntry, ...status }
}
