import { Stack, styled, Typography } from '@mui/material'

import { BrandLogo } from '../../atoms/BrandLogo'

const FooterContainer = styled('footer')(({ theme }) => ({
  padding: '40px 24px',
  color: theme.palette.colors.foam,
  backgroundColor: theme.palette.colors.granite,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(8),

  [theme.breakpoints.down('lg')]: {
    paddingBottom: 40 + 56, // make space for fixed bottom nav
  },

  [theme.breakpoints.up('md')]: {
    flexFlow: 'row',
    alignItems: 'center',
    paddingRight: 40,
    paddingLeft: 40,
  },
}))

const NavWrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.colors.lightGrey,
  gap: theme.spacing(12),
  alignItems: 'center',

  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(4),
    flexFlow: 'column',
    alignItems: 'flex-end',
  },

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },

  a: {
    color: 'inherit',
  },
}))

const FooterLink = styled('a')(({ theme }) => ({
  color: 'inherit',
  transition: theme.transitions.create('color'),

  ':hover': {
    color: theme.palette.colors.foam,
  },
}))

export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <FooterContainer>
      <BrandLogo fontSize={32} />

      <NavWrapper direction="row">
        <Stack direction="row" spacing={6}>
          <Typography
            component={FooterLink}
            variant="body2"
            href="https://www.neuefische.de/en/meta/leitbild"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mission
          </Typography>

          <Typography
            component={FooterLink}
            variant="body2"
            href="/meta/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </Typography>

          <Typography
            component={FooterLink}
            variant="body2"
            href="/meta/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use
          </Typography>

          <Typography
            component={FooterLink}
            variant="body2"
            href="https://www.neuefische.de/en/meta/impressum"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal Notice
          </Typography>
        </Stack>
        <Typography variant="body2">
          &copy; {currentYear} neuefische GmbH
        </Typography>
      </NavWrapper>
    </FooterContainer>
  )
}
