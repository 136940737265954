import {
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderProps,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

import { EditButton } from '../EditButton'
import { EmptySection } from '../EmptySection'

export interface ProfileSectionProps
  extends Pick<CardHeaderProps, 'title' | 'avatar' | 'subheader'> {
  isOwnProfile: boolean
  content?: ReactNode
  editModal?: ReactNode
  emptyComponent?: ReactNode
  isIntro?: boolean
  actions?: ReactNode
}

export const ProfileSection = ({
  avatar,
  title,
  subheader,
  isOwnProfile,
  content,
  actions,
  editModal,
  isIntro = false,
}: ProfileSectionProps) => {
  // Show nothing for public profile if there is no content to show
  if (!content && !isOwnProfile && !isIntro) {
    return null
  }

  return (
    <>
      <CardHeader
        id={title}
        avatar={avatar}
        title={<Typography variant={isIntro ? 'h4' : 'h5'}>{title}</Typography>}
        subheader={subheader}
        action={
          isOwnProfile && editModal && <EditButton modalContent={editModal} />
        }
      />

      {isIntro && !content ? null : (
        <CardContent>{content ?? <EmptySection />}</CardContent>
      )}

      {actions && <CardActions sx={{ columnGap: 4 }}>{actions}</CardActions>}
    </>
  )
}
