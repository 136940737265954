import { Stack } from '@mui/material'
import { ReactNode } from 'react'

import { ContentListItem, ContentListItemProps } from './ContentListItem'

export interface ContentListProps {
  icon?: ReactNode
  items: ContentListItemProps[]
}

export const ContentList = ({ items, icon }: ContentListProps) => {
  return (
    <Stack spacing={3}>
      {items.map((item, key) => (
        <ContentListItem {...item} icon={icon} key={`contentList-${key}`} />
      ))}
    </Stack>
  )
}
