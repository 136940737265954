import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'

import type { AlgoliaRefinementListItem } from '../../../../types/algolia.d'
import { ExtendAndCollapse } from './ExtendAndCollapse'

type Props = {
  title: string
  refine: (value: string) => void
  items: AlgoliaRefinementListItem[]
  toggleShowMore?: () => void
  isShowingMore?: boolean
}

export const RefinementListCheckboxes = (props: Props) => {
  return (
    <>
      <Typography color="colors.grey">{props.title}</Typography>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
          gap: 6,
        }}
      >
        {props.items.map(item => (
          <FormControlLabel
            control={
              <Checkbox
                checked={item.isRefined}
                onChange={() => props.refine(item.value)}
              />
            }
            label={item.label}
            key={item.label}
          />
        ))}
      </FormGroup>

      {props.toggleShowMore && props.isShowingMore != undefined && (
        <ExtendAndCollapse
          toggleShowMore={props.toggleShowMore}
          isShowingMore={props.isShowingMore}
        />
      )}
    </>
  )
}
