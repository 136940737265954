import { styled } from '@mui/material'
import { ReactNode } from 'react'

import { ModalGlobal } from '../components/molecules/Modal'
import { Footer as FooterComponent } from '../components/organisims/Footer'
import { Header as HeaderComponent } from '../components/organisims/Header'

const Page = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled(HeaderComponent)`
  flex-shrink: 0;
`

const Content = styled('div')`
  flex-grow: 1;
`

const Footer = styled(FooterComponent)`
  flex-shrink: 0;
`

export const LayoutPage = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Page>
        <Header />
        <Content>{children}</Content>
        <Footer />
      </Page>
      <ModalGlobal />
    </>
  )
}
