import {
  Avatar,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format } from 'date-fns'
import { useMemo } from 'react'

import type { AlgoliaPartnerLog } from '../../../../types/algolia.d'
import { LetterMailCircle, UserProfileCircle1 } from '../../../assets/icons'

export const LogItem = ({
  type,
  partnerMemberName,
  created_timestamp,
  destinationName,
  destinationAvatarUrl,
  partnerName,
  tracing,
}: AlgoliaPartnerLog) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const itemData = useMemo(() => {
    switch (type) {
      case 'CONTACT':
        return {
          Icon: UserProfileCircle1,
          iconColor: 'colors.nemo',
          action: 'has contacted',
          destinationElement: (
            <Stack
              direction="row"
              spacing={{ xs: 0, lg: 2 }}
              sx={theme => ({
                display: 'inline-block',
                color: 'colors.nemo',

                [theme.breakpoints.up('lg')]: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  padding: 2,
                  backgroundColor: 'colors.nemo',
                  borderRadius: 2,
                  color: 'colors.foam',
                },
              })}
            >
              <Avatar
                src={destinationAvatarUrl}
                variant="rounded-small"
                sx={theme => ({
                  width: 24,
                  height: 24,
                  [theme.breakpoints.down('lg')]: {
                    display: 'none',
                  },
                })}
              />
              <Typography variant="body2Strong" whiteSpace="nowrap">
                {destinationName}
              </Typography>
            </Stack>
          ),
        }
      case 'INVITE':
        return {
          Icon: LetterMailCircle,
          iconColor: 'inherit',
          action: 'sent an invite to',
          destinationElement: (
            <Typography variant="body2Strong" component="span">
              {destinationName}
            </Typography>
          ),
        }
      default:
        return {
          Icon: null,
          iconColor: 'inherit',
          action: '',
          destinationElement: null,
        }
    }
  }, [destinationAvatarUrl, destinationName, type])

  const time = useMemo(
    () => (
      <Typography component="time" variant="caption" color="colors.grey">
        {format(created_timestamp, 'PPP p')}
      </Typography>
    ),
    [created_timestamp]
  )

  const text = useMemo(
    () => (
      <Box
        sx={theme => ({
          [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
            gap: '.75ch',
          },
        })}
      >
        <Typography variant="body2Strong" component="span">
          {tracing ? partnerName : partnerMemberName}
        </Typography>{' '}
        <Typography variant="body2" component="span">
          {itemData.action}
        </Typography>{' '}
        {itemData.destinationElement}
      </Box>
    ),
    [
      itemData.action,
      itemData.destinationElement,
      partnerMemberName,
      tracing,
      partnerName,
    ]
  )

  return (
    <>
      <Box
        component={itemData.Icon || 'div'}
        fontSize={32}
        color={itemData.iconColor}
        sx={{
          boxShadow: 'inset 0 0 0 0.5em #fff, 0 0 0 12px #fff',
        }}
      />
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          {time}
          {text}
        </Box>
      ) : (
        <>
          {time}
          {text}
        </>
      )}
    </>
  )
}
