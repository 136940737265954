import 'react-advanced-cropper/dist/style.css'

import { Box, styled } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Cropper, CropperRef } from 'react-advanced-cropper'

import { getSquarteImage } from '../../../../service/image/getSquarteImage'
import { ProfileForm } from '../../organisims/Profile/Forms/ProfileForm'

const StyledCropper = styled(Cropper)(({ theme }) => ({
  maxWidth: '100%',
  color: theme.palette.colors.darkGrey,
}))

export type ImageCropperProps = {
  image: string
  setCroppedImage: (img: File) => void
  onCancel: () => void
  loading?: boolean
  ratio?: number
  padded?: boolean
}

export const ImageCropper = ({
  image,
  setCroppedImage,
  onCancel,
  loading = false,
  ratio = 1,
  padded = false,
}: ImageCropperProps) => {
  const cropperRef = useRef<CropperRef>(null)
  const [imageSrc, setImageSrc] = useState(image)

  const setSquare = useCallback(
    async (img: string) => {
      if (padded) {
        setImageSrc(await getSquarteImage(img))
      }
    },
    [padded]
  )

  useEffect(() => {
    setSquare(image)
  }, [image, setSquare])

  const saveFile = useCallback(async () => {
    if (!cropperRef.current) {
      return
    }

    const canvas = cropperRef.current.getCanvas({
      minWidth: 400,
      minHeight: 400,
    })

    if (!canvas) {
      return
    }

    canvas.toBlob(blob => {
      if (blob) {
        setCroppedImage(blob as File)
      }
    }, 'image/jpeg')
  }, [setCroppedImage])

  return (
    <ProfileForm
      title="Profile picture"
      onCancel={onCancel}
      onSubmit={saveFile}
      loading={loading}
      contentSx={padded ? { backgroundColor: 'colors.water10' } : {}}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledCropper
          ref={cropperRef}
          src={imageSrc}
          backgroundWrapperProps={{
            scaleImage: false,
          }}
          stencilProps={{
            lines: false,
            aspectRatio: ratio,
          }}
        />
      </Box>
    </ProfileForm>
  )
}
