import { CircularProgress } from '@mui/material'

import { useIubenda } from '../common/hooks/useIubenda'

export const PageTermsOfUse = () => {
  const { loading, termsAndConditions } = useIubenda()

  if (loading) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <div data-cy-post-login="true" data-test="terms">
      {termsAndConditions}
    </div>
  )
}
