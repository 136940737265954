import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiFormLabel: Components['MuiFormLabel'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      marginBottom: muiDefaultTheme.spacing(6),
    },
  },
}
