import { Button, styled } from '@mui/material'
import Card from '../../../common-deprecated/components/Card'
import DropableWrapper from '../../../common-deprecated/components/DropableWrapper'
import InputWithLabel from '../../../common-deprecated/components/InputWithLabel'
import { Close } from '../../../common-deprecated/icons'
import { BootcampParticipation, Team } from '../../../graphql/graphqlTypes'
import useTeamManagement from '../../../graphql/team/useTeamManagement'
import { getPersonName } from '../../../service/profile/person-service'

type Props = {
  id: string
  teams: Team[]
}

const TeamsOverview = ({ id, teams }: Props) => {
  const {
    teams: liveTeams,
    updateTeamName,
    save,
    deleteTeam,
    addTeam,
    addToTeam,
    removeFromTeam,
  } = useTeamManagement(teams)

  return (
    <TeamsView>
      <Teams>
        {liveTeams.map(team => (
          <DropableWrapper
            item={team}
            accept="student"
            key={team.id}
            onDrop={(dropped: BootcampParticipation, item: Team) =>
              addToTeam(dropped, item)
            }
          >
            <TeamCard>
              <InputWithLabel
                labelText="Name"
                value={team.name}
                onChange={event => updateTeamName(team.id, event.target.value)}
                onBlur={save}
              />
              <Button
                className="deleteTeam"
                variant="text"
                onClick={() => deleteTeam(team.id)}
              >
                Delete
              </Button>
              <h5>Members</h5>
              <ul>
                {team.participants?.map(participant => {
                  return (
                    <li key={participant.person.id} className="member">
                      {getPersonName(participant.person)}
                      <Button
                        variant="text"
                        onClick={() =>
                          removeFromTeam(team.id, participant.person.id, id)
                        }
                      >
                        <Close />
                      </Button>
                    </li>
                  )
                })}
              </ul>
            </TeamCard>
          </DropableWrapper>
        ))}
      </Teams>
      <Actions>
        <Button onClick={() => addTeam(id)}>Add new team</Button>
      </Actions>
    </TeamsView>
  )
}

const TeamsView = styled('div')`
  width: 100%;
`

const Teams = styled('div')`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
`

const Actions = styled('div')`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 25px;
  }
`

const TeamCard = styled(Card)`
  position: relative;
  margin: 10px;
  button.deleteTeam {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  ul {
    margin-top: 4px;
    padding: 0;
    li {
      display: flex;
      button {
        margin-left: 5px;
        padding: 0;
      }
    }
  }
`

export default TeamsOverview
