import { gql, useQuery } from '@apollo/client'

import { getTrainingProviderSelectOption } from '../../service/training-provider-service'
import { TrainingProviderEnum } from '../graphqlTypes'

export const LIST_TRAINING_PROVIDERS = gql`
  query ListTrainingProviders {
    listTrainingProviders
  }
`

export const useListTrainingProviders = () => {
  const query = useQuery<{ listTrainingProviders: TrainingProviderEnum[] }>(
    LIST_TRAINING_PROVIDERS
  )
  const trainingProviders = query.data?.listTrainingProviders || []
  const trainingProvidersSelectOptions = trainingProviders.map(
    getTrainingProviderSelectOption
  )
  const completed = query.called && !query.loading && !query.error
  return {
    ...query,
    trainingProviders,
    trainingProvidersSelectOptions,
    completed,
  }
}
