import { Stack, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { MutationUpdatePartnerArgs } from '../../../../graphql/graphqlTypes'
import useAddPartner from '../../../../graphql/partner/useAddPartner'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

type FormValues = Pick<
  MutationUpdatePartnerArgs,
  'name' | 'description' | 'industry'
>

type FormPartnerType = {
  id?: string
  onCancel: () => void
  onSuccess: () => void
}

export const FormAddPartner = ({ onCancel, onSuccess }: FormPartnerType) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      industry: '',
    },
  })

  const { field: fieldName, fieldState: fieldNameState } = useController({
    name: 'name',
    control,
    rules: {
      required: `Please name your company`,
    },
  })

  const {
    field: fieldIndustry,
    fieldState: fieldIndustryState,
  } = useController({
    name: 'industry',
    control,
    rules: {
      required: 'Please choose an industry',
    },
  })

  const { addPartner, loading: loadingAdd } = useAddPartner({
    onError: error => toast.error(`${error.message} 🙀`),
    onCompleted: () => {
      toast.success('Successfully added partner')
      onSuccess()
    },
  })

  const handleCancel = () => {
    onCancel()
  }

  const submit = useCallback(
    (data: FormValues) => {
      if (data) {
        addPartner(data)
      }
    },
    [addPartner]
  )

  return (
    <ProfileForm
      title="Add new partner"
      onCancel={handleCancel}
      onSubmit={handleSubmit(submit)}
      loading={loadingAdd}
    >
      <Stack component="form" spacing={8}>
        <Stack spacing={4}>
          <TextField
            label="Company name"
            {...fieldName}
            error={!!fieldNameState.error}
            helperText={fieldNameState.error?.message}
          />
          <TextField
            label="Industry"
            {...fieldIndustry}
            error={!!fieldIndustryState.error}
            helperText={fieldIndustryState.error?.message}
          />
        </Stack>
      </Stack>
    </ProfileForm>
  )
}
