import { Box, Button, Dialog } from '@mui/material'
import { useState } from 'react'

import { LanguageSkill } from '../../../../graphql/graphqlTypes'
import { Plus } from '../../../assets/icons'
import { FormProfileLanguage } from './Forms/FormProfileLanguage'

export const ModalProfileLanguageAddItem = ({
  personId,
  languageSkills,
  allLanguages,
}: {
  personId: string
  languageSkills: LanguageSkill[]
  allLanguages: string[]
}) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 32,
        }}
      >
        <Button
          startIcon={<Plus />}
          onClick={() => setModal(true)}
          sx={{ mb: 10 }}
        >
          Add language
        </Button>
      </Box>

      <Dialog open={modal} onClose={() => setModal(false)}>
        <FormProfileLanguage
          allLanguages={allLanguages}
          personId={personId}
          currentLanguageSkills={languageSkills}
          title="Add language"
          onCancel={() => setModal(false)}
          onSubmit={() => setModal(false)}
        />
      </Dialog>
    </>
  )
}
