import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationInvitePersonArgs } from '../graphqlTypes'
import { LIST_PERSONS_WITH_EMAIL } from './useListPrivatePersons'

const INVITE_PERSON = gql`
  mutation InvitePerson(
    $firstName: String!
    $lastName: String!
    $email: String!
    $personType: PersonTypeEnum!
    $bootcampId: String
    $partnerId: String
    $trainingProvider: TrainingProviderEnum
  ) {
    invitePerson(
      firstName: $firstName
      lastName: $lastName
      email: $email
      personType: $personType
      bootcampId: $bootcampId
      partnerId: $partnerId
      trainingProvider: $trainingProvider
    ) {
      id
      email
      firstName
      lastName
      personType
      avatar {
        id
        urls {
          s
          m
          l
          xl
        }
      }
    }
  }
`

export const useInvitePerson = (options: BaseMutationOptions) => {
  const [invite, result] = useMutation(INVITE_PERSON, options)

  const invitePerson = (variables: MutationInvitePersonArgs) =>
    invite({
      variables: variables,
      refetchQueries: [LIST_PERSONS_WITH_EMAIL],
    })
  return { invitePerson, ...result }
}
