import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { MutationSetPersonJobPreferencesArgs } from '../graphqlTypes'

const SET_JOB_PREFERENCES = gql`
  mutation SetJobPreferences(
    $available: Boolean!
    $freelance: Boolean!
    $fullTime: Boolean!
    $internship: Boolean!
    $placeIds: [String!]!
    $flexible: Boolean!
    $partTime: Boolean!
    $personId: String!
    $remote: Boolean!
  ) {
    setPersonJobPreferences(
      personId: $personId
      available: $available
      fullTime: $fullTime
      partTime: $partTime
      freelance: $freelance
      remote: $remote
      flexible: $flexible
      placeIds: $placeIds
      internship: $internship
    ) {
      id
      jobPreferences {
        id
        available
        fullTime
        partTime
        freelance
        internship
        flexible
        remote
        geolocationPreferences {
          placeId
          name
        }
      }
    }
  }
`

export const useSetJobPreferences = (options?: BaseMutationOptions) => {
  const [updateJobPreferences, result] = useMutation(
    SET_JOB_PREFERENCES,
    options
  )

  const setJobPreferences = (variables: MutationSetPersonJobPreferencesArgs) =>
    updateJobPreferences({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, setJobPreferences }
}
