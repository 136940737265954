import { gql, useQuery } from '@apollo/client'

import { Bootcamp } from '../graphqlTypes'

export const GET_BOOTCAMP_DETAILS = gql`
  query GetBootcampDetails($id: String!) {
    getBootcamp(id: $id) {
      endDate
      id
      location {
        id
        name
      }
      startDate
      track {
        id
        name
      }
      participants {
        role {
          id
          name
        }
        bootcamp {
          id
        }
        person {
          id
          firstName
          lastName
          email
          teaser
          avatar {
            id
            urls {
              s
              m
              l
              xl
            }
          }
        }
        certificateReady
      }
      curriculumDto {
        id
        curriculumColumns {
          curriculumEntries {
            title
            level
          }
        }
      }
      event {
        id
        description
        techStack
        published
        date
        time
        token
        location
      }
      teams {
        id
        name
        participants {
          bootcamp {
            id
          }
          person {
            id
            firstName
            lastName
            email
          }
        }
      }
      language
    }
  }
`

export const useGetBootcampDetails = (id: string) => {
  const query = useQuery<{ getBootcamp: Bootcamp }>(GET_BOOTCAMP_DETAILS, {
    variables: { id },
  })
  const bootcamp = query.data?.getBootcamp
  const completed = !query.error && !query.loading
  return { ...query, bootcamp, completed }
}
