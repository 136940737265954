import { FC } from 'react'
import { styled } from '@mui/material'
import mask from './mask.svg'
import placeholder from './placeholder.png'

type AvatarProps = {
  src?: string
  className?: string
  size?: number
}

const defaultImageSize = 150

const Avatar: FC<AvatarProps> = ({ src, size, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AvatarBackgroundImage
        src={src || placeholder}
        size={size || defaultImageSize}
      />
      <Mask src={mask} alt="" size={size || defaultImageSize} />
    </Wrapper>
  )
}

export default Avatar

const Mask = styled('img')<AvatarProps>`
  height: ${props => props.size || defaultImageSize}px;
  width: ${props => props.size || defaultImageSize}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Wrapper = styled('div')`
  position: relative;
  display: inline-block;
`

const AvatarBackgroundImage = styled('div')<AvatarProps>`
  height: ${props => props.size || defaultImageSize}px;
  width: ${props => props.size || defaultImageSize}px;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid white;
`
