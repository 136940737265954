import { Bootcamp } from '../../../graphql/graphqlTypes'
import { useGetBootcampProfileCompletion } from '../../../graphql/person/useGetBootcampProfileCompletion'
import { styled } from '@mui/material'
type ProfileCompletionData = {
  bootcamp: Bootcamp
}

export default function ProfileCompletionData({
  bootcamp,
}: ProfileCompletionData) {
  const { completionData } = useGetBootcampProfileCompletion(bootcamp.id)
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Basic</th>
          <th>Skills</th>
          <th>Languages</th>
          <th>Experiences</th>
          <th>Job Preferences</th>
          <th>Capstone</th>
        </tr>
      </thead>
      <tbody>
        {completionData?.map(student => (
          <tr key={student.lastName}>
            <td>
              {student.lastName}, {student?.firstName?.charAt(0)}
            </td>
            <Cell> {student.isBasicInfoComplete ? '\u2713' : '\u274C'}</Cell>
            <Cell> {student.isSkillsComplete ? '\u2713' : '\u274C'}</Cell>
            <Cell> {student.isLanguageComplete ? '\u2713' : '\u274C'}</Cell>
            <Cell> {student.isExperienceComplete ? '\u2713' : '\u274C'}</Cell>
            <Cell>
              {' '}
              {student.isJobPreferencesComplete ? '\u2713' : '\u274C'}
            </Cell>
            <Cell> {student.isCapstoneComplete ? '\u2713' : '\u274C'}</Cell>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const Cell = styled('td')`
  text-align: center;
`
