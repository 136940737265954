import { css, styled } from '@mui/material'
import { FC, ReactNode } from 'react'

type LabelProps = {
  srOnly?: boolean
  labelText: string
  children: ReactNode | ReactNode[]
  className?: string
}

const Label: FC<LabelProps> = ({ srOnly, labelText, children, className }) => {
  return (
    <LabelStyled className={className} srOnly={srOnly}>
      <LabelText srOnly={srOnly}>{labelText}</LabelText>
      {children}
    </LabelStyled>
  )
}

export default Label

const LabelStyled = styled('label')<{ srOnly?: boolean }>`
  ${props =>
    props.srOnly &&
    css`
      display: block;
    `}
`

const LabelText = styled('span')<{ srOnly?: boolean }>`
  ${props =>
    props.srOnly &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    `}
`
