import { SearchClient } from 'algoliasearch'

import { getUserIdToken } from '../service/auth/cognito-auth-service'
import { BASE_URL } from './backendConfig'

export const algoliaConfig = {
  key: process.env.REACT_APP_ALGOLIA_KEY || '',
  project: process.env.REACT_APP_ALGOLIA_PROJECT || '',
}

export const searchClient = {
  async search(requests: unknown[]) {
    const token = await getUserIdToken()
    const res = await fetch(BASE_URL + '/search', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requests[0]),
    })
    const responses = await res.json()
    return { results: [responses] }
  },
} as SearchClient

export const getIndexName = (index: string) => {
  if (
    process.env.REACT_APP_ALGOLIA_CUSTOM_INDEX === 'true' &&
    process.env.NODE_ENV === 'development'
  ) {
    const isReplica = index.includes('__')
    if (isReplica) {
      const [indexName, replicaName] = index.split('__')
      return `${indexName}-${process.env.REACT_APP_USER}__${replicaName}`
    }
    return `${index}-${process.env.REACT_APP_USER}`
  }
  return index
}
