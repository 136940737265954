import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useClearRefinements, useHits } from 'react-instantsearch-hooks-web'

import type { AlgoliaBootcampHit } from '../../../../types/algolia.d'
import { BootcampSearchHit } from '../../molecules/BootcampSearchHit'

export const BootcampSearchHits = () => {
  const { hits } = useHits<AlgoliaBootcampHit>()
  const { refine } = useClearRefinements()
  if (!hits.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Typography>No search results</Typography>
        <Button onClick={refine}>Clear filters</Button>
      </Box>
    )
  }

  return (
    <Stack spacing={8}>
      <Divider light />

      {hits.map(hit => (
        <Fragment key={hit.objectID}>
          <BootcampSearchHit {...hit} />
          <Divider light />
        </Fragment>
      ))}
    </Stack>
  )
}
