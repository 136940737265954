import axios from 'axios'

import { getUserIdToken } from './cognito-auth-service'

export async function getClient(headers?: Record<string, unknown>) {
  const token = await getUserIdToken()
  return axios.create({
    timeout: 120000,
    headers: { Authorization: token, ...headers },
  })
}
