import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Person } from '../../../../graphql/graphqlTypes'
import { useSetPersonName } from '../../../../graphql/person/useSetPersonName'

type FormValues = {
  firstName: string
  lastName: string
}

export const NameSettings = (person: Person) => {
  const defaultValues = useMemo(
    () => ({
      firstName: person.firstName || '',
      lastName: person.lastName || '',
    }),
    [person.firstName, person.lastName]
  )

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues,
  })

  const {
    field: fieldFirstName,
    fieldState: fieldFirstNameState,
  } = useController({
    name: 'firstName',
    control,
    rules: {
      required: 'First name can not be empty',
    },
  })

  const {
    field: fieldLastName,
    fieldState: fieldLastNameState,
  } = useController({
    name: 'lastName',
    control,
    rules: {
      required: 'Last name can not be empty',
    },
  })

  const { setPersonName, loading: personNameLoading } = useSetPersonName({
    onCompleted: () => {
      toast.success('Name updated')
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    ({ firstName, lastName }: FormValues) => {
      person &&
        setPersonName(person.id, firstName, lastName).finally(() =>
          reset({ firstName, lastName })
        )
    },
    [person, reset, setPersonName]
  )

  return (
    <Stack component="form" spacing={6} onSubmit={handleSubmit(submit)}>
      <Typography variant="title2">Account</Typography>

      <Stack spacing={1}>
        <TextField
          label="First name"
          {...fieldFirstName}
          error={!!fieldFirstNameState.error}
          helperText={fieldFirstNameState.error?.message || ' '}
        />

        <TextField
          label="Last name"
          {...fieldLastName}
          error={!!fieldLastNameState.error}
          helperText={fieldLastNameState.error?.message || ' '}
        />
      </Stack>

      <Box>
        <LoadingButton
          disabled={!formState.isDirty}
          loading={personNameLoading}
          type="submit"
        >
          Save changes
        </LoadingButton>
      </Box>
    </Stack>
  )
}
