import { GrowthBook } from '@growthbook/growthbook-react'
import axios from 'axios'
import { useEffect, useState } from 'react'

import { growthbookConfig } from '../../config/growthbookConfig'

const ENDPOINT = `https://cdn.growthbook.io/api/features/${growthbookConfig.key}`

const growthbook = new GrowthBook({})
export const useInitGrowthBook = () => {
  const [growthbookLoading, setGrowthbookLoading] = useState(false)
  useEffect(() => {
    setGrowthbookLoading(true)
    axios
      .get(ENDPOINT)
      .then(res => res.data)
      .then(parsed => {
        growthbook.setFeatures(parsed.features)
      })
      .finally(() => setGrowthbookLoading(false))
  }, [])

  return { growthbook, growthbookLoading }
}
