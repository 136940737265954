import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

export const SET_PARTNER_DISABLED = gql`
  mutation SetPartnerDisabled($partnerId: String!, $disabled: Boolean!) {
    setPartnerDisabled(partnerId: $partnerId, disabled: $disabled) {
      id
      name
      disabled
    }
  }
`

export default function useSetPartnerDisabled(options: BaseMutationOptions) {
  const [setPartnerDisabledMutation, status] = useMutation(
    SET_PARTNER_DISABLED,
    options
  )

  const setPartnerDisabled = (partnerId: string, disabled: boolean) =>
    setPartnerDisabledMutation({ variables: { partnerId, disabled } })

  return { setPartnerDisabled, ...status }
}
