import { gql, useQuery } from '@apollo/client'

import {
  ContactedStudent,
  QueryGetContactedStudentsArgs,
} from '../graphqlTypes'

export const GET_CONTACTED_STUDENTS = gql`
  query GetContactedStudents(
    $filter: ContactedStudentFilter
    $pagination: PaginationParameter
  ) {
    getContactedStudents(filter: $filter, pagination: $pagination) {
      totalPages
      totalElements
      current
      size
      contactedStudents {
        id
        firstName
        lastName
        tracks
        avatar {
          urls {
            s
          }
        }
      }
    }
  }
`

export const useGetContactedStudents = (
  variables: QueryGetContactedStudentsArgs
) => {
  const query = useQuery<{
    getContactedStudents: {
      contactedStudents: ContactedStudent[]
      current: number
      totalPages: number
      totalElements: number
      size: number
    }
  }>(GET_CONTACTED_STUDENTS, { variables })
  const students = query.data?.getContactedStudents.contactedStudents || []

  const completed = query.called && !query.loading && !query.error

  return {
    ...query,
    students,
    currentPage: query.data?.getContactedStudents.current || 1,
    totalPages: query.data?.getContactedStudents.totalPages || 1,
    totalElements: query.data?.getContactedStudents.totalElements || 1,
    pageSize: query.data?.getContactedStudents.size || 1,
    completed,
  }
}
