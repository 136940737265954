import { gql, useQuery } from '@apollo/client'

import { Person, QueryListPersonsArgs } from '../graphqlTypes'

export const LIST_PERSONS_WITH_EMAIL = gql`
  query ListPersonsWithEMail(
    $filter: PersonFilterInput
    $pagination: PaginationParameter
  ) {
    listPersons(filter: $filter, pagination: $pagination) {
      totalElements
      current
      size
      persons {
        id
        participates {
          bootcamp {
            id
          }
        }
        email
        created
        firstName
        lastName
        status
        isAdmin
        personType
        avatar {
          id
          urls {
            s
            m
            l
            xl
          }
        }
      }
    }
  }
`

export const useListPrivatePersons = (variables: QueryListPersonsArgs) => {
  const query = useQuery<{
    listPersons: {
      persons: Person[]
      current: number
      totalElements: number
      size: number
    }
  }>(LIST_PERSONS_WITH_EMAIL, {
    variables,
  })

  const persons = query.data?.listPersons?.persons || []
  const completed = query.called && !query.loading && !query.error
  return {
    ...query,
    persons,
    currentPage: query.data?.listPersons.current || 1,
    totalElements: query.data?.listPersons.totalElements || 1,
    pageSize: query.data?.listPersons.size || 1,
    completed,
  }
}
