import { ReactNode } from 'react'

import { GlobalStyles } from '../../../GlobalStyles'

export const GlobalStylesContainer = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <>
      <GlobalStyles />

      {children}
    </>
  )
}
