import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationAddBootcampArgs } from '../graphqlTypes'
import { LIST_BOOTCAMPS } from './listBootcampService'

const ADD_BOOTCAMP = gql`
  mutation AddBootcampDetails(
    $id: String!
    $startDate: String!
    $endDate: String!
    $locationId: String!
    $trackId: String!
    $language: LanguageCode
  ) {
    addBootcamp(
      id: $id
      startDate: $startDate
      endDate: $endDate
      locationId: $locationId
      trackId: $trackId
      language: $language
    ) {
      id
      endDate
      location {
        id
        name
      }
      startDate
      track {
        id
        name
      }
      language
    }
  }
`

const UPDATE_BOOTCAMP = gql`
  mutation UpdateBootcampDetails(
    $id: String!
    $startDate: String!
    $endDate: String!
    $locationId: String!
    $trackId: String!
    $language: LanguageCode!
  ) {
    updateBootcamp(
      id: $id
      startDate: $startDate
      endDate: $endDate
      locationId: $locationId
      trackId: $trackId
      language: $language
    ) {
      id
      endDate
      location {
        id
        name
      }
      startDate
      track {
        id
        name
      }
      language
    }
  }
`

const DELETE_BOOTCAMP = gql`
  mutation DeleteBootcamp($id: String!) {
    deleteBootcamp(id: $id)
  }
`

export const useBootcampManagement = (options: BaseMutationOptions) => {
  const [addBootcampMutation, status] = useMutation(ADD_BOOTCAMP, options)
  const [deleteBootcampMutation, deleteStatus] = useMutation(
    DELETE_BOOTCAMP,
    options
  )
  const [updateBootcampMutation, updateStatus] = useMutation(
    UPDATE_BOOTCAMP,
    options
  )

  const addBootcamp = (bootcamp: MutationAddBootcampArgs) =>
    addBootcampMutation({
      variables: bootcamp,
      refetchQueries: [LIST_BOOTCAMPS],
    })

  const updateBootcamp = (bootcamp: MutationAddBootcampArgs) =>
    updateBootcampMutation({
      variables: bootcamp,
      refetchQueries: [LIST_BOOTCAMPS],
    })

  const deleteBootcamp = (id: string) =>
    deleteBootcampMutation({
      variables: { id },
      refetchQueries: [LIST_BOOTCAMPS],
    })

  const loading = status.loading || updateStatus.loading || deleteStatus.loading
  return { addBootcamp, updateBootcamp, deleteBootcamp, loading }
}
