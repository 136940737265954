import { Person } from '../../graphql/graphqlTypes'

const mapRole: { [key: string]: string } = {
  STUDENT: 'student',
  STUDENT_SERVICE: 'student-service',
  COACH: 'coach',
  PARTNER_MANAGEMENT: 'partner-management',
  PARTNER_MEMBER: 'partner-member',
}

export const mapToAuthorities = (person: Person) => {
  const role = mapRole[person.personType]
  const authorities = [role]
  if (person.isAdmin) {
    authorities.push('admin')
  }

  return authorities
}

export const checkHasAccess = (
  authorities: string[],
  givenAuthorities: string[]
) => {
  return givenAuthorities.some(entry => authorities.includes(entry))
}
