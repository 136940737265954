import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiCardContent: Components['MuiCardContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      rowGap: 16,
      padding: 24,
      [muiDefaultTheme.breakpoints.up('lg')]: {
        padding: 32,
      },
      '.MuiCardHeader-root + &': {
        paddingTop: 0,
      },

      ':last-child': {
        paddingBottom: 32, // overwrite default
      },
    },
  },
}
