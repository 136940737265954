import { gql, useQuery } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { ProfileCompletion } from '../graphqlTypes'

export const GET_PROFILE_COMPLETION = gql`
  query GetProfileCompletion {
    getProfileCompletion {
      completionScore {
        score
        capstoneConsidered
      }
      isBasicInfoComplete
      isSkillsComplete
      isExperienceComplete
      isJobPreferencesComplete
      isCapstoneComplete
      isLanguageComplete
    }
  }
`

export const useGetProfileCompletion = (options?: BaseMutationOptions) => {
  const query = useQuery<{ getProfileCompletion: ProfileCompletion }>(
    GET_PROFILE_COMPLETION,
    options
  )
  const completionData: ProfileCompletion | undefined =
    query.data?.getProfileCompletion
  return { ...query, completionData }
}
