import { gql, useQuery } from '@apollo/client'

import { Partner } from '../graphqlTypes'

const LIST_RANDOM_PARTNERS_QUERY = gql`
  query ListRandomPartners($count: Int!) {
    listRandomPartners(count: $count) {
      id
      name
      industry
      profileImage {
        id
        urls {
          m
        }
      }
    }
  }
`

export const useListRandomPartners = (count = 3) => {
  const query = useQuery<{ listRandomPartners: Partner[] }>(
    LIST_RANDOM_PARTNERS_QUERY,
    { variables: { count } }
  )

  const partners = query.data?.listRandomPartners || []

  return { partners, ...query }
}
