import { Button } from '@neuefische/design-system'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { styled } from '@mui/material'
import LoadingIndicator from '../../common-deprecated/components/LoadingIndicator'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { useGetBootcampDetails } from '../../graphql/bootcamp/getBootcampDetailsService'
import { useBootcampManagement } from '../../graphql/bootcamp/manageBootcampService'
import CreateBootcampForm from '../admin-bootcamp-overview/components/CreateBootcampForm'
import BootcampPersonList from './components/BootcampPersonList'
import CurriculumView from './components/CurriculumView'
import EventForm from './components/EventForm'
import TeamsOverview from './components/TeamsOverview'
import { useState } from 'react'
import ProfileCompletionData from './components/ProfileCompletionData'
import Card from '../../common-deprecated/components/Card'

export default function BootcampDetailsPage() {
  const { id } = useParams<{ id: string }>()
  const [expanded, setExpanded] = useState(true)

  const { loading, completed, bootcamp } = useGetBootcampDetails(id!)
  const navigate = useNavigate()

  const { deleteBootcamp } = useBootcampManagement({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      toast.success('Bootcamp successfully deleted 🎉')
      navigate('/admin/bootcamps')
    },
  })

  return (
    <Wrapper>
      <DeprecatedGlobalStyles />
      {loading && <LoadingIndicator />}
      {completed && bootcamp && (
        <DndProvider backend={HTML5Backend}>
          <CardStyled>
            <h1>{bootcamp.id}</h1>
            <CreateBootcampForm
              bootcamp={{
                id: bootcamp.id,
                trackId: bootcamp.track.id,
                startDate: bootcamp.startDate.substring(0, 10),
                endDate: bootcamp.endDate.substring(0, 10),
                locationId: bootcamp.location.id,
                language: bootcamp.language,
              }}
            />
          </CardStyled>
          <CardStyled>
            <LinkButton
              variant="primary"
              label="Add Participant"
              onClick={() =>
                navigate(`/admin/bootcamp/${bootcamp.id}/add-participants`)
              }
            />
            <h2>Lead Coach</h2>
            <BootcampPersonList bootcamp={bootcamp} roleId="head-coach" />

            <h2>Coaches</h2>
            <BootcampPersonList bootcamp={bootcamp} roleId="coach" />

            <h2>Teams</h2>
            <TeamsOverview id={bootcamp.id} teams={bootcamp.teams || []} />

            <h2>Students</h2>
            <BootcampPersonList
              bootcamp={bootcamp}
              roleId={'student'}
              showCertificateLink
              canDrag
            />
          </CardStyled>
          <CardStyled>
            <h2>Student Profile Completion Data</h2>
            <ProfileCompletionData bootcamp={bootcamp} />
          </CardStyled>
          <CardStyled>
            <h2>Curriculum</h2>
            <CurriculumView curriculum={bootcamp.curriculumDto} />
            <LinkButton
              variant="primary"
              onClick={() =>
                navigate(`/admin/bootcamp/${bootcamp.id}/edit-curriculum`)
              }
              label={'Edit Curriculum'}
            />
          </CardStyled>
          <CardStyled>
            <h2>Event</h2>
            <EventForm event={bootcamp.event} />
            <Divider />
          </CardStyled>
          <Button
            onClick={() => deleteBootcamp(bootcamp.id)}
            variant="primary"
            label="⚠️ Delete bootcamp"
          />
        </DndProvider>
      )}
    </Wrapper>
  )
}

const Divider = styled('div')`
  margin: 20px auto;
  width: 33%;
  border: 0.5px solid var(--secondaryTwo);
`

const CardStyled = styled(Card)`
  display: grid;
`

const Wrapper = styled('main')`
  display: grid;
  grid-gap: var(--spacing-m);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);

  h1 {
    text-align: center;
    text-transform: uppercase;
  }

  a {
    justify-self: end;
  }
`
const LinkButton = styled(Button)`
  justify-self: end;
`
