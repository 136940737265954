import { Avatar, Box, Card, CardHeader, Typography } from '@mui/material'

import { getPersonName } from '../../../../service/profile/person-service'
import { RightArrow as IconRightArrow } from '../../../assets/icons'
import { CardLink } from '../../atoms/CardLink'
import { ProfileParticipation } from '../ProfileParticipation'

export interface ProfileLinkProps {
  id: string
  firstName?: string
  lastName?: string
  avatarUrl?: string
  roles?: string[]
}

export const ProfileLink = ({
  id,
  firstName,
  lastName,
  avatarUrl,
  roles,
}: ProfileLinkProps) => {
  return (
    <CardLink href={`/profile/${id}`} target="_blank">
      <Card
        sx={{
          height: '100%',
          borderRadius: 2,
          '.MuiCardHeader-root': {
            padding: 0,
          },
          '.MuiCardHeader-content': {
            flex: 'none',
          },
        }}
      >
        <CardHeader
          sx={{
            padding: 0,
            alignItems: 'center',
            gap: 3,
          }}
          action={<IconRightArrow fontSize={18} />}
          avatar={
            <Box sx={{ position: 'relative' }}>
              <Avatar
                src={avatarUrl ?? undefined}
                alt={getPersonName({ firstName, lastName, id })}
                variant="rounded-small"
              />
            </Box>
          }
          title={
            <Typography variant="body2Strong">
              {getPersonName({ firstName, lastName, id })}
            </Typography>
          }
          subheader={roles && <ProfileParticipation items={roles} />}
        />
      </Card>
    </CardLink>
  )
}
