import { Components } from '@mui/material'

import { DeleteDisabled } from '../../assets/icons'
import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'
import { typography } from './typography'

export const MuiChip: Components['MuiChip'] = {
  defaultProps: {
    deleteIcon: <DeleteDisabled />,
  },
  styleOverrides: {
    root: {
      ...typography.body2Strong,
      '.MuiChip-deleteIcon': {
        color: colors.grey,
        fontSize: 24,
        marginRight: 6,
        marginLeft: 4,
      },
    },
    label: {
      textOverflow: 'initial',
    },
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: {
        borderRadius: 4,
        height: muiDefaultTheme.spacing(10),
        color: colors.grey,
        backgroundColor: colors.water10,
      },
    },
    {
      props: { variant: 'filled', color: 'primary' },
      style: {
        color: colors.nemo,
        backgroundColor: colors.foam,
        borderRadius: 60,
      },
    },
    {
      props: { variant: 'filled', color: 'secondary' },
      style: {
        color: colors.lightGrey,
        backgroundColor: colors.darkGrey,
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        borderRadius: 60,
        height: muiDefaultTheme.spacing(9),
        color: colors.grey,
        borderColor: colors.water10,
        backgroundColor: 'transparent',
      },
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        color: colors.nemo,
        borderColor: colors.nemo,
        backgroundColor: 'transparent',
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        color: colors.darkGrey,
        borderColor: colors.darkGrey,
        backgroundColor: 'transparent',
      },
    },
  ],
}
