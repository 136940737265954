import { BASE_URL } from '../../../config/backendConfig'
import { getClient } from '../../../service/auth/auth-client'

export const batchInviteStudents = async (formData: FormData) => {
  const client = await getClient()
  return await client
    .post(`${BASE_URL}/api/admin/invite`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}
