import { JobExperience, JobExperienceType } from '../graphql/graphqlTypes'

export const findLatestExperienceOfType = (
  type: JobExperienceType,
  experiences: JobExperience[]
) => {
  const filtered = experiences.filter(exp => exp.type === type)
  for (const exp of filtered) {
    if (!exp.endDate) {
      // prefer first ongoing experience
      return exp
    }
  }
  return filtered[0]
}
