import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { MutationDeleteEntryFromJobExperienceFromPersonArgs } from '../graphqlTypes'

const DELETE_JOB_EXPERIENCE = gql`
  mutation DeleteJobExperience($id: String!, $personId: String!) {
    deleteEntryFromJobExperienceFromPerson(id: $id, personId: $personId)
  }
`

export const useDeleteJobExperience = (options?: BaseMutationOptions) => {
  const [deleteEntry, result] = useMutation(DELETE_JOB_EXPERIENCE, options)

  const deleteJobExperienceEntry = (
    variables: MutationDeleteEntryFromJobExperienceFromPersonArgs
  ) =>
    deleteEntry({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, deleteJobExperienceEntry }
}
