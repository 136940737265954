import { Route, Routes } from 'react-router-dom'

import { ContentLayout } from './../common/components/organisims/ContentLayout'
import { PageEventDetails } from './../pages/PageEventDetails'

export const EventRoutes = () => {
  return (
    <Routes>
      <Route path="/event" element={<ContentLayout disablePadding />}>
        <Route path=":id" element={<PageEventDetails />} />
      </Route>
    </Routes>
  )
}
