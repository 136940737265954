import { styled, Typography } from '@mui/material'

const NavWrapper = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  color: theme.palette.colors.lightGrey,
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  columnGap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    columnGap: theme.spacing(8),
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-end',
    columnGap: theme.spacing(10),
    paddingRight: 0,
    paddingLeft: 0,
  },

  a: {
    color: 'inherit',
  },
}))

const NavList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  columnGap: theme.spacing(6),
}))

const FooterLink = styled('a')(({ theme }) => ({
  paddingTop: '.5em',
  paddingBottom: '.5em',
  color: 'inherit',
  transition: theme.transitions.create('color'),
  whiteSpace: 'nowrap',

  ':hover': {
    color: theme.palette.colors.foam,
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.colors.granite,
    },
  },
}))

export const FooterMinimal = () => {
  const currentYear = new Date().getFullYear()

  return (
    <NavWrapper>
      <NavList>
        <Typography
          component={FooterLink}
          variant="body2"
          href="https://www.neuefische.de/en/meta/leitbild"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mission
        </Typography>

        <Typography
          component={FooterLink}
          variant="body2"
          href="/meta/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Typography>

        <Typography
          component={FooterLink}
          variant="body2"
          href="/meta/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of use
        </Typography>

        <Typography
          component={FooterLink}
          variant="body2"
          href="https://www.neuefische.de/en/meta/impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal Notice
        </Typography>
      </NavList>

      <Typography
        variant="body2"
        sx={{
          paddingTop: '.5em',
          paddingBottom: '.5em',
          whiteSpace: 'nowrap',
        }}
      >
        &copy; {currentYear} neuefische GmbH
      </Typography>
    </NavWrapper>
  )
}
