import { Button, Dialog } from '@mui/material'
import { useState } from 'react'

import { Plus } from '../../../assets/icons'
import { FormAddBootcamp } from './FormAddBootcamp'

export const AddBootcamp = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setModalOpen(true)} startIcon={<Plus />}>
        Add Bootcamp
      </Button>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <FormAddBootcamp
          onCancel={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      </Dialog>
    </>
  )
}
