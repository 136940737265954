import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import { min } from 'lodash'
import { useCallback, useState } from 'react'
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web'

import { getIndexName, searchClient } from '../../../../config/algoliaConfig'
import type { AlgoliaPartnerLog } from '../../../../types/algolia.d'
import { LogGrid, LogItem } from '../../atoms/LogItem'

export const ActivityLogs = ({ id }: { id: string }) => {
  const [shownResults, setShownResults] = useState(loadMoreAmount)
  const { hits, results } = useHits<AlgoliaPartnerLog>()

  const onLoadMore = useCallback(() => {
    const newShownResults = min([
      results?.nbHits || 0,
      shownResults + loadMoreAmount,
    ]) as number

    if (newShownResults > shownResults) {
      setShownResults(newShownResults)
    }
  }, [results?.nbHits, shownResults])

  const showLoadmore = !results?.nbHits || shownResults < results?.nbHits

  const partnerName = !!hits.length && hits[0].partnerName

  return (
    <>
      <Configure filters={`partnerId:${id}`} hitsPerPage={shownResults} />

      <Card>
        <CardHeader
          title={
            <Stack mb={4} spacing={2}>
              {partnerName && (
                <Typography variant="body2Strong" color="colors.nemo">
                  {partnerName}
                </Typography>
              )}

              <Typography variant="h5">Activity Log</Typography>
            </Stack>
          }
        />
        <CardContent>
          {!hits.length ? (
            <Typography variant="body2">No activities found</Typography>
          ) : (
            <Stack spacing={8}>
              <LogGrid>
                {hits.map(log => (
                  <LogItem {...log} key={log.objectID} />
                ))}
              </LogGrid>

              {showLoadmore && (
                <Button
                  variant="text"
                  sx={{
                    color: 'colors.grey',
                    ':hover': {
                      backgroundColor: '#9999991a',
                    },
                  }}
                  onClick={onLoadMore}
                >
                  Load more
                </Button>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  )
}

const loadMoreAmount = 8

export const PartnerActivityLog = ({ id }: { id?: string }) => {
  if (!id) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName('partnerLog')}
    >
      <ActivityLogs id={id} />
    </InstantSearch>
  )
}
