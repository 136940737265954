import { BoxProps, IconButton } from '@mui/material'
import { ReactNode, useCallback } from 'react'

import { PenEdit } from '../../../assets/icons'
import { useModalStore } from '../../../stores/modalStore'

export interface EditButtonProps {
  modalContent: ReactNode
  sx?: BoxProps['sx']
}

export const EditButton = ({ modalContent, sx }: EditButtonProps) => {
  const setModal = useModalStore(state => state.setModal)
  const setModalContent = useModalStore(state => state.setModalContent)

  const onEdit = useCallback(() => {
    if (modalContent) {
      setModalContent(modalContent)
      setModal(true)
    }
  }, [modalContent, setModal, setModalContent])

  return (
    <IconButton aria-label="edit" onClick={onEdit} color="inherit" sx={sx}>
      <PenEdit />
    </IconButton>
  )
}
