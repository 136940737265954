import { ApolloCache } from '@apollo/client'

// seems like there is a known issue with refetechQueries in some cases as described here:
// https://github.com/apollographql/apollo-client/issues/5419#issuecomment-1242511457
export const evictFieldFromCache = (fieldName: string) => (
  cache: ApolloCache<unknown>
) =>
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName,
  })

export const evictProfileCompletionData = evictFieldFromCache(
  'getProfileCompletionData'
)
