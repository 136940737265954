import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'
import { typography } from './typography'

export const MuiDialogTitle: Components['MuiDialogTitle'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: '16px 24px',
      [muiDefaultTheme.breakpoints.up('lg')]: {
        padding: '16px 32px',
      },

      ...typography.title2,
    },
  },
}
