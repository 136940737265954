import { Components } from '@mui/material/styles'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'

const { down } = muiDefaultTheme.breakpoints

export const MuiSpeedDial: Components['MuiSpeedDial'] = {
  styleOverrides: {
    root: {
      [down('md')]: {
        position: 'absolute',
        left: 0,
        bottom: 0,
      },
    },
    fab: {
      flex: 'none',
      fontSize: `${24 / 16}rem`,
      width: `${48 / 16}rem`,
      height: `${48 / 16}rem`,
      boxShadow: 'none',
      '&:hover, &:focus': {
        boxShadow: 'none',
        backgroundColor: colors.darkNemo,
      },
    },
  },
}
