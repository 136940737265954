import { Components } from '@mui/material/styles'

import { colors } from '../constants/colors'
import { typography } from './typography'

export const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      ...typography.caption,
      color: colors.granite,
      background: colors.water,
    },
  },
}
