import { Components } from '@mui/material/styles'

import { colors } from '../constants/colors'

export const MuiSpeedDialAction: Components['MuiSpeedDialAction'] = {
  styleOverrides: {
    fab: {
      color: colors.granite,
      background: colors.water10,
      fontSize: `${24 / 16}rem`,
      width: `${40 / 16}rem`,
      height: `${40 / 16}rem`,
      boxShadow: 'none',
      margin: 4,
      '&:focus': {
        border: `2px solid ${colors.purple}`,
        boxShadow: 'none',
        tapHighlightColor: 'transparent',
      },
      '@media(hover:hover)': {
        '&:focus': {
          border: 0,
        },
      },
      '&:hover': {
        boxShadow: 'none',
        color: colors.foam,
        background: colors.nemo,
      },
      '&:active': {
        boxShadow: 'none',
        color: colors.foam,
        background: colors.darkNemo,
      },
      '&:focus-visible': {
        border: `2px solid ${colors.purple}`,
        boxShadow: 'none',
      },
    },
  },
}
