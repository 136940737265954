import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { MutationUpdatePartnerArgs } from '../graphqlTypes'
import { GET_PARTNER_DETAILS } from './usePartnerDetails'

const UPDATE_PARTNER = gql`
  mutation UpdatePartner(
    $id: String!
    $name: String!
    $description: String!
    $industry: String
    $homepageUrl: String
    $yearOfEstablishment: Int
    $size: String
    $revenueCategory: String
    $address: String
    $postalCode: String
    $city: String
  ) {
    updatePartner(
      id: $id
      name: $name
      description: $description
      industry: $industry
      homepageUrl: $homepageUrl
      yearOfEstablishment: $yearOfEstablishment
      size: $size
      revenueCategory: $revenueCategory
      address: $address
      postalCode: $postalCode
      city: $city
    ) {
      id
      name
      description
      industry
      homepageUrl
      yearOfEstablishment
      size
      revenueCategory
      address
      postalCode
      city
    }
  }
`

export default function useUpdatePartner(options: BaseMutationOptions) {
  const [updatePartnerMutation, status] = useMutation(UPDATE_PARTNER, options)

  const updatePartner = (variables: MutationUpdatePartnerArgs) =>
    updatePartnerMutation({ variables, refetchQueries: [GET_PARTNER_DETAILS] })

  return { updatePartner, ...status }
}
