import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

const noGutter = {
  paddingLeft: 0,
  paddingRight: 0,
}

export const smallGutter = {
  paddingLeft: 24,
  paddingRight: 24,
}

const largeGutter = {
  paddingLeft: 40,
  paddingRight: 40,
}

export const MuiContainer: Components['MuiContainer'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      ...smallGutter,
      [muiDefaultTheme.breakpoints.up('xs')]: smallGutter,
      [muiDefaultTheme.breakpoints.up('sm')]: smallGutter,
      [muiDefaultTheme.breakpoints.up('md')]: largeGutter,
      [muiDefaultTheme.breakpoints.up('lg')]: largeGutter,
      [muiDefaultTheme.breakpoints.up('xl')]: largeGutter,
    },
    maxWidthXl: {
      maxWidth: 1440,
    },
    disableGutters: {
      ...noGutter,
      [muiDefaultTheme.breakpoints.up('xs')]: noGutter,
      [muiDefaultTheme.breakpoints.up('sm')]: noGutter,
      [muiDefaultTheme.breakpoints.up('md')]: noGutter,
      [muiDefaultTheme.breakpoints.up('lg')]: noGutter,
      [muiDefaultTheme.breakpoints.up('xl')]: noGutter,
    },
  },
}
