import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { LIST_PERSONS_WITH_EMAIL } from './useListPrivatePersons'

const DELETE_PERSON = gql`
  mutation DeletePerson($personId: String!) {
    deletePerson(personId: $personId)
  }
`

export const useDeletePerson = (options: BaseMutationOptions) => {
  const [deletePersonMutation, result] = useMutation(DELETE_PERSON, options)

  const deletePerson = (personId: string) =>
    deletePersonMutation({
      variables: {
        personId,
      },
      refetchQueries: [LIST_PERSONS_WITH_EMAIL],
    })
  return { deletePerson, ...result }
}
