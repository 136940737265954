import { FC, InputHTMLAttributes } from 'react'
import { styled } from '@mui/material'
import Label from './Label'

type TextAreaWithLabelProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  srOnly?: boolean
  labelText: string
  cols?: number
  rows?: number
}

const TextAreaWithLabel: FC<TextAreaWithLabelProps> = ({
  srOnly,
  labelText,
  cols,
  rows,
  ...props
}) => {
  return (
    <LabelStyled srOnly={srOnly} labelText={labelText}>
      <TextArea cols={cols} rows={rows} {...props} />
    </LabelStyled>
  )
}

export default TextAreaWithLabel

const TextArea = styled('textarea')`
  border: 1px solid var(--secondaryTwo);
  padding: var(--spacing-m) var(--spacing-l);
  border-radius: var(--spacing-xs);
`

const LabelStyled = styled(Label)`
  display: grid;
  grid-gap: var(--spacing-s);
`
