import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Slider from '@mui/material/Slider'
import { useState } from 'react'
import { Configure } from 'react-instantsearch-hooks-web'

import { Geolocation } from '../../../../graphql/graphqlTypes'
import { useGetGeolocationSuggestions } from '../../../../graphql/person/useGetGeolocationSuggestions'
import { toLocationFilterName } from '../../../../service/geolocation-service'
import type { AlgoliaRefinementListItem } from '../../../../types/algolia'

type Props = {
  refine: (value: string) => void
  items: AlgoliaRefinementListItem[]
  onSelectGeolocation: (geolocation: Geolocation | null) => void
  selectedGeolocation: Geolocation | null
  latLng?: string
  selectedRadius: number
  onSelectRadius: (radius: number) => void
}

const FILTER_RADIUS_VALUES = [
  { value: 5, label: '5km' },
  { value: 50, label: '50km' },
  {
    value: 100,
    label: '100km',
  },
]

export const LocationFilter = (props: Props) => {
  const [geolocationInput, setGeolocationInput] = useState('')

  const { suggestions } = useGetGeolocationSuggestions(geolocationInput)

  const checkboxItems = props.items.filter(
    item => item.value === 'Remote' || item.value === 'Flexible'
  )

  const selectedGeolocationName = props.selectedGeolocation
    ? toLocationFilterName(props.selectedGeolocation, props.selectedRadius)
    : ''

  return (
    <>
      <Configure
        aroundLatLng={props.latLng}
        aroundRadius={props.latLng ? props.selectedRadius * 1000 : undefined}
      />
      <Typography color="colors.grey">Location</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
          gap: 6,
        }}
      >
        {checkboxItems.map(item => (
          <FormControlLabel
            key={item.label}
            control={
              <Checkbox
                checked={item.isRefined}
                onChange={() => props.refine(item.value)}
              />
            }
            label={item.label}
          />
        ))}
      </Box>
      {!props.selectedGeolocation && (
        <Autocomplete
          disablePortal
          popupIcon=""
          options={suggestions}
          getOptionLabel={geoLoc => geoLoc.text}
          inputValue={geolocationInput}
          value={props.selectedGeolocation}
          onInputChange={(event, newValue) => {
            if (geolocationInput === newValue) {
              return
            }
            setGeolocationInput(newValue)
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              return
            }
            props.onSelectGeolocation(newValue)
            setGeolocationInput(newValue.text)
          }}
          renderInput={params => (
            <TextField {...params} label="Enter Location" />
          )}
        />
      )}
      {props.selectedGeolocation && (
        <Stack spacing={2}>
          <Chip
            sx={{ width: '100%' }}
            variant="outlined"
            label={selectedGeolocationName}
            onDelete={() => props.onSelectGeolocation(null)}
          />
          <Slider
            min={5}
            max={100}
            step={5}
            marks={FILTER_RADIUS_VALUES}
            value={props.selectedRadius}
            onChange={(e, newValue) => props.onSelectRadius(newValue as number)}
          />
        </Stack>
      )}
    </>
  )
}
