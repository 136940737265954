import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'
import { typography } from './typography'

export const MuiMenuItem: Components['MuiMenuItem'] = {
  defaultProps: {
    divider: true,
  },
  styleOverrides: {
    root: {
      padding: '8px 12px',
      ...typography.captionStrong,
      borderRadius: 4,
      transition: muiDefaultTheme.transitions.create('background-color'),
      '> a, > a:focus, > a:visited': {
        color: 'inherit',
      },

      ':hover': {
        backgroundColor: colors.water10,
      },
    },
    dense: {
      padding: '6px 16px',
    },
    divider: {
      '&:last-of-type': {
        borderBottom: 0,
      },
    },
  },
}
