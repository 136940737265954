import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const useMarkdown = (path: string) => {
  const [md, setMd] = useState('')

  useEffect(() => {
    fetch(path)
      .then(res => res.text())
      .then(setMd)
      .catch(() => toast.error('Error loading markdown'))
  }, [path])

  return md
}
