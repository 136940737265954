import { Button, styled } from '@mui/material'
import { Checkbox } from '@neuefische/design-system'
import { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputWithLabel from '../../../common-deprecated/components/InputWithLabel'
import TextAreaWithLabel from '../../../common-deprecated/components/TextAreaWithLabel'
import { useSetEventDetails } from '../../../graphql/events/setEventDetails'
import { Event } from '../../../graphql/graphqlTypes'

export default function EventForm({ event }: { event: Event }) {
  const [eventToEdit, setEventToEdit] = useState({
    ...event,
    techStack: event.techStack.join(','),
  })

  const { setEventDetails } = useSetEventDetails({
    onCompleted: ({ setEventDetails }) => {
      toast.success(`Event Details updated`)
      setEventToEdit({
        ...setEventDetails,
        techStack: setEventDetails.techStack.join(','),
      })
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const handleChange = (event: {
    target: HTMLInputElement | HTMLTextAreaElement
  }) => {
    setEventToEdit({ ...eventToEdit, [event.target.name]: event.target.value })
  }

  const handlePublishing = (event: ChangeEvent<HTMLInputElement>) => {
    setEventToEdit({ ...eventToEdit, published: event.target.checked })
  }

  const isFormValid = eventToEdit.date && eventToEdit.time

  const save = (event: any) => {
    event.preventDefault()
    if (isFormValid) {
      setEventDetails({
        ...eventToEdit,
        date: eventToEdit.date!,
        time: eventToEdit.time!,
        techStack: eventToEdit.techStack.split(','),
      })
    }
  }

  return (
    <Form onSubmit={save}>
      <InputWithLabel
        labelText="Date:"
        type="date"
        name="date"
        value={eventToEdit.date || ''}
        onChange={handleChange}
      />
      <InputWithLabel
        labelText="Time:"
        type="time"
        name="time"
        value={eventToEdit.time || ''}
        onChange={handleChange}
      />
      <TextAreaWithLabel
        labelText="Tech stack (comma seperated):"
        name="techStack"
        value={eventToEdit.techStack}
        onChange={handleChange}
      />
      <TextAreaWithLabel
        labelText="Location:"
        placeholder="Remote event link or multiline address"
        type="location"
        name="location"
        value={eventToEdit.location || ''}
        onChange={handleChange}
      />
      <Checkbox
        name="published"
        label="Published"
        checked={eventToEdit.published}
        onChange={handlePublishing}
      />
      <div>
        <Link
          target="_blank"
          to={`/event/${eventToEdit.id}?token=${eventToEdit.token}`}
        >
          {eventToEdit.token ? 'Open Event Page' : 'Preview Event Page'}
        </Link>
        <Button disabled={!isFormValid} type="submit">
          Save Event Details
        </Button>
      </div>
    </Form>
  )
}

const Form = styled('form')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-m);
  align-items: center;

  div {
    grid-column: 2;
    justify-self: end;
    text-align: center;
    button {
      margin-left: 25px;
    }
  }
`
