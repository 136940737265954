import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'

export const MuiSlider: Components['MuiSlider'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      height: 8,
      '.MuiSlider-rail': {
        backgroundColor: colors.water,
      },
      '.MuiSlider-track': {
        border: 0,
        backgroundColor: colors.lightNemo,
      },
      '& .MuiSlider-thumb': {
        height: muiDefaultTheme.spacing(7),
        width: muiDefaultTheme.spacing(7),
        backgroundColor: colors.nemo,
        border: 0,
        '&::before, &::after': {
          display: 'none',
        },
        '&:focus, &.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 1px ${colors.purple}`,
        },
        '&:active, &:hover': {
          backgroundColor: colors.darkNemo,
          boxShadow: `0px 0px 0px 4px ${colors.darkNemo}`,
        },
        '.MuiSvgIcon-root': {
          fontSize: 14,
          fill: colors.foam,
          transform: 'rotate(90deg)',
        },
      },
      '&.Mui-disabled': {
        '.MuiSlider-track': {
          backgroundColor: colors.water10,
        },
        '.MuiSlider-thumb': {
          backgroundColor: colors.lightGrey,
        },
      },
    },
  },
}
