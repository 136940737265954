import { Box, Chip, Stack, useMediaQuery, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import {
  Configure,
  useClearRefinements,
  useRefinementList,
} from 'react-instantsearch-hooks-web'

import { Geolocation } from '../../../../graphql/graphqlTypes'
import { useGetLatLng } from '../../../../graphql/useGetLatLng'
import {
  DEFAULT_RADIUS,
  toLocationFilterName,
} from '../../../../service/geolocation-service'
import type { AlgoliaRefinementListItem } from '../../../../types/algolia'
import { SearchBox } from '../../molecules/SearchBox'
import { RefinementListCallback } from './RefinementListItem'
import { StudentFilterModalButton } from './StudentFilterModalButton'

type Props = AlgoliaRefinementListItem & {
  refine: (value: string) => void
}

const HITS_PER_PAGE = 10

export const SearchBar = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [numberOfRefinements, setNumberOfRefinements] = useState(0)

  const tracksRefinement = useRefinementList({
    attribute: 'tracks',
  })
  const jobPreferencesRefinement = useRefinementList({
    attribute: 'jobPreferences',
  })
  const statusRefinement = useRefinementList({
    attribute: 'status',
  })
  const availabilityRefinement = useRefinementList({
    attribute: 'availability',
  })
  const locationRefinement = useRefinementList({
    attribute: 'locationPreferences',
  })
  const languageRefinement = useRefinementList({
    attribute: 'languages',
    limit: 100,
  })
  const skillRefinement = useRefinementList({
    attribute: 'skills',
    limit: 100,
    operator: 'and',
  })
  const { refine: clearAllRefinements } = useClearRefinements()
  const [geolocation, setGeolocation] = useState<Geolocation | null>(null)
  const [radius, setRadius] = useState(50)

  const applyRefinements: RefinementListCallback = (
    tracks,
    jobPreferences,
    availability,
    status,
    locations,
    languages,
    skills,
    geolocation,
    radius
  ) => {
    clearAllRefinements()
    tracks.forEach(
      item => item.isRefined && tracksRefinement.refine(item.value)
    )
    jobPreferences.forEach(
      item => item.isRefined && jobPreferencesRefinement.refine(item.value)
    )
    status.forEach(
      item => item.isRefined && statusRefinement.refine(item.value)
    )
    availability.forEach(
      item => item.isRefined && availabilityRefinement.refine(item.value)
    )
    locations.forEach(
      item => item.isRefined && locationRefinement.refine(item.value)
    )
    languages.forEach(
      item => item.isRefined && languageRefinement.refine(item.value)
    )
    skills.forEach(item => item.isRefined && skillRefinement.refine(item.value))
    setGeolocation(geolocation)
    setRadius(radius)
  }

  const RefinedItem: FC<Props> = props => (
    <Chip
      key={props.value}
      label={props.label}
      variant="outlined"
      onDelete={() => {
        props.refine(props.value)
        setNumberOfRefinements(numberOfRefinements - 1)
      }}
      sx={{ flexShrink: 0 }}
    />
  )

  const { latLng } = useGetLatLng(geolocation?.placeId)
  const filterRadius = latLng ? radius * 1000 : 'all'

  return (
    <Stack spacing={6}>
      <Configure
        hitsPerPage={HITS_PER_PAGE}
        aroundLatLng={latLng}
        aroundRadius={filterRadius}
      />

      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: { xs: 2, lg: 4 } }}
      >
        <SearchBox />
        <StudentFilterModalButton
          onRefine={applyRefinements}
          tracks={tracksRefinement.items}
          jobPreferences={jobPreferencesRefinement.items}
          status={statusRefinement.items}
          availability={availabilityRefinement.items}
          numberOfRefinements={numberOfRefinements}
          locations={locationRefinement.items}
          languages={languageRefinement.items}
          skills={skillRefinement.items}
          setNumberOfRefinements={setNumberOfRefinements}
          geolocation={geolocation}
          radius={radius}
        />
      </Box>
      {numberOfRefinements > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: { xs: 2, lg: 4 },
            flexWrap: isMobile ? 'nowrap' : 'wrap',
            overflow: isMobile ? 'auto' : 'initial',
          }}
        >
          {tracksRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={tracksRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {jobPreferencesRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={jobPreferencesRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {statusRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={statusRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {availabilityRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={availabilityRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {locationRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={locationRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {skillRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={skillRefinement.refine}
                  key={item.value}
                />
              )
          )}
          {geolocation && (
            <Chip
              label={toLocationFilterName(geolocation, radius)}
              variant="outlined"
              onDelete={() => {
                setGeolocation(null)
                setRadius(DEFAULT_RADIUS)
                setNumberOfRefinements(numberOfRefinements - 1)
              }}
              sx={{ flexShrink: 0 }}
            />
          )}
          {languageRefinement.items.map(
            item =>
              item.isRefined && (
                <RefinedItem
                  {...item}
                  refine={languageRefinement.refine}
                  key={item.value}
                />
              )
          )}
        </Box>
      )}
    </Stack>
  )
}
