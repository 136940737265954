import {
  ColorPartial,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

import { colors } from '../constants/colors'

export const grey: ColorPartial = {
  50: colors.water,
  100: colors.water10,
  400: colors.lightGrey,
  600: colors.grey,
  800: colors.darkGrey,
}

export const palette: PaletteOptions = {
  primary: {
    main: colors.nemo,
    light: colors.lightNemo,
    dark: colors.darkNemo,
  },
  secondary: {
    main: colors.granite,
  },
  background: {
    default: colors.water,
    paper: '#FFFFFF',
  },
  text: {
    primary: colors.granite,
  },
  grey,
  colors,
}
