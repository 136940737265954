import { css, styled } from '@mui/material'
import { HTMLAttributes } from 'react'

type MessageVariant = 'error' | 'success'

type MessageProps = HTMLAttributes<HTMLParagraphElement> & {
  variant: MessageVariant
}

export default styled('p')<MessageProps>`
  text-align: center;
  border: 2px solid;
  padding: 1em;
  width: auto;

  ${props =>
    props.variant === 'error' &&
    css`
      color: var(--accent);
    `}

  ${props =>
    props.variant === 'success' &&
    css`
      color: #0c813a;
    `}
`
