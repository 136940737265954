import { FC, useState } from 'react'
import { toast } from 'react-toastify'

import { Person } from '../../../../graphql/graphqlTypes'
import useSendMessage from '../../../../graphql/partner/useSendMessage'
import { useUser } from '../../../../graphql/person/useUser'
import { CheckmarkCircle1, Warning } from '../../../assets/icons'
import { useModalStore } from '../../../stores/modalStore'
import { colors } from '../../../theme/constants/colors'
import { FormContact } from './ContactForm'
import { ContactStatusMessage } from './ContactStatusMessage'

const successMessage = (
  <>
    Your mail has been sent. Responses will <br /> be sent directly to your
    mail.
  </>
)
const errorMessage = (
  <>
    Something went wrong. <br /> Please try again or write us at
    <br />
    support@neuefische.com
  </>
)

export const Contact: FC<{ person: Person }> = ({ person }) => {
  const [onSuccess, setOnSucces] = useState(false)
  const [message, setMessage] = useState('')

  const { person: partner } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const { setModal } = useModalStore()

  const { sendMessage, error } = useSendMessage({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      setOnSucces(true)
    },
  })

  if (!partner) {
    return null
  }

  const submit = (text: string) => {
    sendMessage(partner.id, person.id, text)
  }

  return (
    <>
      {!(onSuccess || error) && (
        <FormContact
          person={person}
          submit={submit}
          setMessage={setMessage}
          handleCancel={() => setModal(false)}
        />
      )}
      {onSuccess && (
        <ContactStatusMessage
          title="Contact"
          icon={
            <CheckmarkCircle1 width={30} height={30} color={colors.success} />
          }
          buttonLabel="Got it"
          headlineLabel="Success!"
          statusMessage={successMessage}
          handleClick={() => setModal(false)}
          handleCancel={() => setModal(false)}
        />
      )}
      {error && (
        <ContactStatusMessage
          title="Contact"
          icon={<Warning width={30} height={30} color={colors.error} />}
          buttonLabel="Try again"
          headlineLabel="Oh no."
          statusMessage={errorMessage}
          handleClick={() => submit(message)}
          handleCancel={() => setModal(false)}
        />
      )}
    </>
  )
}
