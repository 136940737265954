import axios from 'axios'
import { useEffect, useState } from 'react'

import { BASE_URL } from '../../config/backendConfig'

const ENDPOINT = `${BASE_URL}/maintenance`
export const useMaintenance = () => {
  const [maintenanceLoading, setMaintenanceLoading] = useState(false)
  const [isMaintenance, setIsMaintenance] = useState(false)

  useEffect(() => {
    setMaintenanceLoading(true)
    axios
      .get(ENDPOINT)
      .then(res => res.data)
      .then(maintenance => setIsMaintenance(maintenance.isMaintenance))
      .finally(() => setMaintenanceLoading(false))
  }, [])

  return { maintenanceLoading, isMaintenance }
}
