import { Box } from '@mui/material'
import { useMemo } from 'react'

import { Device } from '../../../../graphql/graphqlTypes'
import { useDeviceFrame } from '../../../hooks/useDeviceFrame'

export type CapstoneImageProps = {
  src?: string
  device: Device
  isFullscreen?: boolean
}

export const CapstoneImage = ({
  src,
  device,
  isFullscreen,
}: CapstoneImageProps) => {
  const { frameUrl, frameInset } = useDeviceFrame(device)

  const maxWidth = useMemo(() => {
    if (device === 'SMARTPHONE') {
      if (isFullscreen) return 900
      return 120
    }
    if (device === 'TABLET_PORTRAIT') {
      if (isFullscreen) return 'none'
      return 190
    }
    return 'none'
  }, [device, isFullscreen])

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth,
        marginX: 'auto',
        height: isFullscreen ? 'fit-content' : 'auto',
      }}
    >
      <Box
        component="img"
        src={
          src ||
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1'/%3E"
        }
        alt=""
        sx={{
          position: 'absolute',
          top: `${frameInset.top}%`,
          right: `${frameInset.right}%`,
          bottom: `${frameInset.bottom}%`,
          left: `${frameInset.left}%`,
          width: `calc(100% - ${frameInset.left}% - ${frameInset.right}%)`,
          height: `calc(100% - ${frameInset.top}% - ${frameInset.bottom}%)`,
          objectFit: 'cover',
          objectPosition: 'top',
          backgroundColor: 'colors.water10',
        }}
      />
      <Box
        component="img"
        src={frameUrl}
        alt=""
        sx={{
          position: 'relative',
        }}
      />
    </Box>
  )
}
