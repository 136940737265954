import { gql, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import { BootcampTrack } from '../graphqlTypes'

export const LIST_BOOTCAMP_TRACKS = gql`
  query ListBootcampTracks {
    listBootcampTracks {
      id
      name
    }
  }
`

export const useListBootcampTracks = () => {
  const query = useQuery<{ listBootcampTracks: BootcampTrack[] }>(
    LIST_BOOTCAMP_TRACKS
  )
  const bootcampTracks = query.data?.listBootcampTracks || []
  const completed = query.called && !query.loading && !query.error
  return { ...query, tracks: sortBy(bootcampTracks, 'name'), completed }
}
