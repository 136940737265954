import {
  Avatar,
  Box,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

import type { CompanyTeaser as CompanyTeaserProps } from '../../../../types/graphql'

export const CompanyTeaser = ({
  id,
  name,
  industry,
  profileImage,
}: CompanyTeaserProps) => {
  return (
    <Box
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'colors.water10',
        borderRadius: 2,
      }}
    >
      <CardActionArea
        component={Link}
        to={`/company/${id}`}
        sx={{ height: '100%' }}
      >
        <CardContent>
          <Stack spacing={6}>
            <Avatar
              src={profileImage?.urls.m}
              variant="rounded-big"
              sx={{ margin: 'auto' }}
            />

            <Stack spacing={1}>
              <Typography variant="body2Strong" textAlign="center">
                {name}
              </Typography>

              {industry && (
                <Typography variant="caption" textAlign="center">
                  {industry}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Box>
  )
}
