import { toast } from 'react-toastify'
import { useReInvitePerson } from '../../../graphql/person/useReInvitePerson'
import { Person } from '../../../graphql/graphqlTypes'
import { useState } from 'react'
import PersonItemMenu from './PersonItemMenu'
import { toLocaleDateString } from '../../../service/date-service'
import { Button, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { getPersonTypeName } from '../../../service/person-type-service'

export default function PersonItem({
  email,
  id,
  firstName,
  lastName,
  personType,
  created,
  status,
  isAdmin,
  participates,
}: Person) {
  const { reinvitePerson, loading: inviteLoading } = useReInvitePerson({
    onCompleted: () => toast.success(`${email} re-invited 🎉`),
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const [showMenu, setShowMenu] = useState(false)
  return (
    <Layout>
      <EmailNameWrapper>
        <span>
          {firstName || '-'} {lastName || '-'}
        </span>
        <Email>{email}</Email>
        <Created>{created && toLocaleDateString(created)}</Created>
      </EmailNameWrapper>
      {isAdmin && <AdminBadge>Admin</AdminBadge>}
      <Type>{getPersonTypeName(personType)}</Type>
      {status !== 'CONFIRMED' ? (
        <InviteButton
          disabled={inviteLoading}
          onClick={() => reinvitePerson(id)}
        >
          Invite again
        </InviteButton>
      ) : (
        <Button
          variant="text"
          size="small"
          component={Link}
          to={`/profile/${id}`}
        >
          Visit Profile
        </Button>
      )}
      <PersonItemMenu
        firstName={firstName}
        lastName={lastName}
        email={email}
        id={id}
        isAdmin={isAdmin}
        showMenu={showMenu}
        participates={participates}
        setShowMenu={setShowMenu}
      />
    </Layout>
  )
}

const Layout = styled('section')`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-s);
`

const AdminBadge = styled('div')`
  padding: 3px;
  color: white;
  background-color: var(--accent);
  font-size: 12px;
`

const Type = styled('span')`
  margin-left: auto;
  margin-right: var(--spacing-m);
  text-transform: uppercase;
  color: var(--secondaryOne);
  font-size: var(--font-xs);
`
const Created = styled('span')`
  color: var(--secondaryOne);
  font-size: var(--font-xs);
`

const Email = styled('span')`
  margin-right: var(--spacing-m);
  color: var(--secondaryOne);
  font-size: var(--font-xs);
`
const InviteButton = styled(Button)`
  padding: var(--spacing-xs);
`

const EmailNameWrapper = styled('div')`
  display: grid;
`
