import { gql, useQuery } from '@apollo/client'

import { ProfileCompletion } from '../graphqlTypes'

export const GET_PROFILE_COMPLETIONS_BY_BOOTCAMP = gql`
  query GetProfileCompletionsByBootcamp($bootcampId: String!) {
    getProfileCompletionsByBootcamp(bootcampId: $bootcampId) {
      firstName
      lastName
      personId
      completionScore {
        score
        capstoneConsidered
      }
      isBasicInfoComplete
      isSkillsComplete
      isExperienceComplete
      isJobPreferencesComplete
      isCapstoneComplete
      isLanguageComplete
    }
  }
`

export const useGetBootcampProfileCompletion = (bootcampId: string) => {
  const query = useQuery<{
    getProfileCompletionsByBootcamp: [ProfileCompletion]
  }>(GET_PROFILE_COMPLETIONS_BY_BOOTCAMP, {
    variables: {
      bootcampId: bootcampId,
    },
  })
  const completionData: [ProfileCompletion] | undefined =
    query.data?.getProfileCompletionsByBootcamp
  return { ...query, completionData }
}
