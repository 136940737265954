import {
  Avatar,
  Box,
  Button,
  Dialog,
  FormHelperText,
  styled,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { defaultAvatar } from '../../../../theme/mui/MuiAvatar'
import { UploadOverlay } from '../../../atoms/UploadOverlay'
import { ImageCropper } from '../../../molecules/ImageCropper'

const ProfileImageWrapper = styled('div')({
  position: 'relative',
  display: 'grid',
  gridTemplateAreas: '"content"',
  margin: 12,

  '> *': {
    gridArea: 'content',
  },
})

type FormValues = {
  image?: string
}

export interface StandardFormProfileImageProps {
  id: string
  image?: string
  deleteImage?: (id: string) => Promise<void>
  setNewImage: (imageId: string, id: string) => Promise<void>
  uploadImage: (img: File) => Promise<{ id: string }>
  ratio?: number
  padded?: boolean
}

export const FormProfileImage = ({
  image,
  deleteImage,
  setNewImage,
  id,
  uploadImage,
  ratio = 1,
  padded = false,
}: StandardFormProfileImageProps) => {
  const [file, setFile] = useState<File>()
  const [croppedImage, setCroppedImage] = useState<File>()
  const [modalOpen, setModalOpen] = useState(false)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles.at(0))
    setModalOpen(true)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const fileUrl = useMemo(() => file && URL.createObjectURL(file), [file])

  const croppedImageUrl = useMemo(
    () => croppedImage && URL.createObjectURL(croppedImage),
    [croppedImage]
  )

  const { control } = useForm<FormValues>({
    defaultValues: { image },
  })

  const { fieldState: fieldAvatarState } = useController({
    name: 'image',
    control,
    rules: {
      required: true,
    },
  })

  const [loading, setLoading] = useState(false)
  const handleSetImage = useCallback(
    (newImg: File) => {
      if (!newImg) {
        return
      }
      setLoading(true)
      uploadImage(newImg)
        .then(({ id: imageId }) => {
          setTimeout(() => {
            setNewImage(imageId, id)
              .then(() => setCroppedImage(newImg))
              .then(() => setFile(undefined))
              .then(() => setLoading(false))
              .then(() => setModalOpen(false))
          }, 3000)
        })
        .catch(error => {
          toast.error(error.response.data)
        })
    },
    [id, setNewImage, uploadImage]
  )
  const handleDelete = () => {
    if (deleteImage) {
      deleteImage(id)
        .then(() => setFile(undefined))
        .then(() => setCroppedImage(undefined))
    }
  }

  const handleCancel = () => {
    setModalOpen(false)
    setFile(undefined)
  }

  return (
    <>
      <Box {...getRootProps()}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <ProfileImageWrapper sx={ratio !== 1 ? { width: '100%' } : {}}>
            {ratio === 1 ? (
              <Avatar src={image} variant="rounded-big" />
            ) : (
              <Box
                component="img"
                src={image || defaultAvatar}
                sx={{
                  width: '100%',
                  aspectRatio: `${ratio}/1`,
                  backgroundColor: 'colors.water',
                }}
              />
            )}

            <UploadOverlay sx={isDragActive ? { opacity: 1 } : {}} />
            <input type="file" accept="image/*" hidden {...getInputProps()} />
          </ProfileImageWrapper>
        </Box>
        {fieldAvatarState.error && (
          <FormHelperText error sx={{ marginLeft: 0 }}>
            {fieldAvatarState.error.message}
          </FormHelperText>
        )}
      </Box>
      {(image || fileUrl || croppedImageUrl) && deleteImage && (
        <Button
          onClick={handleDelete}
          variant="text"
          component="div"
          color="error"
          fullWidth
        >
          Delete image
        </Button>
      )}
      {fileUrl && (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <ImageCropper
            image={fileUrl}
            setCroppedImage={handleSetImage}
            loading={loading}
            onCancel={handleCancel}
            ratio={ratio}
            padded={padded}
          />
        </Dialog>
      )}
    </>
  )
}
