export const getNumberOfShownResults = (
  totalElements: number,
  maxResultsPerPage: number,
  page: number
) => {
  if (totalElements === 0) {
    return '0'
  }
  if (totalElements <= maxResultsPerPage) {
    return `1-${totalElements}`
  }
  const showingResultsFrom = page * maxResultsPerPage + 1
  const showingResultsUntil = Math.min(
    showingResultsFrom + maxResultsPerPage - 1,
    totalElements
  )
  return `${showingResultsFrom}-${showingResultsUntil}`
}
