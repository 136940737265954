import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'

import { Event } from '../../../../graphql/graphqlTypes'
import {
  compareRole,
  isCoach,
} from '../../../../service/profile/user-dashboard-service'
import { ChevronDown as IconChevronDown } from '../../../assets/icons'
import { BootcampCoachesList } from '../../molecules/BootcampCoachesList'
import { Tags } from '../../molecules/Tags'

const EventHeader = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.colors.almostGranite,
  color: theme.palette.colors.foam,
}))

const EventHiddenInfo = styled(Card)({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
})

const CardToggleHeader = styled(CardHeader)(({ theme }) => ({
  gap: 10,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  WebkitTapHighlightColor: 'transparent',

  '.MuiCardHeader-action': {
    alignSelf: 'center',
  },
  '.MuiCardHeader-content': {
    flex: 'none',
  },
}))

const BootcampId = styled('span')({
  textTransform: 'uppercase',
})

type EventInfoCardProps = { event: Event }

export const EventInfoCard = ({ event }: EventInfoCardProps) => {
  const [showHiddenInfo, setShowHiddenInfo] = useState(false)
  const coaches = event.bootcamp.participants?.filter(isCoach).sort(compareRole)

  const date = useMemo(() => {
    if (!event?.date) return 'Date TBD'

    return format(new Date(event?.date), 'EEEE, MMMM dd, yyyy')
  }, [event?.date])

  const hasCoaches = coaches && coaches.length > 0
  const hasTechStack =
    event.techStack &&
    event.techStack.length > 0 &&
    event.techStack[0].length > 0

  const hiddenInfoLabel = useMemo(() => {
    if (showHiddenInfo) {
      return 'See less'
    }

    if (hasCoaches && hasTechStack) {
      return 'Show coaches & technologies'
    }

    if (hasCoaches && !hasTechStack) {
      return 'Show coaches'
    }

    if (hasTechStack && !hasCoaches) {
      return 'Show technologies'
    }

    return 'See more' //Fallback
  }, [showHiddenInfo, hasCoaches, hasTechStack])

  return (
    <>
      <EventHeader
        sx={[
          {
            /* rs: this can probably be optimized by consolidating the cards not having a border radius on mobile in general */
            borderRadius: { xs: 0, lg: `${12 / 16}rem` },
          },
          hasCoaches || hasTechStack
            ? {
                borderBottomLeftRadius: { lg: 0 },
                borderBottomRightRadius: { lg: 0 },
              }
            : {},
        ]}
      >
        <CardContent>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 6, md: 10 }}
          >
            <div style={{ flex: 1 }}>
              <Typography variant="body2Strong" color="colors.nemo" mb={2}>
                Graduation Event
              </Typography>
              <Typography variant="h5" mb={6}>
                {event.bootcamp.track?.name}:<br />
                <BootcampId>{event.bootcamp.id}</BootcampId>
              </Typography>
              {event.location && (
                <Button
                  href={
                    event.onlineEvent
                      ? event.location
                      : `https://www.google.com/maps/search/?api=1&query=${event.location}`
                  }
                  size="medium"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {event.onlineEvent
                    ? 'Join event via Zoom call'
                    : 'Find location in Google Maps'}
                </Button>
              )}
            </div>

            <div style={{ flex: 1 }}>
              <Typography mb={4} color="colors.grey">
                Date & Time
              </Typography>
              <Typography variant="body2Strong" mb={6} color="colors.foam">
                {date} — {event.time && <time>{event.time}</time>}
              </Typography>
              <Typography mb={4} color="colors.grey">
                Location
              </Typography>
              <Typography
                variant="body2Strong"
                color="colors.foam"
                whiteSpace="pre-line"
              >
                {event.location
                  ? event.onlineEvent
                    ? 'Remote Event'
                    : event.location
                  : 'TBD'}
              </Typography>
            </div>
          </Stack>
        </CardContent>
      </EventHeader>

      {(hasCoaches || hasTechStack) && (
        <EventHiddenInfo>
          <Collapse in={showHiddenInfo} timeout="auto" unmountOnExit>
            <CardContent>
              <>
                {hasCoaches ? (
                  <>
                    <Typography color="colors.grey">Coaches</Typography>
                    <BootcampCoachesList coaches={coaches} />

                    {hasTechStack && (
                      <Divider sx={{ paddingTop: 4, marginBottom: 4 }} light />
                    )}
                  </>
                ) : null}

                {hasTechStack ? (
                  <>
                    <Typography color="colors.grey">
                      Learned Technologies
                    </Typography>
                    <Tags tags={event.techStack} />
                  </>
                ) : null}
              </>
            </CardContent>
          </Collapse>

          <CardToggleHeader
            title={
              <Typography variant="captionStrong" color="colors.grey">
                {hiddenInfoLabel}
              </Typography>
            }
            onClick={() => setShowHiddenInfo(!showHiddenInfo)}
            action={
              <Typography
                variant="captionStrong"
                color="colors.grey"
                sx={{
                  transform: !showHiddenInfo
                    ? 'rotate(0deg)'
                    : 'rotate(180deg)',
                  transition: theme => theme.transitions.create('transform'),
                }}
              >
                <IconChevronDown />
              </Typography>
            }
          />
        </EventHiddenInfo>
      )}
    </>
  )
}
