import { FC } from 'react'
import { Svg, SvgProps } from './Svg'

const ArrowLeft: FC<SvgProps> = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      transform="rotate(180 12 12)"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 18L16 12 10 6"
    />
  </Svg>
)

export default ArrowLeft
