import { Box, Chip, ChipProps } from '@mui/material'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { DotsDrag } from '../../../assets/icons'

export interface TagDraggableProps extends ChipProps {
  index: number
  onMove: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const TagDraggable = ({
  index,
  onMove,
  label,
  ...props
}: TagDraggableProps) => {
  const ref = useRef<HTMLLIElement>(null)

  const [, drop] = useDrop<DragItem>({
    accept: 'tag',
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      if (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Math.abs(monitor.getClientOffset()!.x - hoverBoundingRect.left) >
        hoverBoundingRect.width / 1.8
      )
        return

      onMove(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'tag',
    item: () => {
      return { id: label, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <Box
      ref={ref}
      sx={theme =>
        isDragging
          ? {
              borderRadius: 0.5,
              outline: `2px dashed ${theme.palette.colors.water10}`,
              cursor: 'grabbing',
            }
          : {}
      }
    >
      <Chip
        {...props}
        sx={isDragging ? { opacity: 0 } : { cursor: 'grab' }}
        label={
          <>
            <Box
              component={DotsDrag}
              sx={{
                display: 'inline',
                marginLeft: -2.5,
                marginRight: 1,
                fontSize: '1.5em',
                verticalAlign: 'bottom',
              }}
            />
            {label}
          </>
        }
      />
    </Box>
  )
}
