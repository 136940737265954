import {
  MutationInvitePersonArgs,
  PersonTypeEnum,
} from '../../../graphql/graphqlTypes'

export const prepareInvitePersonVariables = (
  formData: MutationInvitePersonArgs
) => {
  const dto = { ...formData }
  switch (formData.personType) {
    case PersonTypeEnum.STUDENT:
      delete dto.partnerId
      break
    case PersonTypeEnum.PARTNER_MEMBER:
      delete dto.bootcampId
      delete dto.trainingProvider
      break
    default:
      delete dto.bootcampId
      delete dto.partnerId
      delete dto.trainingProvider
  }
  return dto
}
