import {
  Box,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface FromProfileCareerDateProps {
  label: string
  onChange: (newDate: string) => void
  date?: string
  before?: string
  after?: string
  error?: string
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const FromProfileCareerDate = ({
  label,
  date: dateString,
  onChange,
  before: beforeString,
  after: afterString,
  error,
}: FromProfileCareerDateProps) => {
  const date = useMemo(() => dateString && new Date(dateString), [dateString])
  const before = useMemo(() => beforeString && new Date(beforeString), [
    beforeString,
  ])
  const after = useMemo(() => afterString && new Date(afterString), [
    afterString,
  ])
  const [month, setMonth] = useState(date ? date.getMonth() : undefined)
  const [year, setYear] = useState(date ? date.getFullYear() : undefined)

  useEffect(() => {
    if (year && (month || month === 0)) {
      onChange(new Date(year, month, 15).toISOString())
    }
  }, [month, onChange, year])

  const startYear = after ? after.getFullYear() : 1983
  const endYear = before ? before.getFullYear() : new Date().getFullYear()

  const isMonthDisabled = useCallback(
    (month: number): boolean => {
      if (!date) return false

      if (
        before &&
        before.getFullYear() === date.getFullYear() &&
        month > before.getMonth()
      ) {
        return true
      }

      if (
        after &&
        after.getFullYear() === date.getFullYear() &&
        month < after.getMonth()
      ) {
        return true
      }

      return false
    },
    [after, before, date]
  )

  return (
    <Stack spacing={2}>
      <Typography variant="caption">{label}</Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
        <TextField
          select
          label="Month"
          value={month}
          onChange={e => setMonth(parseInt(e.target.value, 10))}
          error={!!error}
        >
          {months.map((month, key) => (
            <MenuItem value={key} key={month} disabled={isMonthDisabled(key)}>
              {month}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Year"
          value={year}
          onChange={e => setYear(parseInt(e.target.value, 10))}
          error={!!error}
        >
          {Array.from(
            { length: endYear + 1 - startYear },
            (_, i) => i + startYear
          )
            .reverse()
            .map(i => (
              <MenuItem value={i} key={i}>
                {i}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  )
}
