const specialCharacters = /[!@#$%^~&*`()_+\-=[\]{};':"\\|,.<>/?]+/

export const validatePassword = (password: string) => {
  const isLongEnough = password.length > 7
  const includesUpperCase = password.toLowerCase() !== password
  const includesLowerCase = password.toUpperCase() !== password
  const includesNumber = /\d/.test(password)
  const includesSpecialCharacter = specialCharacters.test(password)

  const success =
    isLongEnough &&
    includesUpperCase &&
    includesLowerCase &&
    includesNumber &&
    includesSpecialCharacter

  return {
    success,
    isLongEnough,
    includesUpperCase,
    includesLowerCase,
    includesNumber,
    includesSpecialCharacter,
  }
}
