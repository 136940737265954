import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Avatar, styled } from '@mui/material'
import { toast } from 'react-toastify'

import { useUser } from '../../../../graphql/person/useUser'

const Button = styled('button')(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  paddingTop: 4,
  paddingRight: 8,
  paddingBottom: 4,
  paddingLeft: 8,
  lineHeight: 1,
  borderRadius: 4,
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: theme.palette.colors.foam,
  transition: theme.transitions.create('background-color'),

  ':hover': {
    backgroundColor: theme.palette.colors.water10,
  },

  ':focus': {
    outline: `1px solid ${theme.palette.colors.purple}`,
  },
}))

export interface ProfileButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const ProfileButton = ({ onClick }: ProfileButtonProps) => {
  const { person } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  return (
    <Button onClick={e => onClick(e)}>
      <Avatar
        src={person?.avatar?.urls.s}
        sx={{ width: 32, height: 32, borderRadius: 1 }}
      />
      <ExpandMoreRoundedIcon sx={{ marginTop: 0, fontSize: 20 }} />
    </Button>
  )
}
