import { styled } from '@mui/material'
import { Pagination } from '@neuefische/design-system'
import LoadingIndicator from '../../../common-deprecated/components/LoadingIndicator'
import Message from '../../../common-deprecated/components/Message'
import SearchBar from '../../../common-deprecated/components/SearchBar'
import { usePersons } from '../../../common/hooks/usePersons'
import PersonItem from './PersonItem'

export default function PersonList() {
  const {
    loading,
    error,
    completed,
    persons,
    setSearch,
    setPersonType,
    setBootcampParticipant,
    totalElements,
    pageSize,
    currentPage,
    setPage,
  } = usePersons()

  return (
    <Wrapper>
      <SearchBar
        placeholder={'Search'}
        onInputChange={setSearch}
        onTypeChange={setPersonType}
        onParticipatesBootcampChange={setBootcampParticipant}
      />
      {loading && <LoadingIndicator />}
      {error && (
        <Message variant={'error'}>Something went terribly wrong 🙀</Message>
      )}
      {completed && persons.length === 0 && (
        <div>
          <Message variant={'error'}> No users found ☹️</Message>
        </div>
      )}
      {completed && persons.length !== 0 && (
        <>
          <List>
            {persons.map(person => (
              <li key={person.id}>
                <PersonItem {...person} />
              </li>
            ))}
          </List>
          <PaginationWrapper>
            <Pagination
              totalPages={Math.ceil(totalElements / pageSize)}
              page={currentPage + 1}
              onChange={page => setPage(page - 1)}
            />
          </PaginationWrapper>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: grid;
  grid-gap: var(--spacing-l);
  grid-template-rows: min-content auto auto;
  min-width: 520px;
  min-height: 350px;
`

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 500px;

  li:not(:last-child) {
    border-bottom: 1px solid var(--secondaryOne);
  }
`

const PaginationWrapper = styled('div')`
  justify-self: center;
  margin-top: 10px;
`
