import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { BASE_URL } from '../config/backendConfig'
import { getUserIdToken } from '../service/auth/cognito-auth-service'
import { inMemoryCacheConfig } from './GraphqlCacheConfig'

const httpLink = createHttpLink({
  uri: BASE_URL + '/graphql',
})
const authLink = setContext(async (_, { headers }) => {
  let token = new URLSearchParams(document.location.search).get('token')
  const defaultHeaders = {
    ...headers,
    'x-timezone-offset': new Date().getTimezoneOffset() * 60 * -1,
  }
  try {
    // get the authentication token from local storage if it exists
    token = await getUserIdToken()
  } catch (error) {
    // do nothing and let authorization header be blank
  }
  return {
    headers: {
      ...defaultHeaders,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const graphqlClient = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(inMemoryCacheConfig),
})
