import { gql, useQuery } from '@apollo/client'
export const GET_ALL_LANGUAGES = gql`
  query GetAllLanguages {
    getAllLanguages
  }
`

export const useGetAllLanguages = () => {
  const query = useQuery<{ getAllLanguages: string[] }>(GET_ALL_LANGUAGES)
  const allLanguages = query.data?.getAllLanguages || []
  return { ...query, allLanguages }
}
