import {
  MutationInvitePersonArgs,
  PersonTypeEnum,
  TrainingProviderEnum,
} from '../../../graphql/graphqlTypes'
import { initialFormData } from './initialFormData'

export const prepareFormResetData = (formData: MutationInvitePersonArgs) => {
  switch (formData.personType) {
    case PersonTypeEnum.STUDENT:
      return {
        ...initialFormData,
        personType: PersonTypeEnum.STUDENT,
        bootcampId: formData.bootcampId,
      }
    case PersonTypeEnum.PARTNER_MEMBER:
      return {
        ...initialFormData,
        personType: PersonTypeEnum.PARTNER_MEMBER,
        partnerId: formData.partnerId,
        trainingProvider: TrainingProviderEnum.NEUE_FISCHE,
      }
    default:
      return {
        ...initialFormData,
        trainingProvider: TrainingProviderEnum.NEUE_FISCHE,
      }
  }
}
