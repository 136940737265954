import { Avatar, Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { ContactedStudent } from '../../../../graphql/graphqlTypes'
import { ProfileParticipation } from '../ProfileParticipation'

type Props = {
  student: ContactedStudent
}

const StudentCard = ({ student }: Props) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Avatar
          src={student.avatar?.urls.s ?? undefined}
          variant="rounded"
          sx={{ height: '60px', width: '60px' }}
        />
        <Box>
          <Typography variant="body1Strong">
            {student.firstName} {student.lastName}
          </Typography>
          <ProfileParticipation items={student.tracks} />
        </Box>
      </Box>
      <Button
        sx={theme => ({ [theme.breakpoints.down('lg')]: { mt: 4 } })}
        variant="outlined"
        onClick={() => navigate(`/profile/${student.id}`)}
      >
        <Typography noWrap variant="body2Strong">
          Go to profile
        </Typography>
      </Button>
    </Box>
  )
}

export default StudentCard
