import { gql, useQuery } from '@apollo/client'

import { Person } from '../graphqlTypes'

export const GET_PARTICIPATING_BOOTCAMPS = gql`
  query GetParticipatingBootcamps($id: String!) {
    getPerson(id: $id) {
      id
      participates {
        bootcamp {
          id
          track {
            id
            name
          }
          participants {
            person {
              id
              firstName
              lastName
              teaser
              avatar {
                urls {
                  s
                  m
                  l
                }
              }
            }
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const useGetParticipatingBootcamps = (id?: string) => {
  const query = useQuery<{ getPerson: Person }>(GET_PARTICIPATING_BOOTCAMPS, {
    variables: { id },
    skip: !id,
  })

  const bootcamps = query.data?.getPerson.participates.map(
    participation => participation.bootcamp
  )

  const completed = !query.error && !query.loading
  return {
    ...query,
    bootcamps,
    completed,
  }
}
