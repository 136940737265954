import {
  Box,
  Card,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import { LeftArrow, RightArrow } from '../../../assets/icons'
import { getNumberOfShownResults } from './pagination-service'

type Props = {
  totalPages: number
  totalElements: number
  maxResultsPerPage: number
  page: number
  setPage: (arg0: number) => void
}

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (v, k) => k + start)

const Pagination = ({
  totalPages,
  totalElements,
  page,
  setPage,
  maxResultsPerPage,
}: Props) => {
  const pages = range(0, totalPages)

  const numberOfResults = getNumberOfShownResults(
    totalElements,
    maxResultsPerPage,
    page
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value)
    setPage(number - 1)
  }

  return (
    <Box sx={{ display: 'grid', justifyItems: 'center' }}>
      <Card
        sx={{
          background: '#fff',
          p: 4,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          disabled={page === 0}
          onClick={() => {
            setPage(page - 1)
          }}
        >
          <LeftArrow />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant="body2">Page:</Typography>
          <TextField
            id="page-number"
            value={page + 1}
            onChange={handleChange}
            select
            sx={{
              '& .MuiInputBase-input': {
                padding: '16px 24px',
                textAlign: 'center',
              },
              width: '110px',
            }}
          >
            {pages.map(page => (
              <MenuItem key={page} value={page + 1}>
                {page + 1}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="body2">of {totalPages}</Typography>
        </Box>

        <IconButton
          disabled={page === totalPages - 1}
          onClick={() => {
            setPage(page + 1)
          }}
        >
          <RightArrow />
        </IconButton>
      </Card>
      <Typography variant="captionStrong" sx={{ fontWeight: 400 }}>
        Showing {numberOfResults} of {totalElements} results
      </Typography>
    </Box>
  )
}

export default Pagination
