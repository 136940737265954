import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { muiDefaultTheme } from './theme'
import { typography } from './typography'
const { up } = muiDefaultTheme.breakpoints

export const MuiSelect: Components['MuiSelect'] &
  Components['MuiOutlinedInput'] = {
  defaultProps: {
    variant: 'filled',
    color: 'primary',
    displayEmpty: true,
  },
  styleOverrides: {
    root: {
      ...typography.body2Strong,
      [up('md')]: {
        ...typography.body2Strong,
      },
      background: colors.almostGranite,
      color: colors.foam,
      border: '1px solid transparent',
      borderRadius: 8,
      '&::before, &::after': {
        display: 'none',
      },
      '&.Mui-disabled': {
        background: colors.almostGranite,
        color: colors.grey,
        textFillColor: colors.grey,
        borderColor: colors.darkGrey,
      },
      '&:not(.Mui-disabled).Mui-focused': {
        borderColor: colors.purple,
        background: colors.almostGranite,
        color: colors.foam,
      },
      '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
        background: colors.darkGrey,
        color: colors.foam,
      },
    },
    select: {
      '&:not([aria-labelledby])': {
        padding: '13px 32px 13px 16px',
      },
      '&.Mui-disabled, &': {
        color: 'inherit',
        textFillColor: 'inherit',
      },
    },
    icon: {
      '&.Mui-disabled, &': {
        color: 'inherit',
        textFillColor: 'inherit',
      },
    },
  },
}
