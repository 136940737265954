import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

const SET_PERSON_NAME = gql`
  mutation SetPersonName(
    $id: String!
    $firstName: String!
    $lastName: String!
  ) {
    setPersonName(id: $id, firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`

export const useSetPersonName = (options?: BaseMutationOptions) => {
  const [addBasicInfo, result] = useMutation(SET_PERSON_NAME, options)

  const setPersonName = (id: string, firstName: string, lastName: string) =>
    addBasicInfo({
      variables: {
        id,
        firstName,
        lastName,
      },
    })

  return { ...result, setPersonName }
}
