import { Person } from '../../../graphql/graphqlTypes'
import { getPersonName } from '../../../service/profile/person-service'
import { Link } from 'react-router-dom'

type Props = {
  members: Person[]
  partnerId: string
}

export default function PartnerMembers({ members, partnerId }: Props) {
  if (!members.length) {
    return (
      <p>
        No members yet.{' '}
        <Link to={`/admin/users?partner-id=${partnerId}`}>
          Maybe invite someone?
        </Link>
      </p>
    )
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {members.map(member => (
          <tr key={member.id}>
            <td>{getPersonName(member)}</td>
            <td>{member.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
