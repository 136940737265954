import { Button } from '@mui/material'
import { useCallback } from 'react'

import { JobExperience } from '../../../../../graphql/graphqlTypes'
import { Plus } from '../../../../assets/icons'
import { useModalStore } from '../../../../stores/modalStore'
import { FormProfileCareer } from '../Forms/FormProfileCareer'

export interface ProfileCareerAddItemProps extends Pick<JobExperience, 'type'> {
  buttonText: string
  personId: string
}

export const ProfileCareerAddItem = ({
  type,
  buttonText,
  personId,
}: ProfileCareerAddItemProps) => {
  const setModal = useModalStore(state => state.setModal)
  const setModalContent = useModalStore(state => state.setModalContent)

  const onClick = useCallback(() => {
    setModalContent(<FormProfileCareer type={type} personId={personId} />)
    setModal(true)
  }, [personId, setModal, setModalContent, type])

  return (
    <Button startIcon={<Plus />} onClick={onClick} fullWidth>
      {buttonText}
    </Button>
  )
}
