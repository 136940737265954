import { Dialog, styled } from '@mui/material'
import { ReactNode, useCallback, useState } from 'react'

import { ProfileForm } from '../../organisims/Profile/Forms/ProfileForm'

const UnstyledButton = styled('button')({
  all: 'unset',
  cursor: 'pointer',
})

export interface ConfirmModalProps {
  cancelButtonText?: string
  onCancel?: () => void
  confirmButtonText?: string
  onConfirm: () => Promise<unknown>
  children: ReactNode
  title: string
  text: ReactNode
  loading?: boolean
}

export const ConfirmModal = ({
  cancelButtonText = 'Nevermind',
  onCancel,
  confirmButtonText = 'Confirm',
  onConfirm,
  children,
  title,
  text,
  loading = false,
}: ConfirmModalProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleConfirm = useCallback(() => {
    onConfirm().then(() => {
      setModalOpen(false)
    })
  }, [onConfirm])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  return (
    <>
      <UnstyledButton onClick={() => setModalOpen(true)} type="button">
        {children}
      </UnstyledButton>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <ProfileForm
          title={title}
          loading={loading}
          onSubmit={handleConfirm}
          submitText={confirmButtonText}
          onCancel={handleCancel}
          cancelText={cancelButtonText}
        >
          <div>{text}</div>
        </ProfileForm>
      </Dialog>
    </>
  )
}
