import { Avatar, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC } from 'react'
import { FieldValues, useController, useForm } from 'react-hook-form'

import { Person } from '../../../../graphql/graphqlTypes'
import {
  getPersonName,
  getPersonNameMultiline,
} from '../../../../service/profile/person-service'
import { colors } from '../../../theme/constants/colors'
import { ReactComponent as IconFish } from '../../../theme/icons/iconFish.svg'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

export const FormContact: FC<{
  person: Person
  submit: (data: string) => void
  setMessage: (message: string) => void
  handleCancel: () => void
}> = ({ person, submit, setMessage, handleCancel }) => {
  const { control, handleSubmit } = useForm()

  const {
    field: fieldMessageContent,
    fieldState: fieldMessageContentState,
  } = useController({
    name: 'messageContent',
    control,
    rules: {
      required: true,
    },
    defaultValue: '',
  })

  const submitForm = (data: FieldValues) => {
    const message = data.messageContent
    submit(message)
    setMessage(message)
  }

  return (
    <ProfileForm
      title="Contact"
      onSubmit={handleSubmit(submitForm)}
      submitText="Send mail"
      onCancel={handleCancel}
    >
      <Box component="form">
        <Stack spacing={10}>
          <Box
            sx={{
              p: 6,
              border: '1px solid',
              borderColor: colors.water,
              borderRadius: 2,
              display: 'flex',
              gap: 4,
            }}
          >
            <Avatar
              src={person?.avatar?.urls.s}
              alt={getPersonName(person)}
              variant="rounded"
            />
            <Box>
              <Typography variant="h5">
                {getPersonNameMultiline(person)}
              </Typography>
              {person.participates?.map(participation => (
                <Stack
                  key={participation.bootcamp.id}
                  component="span"
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <IconFish />
                  <span>{participation.bootcamp.track.name}</span>
                </Stack>
              ))}
            </Box>
          </Box>

          <TextField
            multiline
            minRows={10}
            label="Your message"
            {...fieldMessageContent}
            error={!!fieldMessageContentState.error}
          />
        </Stack>
      </Box>
    </ProfileForm>
  )
}
