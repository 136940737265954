import { Card, Components } from '@mui/material'

export const MuiDialog: Components['MuiDialog'] = {
  defaultProps: {
    onClose: () => null,
    maxWidth: 'md',
    PaperComponent: props => (
      <Card
        {...props}
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('md')]: {
            height: '100%',
            maxHeight: '100%',
            margin: 0,
            borderRadius: 0,
          },
        })}
      />
    ),
  },
}
