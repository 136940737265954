import { sortBy } from 'lodash'

import { LanguageCode } from '../graphql/graphqlTypes'

export const DefaultLanguage: LanguageCode = LanguageCode.DE

const LanguageNames = {
  DE: 'German',
  EN: 'English',
}

export const getLanguageName = (code: LanguageCode) => LanguageNames[code]

export const languageOptions = sortBy(
  [LanguageCode.DE, LanguageCode.EN].map(code => ({
    id: code,
    name: getLanguageName(code),
  })),
  'name'
)
