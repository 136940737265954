import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { requestNewPassword } from '../../../../service/auth/cognito-auth-service'

type FormValues = {
  email: string
}

export const ResetPasswordForm = ({
  onCancel,
  onRequestSuccess,
}: {
  onCancel: () => void
  onRequestSuccess: (email: string) => void
}) => {
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { email: '' },
  })

  const { field: fieldEmail, fieldState: fieldEmailState } = useController({
    name: 'email',
    control,
    rules: {
      required: 'Please enter your registered email.',
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      setLoading(true)
      requestNewPassword(data.email)
        .then(() => {
          onRequestSuccess(data.email)
        })
        .catch(() => {
          setLoading(false)
          toast.error('Unknown or unverified E-Mail.')
        })
    },
    [onRequestSuccess]
  )

  return (
    <Stack
      spacing={8}
      alignItems={{ xs: 'center', lg: 'start' }}
      width="100%"
      paddingY={4}
    >
      <Typography variant="h5">Reset password</Typography>
      <Typography variant="body2">
        Enter your E-Mail and we’ll send you instructions to reset your
        password.
      </Typography>

      <Stack
        spacing={10}
        component="form"
        onSubmit={handleSubmit(submit)}
        width="100%"
      >
        <TextField
          label="E-Mail"
          {...fieldEmail}
          error={!!fieldEmailState.error}
          helperText={fieldEmailState.error?.message}
          sx={{ width: '100%' }}
        />

        <Stack spacing={4} direction={{ xs: 'column', lg: 'row' }}>
          <LoadingButton
            type="submit"
            loading={loading}
            sx={theme => ({
              [theme.breakpoints.up('lg')]: {
                flexGrow: 1,
              },
            })}
          >
            Request reset code
          </LoadingButton>

          <Button variant="text" disabled={loading} onClick={onCancel}>
            Back to login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
