import { css, styled } from '@mui/material'
import { FC, SelectHTMLAttributes } from 'react'

type SelectOption = {
  id: string
  name?: string
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: SelectOption[]
  labelText: string
  srOnly?: boolean
}

const Select: FC<SelectProps> = ({ options, labelText, srOnly, ...props }) => {
  return (
    <Label srOnly={srOnly}>
      <span>{labelText}</span>
      <CustomSelectBoxStyle>
        <select {...props}>
          {options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name ?? option.id}
            </option>
          ))}
        </select>
      </CustomSelectBoxStyle>
    </Label>
  )
}

export default Select

// Custom Select Box Styles: https://moderncss.dev/custom-select-styles-with-pure-css/
const CustomSelectBoxStyle = styled('div')`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid var(--secondaryTwo);
  border-radius: var(--spacing-xs);
  padding-right: var(--spacing-m);

  &::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: var(--primary);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    padding: var(--spacing-m);
  }

  &:focus-within {
    border: 1px solid var(--primary);
  }
`

const Label = styled('label')<{ srOnly?: boolean }>`
  display: flex;
  gap: var(--spacing-s);
  align-items: baseline;

  ${props =>
    props.srOnly &&
    css`
      span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    `}
`
