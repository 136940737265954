import { gql, useQuery } from '@apollo/client'

import { Bootcamp, QueryListBootcampsArgs } from '../graphqlTypes'

export const LIST_BOOTCAMPS = gql`
  query ListBootcamps(
    $endsAfter: String
    $endsBefore: String
    $startsAfter: String
    $startsBefore: String
  ) {
    listBootcamps(
      endsAfter: $endsAfter
      endsBefore: $endsBefore
      startsAfter: $startsAfter
      startsBefore: $startsBefore
    ) {
      endDate
      id
      location {
        id
        name
      }
      startDate
      track {
        id
        name
      }
      language
    }
  }
`

export const useListBootcamps = (variables?: QueryListBootcampsArgs) => {
  const query = useQuery<{ listBootcamps: Bootcamp[] }>(LIST_BOOTCAMPS, {
    variables,
  })
  const bootcamps = query.data?.listBootcamps || []
  return { ...query, bootcamps }
}
