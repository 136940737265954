import { max } from 'lodash'

const createImage = async (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const getSquarteImage = async (imageSrc: string): Promise<string> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxDimension = max([image.width, image.height]) as number

  canvas.width = maxDimension
  canvas.height = maxDimension

  if (!ctx) {
    return imageSrc
  }

  ctx.beginPath()
  ctx.rect(0, 0, maxDimension, maxDimension)
  ctx.fillStyle = '#fff'
  ctx.fill()

  ctx.drawImage(
    image,
    canvas.width / 2 - image.width / 2,
    canvas.height / 2 - image.height / 2
  )

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(URL.createObjectURL(blob as File))
    }, 'image/jpeg')
  })
}
