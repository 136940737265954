import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useListRandomPartners } from '../../../../graphql/partner/useListRandomPartners'
import { Carousel } from '../../molecules/Carousel'
import { CompanyTeaser } from '../CompanyTeaser'

export const CompanyTeasers: FC = () => {
  const theme = useTheme()

  const { partners } = useListRandomPartners(6)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const responsive = {
    xl: {
      breakpoint: {
        min: theme.breakpoints.values.xl,
        max: 5200,
      },
      items: 3,
    },
    lg: {
      breakpoint: {
        min: theme.breakpoints.values.md,
        max: theme.breakpoints.values.xl,
      },
      items: 2,
    },
    sm: {
      breakpoint: {
        min: theme.breakpoints.values.sm,
        max: theme.breakpoints.values.md,
      },
      items: 1,
    },
  }

  return (
    <Card>
      <CardHeader
        title={
          <Stack spacing={4}>
            <Typography variant="h5">Companies on the Talent App</Typography>
            <Typography>
              These are just some of the corporations that you can find on here.
            </Typography>
          </Stack>
        }
        aria-label="Display all companies"
        action={
          !isMobile && (
            <Box>
              <Button
                component={Link}
                size="medium"
                variant="contained"
                to="/companies"
              >
                All Companies
              </Button>
            </Box>
          )
        }
      />
      <CardContent>
        <Stack>
          {!!partners?.length && (
            // to prevent stack to mess with carousel margins
            <div>
              <Carousel responsive={responsive} gap={1.5} autoPlaySpeed={8000}>
                {partners.map(partner => (
                  <CompanyTeaser key={partner.id} {...partner} />
                ))}
              </Carousel>
            </div>
          )}
        </Stack>
        {isMobile && (
          <Button component={Link} to="/companies">
            All Companies
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
