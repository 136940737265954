import { TrainingProviderEnum } from '../graphql/graphqlTypes'

export const getTrainingProviderName = (
  trainingProvider: TrainingProviderEnum
) => {
  switch (trainingProvider) {
    case TrainingProviderEnum.NEUE_FISCHE:
      return 'Neue Fische'
    case TrainingProviderEnum.SPICED_ACADEMY:
      return 'Spiced Academy'
    default:
      throw new Error('Unknown training provider: ' + trainingProvider)
  }
}

export const getTrainingProviderSelectOption = (
  trainingProvider: TrainingProviderEnum
) => {
  return {
    name: getTrainingProviderName(trainingProvider),
    id: trainingProvider,
  }
}
