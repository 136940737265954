import { styled } from '@mui/material'
import React from 'react'

type ImportantNotesProps = {
  notes: string[]
}

const ImportantNotes = ({ notes, ...props }: ImportantNotesProps) => {
  return (
    <WarningText {...props}>
      <p> ⚠️ Important Notes ⚠️ </p>
      <ul>
        {notes.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </WarningText>
  )
}

export default ImportantNotes

const WarningText = styled('div')`
  margin: 16px 0;
  border: red 1px solid;
  padding: 12px;

  p {
    margin-top: 0;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      margin: 4px 0;

      &:before {
        content: '•';
        margin-right: 6px;
      }
    }
  }
`
