import 'react-multi-carousel/lib/styles.css'

import { styled, Typography } from '@mui/material'
import { Children, FC, useCallback, useState } from 'react'
import ReactCarousel, {
  ButtonGroupProps,
  CarouselInternalState,
  CarouselProps,
} from 'react-multi-carousel'

import { LeftArrow, RightArrow } from '../../../assets/icons'

const StyledCarousel = styled(ReactCarousel)``

const Nav = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 48,
  marginTop: 16,
  marginBottom: 8,

  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(8),
  },
}))

const DotWrapper = styled('div')({
  alignItems: 'center',
  display: 'grid',
  gap: '.25em',
})

const Dot = styled('div')(({ theme }) => ({
  width: '1.25em',
  height: '.25em',
  borderRadius: '1em',
  backgroundColor: 'currentcolor',
  color: theme.palette.colors.water10,
  transition: theme.transitions.create('color'),
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
}))

const SliderButton = styled('button')`
  all: unset;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
  }
`

const Pagination: FC<{ current: number; total: number }> = ({
  current,
  total,
}) => (
  <Typography variant="button">
    {current}/{total}
  </Typography>
)

const Buttons: FC<
  ButtonGroupProps & {
    current: number
    total: number
    showPagination: boolean
  }
> = ({ next, previous, carouselState, current, total, showPagination }) => {
  const prevDisabled = !carouselState || carouselState.currentSlide < 1
  const nextDisabled =
    !carouselState ||
    carouselState.currentSlide >=
      carouselState.totalItems - carouselState.slidesToShow

  const getIsActive = useCallback(
    (index: number): boolean => {
      if (!carouselState) {
        return false
      }

      if (
        carouselState &&
        index >= current &&
        index < current + carouselState.slidesToShow
      ) {
        return true
      }

      return false
    },
    [carouselState, current]
  )

  // No point showing the navigation if there's nothing to navigate through.
  if (prevDisabled && nextDisabled) {
    return null
  }

  return (
    <Nav>
      {showPagination && <Pagination current={current} total={total} />}

      <ButtonWrapper>
        <SliderButton onClick={previous} disabled={prevDisabled}>
          <LeftArrow />
        </SliderButton>
        <DotWrapper sx={{ gridTemplateColumns: `repeat(${total}, 1fr)` }}>
          {[...Array(total)].map((a, key) => (
            <Dot
              key={`dot--${key}`}
              sx={
                getIsActive(key + 1)
                  ? {
                      color: 'colors.nemo',
                    }
                  : {}
              }
            />
          ))}
        </DotWrapper>
        <SliderButton onClick={next} disabled={nextDisabled}>
          <RightArrow />
        </SliderButton>
      </ButtonWrapper>
    </Nav>
  )
}

export const Carousel = ({
  responsive,
  gap = 0,
  infinite,
  partialVisible,
  centerMode,
  arrows = true,
  autoPlay = false,
  autoPlaySpeed = 3000,
  children,
  showPagination = false,
  overflow = 'hidden',
}: Pick<
  CarouselProps,
  | 'arrows'
  | 'autoPlay'
  | 'autoPlaySpeed'
  | 'centerMode'
  | 'children'
  | 'infinite'
  | 'partialVisible'
  | 'responsive'
> & {
  gap?: number
  showPagination?: boolean
  overflow?: 'hidden' | 'visible'
  fullWidth?: boolean
}) => {
  const [slide, setSlide] = useState(1)

  const total = Children.count(children)

  const beforeChange = useCallback(
    (next: number, state: CarouselInternalState) => {
      const calcedNext =
        ((next - (infinite ? state.slidesToShow * 2 : 0)) % total) + 1

      return setSlide(calcedNext < 1 ? calcedNext + total : calcedNext)
    },
    [total, infinite]
  )

  return (
    <StyledCarousel
      arrows={arrows}
      responsive={responsive}
      infinite={infinite}
      centerMode={centerMode}
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      partialVisible={!centerMode && partialVisible}
      renderButtonGroupOutside
      beforeChange={beforeChange}
      customButtonGroup={
        <Buttons
          current={slide}
          total={total}
          showPagination={showPagination}
        />
      }
      removeArrowOnDeviceType={Object.keys(responsive)}
      ssr
      deviceType="lg"
      pauseOnHover
      shouldResetAutoplay={false}
      sx={{
        overflow,
        marginRight: `-${gap}rem !important`,

        '.react-multi-carousel-item': {
          paddingRight: `${gap}rem`,
        },
      }}
    >
      {children}
    </StyledCarousel>
  )
}
