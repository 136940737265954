import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { MutationSetCapstoneProjectArgs } from '../graphqlTypes'

const SET_CAPSTONE_PROJECT = gql`
  mutation SetCapstoneProject(
    $personId: String!
    $bootcampId: String!
    $name: String
    $description: String
    $appUrl: String
    $repositoryUrl: String
    $youtubeUrl: String
    $techStack: [String!]
    $imageId: String
    $device: Device
  ) {
    setCapstoneProject(
      personId: $personId
      bootcampId: $bootcampId
      name: $name
      description: $description
      appUrl: $appUrl
      techStack: $techStack
      repositoryUrl: $repositoryUrl
      youtubeUrl: $youtubeUrl
      imageId: $imageId
      device: $device
    ) {
      id
      person {
        id
      }
      bootcamp {
        id
      }
      name
      description
      appUrl
      repositoryUrl
      youtubeUrl
      techStack
      image {
        id
        urls {
          s
          m
          l
          xl
        }
      }
      device
    }
  }
`

export const useSetCapstoneProject = (options?: BaseMutationOptions) => {
  const [setData, result] = useMutation(SET_CAPSTONE_PROJECT, options)

  const setCapstoneProject = (variables: MutationSetCapstoneProjectArgs) =>
    setData({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, setCapstoneProject }
}
