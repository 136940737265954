import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { TagDraggable, TagDraggableProps } from './TagDraggable'
import { TagsContainer, TagsProps } from './Tags'

export interface TagsSortableProps extends TagsProps {
  onMove: TagDraggableProps['onMove']
}

export const TagsSortable = ({
  tags,
  color,
  onDelete,
  onMove,
}: TagsSortableProps) => {
  if (tags.length === 0) return null

  return (
    <TagsContainer>
      <DndProvider backend={HTML5Backend}>
        {tags.map((tag, index) => (
          <TagDraggable
            key={tag}
            id={tag}
            index={index}
            label={tag}
            color={color}
            onMove={onMove}
            onDelete={onDelete ? () => onDelete(tag) : undefined}
          />
        ))}
      </DndProvider>
    </TagsContainer>
  )
}
