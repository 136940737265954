import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { GET_PARTNER_DETAILS } from './usePartnerDetails'

const DELETE_PARTNER_PROFILE_IMAGE = gql`
  mutation DeletePartnerProfileImage($partnerId: String!) {
    deletePartnerProfileImage(partnerId: $partnerId)
  }
`

export const useDeletePartnerLogo = (options: BaseMutationOptions) => {
  const [deletePartnerLogoMutation, status] = useMutation(
    DELETE_PARTNER_PROFILE_IMAGE,
    options
  )

  const deletePartnerLogo = async (partnerId: string) =>
    deletePartnerLogoMutation({
      variables: { partnerId },
      refetchQueries: [GET_PARTNER_DETAILS],
    })

  return { deletePartnerLogo, ...status }
}

const DELETE_PARTNER_PROFILE_BANNER = gql`
  mutation DeletePartnerProfileBanner($partnerId: String!) {
    deletePartnerProfileBanner(partnerId: $partnerId)
  }
`

export const useDeletePartnerBanner = (options: BaseMutationOptions) => {
  const [deletePartnerBannerMutation, status] = useMutation(
    DELETE_PARTNER_PROFILE_BANNER,
    options
  )

  const deletePartnerBanner = async (partnerId: string) =>
    deletePartnerBannerMutation({
      variables: { partnerId },
      refetchQueries: [GET_PARTNER_DETAILS],
    })

  return { deletePartnerBanner, ...status }
}
