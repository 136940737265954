import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Geolocation,
  JobPreferences,
} from '../../../../../graphql/graphqlTypes'
import { useGetGeolocationSuggestions } from '../../../../../graphql/person/useGetGeolocationSuggestions'
import { useSetJobPreferences } from '../../../../../graphql/person/useSetJobPreferences'
import { useModalStore } from '../../../../stores/modalStore'
import { InfoBox } from '../../../atoms/InfoBox'
import { Tags } from '../../../molecules/Tags'
import { ProfileForm } from './ProfileForm'
type FormValues = Pick<
  JobPreferences,
  | 'available'
  | 'fullTime'
  | 'partTime'
  | 'freelance'
  | 'internship'
  | 'flexible'
  | 'remote'
> & {
  city?: string
}

export interface FormProfileJobPreferences {
  jobPreferences: JobPreferences
  id: string
}

export const FormProfileJobPreferences = ({
  jobPreferences,
  id,
}: FormProfileJobPreferences) => {
  const setModal = useModalStore(state => state.setModal)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState,
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const { field: fieldAvailable } = useController({
    name: 'available',
    control,
    defaultValue: jobPreferences.available,
  })

  const { field: fieldFullTime } = useController({
    name: 'fullTime',
    control,
    defaultValue: jobPreferences.fullTime,
  })

  const { field: fieldPartTime } = useController({
    name: 'partTime',
    control,
    defaultValue: jobPreferences.partTime,
  })

  const { field: fieldFreelance } = useController({
    name: 'freelance',
    control,
    defaultValue: jobPreferences.freelance,
  })

  const { field: fieldInternship } = useController({
    name: 'internship',
    control,
    defaultValue: jobPreferences.internship,
  })

  const workTimes = useMemo(
    () => [
      {
        label: 'Full-time',
        field: fieldFullTime,
      },
      {
        label: 'Part-time',
        field: fieldPartTime,
      },
      {
        label: 'Freelance',
        field: fieldFreelance,
      },
      {
        label: 'Internship',
        field: fieldInternship,
      },
    ],
    [fieldFreelance, fieldFullTime, fieldInternship, fieldPartTime]
  )

  const { field: fieldFlexible } = useController({
    name: 'flexible',
    control,
    defaultValue: jobPreferences.flexible,
  })

  const { field: fieldRemote } = useController({
    name: 'remote',
    control,
    defaultValue: jobPreferences.remote,
  })

  const locationOptions = useMemo(
    () => [
      {
        label: 'Flexible',
        field: fieldFlexible,
      },
      {
        label: 'Remote',
        field: fieldRemote,
      },
    ],
    [fieldFlexible, fieldRemote]
  )

  const [geoLocations, setGeoLocations] = useState<Geolocation[]>(
    jobPreferences.geolocationPreferences.map(dto => ({
      text: dto.name,
      placeId: dto.placeId,
    }))
  )

  const locationIncluded = useCallback(
    (name: string) => geoLocations.some(loc => loc.text === name),
    [geoLocations]
  )

  const { field: fieldCity } = useController({
    name: 'city',
    control,
    rules: {
      validate: value => {
        if (!value) {
          return true
        }
        if (locationIncluded(value.trim())) {
          return 'City already set'
        }
        return true
      },
    },
    defaultValue: '',
  })

  const { suggestions } = useGetGeolocationSuggestions(fieldCity.value ?? '')

  const addLocation = useCallback(
    (city: string) => {
      const trimmedCity = city.trim()
      if (trimmedCity) {
        if (!locationIncluded(trimmedCity)) {
          setGeoLocations(geoLocations => {
            const location = suggestions.find(loc => loc.text === trimmedCity)
            if (!location) {
              return geoLocations
            }
            return [...geoLocations, location]
          })
        }
      }
      setValue('city', '')
      setSelectedLocation(null)
    },
    [locationIncluded, setValue, suggestions]
  )

  const removeLocation = useCallback((key: string) => {
    setGeoLocations(geoLocations => geoLocations.filter(l => l.text !== key))
  }, [])

  const { setJobPreferences, loading } = useSetJobPreferences({
    onCompleted: () => {
      toast.success('Job preferences updated')
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)

  const locationNames = suggestions
    .map(suggestion => suggestion.text)
    .filter(l => !locationIncluded(l))

  const submit = useCallback(
    (data: FormValues) => {
      delete data.city
      const placeIds = geoLocations.map(loc => loc.placeId)
      setJobPreferences({
        personId: id,
        placeIds,
        ...data,
      })
    },
    [id, setJobPreferences, geoLocations]
  )

  return (
    <ProfileForm
      title="Job Preferences"
      onCancel={() => {
        reset()
        setModal(false)
      }}
      onSubmit={handleSubmit(submit)}
      submitText="Save"
      loading={loading}
      formState={formState}
    >
      <Stack spacing={8}>
        <InfoBox>
          You are now part of the neue fische talent pool. Let our partners know
          in what kind of employment you are interested in.
          <br />
          <br />
          This increases the chances for you to find a job already during your
          bootcamp.
        </InfoBox>

        <Box component="form" onSubmit={(e: FormEvent) => e.preventDefault()}>
          <FormControl>
            <FormLabel component="legend">Are you available?</FormLabel>
            <FormControlLabel
              control={
                <Switch {...fieldAvailable} checked={fieldAvailable.value} />
              }
              label={
                fieldAvailable.value
                  ? "Yes, I'm available."
                  : "No, I'm already working."
              }
              sx={{ marginLeft: 0 }}
            />
          </FormControl>

          <Collapse in={fieldAvailable.value}>
            <Stack spacing={10} marginTop={10}>
              <FormControl>
                <FormLabel component="legend">
                  How do you want to work?
                </FormLabel>
                <FormGroup
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 6,
                  }}
                >
                  {workTimes.map(time => (
                    <FormControlLabel
                      control={
                        <Checkbox {...time.field} checked={time.field.value} />
                      }
                      label={time.label}
                      key={time.field.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormControl sx={{ marginTop: 10, gap: 6 }}>
                <FormLabel component="legend" sx={{ marginBottom: 0 }}>
                  Do you have a location in mind?
                </FormLabel>
                <FormGroup
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 6,
                  }}
                >
                  {locationOptions.map(locationOption => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...locationOption.field}
                          checked={locationOption.field.value}
                        />
                      }
                      label={locationOption.label}
                      key={locationOption.field.name}
                    />
                  ))}
                </FormGroup>

                <Autocomplete
                  disablePortal
                  popupIcon=""
                  options={locationNames}
                  value={selectedLocation}
                  inputValue={fieldCity.value}
                  onInputChange={(event, newValue) => {
                    if (fieldCity.value === newValue) {
                      return
                    }
                    fieldCity.onChange(newValue ?? '')
                  }}
                  onChange={(event, newValue) => {
                    newValue && addLocation(newValue)
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Add Location" />
                  )}
                />
                <Tags
                  tags={geoLocations.map(loc => loc.text)}
                  variant="outlined"
                  onDelete={removeLocation}
                />
              </FormControl>
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    </ProfileForm>
  )
}
