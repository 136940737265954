import { alpha, Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiBackdrop: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      backgroundColor: alpha(colors.granite, 0.4),
    },
  },
}
