import { Box, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'

import { JobExperience } from '../../../../../graphql/graphqlTypes'
import { Shape04 } from '../../../../assets/svgs'
import { EmptySection } from '../../../molecules/EmptySection'
import { ProfileCareerItem } from './ProfileCareerItem'

export interface ProfileCareerProps {
  jobExperiences: JobExperience[]
  personId?: string
  isOwnProfile?: boolean
}

export const ProfileCareer = ({
  jobExperiences,
  personId,
  isOwnProfile = false,
}: ProfileCareerProps) => {
  const employments = useMemo(
    () => jobExperiences.filter(i => i.type === 'employment'),
    [jobExperiences]
  )

  const educations = useMemo(
    () => jobExperiences.filter(i => i.type === 'education'),
    [jobExperiences]
  )

  if (!personId) {
    return null
  }

  return (
    <Stack spacing={6}>
      <Stack spacing={4}>
        <Typography color="colors.grey">Professional</Typography>

        {employments.length ? (
          employments.map(job => (
            <Box
              sx={{
                display: 'grid',
                gap: 5,
                gridTemplateColumns: 'auto 1fr',
              }}
              key={job.id}
            >
              <Box component={Shape04} fontSize={10} marginTop={1} />
              <ProfileCareerItem {...job} isOwnProfile={isOwnProfile} />
            </Box>
          ))
        ) : (
          <EmptySection icon={false} text="No employment entry yet" />
        )}
      </Stack>

      <Stack spacing={4}>
        <Typography color="colors.grey">Education</Typography>

        {educations.length ? (
          educations.map(job => (
            <Box
              sx={{
                display: 'grid',
                gap: 5,
                gridTemplateColumns: 'auto 1fr',
              }}
              key={job.id}
            >
              <Box component={Shape04} fontSize={10} marginTop={1} />
              <ProfileCareerItem {...job} isOwnProfile={isOwnProfile} />
            </Box>
          ))
        ) : (
          <EmptySection icon={false} text="No education entry yet" />
        )}
      </Stack>
    </Stack>
  )
}
