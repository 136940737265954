import LoadingButton from '@mui/lab/LoadingButton'
import {
  FormHelperText,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactCodeInput from 'react-verification-code-input'

import { refetchUser } from '../../../../graphql/person/useUser'
import { setNewPassword } from '../../../../service/auth/cognito-auth-service'
import { validatePassword } from '../../../../service/auth/validate-password-service'
import { useAuth } from '../../../contexts/AuthContext'
import { colors } from '../../../theme/constants/colors'
import { BrandLogo } from '../../atoms/BrandLogo'

const CodeInput = styled(ReactCodeInput)(({ theme }) => ({
  width: 'auto !important',

  '> div': {
    display: 'grid',
    width: 'min-content',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: 16,

    '> input': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '36px !important',
      minWidth: 0,
      height: '48px !important',
      padding: '.5em',
      font: 'inherit',
      fontWeight: 600,
      borderRadius: '8px !important',
      border: `1px solid ${theme.palette.colors.lightGrey} !important`,
    },
  },
}))

const Span = styled('span')``

type FormValues = {
  code: string
  password: string
  passwordConfirmation: string
}

export const SetPasswordForm = ({
  codeIsRequired = false,
  email,
}: {
  codeIsRequired?: boolean
  email?: string
}) => {
  const { loginUser, completeSetPassword } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: { code: '', password: '', passwordConfirmation: '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { fieldState: fieldCodeState } = useController({
    name: 'code',
    control,
    rules: {
      required: codeIsRequired
        ? 'Please enter the code we send you per email'
        : undefined,
      pattern: /([0-9]){6}/,
    },
  })

  const {
    field: fieldPassword,
    fieldState: fieldPasswordState,
  } = useController({
    name: 'password',
    control,
    rules: {
      required: 'Please input your password',
      validate: value => {
        const res = validatePassword(value)

        if (res.success) return true

        if (!res.includesLowerCase) {
          return 'Your password doesn’t include a lowercase character.'
        }
        if (!res.includesNumber) {
          return 'Your password doesn’t include a number.'
        }
        if (!res.includesSpecialCharacter) {
          return 'Your password doesn’t include a special character.'
        }
        if (!res.includesUpperCase) {
          return 'Your password doesn’t include a uppercase character.'
        }
        if (!res.isLongEnough) {
          return 'Your password needs to be at least 8 characters.'
        }

        return 'Something else is wrong but I don’t know what 🤷🏻‍♂️'
      },
    },
  })

  const {
    field: fieldPasswordConfirmation,
    fieldState: fieldPasswordConfirmationState,
  } = useController({
    name: 'passwordConfirmation',
    control,
    rules: {
      required: 'Please confirm your password',
      validate: value =>
        fieldPassword.value === value ||
        'The password confirmation does not match',
    },
  })

  const passwordValidation = useMemo(
    () => validatePassword(fieldPassword.value),
    [fieldPassword.value]
  )

  const submit = useCallback(
    (data: FormValues) => {
      setLoading(true)
      if (codeIsRequired && email) {
        setNewPassword(email, data.password, data.code)
          .then(() => {
            loginUser(email, data.password).then(() => navigate('/'))
          })
          .catch(() => {
            toast.error('Error setting new password')
            setLoading(false)
          })
      } else {
        completeSetPassword(data.password)
          .then(() => refetchUser())
          .then(() => navigate('/'))
          .catch(() => {
            toast.error('Error setting new password')
            setLoading(false)
          })
      }
    },
    [codeIsRequired, completeSetPassword, email, loginUser, navigate]
  )

  return (
    <Stack spacing={8} alignItems={{ xs: 'center', lg: 'start' }} width="100%">
      <BrandLogo type="icon" fontSize={40} color={colors.nemo} />
      <Typography variant="h5">Set new password</Typography>

      {codeIsRequired && (
        <Stack spacing={4}>
          <Typography variant="body2">
            Enter the code we sent to you.
          </Typography>
          <div>
            <CodeInput
              fields={6}
              type="number"
              onChange={value => setValue('code', value)}
              sx={
                fieldCodeState.error
                  ? theme => ({
                      '> div > input': {
                        borderColor: `${theme.palette.colors.error} !important`,
                      },
                    })
                  : {}
              }
            />
            {!!fieldCodeState.error && (
              <FormHelperText error>
                {fieldCodeState.error.message}
              </FormHelperText>
            )}
          </div>
        </Stack>
      )}

      <Stack
        spacing={10}
        component="form"
        onSubmit={handleSubmit(submit)}
        width="100%"
      >
        <Stack spacing={4}>
          <TextField
            label="New password"
            type="password"
            {...fieldPassword}
            error={!!fieldPasswordState.error}
            helperText={fieldPasswordState.error?.message}
            sx={{ width: '100%' }}
          />
          <TextField
            label="Confirm new password"
            type="password"
            {...fieldPasswordConfirmation}
            error={!!fieldPasswordConfirmationState.error}
            helperText={fieldPasswordConfirmationState.error?.message}
            sx={{ width: '100%' }}
          />

          <Typography component="span">
            <Span>Requirements: </Span>
            <Span
              sx={{
                color: passwordValidation.isLongEnough
                  ? 'colors.success'
                  : 'inherit',
              }}
            >
              8 characters
            </Span>
            {', '}
            <Span
              sx={{
                color: passwordValidation.includesUpperCase
                  ? 'colors.success'
                  : 'inherit',
              }}
            >
              1 uppercase letter
            </Span>
            {', '}
            <Span
              sx={{
                color: passwordValidation.includesLowerCase
                  ? 'colors.success'
                  : 'inherit',
              }}
            >
              1 lowercase letter
            </Span>
            {', '}
            <Span
              sx={{
                color: passwordValidation.includesNumber
                  ? 'colors.success'
                  : 'inherit',
              }}
            >
              1 number
            </Span>
            {', '}
            <Span
              sx={{
                color: passwordValidation.includesSpecialCharacter
                  ? 'colors.success'
                  : 'inherit',
              }}
            >
              1 special character
            </Span>
          </Typography>
        </Stack>

        <LoadingButton
          fullWidth
          type="submit"
          disabled={loading || !!fieldPasswordState.error}
        >
          Set password and login
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
