import { LoadingButton } from '@mui/lab'
import {
  Box,
  BoxProps,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material'
import { ReactNode, useCallback } from 'react'
import { FormState } from 'react-hook-form'

import { DeleteDisabled } from '../../../../../assets/icons'

const CloseIcon = styled(DeleteDisabled)`
  font-size: 32px;
  cursor: pointer;
`

export interface ProfileFormProps {
  title: string
  children: ReactNode
  cancelText?: string
  onCancel?: () => void
  submitText?: string
  onSubmit?: () => void
  hideCardActions?: boolean
  loading?: boolean
  formState?: FormState<object>
  contentSx?: BoxProps['sx']
}

export const ProfileForm = ({
  title,
  children,
  cancelText = 'Cancel',
  onCancel,
  submitText = 'Save',
  onSubmit,
  hideCardActions = false,
  loading = false,
  formState,
  contentSx = {},
}: ProfileFormProps) => {
  const hasUnsavedChanges = formState
    ? !!Object.keys(formState.dirtyFields).length
    : false
  const handleCancel = useCallback(() => {
    if (!onCancel) {
      return
    }
    if (hasUnsavedChanges) {
      const shouldDismissData = confirm(
        'You have made unsaved changes. Are you sure you want to discard them?'
      )
      if (shouldDismissData) {
        onCancel()
      }
    } else {
      onCancel()
    }
  }, [hasUnsavedChanges, onCancel])

  return (
    <>
      {title && (
        <>
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <span>{title}</span>
              <CloseIcon onClick={() => handleCancel()} />
            </Box>
          </DialogTitle>
          <Divider light />
        </>
      )}

      <DialogContent
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          ...contentSx,
        }}
      >
        {children}
      </DialogContent>

      {(onCancel || onSubmit) && !hideCardActions && (
        <>
          <Divider light />

          <DialogActions>
            {onCancel && (
              <Button
                variant="outlined"
                onClick={handleCancel}
                disabled={loading}
              >
                {cancelText}
              </Button>
            )}

            {onSubmit && (
              <LoadingButton type="submit" onClick={onSubmit} loading={loading}>
                {submitText}
              </LoadingButton>
            )}
          </DialogActions>
        </>
      )}
    </>
  )
}
