import { gql, useQuery } from '@apollo/client'

import { BootcampParticipationRole } from '../graphqlTypes'

export const LIST_BOOTCAMP_PARTICIPANT_ROLES = gql`
  query ListBootcampParticipantRoles {
    listBootcampParticipationRoles {
      id
      name
    }
  }
`

export const useListBootcampParticipantRoles = () => {
  const query = useQuery<{
    listBootcampParticipationRoles: BootcampParticipationRole[]
  }>(LIST_BOOTCAMP_PARTICIPANT_ROLES)
  const roles = query.data?.listBootcampParticipationRoles || []
  const completed = query.called && !query.loading && !query.error
  return { ...query, roles, completed }
}
