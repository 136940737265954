import {
  CurriculumColumn,
  CurriculumDtoInput,
  CurriculumEntryDto,
  CurriculumEntryDtoInput,
} from '../../graphql/graphqlTypes'

export const mapInputToDto = (input: string) => {
  let level = 0
  let slicedTitle = input

  if (input.startsWith('-')) {
    slicedTitle = input.slice(1)
    level = 1
  }
  if (input.startsWith('--')) {
    slicedTitle = input.slice(2)
    level = 2
  }

  return { title: slicedTitle, level }
}

export const mapToText = (curriculumEntries: CurriculumEntryDto[]) =>
  curriculumEntries
    ? curriculumEntries
        .map(curriculumEntry => {
          if (curriculumEntry.level === 1) {
            return '-' + curriculumEntry.title
          }
          if (curriculumEntry.level === 2) {
            return '--' + curriculumEntry.title
          }
          return curriculumEntry.title
        })
        .join('\n')
    : ''

export const removeTypenameFromCurriculumColumn = (
  dto: CurriculumColumn
): CurriculumColumn => {
  const newColumn = { ...dto }
  delete newColumn.__typename
  newColumn.curriculumEntries = newColumn.curriculumEntries.map(entry => {
    const newEntry = { ...entry }
    delete newEntry.__typename
    return newEntry
  })
  return newColumn
}

export const trimCurriculumEntryTitles = (
  curriculumDto: CurriculumDtoInput
) => {
  return {
    curriculumColumns: curriculumDto.curriculumColumns.map(column => ({
      curriculumEntries: column.curriculumEntries.reduce(
        gatherNonEmptyTitles,
        [] as CurriculumEntryDtoInput[]
      ),
    })),
  }
}

const gatherNonEmptyTitles = (
  entries: CurriculumEntryDtoInput[],
  entry: CurriculumEntryDtoInput
) => {
  const title = entry.title.trim()
  if (title) {
    entries.push({ ...entry, title })
  }
  return entries
}
