import { gql, useQuery } from '@apollo/client'

import { Partner } from '../graphqlTypes'

export const LIST_PARTNERS_QUERY = gql`
  query ListPartners {
    listPartners {
      id
      name
    }
  }
`

export const useListPartners = () => {
  const query = useQuery<{ listPartners: Partner[] }>(LIST_PARTNERS_QUERY)

  const partners = query.data?.listPartners || []

  return { partners, ...query }
}
