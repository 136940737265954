export const DEFAULT_RADIUS = 50

export const toLocationFilterName = (geo: { text: string }, radius: number) =>
  `${trimAtFirstComma(geo.text)} (+${radius}km)`
export const geolocationDtoToShortName = (g: { name: string }) =>
  trimAtFirstComma(g.name)

const trimAtFirstComma = (s: string) => {
  const index = s.indexOf(',')
  return index > -1 ? s.substring(0, index) : s
}
