import { Box, IconButton, Modal, styled } from '@mui/material'
import { useState } from 'react'

import { Device } from '../../../../graphql/graphqlTypes'
import { DeleteDisabled } from '../../../assets/icons'
import { Pattern02 } from '../../../assets/svgs'
import { ReactComponent as IconFish } from '../../../theme/icons/iconFish.svg'
import { CapstoneImage } from './CapstoneImage'

const FullscreenButtonOpen = styled('button')`
  display: block;
  width: 100%;
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
`

type Props = {
  device: Device
  src?: string
  srcXl?: string
  className?: string
  disableModal?: boolean
}

export const CapstoneImageView = ({
  className,
  src,
  srcXl,
  device,
  disableModal = false,
}: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const switchToFullscreen = () => {
    setIsFullscreen(true)
  }

  return (
    <Box className={className}>
      <FullscreenButtonOpen
        as={disableModal || !src ? 'div' : 'button'}
        onClick={!disableModal && src ? switchToFullscreen : undefined}
        title="View image in fullscreen"
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {src ? (
            <>
              <Box
                component={Pattern02}
                sx={{
                  position: 'absolute',
                  top: '60%',
                  left: '60%',
                  transform: 'translate(-50%, -50%)',
                  color: 'colors.water10',
                }}
                fontSize={700}
              />
              <CapstoneImage src={src} device={device} />
            </>
          ) : (
            <Box
              component={IconFish}
              sx={theme => ({
                width: 120,
                height: 120,
                '> path': {
                  fill: theme.palette.colors.water,
                },
                margin: '24px auto',
              })}
            />
          )}
        </Box>
      </FullscreenButtonOpen>

      <Modal open={isFullscreen && !disableModal}>
        <>
          <IconButton
            color="secondary"
            size="medium"
            onClick={() => setIsFullscreen(false)}
            title="Exit image fullscreen view"
            sx={{
              position: 'absolute',
              top: 32,
              right: 32,
              zIndex: 10000,
              padding: 2,
            }}
          >
            <DeleteDisabled fontSize={32} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              padding: '3vmin',
              overflow: 'auto',
            }}
          >
            <CapstoneImage device={device} src={srcXl} isFullscreen />
          </Box>
        </>
      </Modal>
    </Box>
  )
}
