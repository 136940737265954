import createTheme from '@mui/material/styles/createTheme'

export const muiDefaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 560,
      lg: 920,
      xl: 1380,
    },
  },
  spacing: 4,
  transitions: {
    duration: {
      standard: 150,
    },
  },
})
export const spacing = Number.parseInt(muiDefaultTheme.spacing(1), 10)
