import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { evictProfileCompletionData } from '../evict-cache'
import { MutationAddEntryToJobExperienceToPersonArgs } from '../graphqlTypes'

const ADD_ENTRY_TO_JOB_EXPERIENCE = gql`
  mutation AddEntryToJobExperience(
    $personId: String!
    $title: String!
    $companyName: String!
    $startDate: String!
    $endDate: String!
    $type: String!
    $description: String
    $location: String
    $employmentType: EmploymentType
  ) {
    addEntryToJobExperienceToPerson(
      personId: $personId
      title: $title
      companyName: $companyName
      startDate: $startDate
      endDate: $endDate
      type: $type
      description: $description
      location: $location
      employmentType: $employmentType
    ) {
      id
      jobExperiences {
        id
        title
        description
        companyName
        startDate
        endDate
        type
        location
        employmentType
      }
    }
  }
`

export const useAddJobExperience = (options?: BaseMutationOptions) => {
  const [addEntry, result] = useMutation(ADD_ENTRY_TO_JOB_EXPERIENCE, options)

  const addEntryToJobExperience = (
    variables: MutationAddEntryToJobExperienceToPersonArgs
  ) =>
    addEntry({
      variables,
      update: evictProfileCompletionData,
    })

  return { ...result, addEntryToJobExperience }
}
