import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

import { MutationSetIsAdminArgs } from '../graphqlTypes'
import { LIST_PERSONS_WITH_EMAIL } from './useListPrivatePersons'

const SET_IS_ADMIN = gql`
  mutation SetIsAdmin($id: String!, $isAdmin: Boolean!) {
    setIsAdmin(id: $id, isAdmin: $isAdmin) {
      id
      isAdmin
    }
  }
`

export const useSetAdmin = (options: BaseMutationOptions) => {
  const [setIsAdmin, result] = useMutation(SET_IS_ADMIN, options)

  const setAdminRights = (variables: MutationSetIsAdminArgs) =>
    setIsAdmin({
      variables: variables,
      refetchQueries: [LIST_PERSONS_WITH_EMAIL],
    })
  return { setAdminRights, ...result }
}
