import {
  MutationInvitePersonArgs,
  PersonTypeEnum,
  TrainingProviderEnum,
} from '../../../graphql/graphqlTypes'

export const initialFormData: MutationInvitePersonArgs = {
  firstName: '',
  lastName: '',
  email: '',
  personType: PersonTypeEnum.STUDENT,
  bootcampId: '',
  partnerId: '',
  trainingProvider: TrainingProviderEnum.NEUE_FISCHE,
}
