import { Box, InputAdornment, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  DateRange,
  DateRangePicker as MuiDateRangePicker,
  LicenseInfo,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import { useCallback, useEffect, useState } from 'react'
import { useRange, UseRangeProps } from 'react-instantsearch-hooks-web'

import { DeleteDisabled } from '../../../assets/icons'

type Range = {
  min: number
  max: number
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '')

export const DateRangePicker = ({
  label,
  ...props
}: UseRangeProps & {
  label: string
}) => {
  const [initialRange, setInitialRange] = useState<Range>({ min: 0, max: 0 })
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null])

  const { range, refine } = useRange({
    ...props,
  })

  useEffect(() => {
    if (!initialRange?.min && range.min && range.max) {
      setInitialRange(range as Range)
    }
  }, [initialRange?.min, range])

  const onChange = useCallback(
    (newDateRange: DateRange<Date>) => {
      const [start, end] = newDateRange
      setDateRange(newDateRange)
      if (start && end) {
        refine([start.getTime(), end.getTime()])
      } else {
        refine([undefined, undefined])
      }
    },
    [refine]
  )

  return (
    <Stack spacing={2}>
      <Typography variant="caption">{label}</Typography>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: 'From', end: 'To' }}
      >
        <MuiDateRangePicker
          value={dateRange}
          onChange={onChange}
          inputFormat="dd/MM/yyyy"
          renderInput={(startProps, endProps) => (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 4,
                flexGrow: 1,
              }}
            >
              <TextField
                {...startProps}
                InputProps={{
                  endAdornment: dateRange[0] ? (
                    <InputAdornment
                      position="end"
                      sx={{ height: 'auto', cursor: 'pointer' }}
                    >
                      <DeleteDisabled
                        fontSize={32}
                        onClick={() => onChange([null, dateRange[1]])}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
              <TextField
                {...endProps}
                InputProps={{
                  endAdornment: dateRange[1] ? (
                    <InputAdornment
                      position="end"
                      sx={{ height: 'auto', cursor: 'pointer' }}
                    >
                      <DeleteDisabled
                        fontSize={32}
                        onClick={() => onChange([dateRange[1], null])}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
    </Stack>
  )
}
