import { ReactElement, useCallback, useEffect, useState } from 'react'

const ID = 29990851

export const useIubenda = () => {
  const [loading, setLoading] = useState(true)
  const [
    termsAndConditions,
    setTermsAndConditions,
  ] = useState<ReactElement | null>(null)

  const fetchTuC = useCallback(async () => {
    fetch(`https://www.iubenda.com/api/terms-and-conditions/${ID}`)
      .then(res => res.json())
      .then(data => {
        if (data.content) {
          setLoading(false)
          setTermsAndConditions(
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          )
        }
      })
  }, [])

  useEffect(() => {
    fetchTuC()
  }, [fetchTuC])

  return { loading, termsAndConditions }
}
