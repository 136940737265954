import { FC, UIEvent, useState } from 'react'
import { Button, Card, styled } from '@mui/material'
import { Person, PersonTypeEnum } from '../../../graphql/graphqlTypes'
import Select from '../../../common-deprecated/components/Select'
import { useListBootcampParticipantRoles } from '../../../graphql/bootcamp/listBootcampParticipantRoles'
import { getPersonTypeName } from '../../../service/person-type-service'
import Avatar from '../../../common-deprecated/components/avatar/Avatar'

type PersonCardWrapperProps = {
  person: Person
  onAddToBootcamp: (id: string, roleId: string) => Promise<unknown>
}

const PersonCardWrapper: FC<PersonCardWrapperProps> = ({
  person,
  onAddToBootcamp,
}) => {
  const [fetching, setFetching] = useState(false)
  const { roles } = useListBootcampParticipantRoles()

  const [roleId, setRoleId] = useState(
    person.personType === PersonTypeEnum.COACH ? 'coach' : 'student'
  )

  const handleAdd = () => {
    setFetching(true)
    onAddToBootcamp(person.id, roleId).finally(() => setFetching(false))
  }

  return (
    <CardWrapper>
      <Avatar src={person.avatar?.urls.l} />
      <p>
        <h2>
          {person.firstName || '-'} {person.lastName || '-'}
        </h2>
        <br />
        {getPersonTypeName(person.personType)}
        <br />
        {person.email}
      </p>
      <SelectWrapper>
        <Select
          srOnly
          labelText="Person Type"
          name="roleId"
          onChange={event => setRoleId(event.target.value)}
          value={roleId}
          options={roles}
        />
        <Button disabled={fetching} onClick={handleAdd}>
          Add to Bootcamp
        </Button>
      </SelectWrapper>
    </CardWrapper>
  )
}

type PersonSearchResultProps = {
  persons: Person[]
  onAddToBootcamp: (id: string, roleId: string) => Promise<unknown>
  onScroll?: (e: UIEvent) => void
}

const PersonsSearchResult: FC<PersonSearchResultProps> = ({
  persons,
  onAddToBootcamp,
  onScroll,
}) => {
  return (
    <Wrapper onScroll={onScroll}>
      {persons.map((person, index) => (
        <>
          {index > 0 && <hr />}
          <PersonCardWrapper
            key={person.id}
            person={person}
            onAddToBootcamp={onAddToBootcamp}
          />
        </>
      ))}
    </Wrapper>
  )
}

export default PersonsSearchResult

const Wrapper = styled('div')`
  padding-right: var(--spacing-s);
  overflow-y: auto;

  p:last-child {
    color: var(--primary);
  }
`

const SelectWrapper = styled('div')`
  display: flex;
  gap: var(--spacing-xs);
`

const CardWrapper = styled(Card)`
  display: grid;
  grid-template-columns: min-content auto max-content;
  align-items: center;
  grid-gap: 12px;

  h2 {
    margin-bottom: 0;
  }
`
