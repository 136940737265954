import { Box, Button, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { colors } from '../common/theme/constants/colors'

export const Page404 = () => {
  const navigate = useNavigate()
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', backgroundColor: colors.water }}
      spacing={10}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="title2" color="colors.nemo" mb={2}>
          Error 404
        </Typography>
        <Typography variant="h3" mb={6}>
          Page not found.
        </Typography>
        <Typography variant="body2" textAlign="center">
          Sorry, this page doesn’t exist. Try again or do something else.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
        <Button onClick={() => navigate(-1)} variant="outlined">
          Go back
        </Button>

        <Button component={Link} to="/">
          Go to homepage
        </Button>
      </Box>
    </Stack>
  )
}
