import { styled } from '@mui/material'
import { SVGProps } from 'react'

export const Icon = styled('svg')`
  width: 1em;
  height: 1em;
  display: inline;
`

export const Svg = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  />
)

export interface SvgProps {
  className?: string
}
