import { SVGProps } from 'react'

import { ReactComponent as LogoFullSvg } from './neuefische-gmbh-logo.svg'
import { ReactComponent as IconSvg } from './neuefische-icon.svg'
import { ReactComponent as LogoSvg } from './neuefische-logo.svg'

export const BrandLogo = ({
  type = 'withText',
  ...props
}: SVGProps<SVGSVGElement> & { type?: 'icon' | 'name' | 'withText' }) => {
  switch (type) {
    case 'icon':
      return <IconSvg width="1em" {...props} />

    case 'name':
      return <LogoSvg width={`${175 / 25}em`} {...props} />

    case 'withText':
      return <LogoFullSvg width={`${175 / 41}em`} {...props} />

    default:
      return null
  }
}
