import { TypographyOptions } from '@mui/material/styles/createTypography'

import { muiDefaultTheme } from './theme'

const { up } = muiDefaultTheme.breakpoints

export const typography: TypographyOptions = {
  h0: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${36 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${36 / 16}rem`,
    },
    [up('md')]: {
      fontSize: `${56 / 16}rem`,
    },
    [up('lg')]: {
      fontSize: `${64 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${80 / 16}rem`,
    },
  },
  h1: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${36 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${36 / 16}rem`,
    },
    [up('md')]: {
      fontSize: `${56 / 16}rem`,
    },
    [up('lg')]: {
      fontSize: `${48 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${60 / 16}rem`,
    },
  },
  h2: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${32 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${32 / 16}rem`,
    },
    [up('md')]: {
      fontSize: `${36 / 16}rem`,
    },
    [up('lg')]: {
      fontSize: `${42 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${48 / 16}rem`,
    },
  },
  h3: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${24 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${24 / 16}rem`,
    },
    [up('lg')]: {
      fontSize: `${32 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${36 / 16}rem`,
    },
  },
  h4: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${20 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${24 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${36 / 16}rem`,
    },
  },
  h5: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${20 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('sm')]: {
      fontSize: `${24 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${26 / 16}rem`,
    },
  },
  title1: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${18 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('md')]: {
      fontSize: `${24 / 16}rem`,
    },
  },
  title2: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${18 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  introStrong: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${16 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.5,
    [up('sm')]: {
      fontSize: `${18 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${21 / 16}rem`,
    },
  },
  intro: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${16 / 16}rem`,
    fontWeight: 400,
    lineHeight: 1.5,
    [up('sm')]: {
      fontSize: `${18 / 16}rem`,
    },
    [up('xl')]: {
      fontSize: `${21 / 16}rem`,
    },
  },
  button: {
    fontFamily: 'PolySans, sans-serif',
    fontSize: `${16 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1,
    textTransform: 'initial',
  },
  body1Strong: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${14 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.4,
    [up('md')]: {
      fontSize: `${16 / 16}rem`,
    },
  },
  body1: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${14 / 16}rem`,
    fontWeight: 400,
    lineHeight: 1.4,
    [up('md')]: {
      fontSize: `${16 / 16}rem`,
    },
  },
  body2Strong: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${14 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  body2: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${14 / 16}rem`,
    fontWeight: 400,
    lineHeight: 1.4,
    [up('md')]: {
      fontSize: `${14 / 16}rem`,
    },
  },
  captionStrong: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${12 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  caption: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${12 / 16}rem`,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  menu: {
    fontFamily: '"Inter var", sans-serif',
    fontSize: `${11 / 16}rem`,
    fontWeight: 600,
    lineHeight: 1.2,
    [up('xl')]: {
      fontSize: `${12 / 16}rem`,
    },
  },
}
