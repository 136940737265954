import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { typography } from './typography'

export const MuiInputLabel: Components['MuiInputLabel'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      ...typography.body2,
      lineHeight: '1.75em',
      paddingLeft: 12,
      transform: 'translate(12px, 18px) scale(1)',
      '&.Mui-error': {
        color: colors.error,
      },
    },
    shrink: {
      transform: 'translate(12px, 7px) scale(.75)',
    },
  },
  variants: [
    {
      props: {
        color: 'primary',
      },
      style: {
        color: colors.grey,
        '&.Mui-focused': {
          color: colors.granite,
        },
        '&.Mui-disabled': {
          color: colors.lightGrey,
        },
      },
    },
    {
      props: {
        color: 'secondary', // dark mode
      },
      style: {
        color: colors.lightGrey,
        '&.Mui-focused': {
          color: colors.foam,
        },
        '&.Mui-disabled': {
          color: colors.grey,
        },
      },
    },
  ],
}
