import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { graphqlClient } from '../GraphqlClient'
import { GET_CONTACTED_STUDENTS } from './useGetContactedStudents'
import { GET_OWN_PARTNER_MESSAGES } from './useGetOwnPartnerMessages'

const SEND_MESSAGE = gql`
  mutation SendMessage($messageRequest: MessageRequest!) {
    sendMessage(messageRequest: $messageRequest)
  }
`

export default function useSendMessage(options: BaseMutationOptions) {
  const [sendMessageMutation, status] = useMutation(SEND_MESSAGE, options)

  const sendMessage = (
    partnerMemberId: string,
    studentId: string,
    text: string
  ) =>
    sendMessageMutation({
      variables: {
        messageRequest: {
          partnerMemberId,
          studentId,
          text,
        },
      },
      onCompleted: () =>
        graphqlClient.refetchQueries({
          include: [GET_OWN_PARTNER_MESSAGES, GET_CONTACTED_STUDENTS],
        }),
    })

  return { sendMessage, ...status }
}
