import { gql, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { graphqlClient } from '../GraphqlClient'
import { Partner } from '../graphqlTypes'

export const GET_PARTNER_DETAILS = gql`
  query GetPartnerDetails($id: String!) {
    getPartner(id: $id) {
      id
      name
      description
      industry
      bannerImage {
        id
        urls {
          s
          m
          l
          xl
        }
      }
      profileImage {
        id
        urls {
          s
          m
          l
          xl
        }
      }
      homepageUrl
      yearOfEstablishment
      size
      revenueCategory
      address
      city
      postalCode
      created
      members {
        id
        firstName
        lastName
        email
        avatar {
          id
          urls {
            s
          }
        }
      }
      disabled
    }
  }
`

export default function usePartnerDetails(id?: string) {
  const query = useQuery<{ getPartner: Partner }>(GET_PARTNER_DETAILS, {
    variables: { id },
    skip: !id,
  })

  query.errors?.forEach(error => {
    toast.error(error.message)
  })

  const partner = id ? query.data?.getPartner : undefined
  return { ...query, partner }
}

export const refetchPartner = async () => {
  await graphqlClient.refetchQueries({
    include: [GET_PARTNER_DETAILS],
  })
}
