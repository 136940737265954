import { useParams } from 'react-router-dom'
import usePartnerDetails from '../../graphql/partner/usePartnerDetails'
import LoadingIndicator from '../../common-deprecated/components/LoadingIndicator'
import EditPartnerForm from './components/EditPartnerForm'
import DeletePartner from './components/DeletePartner'
import PartnerMembers from './components/PartnerMembers'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { styled } from '@mui/material'

export default function PartnerDetails() {
  const { id } = useParams<{ id: string }>()

  const { partner, loading } = usePartnerDetails(id!)

  return (
    <Wrapper>
      <DeprecatedGlobalStyles />
      {loading || !partner ? (
        <LoadingIndicator />
      ) : (
        <>
          <h1>{partner.name}</h1>
          <EditPartnerForm partner={partner} />
          <h2>Members</h2>
          <PartnerMembers partnerId={partner.id} members={partner.members} />
          <h2>Danger Zone</h2>
          <DeletePartner partner={partner} />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('main')`
  display: grid;
  grid-gap: var(--spacing-m);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);
  overflow-y: auto;

  h1,
  h2 {
    text-align: center;
    text-transform: uppercase;
  }
`
