import { Button, Dialog } from '@mui/material'
import { useState } from 'react'
import { useInstantSearch } from 'react-instantsearch-hooks-web'

import { Plus } from '../../../assets/icons'
import { FormAddPartner } from './FormAddPartner'

export const AddPartner = () => {
  const { refresh } = useInstantSearch()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setModalOpen(true)} startIcon={<Plus />}>
        Add partner
      </Button>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <FormAddPartner
          onCancel={() => {
            setModalOpen(false)
            refresh()
          }}
          onSuccess={() => {
            setModalOpen(false)
            refresh()
          }}
        />
      </Dialog>
    </>
  )
}
