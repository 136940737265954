import { gql, useQuery } from '@apollo/client'

import { personFragment } from '../fragments/personFragment'
import { graphqlClient } from '../GraphqlClient'
import { Person, QueryGetPersonArgs } from '../graphqlTypes'

export const GET_PERSON = gql`
  ${personFragment}
  query GetPerson($id: String!) {
    getPerson(id: $id) {
      ...Person
    }
  }
`

export const useGetPerson = (options: {
  variables: QueryGetPersonArgs
  onCompleted?: (data: unknown) => void
}) => {
  const query = useQuery<{ getPerson: Person }>(GET_PERSON, options)
  const person: Person = query.data?.getPerson || ({} as Person)
  return { ...query, person }
}

export const refetchPerson = async () => {
  await graphqlClient.refetchQueries({
    include: [GET_PERSON],
  })
}
