import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { MutationAddPartnerArgs } from '../graphqlTypes'
import { LIST_PARTNERS_QUERY } from './useListPartners'

const ADD_PARTNER_MUTATION = gql`
  mutation AddPartner(
    $name: String!
    $description: String!
    $industry: String!
  ) {
    addPartner(name: $name, description: $description, industry: $industry) {
      id
      name
      description
      industry
      created
    }
  }
`

export default function useAddPartner(options: BaseMutationOptions) {
  const [addPartnerMutation, status] = useMutation(
    ADD_PARTNER_MUTATION,
    options
  )

  const addPartner = (variables: MutationAddPartnerArgs) =>
    addPartnerMutation({ variables, refetchQueries: [LIST_PARTNERS_QUERY] })

  return { addPartner, ...status }
}
