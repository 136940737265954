import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'
import { useCallback, useMemo } from 'react'

import { evictProfileCompletionData } from '../evict-cache'
import {
  LanguageSkill,
  LanguageSkillInput,
  MutationSetPersonLanguageSkillsArgs,
} from '../graphqlTypes'
import { GET_USER } from './useUser'

const SET_PERSON_LANGAUGE_SKILLS = gql`
  mutation SetPersonLanguageSkills(
    $personId: String!
    $languageSkills: [LanguageSkillInput!]!
  ) {
    setPersonLanguageSkills(
      languageSkills: $languageSkills
      personId: $personId
    ) {
      id
      language
      level
    }
  }
`

export const useSetPersonLanguageSkill = (
  currentSkills: LanguageSkill[] = [],
  options?: BaseMutationOptions
) => {
  const [setPersonLanguageSkills, result] = useMutation(
    SET_PERSON_LANGAUGE_SKILLS,
    options
  )

  const newSkills: LanguageSkillInput[] = useMemo(
    () =>
      currentSkills.map(c => ({
        language: c.language,
        level: c.level,
      })),
    [currentSkills]
  )

  const setPersonLanguageSkill = useCallback(
    ({
      personId,
      languageSkill,
    }: {
      personId: MutationSetPersonLanguageSkillsArgs['personId']
      languageSkill: LanguageSkillInput
    }) => {
      let updatedSkills = newSkills

      if (newSkills.some(s => s.language === languageSkill.language)) {
        updatedSkills = newSkills.map(s =>
          s.language === languageSkill.language ? languageSkill : s
        )
      } else {
        updatedSkills.push(languageSkill)
      }

      setPersonLanguageSkills({
        variables: { personId, languageSkills: updatedSkills },
        update: evictProfileCompletionData,
        refetchQueries: [GET_USER],
      })
    },
    [newSkills, setPersonLanguageSkills]
  )

  const deletePersonLanguageSkill = useCallback(
    ({
      personId,
      language,
    }: {
      personId: MutationSetPersonLanguageSkillsArgs['personId']
      language: LanguageSkillInput['language']
    }) => {
      setPersonLanguageSkills({
        variables: {
          personId,
          languageSkills: newSkills.filter(s => s.language !== language),
        },
        update: evictProfileCompletionData,
        refetchQueries: [GET_USER],
      })
    },
    [newSkills, setPersonLanguageSkills]
  )

  return { ...result, setPersonLanguageSkill, deletePersonLanguageSkill }
}
