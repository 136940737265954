import { Card, CardContent, Stack, styled, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { PartnerActivityLog } from '../common/components/organisims/PartnerActivityLog'
import { PartnerStudentTeasers } from '../common/components/organisims/PartnerStudentTeasers'
import { UpcomingEvents } from '../common/components/organisims/UpcomingEvents'
import { ReactComponent as IconFish } from '../common/theme/icons/iconFish.svg'
import { useUser } from '../graphql/person/useUser'

const Main = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.up('lg')]: {
    gap: 24,
  },
}))

const WelcomeCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.colors.almostGranite,
  color: theme.palette.colors.foam,
  position: 'relative',

  [theme.breakpoints.down('lg')]: {
    borderRadius: 0,
  },
}))

const BackgroundDecoration = styled(IconFish)({
  position: 'absolute',
  bottom: 0,
  right: 24,
  transform: `translate(-5%, 50%)`,
  width: 120,
  height: 120,
})

export const PageDashboardPartner = () => {
  const { person } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  return (
    <Main data-cy-post-login="true" data-test="main">
      <WelcomeCard>
        <CardContent
          sx={{
            pt: 7,
            pl: 8,
            ':last-child': { pb: 7 },
          }}
        >
          <BackgroundDecoration />
          <Stack sx={{ position: 'relative' }}>
            <Typography variant="body2Strong">
              Welcome back, {person?.firstName}!
            </Typography>
          </Stack>
        </CardContent>
      </WelcomeCard>

      <UpcomingEvents />

      <PartnerStudentTeasers />

      <PartnerActivityLog id={person?.partner?.id} />
    </Main>
  )
}
