import {
  Button,
  Card,
  CardHeader,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { FC, ReactNode } from 'react'

import { DeleteDisabled } from '../../../assets/icons'

const CloseIcon = styled(DeleteDisabled)`
  font-size: 32px;
  cursor: pointer;
`

export const ContactStatusMessage: FC<{
  icon: ReactNode
  title: string
  statusMessage: ReactNode
  headlineLabel: string
  buttonLabel: string
  handleClick: () => void
  handleCancel: () => void
}> = ({
  title,
  icon,
  statusMessage,
  headlineLabel,
  buttonLabel,
  handleClick,
  handleCancel,
}) => {
  return (
    <Card>
      <CardHeader
        title={title && <Typography variant="title2">{title}</Typography>}
        action={<CloseIcon onClick={handleCancel} />}
        sx={{
          alignItems: 'center',
          marginBottom: 0,
          paddingY: 4,
          paddingX: { xs: 6, lg: 8 },
          ...(title
            ? {
                borderBottom: '1px solid',
                borderBottomColor: 'colors.water10',
              }
            : { marginBottom: -4, paddingTop: 6, paddingBottom: 0 }),
        }}
      />
      <Stack alignItems="center" mt={10} mb={10}>
        {icon}
        <Typography variant="h5" marginTop={7}>
          {headlineLabel}
        </Typography>
        <Typography textAlign="center" marginTop={4}>
          {statusMessage}
        </Typography>
        <Button
          size="medium"
          variant="contained"
          onClick={handleClick}
          sx={{ marginTop: 6 }}
        >
          {buttonLabel}
        </Button>
      </Stack>
    </Card>
  )
}
