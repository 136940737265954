import { useFeature } from '@growthbook/growthbook-react'
import {
  BoxProps,
  IconButton,
  MenuItem,
  Stack,
  styled,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useMenu, UseMenuProps } from 'react-instantsearch-hooks-web'

import { DeleteDisabled } from '../../../assets/icons'
import { DateRangePicker } from '../../molecules/DateRangePicker'
import { SearchBox } from '../../molecules/SearchBox'

export const CustomMenu = (
  props: UseMenuProps & {
    label: string
    sx?: BoxProps['sx']
  }
) => {
  const { items, refine } = useMenu({ ...props, limit: 100 })
  const [initialItems, setInitialItems] = useState(items || [])
  const refinedItem = items.find(item => item.isRefined)

  useEffect(() => {
    if (!initialItems.length && items.length) {
      setInitialItems(items)
    }
  }, [initialItems.length, items])

  return (
    <TextField
      select
      id={props.label}
      label={props.label}
      onChange={event =>
        event.target.value ? refine(event.target.value) : refine('')
      }
      value={refinedItem?.label || ''}
      sx={props.sx}
      disabled={!items.length}
      InputProps={{
        endAdornment: (
          <IconButton
            sx={{
              marginRight: 4,
              visibility: refinedItem?.label ? 'visible' : 'hidden',
            }}
            onClick={() => refine('')}
          >
            <DeleteDisabled />
          </IconButton>
        ),
      }}
    >
      {initialItems.map(item => (
        <MenuItem value={item.value} key={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 16,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}))

const GridDatePicker = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 16,

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}))

export const BootcampFilter = () => {
  const showDateRangeFilter = useFeature('show-bootcamp-date-filter').on
  return (
    <Stack spacing={4}>
      <Grid>
        <SearchBox sx={{ gridColumn: { xs: '1/-1', lg: 'auto' } }} />
        <CustomMenu attribute="track" label="Tracks" />
        <CustomMenu attribute="location" label="Location" />
        <CustomMenu attribute="status" label="Status" />
        <CustomMenu attribute="language" label="Language" />
        <CustomMenu
          attribute="headCoach"
          label="Lead coach"
          sx={{ gridColumn: { xs: '1/-1', lg: 'auto' } }}
        />
      </Grid>
      {showDateRangeFilter && (
        <GridDatePicker>
          <DateRangePicker label="Start date" attribute="startDate" />
          <DateRangePicker label="End date" attribute="endDate" />
        </GridDatePicker>
      )}
    </Stack>
  )
}
