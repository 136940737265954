import { gql, useQuery } from '@apollo/client'

import { LatLng } from './graphqlTypes'

const GET_LAT_LNG = gql`
  query GetLatLng($placeId: String!) {
    getLatLng(placeId: $placeId) {
      lat
      lng
    }
  }
`

export const useGetLatLng = (placeId?: string) => {
  const query = useQuery<{ getLatLng: LatLng }>(GET_LAT_LNG, {
    variables: {
      placeId,
    },
    skip: placeId === undefined,
  })

  const latLng =
    query.data && `${query.data.getLatLng.lat}, ${query.data.getLatLng.lng}`

  return { ...query, latLng }
}
