import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Person } from '../../../../../graphql/graphqlTypes'
import { useAddPersonBasicInfo } from '../../../../../graphql/person/useAddPersonBasicInfo'
import { useGetPerson } from '../../../../../graphql/person/useGetPerson'
import { refetchUser } from '../../../../../graphql/person/useUser'
import {
  createGitHubUrl,
  getGitHubUser,
} from '../../../../../service/profile/github-service'
import { Github, MarkerPin2 } from '../../../../assets/icons'
import { useModalStore } from '../../../../stores/modalStore'
import { PersonFormProfileAvatar } from './PersonFormProfileAvatar'
import { ProfileForm } from './ProfileForm'

type FormValues = Pick<
  Person,
  | 'firstName'
  | 'lastName'
  | 'shortDescription'
  | 'teaser'
  | 'location'
  | 'gitHubUrl'
  | 'videoUrl'
>

export type FormProfileInfoProps = FormValues & Pick<Person, 'id' | 'avatar'>

export const FormProfileInfo = ({ personId }: { personId: string }) => {
  const setModal = useModalStore(state => state.setModal)
  const { person } = useGetPerson({
    variables: {
      id: personId,
    },
  })

  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: person.firstName || '',
      lastName: person.lastName || '',
      location: person.location || '',
      gitHubUrl: person.gitHubUrl ? getGitHubUser(person.gitHubUrl) : '',
      teaser: person.teaser || '',
      videoUrl: person.videoUrl || '',
      shortDescription: person.shortDescription || '',
    },
  })

  const {
    field: fieldFirstName,
    fieldState: fieldFirstNameState,
  } = useController({
    name: 'firstName',
    control,
    rules: {
      validate: value =>
        (value && value.length > 0) || 'First name can not be empty',
    },
  })

  const {
    field: fieldLastName,
    fieldState: fieldLastNameState,
  } = useController({
    name: 'lastName',
    control,
    rules: {
      validate: value =>
        (value && value.length > 0) || 'Last name can not be empty',
    },
  })

  const {
    field: fieldLocation,
    fieldState: fieldLocationState,
  } = useController({
    name: 'location',
    control,
  })

  const {
    field: fieldGithubUrl,
    fieldState: fieldGithubUrlState,
  } = useController({
    name: 'gitHubUrl',
    control,
  })

  const {
    field: fieldVideoUrl,
    fieldState: fieldVideoUrlState,
  } = useController({
    name: 'videoUrl',
    control,
  })

  const { field: fieldTeaser, fieldState: fieldTeaserState } = useController({
    name: 'teaser',
    control,
    rules: {
      validate: value =>
        (value?.length || 0) <= 100 ||
        `(${value?.length || 0}/100 characters) Your text is too long`,
    },
  })

  const {
    field: fieldShortDescription,
    fieldState: fieldShortDescriptionState,
  } = useController({
    name: 'shortDescription',
    control,
    rules: {
      validate: value =>
        (value?.length || 0) <= 250 ||
        `(${value?.length || 0}/250 characters) Your text is too long`,
    },
  })

  const { addPersonBasicInfo, loading } = useAddPersonBasicInfo({
    onCompleted: async () => {
      toast.success('Information updated')
      await refetchUser() // to retry loading new avatar
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      addPersonBasicInfo({
        id: person.id,
        ...data,
        gitHubUrl: createGitHubUrl(data.gitHubUrl || ''),
      })
    },
    [addPersonBasicInfo, person.id]
  )

  const onCancel = useCallback(async () => {
    await refetchUser() // to retry loading new avatar
    setModal(false)
  }, [setModal])

  return (
    <ProfileForm
      title="Info"
      formState={formState}
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
      loading={loading}
    >
      <Box marginBottom={6}>
        <PersonFormProfileAvatar
          id={person.id}
          avatar={person.avatar?.urls.s}
        />
      </Box>

      <Box component="form" paddingBottom={8}>
        <Stack spacing={10} flexGrow={1}>
          <Stack spacing={4} flexGrow={1}>
            <TextField
              label="First name"
              {...fieldFirstName}
              error={!!fieldFirstNameState.error}
              helperText={fieldFirstNameState.error?.message}
            />

            <TextField
              label="Last name"
              {...fieldLastName}
              error={!!fieldLastNameState.error}
              helperText={fieldLastNameState.error?.message}
            />

            <TextField
              label="Location"
              {...fieldLocation}
              error={!!fieldLocationState.error}
              helperText={fieldLocationState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component={MarkerPin2} fontSize={32} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <TextField
            label="Github Username"
            {...fieldGithubUrl}
            error={!!fieldGithubUrlState.error}
            helperText={fieldGithubUrlState.error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box component={Github} fontSize={32} />
                </InputAdornment>
              ),
            }}
          />
          <Stack spacing={4} flexGrow={1}>
            <Typography variant="body2">
              Present yourself in a short video clip, hosted on a provider of
              your choice.
            </Typography>
            <TextField
              label="Video Url"
              {...fieldVideoUrl}
              onFocus={() => {
                if (!fieldVideoUrl.value) {
                  fieldVideoUrl.onChange('https://')
                }
              }}
              error={!!fieldVideoUrlState.error}
              helperText={fieldVideoUrlState.error?.message}
            />
          </Stack>
          <Stack spacing={4} flexGrow={1}>
            <Typography variant="body2">
              A short and sweet intro about yourself. It’ll be featured in the
              profile search for partners.
            </Typography>

            <TextField
              multiline
              minRows={3}
              label="Your short introduction"
              {...fieldTeaser}
              error={!!fieldTeaserState.error}
              helperText={
                fieldTeaserState.error?.message ||
                `(${fieldTeaser?.value?.length || 0}/100 characters)`
              }
            />
          </Stack>

          <Stack spacing={4} flexGrow={1}>
            <Typography variant="body2">
              Who are you and what is your motivation? This description will be
              shown on your profile page.
            </Typography>

            <TextField
              multiline
              minRows={5}
              label="About you"
              {...fieldShortDescription}
              error={!!fieldShortDescriptionState.error}
              helperText={
                fieldShortDescriptionState.error?.message ||
                `(${fieldShortDescription?.value?.length || 0}/250 characters)`
              }
            />
          </Stack>
        </Stack>
      </Box>
    </ProfileForm>
  )
}
