import { Grid } from '@mui/material'

import { BootcampParticipation } from '../../../../graphql/graphqlTypes'
import { ProfileLink } from '../ProfileLink'

export interface BootcampCoachesListProps {
  coaches: BootcampParticipation[]
}

export const BootcampCoachesList = ({ coaches }: BootcampCoachesListProps) => {
  if (coaches.length === 0) return null

  return (
    <Grid
      container
      columns={{
        xs: 1,
        md: 2,
      }}
      spacing={6}
    >
      {coaches.map(coach => (
        <Grid item xs={1} key={coach.person.id}>
          <ProfileLink
            id={coach.person.id}
            firstName={coach.person.firstName ?? ''}
            lastName={coach.person.lastName ?? ''}
            avatarUrl={coach.person?.avatar?.urls?.s}
            roles={['Coach']}
          />
        </Grid>
      ))}
    </Grid>
  )
}
