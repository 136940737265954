import { Components } from '@mui/material'

import { muiDefaultTheme } from './theme'

export const MuiCardActions: Components['MuiCardActions'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      rowGap: 16,
      padding: 24,
      [muiDefaultTheme.breakpoints.up('lg')]: {
        padding: 32,
      },
      '.MuiCardContent-root + &': {
        paddingTop: 0,
      },

      '>:not(:first-of-type)': { marginLeft: 0 },
    },
  },
}
