import { styled } from '@mui/material'
import { FC } from 'react'
import { CurriculumDto } from '../../../graphql/graphqlTypes'

export type CurriculumViewProps = {
  curriculum: CurriculumDto
}

const CurriculumView: FC<CurriculumViewProps> = ({ curriculum }) => (
  <Wrapper>
    {curriculum.curriculumColumns.map((column, i) => (
      <div key={i}>
        {column.curriculumEntries.map(entry => (
          <div key={entry.title} className={`entry-${entry.level}`}>
            {entry.title}
          </div>
        ))}
      </div>
    ))}
  </Wrapper>
)

const Wrapper = styled('section')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  h3 {
    margin-bottom: 0;
  }

  .entry-0 {
    font-weight: 700;
    margin: 10px 0;
  }

  .entry-1 {
  }

  .entry-2 {
    margin-left: 10px;
  }
  .entry-2::before {
    content: '○';
    margin-right: 5px;
  }
`

export default CurriculumView
