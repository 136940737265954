import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CapstoneTeaser } from '../common/components/molecules/CapstoneTeaser'
import {
  ProfileCard,
  ProfileCardProps,
} from '../common/components/molecules/ProfileCard'
import { EventInfoCard } from '../common/components/organisims/EventInfoCard'
import { useEvent } from '../graphql/events/useEvent'
import { JobExperienceType } from '../graphql/graphqlTypes'
import { isStudent } from '../service/profile/user-dashboard-service'

const Main = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.up('lg')]: {
    gap: 32,
  },
}))

const StyledStudentList = styled('div')({
  display: 'grid',
  gap: 30,
  gridTemplateColumns: `repeat(auto-fill, minmax(min(400px, 100%), 1fr))`,
})

export const PageEventDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { loading, event, error } = useEvent({ variables: { id } })
  const [activeTab, setActiveTab] = useState('students')

  if (loading) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  if (error) {
    navigate('/')
    return null
  }

  if (!event) {
    return null
  }

  const students = event.bootcamp.participants?.filter(isStudent)

  const preparedStudents =
    students &&
    students.length > 0 &&
    students.map(participant => {
      const tracks = participant.person?.participates.map(
        p => p.bootcamp.track.name
      )

      const lastEmployment = participant.person?.jobExperiences.find(
        experience => experience.type === JobExperienceType.EMPLOYMENT
      )

      const lastEducation = participant.person?.jobExperiences.find(
        experience => experience.type === JobExperienceType.EDUCATION
      )

      return {
        id: participant.person.id,
        firstName: participant.person.firstName,
        lastName: participant.person.lastName,
        teaser: participant.person.teaser,
        availability: participant.person.jobPreferences.available,
        avatarUrl: participant.person?.avatar?.urls.s,
        tracks: tracks,
        lastEmployment: lastEmployment,
        lastEducation: lastEducation,
      } as ProfileCardProps
    })

  const capstoneProjects =
    students?.map(student => student.capstoneProject) || []

  return (
    <Main>
      <Box>
        <EventInfoCard event={event} />
      </Box>

      <Card>
        <CardContent>
          <Tabs
            textColor="inherit"
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            sx={{
              '.MuiTabs-flexContainer': {
                gap: 5,
              },
              '.MuiTabs-indicator .MuiTabs-indicatorSpan': {
                width: '100%',
                backgroundColor: 'currentColor',
              },
              '.MuiTab-root': {
                padding: 0,
              },
            }}
          >
            {preparedStudents && preparedStudents.length > 0 && (
              <Tab label="Students" value="students" />
            )}
            <Tab label="Capstone Projects" value="capstone-projects" />
          </Tabs>

          {preparedStudents &&
            preparedStudents.length > 0 &&
            activeTab === 'students' && (
              <Box>
                <Typography variant="body2" color="colors.lightGrey" mb={10}>
                  {event.description}
                </Typography>

                <StyledStudentList>
                  {preparedStudents.map(student => (
                    <ProfileCard key={student.id} hasBorder {...student} />
                  ))}
                </StyledStudentList>
              </Box>
            )}

          {activeTab === 'capstone-projects' && (
            <Stack spacing={8}>
              <Typography color="colors.grey">
                During the final weeks of their course, every students’ skill is
                getting tested by creating their own capstone project. More
                information about each project and student can be found on their
                profile page.
              </Typography>

              <Stack spacing={8}>
                {capstoneProjects.map((capProject, key) => (
                  <Fragment key={key}>
                    <CapstoneTeaser {...capProject} />
                    {key < capstoneProjects.length - 1 && <Divider light />}
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Main>
  )
}
