import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useCurrentRefinements } from 'react-instantsearch-hooks-web'

import { useGetSkillsSuggestions } from '../../../../graphql/skills/useGetSkillsSuggestions'
import type { AlgoliaRefinementListItem } from '../../../../types/algolia.d'

type Props = {
  refine: (value: string) => void
  items: AlgoliaRefinementListItem[]
}

export const SkillFilter = (props: Props) => {
  const [value, setValue] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const { items: refinedSkillItems } = useCurrentRefinements({
    includedAttributes: ['skills'],
  })

  const refinedItems =
    refinedSkillItems[0]?.refinements.map(item => item.value as string) ?? []

  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true)
    }
  }

  const { skillsSuggestions } = useGetSkillsSuggestions(
    inputValue ?? '',
    1,
    refinedItems
  )

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    setInputValue(newInputValue)
    if (newInputValue.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <>
      <Typography color="colors.grey">Skills</Typography>
      <Autocomplete
        id="skills"
        value={value}
        inputValue={inputValue}
        open={open}
        onOpen={handleOpen}
        onChange={(event, newValue: null | string) => {
          newValue && props.refine(newValue)
          setValue(null)
          setInputValue('')
          setOpen(false)
        }}
        onInputChange={handleInputChange}
        options={skillsSuggestions.map(s => s.name)}
        renderInput={params => <TextField {...params} label="Skills" />}
        popupIcon=""
      />
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
        {refinedItems.map(item => (
          <Chip
            key={item}
            variant="outlined"
            label={item}
            onDelete={() => props.refine('' + item)}
          />
        ))}
      </Box>
    </>
  )
}
