import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import {
  getPersonName,
  getPersonNameMultiline,
} from '../../../../service/profile/person-service'
import {
  GraduatePerson,
  RightArrow as IconRightArrow,
  SuitcasePortfolio1,
} from '../../../assets/icons'
import { CardLink } from '../../atoms/CardLink'
import { ContentListItem } from '../ContentList'
import { EmptySection } from '../EmptySection'
import { ProfileParticipation } from '../ProfileParticipation'

const ProfileTag = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.colors.darkNemo,
  color: theme.palette.colors.foam,
  fontWeight: 600,
  display: 'inline-block',
  position: 'absolute',
  bottom: -6,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: '4px',
  lineHeight: 1,
  marginLeft: '50%',
  transform: `translateX(-50%)`,
}))

export interface ProfileCardProps {
  id: string
  tracks: string[]
  availability: boolean
  firstName: string
  lastName: string
  trainingProvider: string
  avatarUrl: string
  teaser?: string
  lastEmployment?: { title: string; id: string; companyName: string }
  lastEducation?: { title: string; id: string; companyName: string }
  hasBorder?: boolean
}

export const ProfileCard = ({
  id,
  tracks,
  availability,
  firstName,
  lastName,
  avatarUrl,
  trainingProvider,
  teaser,
  lastEmployment,
  lastEducation,
  hasBorder = false,
}: ProfileCardProps) => {
  return (
    <CardLink href={`/profile/${id}`} target="_blank">
      <Card
        sx={[
          {
            height: '100%',
            borderRadius: 2,
          },
          hasBorder
            ? {
                border: `1px solid`,
                borderColor: 'colors.water10',
              }
            : {},
        ]}
      >
        <CardHeader
          sx={{ padding: 6, gap: 4 }}
          action={<IconRightArrow fontSize={18} />}
          avatar={
            <Box sx={{ position: 'relative' }}>
              <Avatar
                src={avatarUrl ?? undefined}
                alt={getPersonName({ firstName, lastName, id })}
                variant="rounded"
              />
              {!availability && (
                <ProfileTag variant="caption">Unavailable</ProfileTag>
              )}
            </Box>
          }
          title={
            <Typography variant="title2">
              {getPersonNameMultiline({ firstName, lastName, id })}
            </Typography>
          }
          subheader={
            <ProfileParticipation
              items={tracks}
              trainingProvider={trainingProvider}
            />
          }
        />

        <CardContent sx={{ padding: 6 }}>
          {teaser || lastEmployment || lastEducation ? (
            <Stack spacing={6}>
              {teaser && (
                <Typography variant="body1" color="colors.grey">
                  {teaser}
                </Typography>
              )}

              <Stack spacing={4}>
                {lastEmployment && (
                  <ContentListItem
                    headline={lastEmployment.title}
                    subline={lastEmployment.companyName}
                    icon={<SuitcasePortfolio1 />}
                  />
                )}

                {lastEducation && (
                  <ContentListItem
                    headline={lastEducation.title}
                    subline={lastEducation.companyName}
                    icon={<GraduatePerson />}
                  />
                )}
              </Stack>
            </Stack>
          ) : (
            <EmptySection icon={false} text="Profile currently empty." />
          )}
        </CardContent>
      </Card>
    </CardLink>
  )
}
