import {
  Box,
  Card,
  CardContent,
  Divider as MuiDivider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, Fragment, useState } from 'react'

import { useGetContactedStudents } from '../../../../graphql/partner/useGetContactedStudents'
import { Search } from '../../../assets/icons'
import { colors } from '../../../theme/constants/colors'
import StudentCard from '../../molecules/StudentCard/StudentCard'
import Pagination from './Pagination'

export const ContactsPage = () => {
  const [page, setPage] = useState(0)
  const [input, setInput] = useState('')
  const maxResultsPerPage = 20

  const { students, totalPages, totalElements } = useGetContactedStudents({
    filter: { search: input.toLowerCase() },
    pagination: { page: page, size: maxResultsPerPage },
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  return (
    <Card>
      <CardContent sx={{ rowGap: 10 }}>
        <Typography variant="h5">Contacts</Typography>

        <TextField
          label="Search"
          value={input}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="20px" color={colors.granite} />
              </InputAdornment>
            ),
          }}
          sx={{ width: '100%' }}
        />

        <Stack spacing={6}>
          <MuiDivider light />

          {students.length ? (
            <>
              {students?.map(student => (
                <Fragment key={student.id}>
                  <StudentCard student={student} />
                  <MuiDivider light sx={{ mt: 10, mb: 10 }} />
                </Fragment>
              ))}
            </>
          ) : (
            <Box sx={{ display: 'grid', gap: 6, justifyItems: 'center' }}>
              <Typography variant="title2">No contacts</Typography>
              <Typography variant="body2">
                Every student you contact will show up here
              </Typography>
            </Box>
          )}
        </Stack>

        {!!students.length && (
          <Pagination
            totalPages={totalPages}
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            maxResultsPerPage={maxResultsPerPage}
          />
        )}
      </CardContent>
    </Card>
  )
}
