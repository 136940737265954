import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import update from 'immutability-helper'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useSetCapstoneProject } from '../../../../../graphql/capstone/setCapstoneProjectService'
import {
  CapstoneProject,
  MutationSetCapstoneProjectArgs,
} from '../../../../../graphql/graphqlTypes'
import { Github, Plus, Youtube } from '../../../../assets/icons'
import { useModalStore } from '../../../../stores/modalStore'
import { TagsSortable } from '../../../molecules/Tags'
import { FormProfileCapstoneImage } from './FormProfileCapstoneImage'
import { ProfileForm } from './ProfileForm'

export type CapstoneFormValues = MutationSetCapstoneProjectArgs & {
  tech?: string
}

export type FormProfileCapstoneProps = Omit<
  CapstoneFormValues,
  'imageId' | 'bootcampId'
> &
  Pick<CapstoneProject, 'image' | 'bootcamp'>

export const FormProfileCapstone = ({
  personId,
  bootcamp,
  name,
  description,
  appUrl,
  repositoryUrl,
  youtubeUrl,
  image,
  techStack,
  device,
}: FormProfileCapstoneProps) => {
  const setModal = useModalStore(state => state.setModal)

  const {
    control,
    handleSubmit,
    setValue,
    formState,
  } = useForm<CapstoneFormValues>({
    mode: 'onChange',
    defaultValues: {
      personId,
      bootcampId: bootcamp.id,
      name: name || '',
      description: description || '',
      appUrl: appUrl || '',
      repositoryUrl: repositoryUrl || '',
      youtubeUrl: youtubeUrl || '',
      imageId: image?.id || undefined,
      techStack: techStack?.length ? techStack : [],
      device,
    },
  })

  const { field: fieldImageId } = useController({
    name: 'imageId',
    control,
  })

  const { field: fieldDevice } = useController({
    name: 'device',
    control,
  })

  const { field: fieldName, fieldState: fieldNameState } = useController({
    name: 'name',
    control,
    rules: {
      required: true,
    },
  })

  const {
    field: fieldDescription,
    fieldState: fieldDescriptionState,
  } = useController({
    name: 'description',
    control,
    rules: {
      validate: value =>
        (value?.length || 0) <= 350 ||
        `(${value?.length || 0}/350 characters) Your text is too long`,
    },
  })

  const { field: fieldAppUrl, fieldState: fieldAppUrlState } = useController({
    name: 'appUrl',
    control,
  })

  const {
    field: fieldRepositoryUrl,
    fieldState: fieldRepositoryUrlState,
  } = useController({
    name: 'repositoryUrl',
    control,
  })

  const {
    field: fieldYoutubeUrl,
    fieldState: fieldYoutubeUrlState,
  } = useController({
    name: 'youtubeUrl',
    control,
  })

  const { field: fieldTech, fieldState: fieldTechState } = useController({
    name: 'tech',
    control,
    rules: {
      validate: value => {
        if (!value) return true
        if (fieldTechStack?.value?.includes(value.trim()))
          return 'Technology already set'
        return true
      },
    },
    defaultValue: '',
  })

  const { field: fieldTechStack } = useController({
    name: 'techStack',
    control,
  })

  const addTech = useCallback(() => {
    if (fieldTech.value && !fieldTechState.error) {
      setValue('techStack', [
        ...(fieldTechStack?.value || []),
        fieldTech.value.trim(),
      ])
      setValue('tech', '')
    }
  }, [fieldTech.value, fieldTechStack?.value, fieldTechState.error, setValue])

  const onMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (fieldTechStack.value) {
        setValue(
          'techStack',
          update(fieldTechStack.value, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, fieldTechStack.value[dragIndex]],
            ],
          })
        )
      }
    },
    [fieldTechStack.value, setValue]
  )

  const removeTech = useCallback(
    (key: string) => {
      setValue('techStack', fieldTechStack?.value?.filter(i => i !== key) || [])
    },
    [fieldTechStack.value, setValue]
  )

  const { setCapstoneProject, loading } = useSetCapstoneProject({
    onCompleted: () => {
      toast.success('Information updated')
      setModal(false)
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const submit = useCallback(
    (data: CapstoneFormValues) => {
      setCapstoneProject(data)
    },
    [setCapstoneProject]
  )

  const onCancel = useCallback(() => {
    setModal(false)
  }, [setModal])

  const deleteImage = useCallback(() => {
    setValue('imageId', null)
  }, [setValue])

  return (
    <ProfileForm
      title="Info"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
      loading={loading}
      formState={formState}
    >
      <FormProfileCapstoneImage
        setValue={setValue}
        image={fieldImageId.value ? image : null}
        device={fieldDevice.value}
        deleteImage={deleteImage}
      />

      <Box component="form" paddingBottom={8}>
        <Stack spacing={10} flexGrow={1}>
          <Stack spacing={4} flexGrow={1}>
            <TextField
              label="Title of your project"
              {...fieldName}
              error={!!fieldNameState.error}
              helperText={fieldNameState.error?.message}
            />

            <TextField
              multiline
              minRows={3}
              label="Description"
              {...fieldDescription}
              error={!!fieldDescriptionState.error}
              helperText={
                fieldDescriptionState.error?.message ||
                `(${fieldDescription?.value?.length || 0}/350 characters)`
              }
            />
          </Stack>

          <Stack spacing={4}>
            <TextField
              label="Git Repository (Url)"
              {...fieldRepositoryUrl}
              error={!!fieldRepositoryUrlState.error}
              helperText={fieldRepositoryUrlState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component={Github} fontSize={32} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="App (Url)"
              {...fieldAppUrl}
              error={!!fieldAppUrlState.error}
              helperText={fieldAppUrlState.error?.message}
            />

            <TextField
              label="YouTube (Url)"
              {...fieldYoutubeUrl}
              error={!!fieldYoutubeUrlState.error}
              helperText={fieldYoutubeUrlState.error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component={Youtube} fontSize={32} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack spacing={4}>
            <Typography variant="body2">
              Add technologies you used in your project.
            </Typography>

            <TextField
              label="Add technology"
              {...fieldTech}
              error={!!fieldTechState.error}
              helperText={fieldTechState.error?.message}
              onKeyUp={e => {
                if (e.code === 'Enter') {
                  addTech()
                }
              }}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      onClick={!fieldTechState.error ? addTech : undefined}
                    >
                      <Plus />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography variant="body2">
              Drag and drop or delete technologies.
            </Typography>

            <TagsSortable
              tags={fieldTechStack?.value || []}
              onMove={onMove}
              onDelete={removeTech}
            />
          </Stack>
        </Stack>
      </Box>
    </ProfileForm>
  )
}
