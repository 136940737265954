import { Button, Dialog } from '@mui/material'
import { useState } from 'react'

import { Plus } from '../../../assets/icons'
import { FormPartnerMember } from './FormPartnerMember'

export const AddPartnerMember = ({ partnerId }: { partnerId: string }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setModalOpen(true)} startIcon={<Plus />}>
        Add member
      </Button>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <FormPartnerMember
          partnerId={partnerId}
          onCancel={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      </Dialog>
    </>
  )
}
