import { Components } from '@mui/material'

import { colors } from '../constants/colors'
import { typography } from './typography'

export const MuiBottomNavigationAction: Components['MuiBottomNavigationAction'] = {
  styleOverrides: {
    root: {
      gap: 7,
      minWidth: 60,
      '.MuiBottomNavigationAction-label': {
        ...typography.menu,
        color: colors.granite,
        '&.Mui-selected': {
          ...typography.menu,
          color: colors.nemo,
        },
      },
      '> svg': {
        fontSize: 24,
      },
    },
  },
}
