import { gql, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import { Location } from '../graphqlTypes'

export const LIST_LOCATIONS = gql`
  query ListLocations {
    listLocations {
      id
      name
    }
  }
`

export const useListLocations = () => {
  const query = useQuery<{ listLocations: Location[] }>(LIST_LOCATIONS)
  const locations = query.data?.listLocations || []
  const completed = query.called && !query.loading && !query.error
  return { ...query, locations: sortBy(locations, 'name'), completed }
}
