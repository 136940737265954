import { Button, styled } from '@mui/material'

import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { Search } from '../icons'
import { useListPersonTypes } from '../../graphql/person/useListPersonTypes'
import Select from './Select'
import { useDebounce } from '../../common/hooks/useDebounce'
import { PersonTypeEnum } from '../../graphql/graphqlTypes'

type SearchBarProps = {
  placeholder: string
  onInputChange: (arg0: string) => void
  onTypeChange: (arg0?: PersonTypeEnum) => void
  onParticipatesBootcampChange: (arg0?: boolean) => void
}

const SearchBar: FC<SearchBarProps> = ({
  placeholder,
  onInputChange,
  onTypeChange,
  onParticipatesBootcampChange,
}) => {
  const [value, setValue] = useState('')
  const debouncedSearch = useDebounce(onInputChange, 200)

  const { personTypeSelectOptions } = useListPersonTypes()

  useEffect(() => debouncedSearch(value), [value, debouncedSearch])

  const handleChange = (event: { target: HTMLInputElement }) => {
    setValue(event.target.value)
  }

  const handleClearSearchInput = () => {
    setValue('')
    onInputChange('')
  }

  const handlePersonTypeChange: ChangeEventHandler<HTMLSelectElement> = event => {
    onTypeChange(
      !event.target.value ? undefined : (event.target.value as PersonTypeEnum)
    )
  }
  const handleParticipatesBootcampChange: ChangeEventHandler<HTMLSelectElement> = event => {
    onParticipatesBootcampChange(
      event.target.value ? event.target.value === 'true' : undefined
    )
  }

  return (
    <WrapperContainer>
      <SearchWrapper>
        <label htmlFor="search" aria-label="Search">
          <Search className="search" />
        </label>
        <SearchInput
          id="search"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        {value && (
          <ButtonStyled
            variant="text"
            onClick={handleClearSearchInput}
            aria-label="Clear search input"
          >
            Clear
          </ButtonStyled>
        )}
      </SearchWrapper>
      <Select
        srOnly
        labelText="Is in bootcamp"
        name="participatesBootcamp"
        onChange={handleParticipatesBootcampChange}
        options={[
          { id: '', name: 'All' },
          { id: 'true', name: 'In Bootcamp' },
          { id: 'false', name: 'No Bootcamp' },
        ]}
      />
      <Select
        srOnly
        labelText="Person Type"
        name="personType"
        onChange={handlePersonTypeChange}
        options={[{ id: '', name: 'All' }, ...personTypeSelectOptions]}
      />
    </WrapperContainer>
  )
}

export default SearchBar

const WrapperContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: var(--spacing-xs);
  height: min-content;
`

const SearchWrapper = styled('div')`
  position: relative;
  display: flex;
  gap: var(--spacing-xs);

  .search {
    margin: 0 10px;
    font-size: var(--font-l);
  }

  label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const SearchInput = styled('input')`
  width: 100%;
  border-radius: 5px;
  border: solid 1px var(--secondaryTwo);
  padding: var(--spacing-m) var(--spacing-l);
  padding-left: 48px;
  padding-right: 52px;
`

const ButtonStyled = styled(Button)`
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`
