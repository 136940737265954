import { IconButton, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { Person } from '../../../../graphql/graphqlTypes'
import { refetchPartner } from '../../../../graphql/partner/usePartnerDetails'
import { useDeletePerson } from '../../../../graphql/person/useDeletePerson'
import { getPersonName } from '../../../../service/profile/person-service'
import { TrashDeleteBin } from '../../../assets/icons'
import { ConfirmModal } from '../../molecules/ConfirmModal'

export const DeletePartnerMember = ({ member }: { member: Person }) => {
  const { deletePerson, loading: deleteLoading } = useDeletePerson({
    onCompleted: () => {
      toast.success('Member deleted')
      refetchPartner()
    },
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  return (
    <ConfirmModal
      title="Delete member?"
      text={
        <>
          Are you sure you want to delete{' '}
          <Typography variant="body2Strong" component="span">
            {getPersonName(member)}
          </Typography>
        </>
      }
      onConfirm={() => deletePerson(member.id)}
      loading={deleteLoading}
    >
      <IconButton component="div" color="inherit">
        <TrashDeleteBin />
      </IconButton>
    </ConfirmModal>
  )
}
