import { gql } from '@apollo/client'

export const personFragment = gql`
  fragment Person on Person {
    id
    isAdmin
    consentGiven
    personType
    firstName
    lastName
    trainingProvider
    location
    shortDescription
    teaser
    gitHubUrl
    videoUrl
    avatar {
      id
      urls {
        s
        m
        l
        xl
      }
    }
    languageSkills {
      id
      language
      level
    }
    participates {
      id
      role {
        id
        name
      }
      bootcamp {
        id
        startDate
        endDate
        track {
          id
          name
          hasCapstone
        }
      }
      capstoneProject {
        id
        name
        description
        appUrl
        repositoryUrl
        youtubeUrl
        techStack
        bootcamp {
          id
        }
        person {
          id
        }
        device
        image {
          id
          urls {
            s
            m
            l
            xl
          }
        }
      }
    }
    jobExperiences {
      id
      companyName
      title
      description
      startDate
      endDate
      type
      location
      employmentType
    }
    jobPreferences {
      id
      available
      fullTime
      partTime
      freelance
      internship
      flexible
      remote
      geolocationPreferences {
        name
        placeId
      }
    }
    partner {
      id
      name
    }
    skills
  }
`
