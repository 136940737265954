import InvitePersonForm from './components/InvitePersonForm'
import PersonList from './components/PersonList'
import { DeprecatedGlobalStyles } from '../../GlobalStyles'
import { styled } from '@mui/material'
import React, { useState } from 'react'

import { BatchInviteStudentsForm } from './BatchInviteStudentsForm'
import { colors } from '../../common/theme/constants/colors'
import { useFeature } from '@growthbook/growthbook-react'
import BetaBadge from '../../common/components/atoms/BetaBadge/BetaBadge'

export default function InviteUsers() {
  const [activeSection, setActiveSection] = useState('single-invite')
  const showBatchInvite = useFeature('show-batch-students-invite').on
  return (
    <>
      <DeprecatedGlobalStyles />
      <Main>
        <Options>
          <Option
            onClick={() => setActiveSection('single-invite')}
            active={activeSection === 'single-invite'}
          >
            Invite single user
          </Option>
          {showBatchInvite && (
            <Option
              onClick={() => setActiveSection('batch-invite')}
              active={activeSection === 'batch-invite'}
            >
              CSV Upload <BetaBadge />
            </Option>
          )}
        </Options>
        <Content>
          {activeSection === 'single-invite' ? (
            <InvitePersonForm />
          ) : (
            <BatchInviteStudentsForm />
          )}
        </Content>
        <h1>Users</h1>
        <PersonList />
      </Main>
    </>
  )
}

const Main = styled('main')`
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xl);
  overflow-y: scroll;

  h1 {
    font-size: var(--font-l);
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
  }

  h2 {
    margin-top: 48px;
    text-align: center;
  }
`

const Options = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

const Option = styled('h1')<{ active: boolean }>`
  font-size: var(--font-l);
  margin: 0 16px;
  cursor: pointer;
  color: ${props => props.active && colors.nemo};
`

const Content = styled('div')`
  text-align: center;
`
