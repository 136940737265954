import { FC, InputHTMLAttributes } from 'react'
import { styled } from '@mui/material'
import Label from './Label'

type InputWithLabelProps = InputHTMLAttributes<HTMLInputElement> & {
  srOnly?: boolean
  labelText: string
}

const InputWithLabel: FC<InputWithLabelProps> = ({
  srOnly,
  labelText,
  ...props
}) => {
  return (
    <LabelStyled srOnly={srOnly} labelText={labelText}>
      <Input {...props} />
    </LabelStyled>
  )
}

export default InputWithLabel

const Input = styled('input')`
  border: 1px solid var(--secondaryTwo);
  width: 400px;
  height: auto;
  padding: var(--spacing-m) var(--spacing-l);
  border-radius: var(--spacing-xs);
`

const LabelStyled = styled(Label)`
  display: grid;
  grid-gap: var(--spacing-s);
  font-weight: 600;
`
