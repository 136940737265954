import { styled } from '@mui/material'

export type InputValidationMessagesProps = {
  formValidation: {
    [key: string]: string
  }
}

export default function InputValidationMessages({
  formValidation,
}: InputValidationMessagesProps) {
  const errorTypes = Object.keys(formValidation)

  return (
    <Wrapper>
      {errorTypes.map(
        error =>
          formValidation[error] && (
            <Criterion key={error}> {formValidation[error]}</Criterion>
          )
      )}
    </Wrapper>
  )
}

const Criterion = styled('li')`
  list-style: none;
  padding: 5px 0;
  color: var(--accent);
`

const Wrapper = styled('ul')`
  margin: 0;
  padding: 0;
`
