import { createContext, useContext } from 'react'

import type { CognitoUser } from '../../types/auth.d'

type AuthContextType = {
  loginUser: (username: string, password: string) => Promise<void>
  logoutUser: () => Promise<void>
  completeSetPassword: (password: string) => Promise<void>
  cognitoUser?: CognitoUser
  isAuthenticated: boolean
  isAdmin: boolean
  isNewPasswordRequired: boolean
  isAuthPending: boolean
  authenticatedUserId?: string
}

export const AuthContext = createContext<AuthContextType>({
  completeSetPassword(): Promise<void> {
    return Promise.resolve(undefined)
  },
  loginUser(): Promise<void> {
    return Promise.resolve(undefined)
  },
  logoutUser(): Promise<void> {
    return Promise.resolve()
  },
  isAuthenticated: false,
  isAdmin: false,
  isNewPasswordRequired: false,
  isAuthPending: false,
})

export const useAuth = () => useContext(AuthContext)
