import { styled } from '@mui/material'

export const LogGrid = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
  gridAutoRows: '1fr',
  columnGap: 20,
  rowGap: 48,

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '32px auto 1fr',
    alignItems: 'center',
  },

  '> *': {
    position: 'relative',
  },

  '::before': {
    content: '""',
    position: 'absolute',
    top: 16,
    left: 15,
    width: 2,
    height: 'calc(100% - 32px)',
    backgroundColor: theme.palette.colors.water10,
  },
}))
