import { styled, Typography } from '@mui/material'
import { ReactNode } from 'react'

import { EyeCross } from '../../../assets/icons'

const EmptySectionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.colors.water,
  borderRadius: 4,
  paddingTop: 24,
  paddingRight: 40,
  paddingBottom: 24,
  paddingLeft: 40,
  textAlign: 'center',
  gap: 8,
}))

export interface EmptySectionProps {
  icon?: ReactNode
  text?: string
}

export const EmptySection = ({
  icon = <EyeCross fontSize={20} />,
  text = 'This section is currently empty and won’t be shown.',
}: EmptySectionProps) => (
  <EmptySectionContainer>
    {icon}
    <Typography variant="caption" color="colors.grey" maxWidth="16em">
      {text}
    </Typography>
  </EmptySectionContainer>
)
