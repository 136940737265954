import {
  Box,
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'

import { BootcampCard } from '../common/components/organisims/Bootcamp/BootcampCard'
import { CompanyTeasers } from '../common/components/organisims/CompanyTeasers'
import { ProgressCard } from '../common/components/organisims/ProgressCard/ProgressCard'
import { ReactComponent as IconFish } from '../common/theme/icons/iconFish.svg'
import { useGetParticipatingBootcamps } from '../graphql/bootcamp/getParticipatingBootcamps'
import { useUser } from '../graphql/person/useUser'

const Main = styled('main')(({ theme }) => ({
  display: 'grid',

  [theme.breakpoints.down('lg')]: {
    '.MuiCard-root': {
      borderRadius: 0,
    },
  },

  [theme.breakpoints.up('lg')]: {
    gap: 20,
  },
}))

const WelcomeCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.colors.almostGranite,
  color: theme.palette.colors.foam,
  position: 'relative',
}))

const BackgroundDecoration = styled(IconFish)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  transform: `translate(-5%, 38%)`,
  width: 120,
  height: 120,
})

export const PageDashboardStudent = () => {
  const { person } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const { bootcamps, error } = useGetParticipatingBootcamps(person?.id)

  if (error) {
    console.error(error)
    toast.error(`${error} 🙀`)
  }

  return (
    <Main data-cy-post-login="true" data-test="main">
      <WelcomeCard>
        <CardContent>
          <BackgroundDecoration />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 6, md: 10 }}
            sx={{ position: 'relative', marginBottom: { xs: 20, md: 0 } }}
          >
            <Typography variant="h5" sx={{ marginBottom: { md: 19 } }}>
              Welcome to the neue fische Talent App
            </Typography>
            <Box>
              <Typography variant="body2Strong" mb={6}>
                This is where our partnering companies come to fish for IT
                talents.
              </Typography>
              <Typography color="colors.lightGrey">
                Make sure to completely fill in your profile and capstone
                project information in order to present yourself and your work
                to your potential future employers.
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </WelcomeCard>

      {person?.id && <ProgressCard personId={person.id} />}

      {bootcamps?.map((bootcamp, index) => {
        return (
          <BootcampCard
            bootcamp={bootcamp}
            personId={person?.id}
            key={bootcamp.id}
            expandedDefault={index === 0}
          />
        )
      })}

      <CompanyTeasers />
    </Main>
  )
}
