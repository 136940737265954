import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    size: 'small',
  },
  styleOverrides: {
    root: {
      lineHeight: 1,
      svg: {
        margin: 0,
      },

      // default colors
      color: colors.granite,
      '&:hover, &:active': {
        background: colors.water,
        color: colors.granite,
      },
      '&.Mui-disabled': {
        color: colors.lightGrey,
      },
      '.MuiCircularProgress-root': {
        color: colors.granite,
      },
    },
    colorInherit: {
      background: colors.water,
      color: colors.granite,
      '&:hover, &:active': {
        background: colors.water10,
        color: colors.granite,
      },
      '&.Mui-disabled': {
        background: colors.water,
        color: colors.lightGrey,
      },
      '.MuiCircularProgress-root': {
        color: colors.nemo,
      },
    },
    colorPrimary: {
      background: colors.nemo,
      color: colors.foam,
      '&:hover, &:active': {
        background: colors.darkNemo,
        color: colors.foam,
      },
      '&.Mui-disabled': {
        background: colors.nemo,
        color: colors.lightNemo,
      },
      '.MuiCircularProgress-root': {
        color: colors.foam,
      },
    },
    colorSecondary: {
      background: colors.granite,
      color: colors.foam,
      '&:hover, &:active': {
        background: colors.granite,
        color: colors.nemo,
      },
      '&.Mui-disabled': {
        background: colors.almostGranite,
        color: colors.foam,
      },
      '.MuiCircularProgress-root': {
        color: colors.foam,
      },
    },
    sizeTiny: {
      fontSize: 16,
      padding: 6,
    },
    sizeSmall: {
      fontSize: 20,
      padding: 8,
    },
    sizeMedium: {
      fontSize: 24,
      padding: 12,
    },
    sizeLarge: {
      fontSize: 28,
      padding: 16,
    },
  },
}
