import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { GET_BOOTCAMP_DETAILS } from '../bootcamp/getBootcampDetailsService'
import { BootcampParticipation, Team } from '../graphqlTypes'
import {
  ADD_TEAM,
  ADD_TO_TEAM,
  DELETE_TEAM,
  REMOVE_FROM_TEAM,
  SET_NAME,
} from './teamQueries'

const refetchBootcampOption = {
  refetchQueries: [GET_BOOTCAMP_DETAILS],
}

type StoredTeam = Team & { changed?: boolean }

const showFailureToast = (error: { message: string }) => {
  toast.error(`${error.message} 🙀`)
}

const useTeamManagement = (teams: Team[]) => {
  const [storedTeams, setStoredTeams] = useState<StoredTeam[]>(teams)

  useEffect(() => {
    setStoredTeams(teams)
  }, [teams])

  const [setTeamName] = useMutation(SET_NAME)
  const [saveNewTeam] = useMutation(ADD_TEAM)
  const [executeDeletion] = useMutation(DELETE_TEAM, refetchBootcampOption)
  const [saveTeamMember] = useMutation(ADD_TO_TEAM, refetchBootcampOption)
  const [removeMemberFromTeam] = useMutation(
    REMOVE_FROM_TEAM,
    refetchBootcampOption
  )

  const updateTeamName = (id: string, name: string) => {
    const cloned = [...storedTeams]
    const index = cloned.findIndex(t => t.id === id)
    if (index === -1) {
      return
    }
    const item = { ...cloned[index] }
    item.name = name
    item.changed = true
    cloned[index] = item
    setStoredTeams(cloned)
  }

  const addTeam = (bootcampId: string) => {
    saveNewTeam({ variables: { bootcampId } })
      .then(({ data }) => {
        const newTeams = [...storedTeams, data.addTeam]
        setStoredTeams(newTeams)
      })
      .catch(showFailureToast)
  }

  const save = () => {
    storedTeams
      .filter(t => t.changed)
      .forEach(t => {
        setTeamName({ variables: { teamId: t.id, name: t.name } })
          .then(({ data }) => {
            const cloned = [...storedTeams]
            const index = cloned.findIndex(team => t.id === team.id)
            cloned[index] = data.setTeamName
            setStoredTeams(cloned)
            toast.success(`Team name saved`)
          })
          .catch(showFailureToast)
      })
  }

  const deleteTeam = (teamId: string) => {
    return executeDeletion({ variables: { teamId } }).then(() => {
      toast.success(`Team deleted`)
      const newTeams = storedTeams.filter(t => t.id !== teamId)
      setStoredTeams(newTeams)
    })
  }

  const addToTeam = (participant: BootcampParticipation, team: Team) => {
    return saveTeamMember({
      variables: {
        teamId: team.id,
        personId: participant.person.id,
        bootcampId: participant.bootcamp.id,
      },
    })
      .then(() => {
        toast.success(`Added to team`)
      })
      .catch(showFailureToast)
  }

  const removeFromTeam = (
    teamId: string,
    personId: string,
    bootcampId: string
  ) => {
    return removeMemberFromTeam({
      variables: {
        teamId,
        personId,
        bootcampId,
      },
    })
      .then(() => {
        toast.success(`Remove from team`)
      })
      .catch(showFailureToast)
  }

  return {
    teams: storedTeams,
    updateTeamName,
    save,
    addTeam,
    deleteTeam,
    addToTeam,
    removeFromTeam,
  }
}

export default useTeamManagement
