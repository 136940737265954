import {
  JobExperience,
  JobExperienceType,
} from '../../../../../graphql/graphqlTypes'
import { findLatestExperienceOfType } from '../../../../../service/job-experience-service'
import { GraduatePerson, SuitcasePortfolio1 } from '../../../../assets/icons'
import { ContentListItem } from '../../../molecules/ContentList'

export const ProfileCurrently = ({
  jobExperiences,
}: {
  jobExperiences: JobExperience[]
}) => {
  const latestEmployment = findLatestExperienceOfType(
    JobExperienceType.EMPLOYMENT,
    jobExperiences
  )
  const latestEducation = findLatestExperienceOfType(
    JobExperienceType.EDUCATION,
    jobExperiences
  )

  return (
    <>
      {latestEmployment && (
        <ContentListItem
          headline={latestEmployment.title}
          subline={latestEmployment.companyName}
          icon={<SuitcasePortfolio1 />}
        />
      )}

      {latestEducation && (
        <ContentListItem
          headline={latestEducation.title}
          subline={latestEducation.companyName}
          icon={<GraduatePerson />}
        />
      )}
    </>
  )
}
