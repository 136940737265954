import { Box, Stack, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'

import { JobExperienceType } from '../../../../../graphql/graphqlTypes'
import { useGetPerson } from '../../../../../graphql/person/useGetPerson'
import { Shape04 } from '../../../../assets/svgs'
import { useModalStore } from '../../../../stores/modalStore'
import { EmptySection } from '../../../molecules/EmptySection'
import { FormProfileCareer } from '../Forms/FormProfileCareer'
import { ProfileForm } from '../Forms/ProfileForm'
import { ProfileCareerAddItem } from './ProfileCareerAddItem'
import { ProfileCareerItem } from './ProfileCareerItem'

export interface ProfileCareerProps {
  personId: string
  isOwnProfile?: boolean
}

export const ProfileCareerModal = ({
  personId,
  isOwnProfile = false,
}: ProfileCareerProps) => {
  const {
    person: { jobExperiences },
  } = useGetPerson({
    variables: {
      id: personId,
    },
  })
  const setModal = useModalStore(state => state.setModal)

  const employments = useMemo(
    () => jobExperiences.filter(i => i.type === 'employment'),
    [jobExperiences]
  )

  const educations = useMemo(
    () => jobExperiences.filter(i => i.type === 'education'),
    [jobExperiences]
  )

  const onCancel = useCallback(() => {
    setModal(false)
  }, [setModal])

  if (!isOwnProfile || !personId) {
    return null
  }

  return (
    <ProfileForm title="Career" onCancel={onCancel} hideCardActions>
      <Stack spacing={10}>
        <ProfileCareerAddItem
          type={JobExperienceType.EMPLOYMENT}
          buttonText="Add new entry"
          personId={personId}
        />

        {employments.length <= 0 && educations.length <= 0 ? (
          <EmptySection
            icon={false}
            text="Start adding your professional experience and education."
          />
        ) : (
          <Stack spacing={4}>
            <Typography color="colors.grey">Professional</Typography>

            {employments.length ? (
              employments.map(job => (
                <Box
                  sx={{
                    display: 'grid',
                    gap: 5,
                    gridTemplateColumns: 'auto 1fr',
                  }}
                  key={job.id}
                >
                  <Box component={Shape04} fontSize={10} marginTop={1} />

                  <ProfileCareerItem
                    {...job}
                    isOwnProfile={isOwnProfile}
                    editModal={
                      <FormProfileCareer {...job} personId={personId} />
                    }
                  />
                </Box>
              ))
            ) : (
              <EmptySection icon={false} text="No employment entry yet" />
            )}

            <Typography color="colors.grey">Education</Typography>

            {educations.length ? (
              educations.map(job => (
                <Box
                  sx={{
                    display: 'grid',
                    gap: 5,
                    gridTemplateColumns: 'auto 1fr',
                  }}
                  key={job.id}
                >
                  <Box component={Shape04} fontSize={10} marginTop={1} />

                  <ProfileCareerItem
                    {...job}
                    isOwnProfile={isOwnProfile}
                    editModal={
                      <FormProfileCareer {...job} personId={personId} />
                    }
                  />
                </Box>
              ))
            ) : (
              <EmptySection icon={false} text="No education entry yet" />
            )}
          </Stack>
        )}
      </Stack>
    </ProfileForm>
  )
}
