import { gql, useMutation } from '@apollo/client'
import { BaseMutationOptions } from '@apollo/client/react/types/types'

const RE_INVITE_PERSON = gql`
  mutation ReInviteUser($id: String!) {
    reinvitePerson(id: $id) {
      id
      email
      status
    }
  }
`

export const useReInvitePerson = (options: BaseMutationOptions) => {
  const [reinvite, result] = useMutation(RE_INVITE_PERSON, options)

  const reinvitePerson = (id: string) =>
    reinvite({
      variables: {
        id,
      },
    })
  return { reinvitePerson, ...result }
}
