import {
  Avatar,
  CardActionArea,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'

import type { AlgoliaPartnerHit } from '../../../../types/algolia.d'

const PartnerSearchHitContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  columnGap: 16,
  rowGap: 32,
  paddingRight: 16,

  [theme.breakpoints.down('lg')]: {
    '> time': {
      order: 2,
      columnSpan: 'all',
    },
  },
}))

export const PartnerSearchHit = ({
  objectID,
  name,
  industry,
  imageUrl,
  created,
  disabled,
}: AlgoliaPartnerHit) => {
  return (
    <CardActionArea href={`/company/${objectID}`} sx={{ borderRadius: 1 }}>
      <PartnerSearchHitContainer>
        <Avatar variant="rounded" src={imageUrl || undefined} />
        <Stack spacing={0.5}>
          <Typography variant="body2Strong">{name}</Typography>
          {industry && (
            <Typography variant="caption" color="colors.grey">
              {industry}
            </Typography>
          )}
        </Stack>
        <Stack spacing={0.5} sx={{ alignItems: 'end' }}>
          <Typography component="time" variant="body2" color="colors.grey">
            {format(new Date(created), 'P')}
          </Typography>
          <Typography variant="body2" color="colors.grey">
            {disabled && '(disabled)'}
          </Typography>
        </Stack>
      </PartnerSearchHitContainer>
    </CardActionArea>
  )
}
