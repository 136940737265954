import { Divider, Stack, Typography } from '@mui/material'
import { Fragment, useCallback } from 'react'
import { toast } from 'react-toastify'

import { useGetAllLanguages } from '../../../../graphql/language/useGetAllLanguages'
import { useUser } from '../../../../graphql/person/useUser'
import { useModalStore } from '../../../stores/modalStore'
import { EmptySection } from '../../molecules/EmptySection'
import { ProfileForm } from './Forms/ProfileForm'
import { ModalProfileLanguageAddItem } from './ModalProfileLanguageAddItem'
import { ModalProfileLanguageItem } from './ModalProfileLanguageItem'

export interface Props {
  personId?: string
  isOwnProfile?: boolean
}

export const ModalProfileLanguages = ({
  personId,
  isOwnProfile = false,
}: Props) => {
  const { person: user } = useUser({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
  })

  const { allLanguages } = useGetAllLanguages()

  const setModal = useModalStore(state => state.setModal)

  const onCancel = useCallback(() => {
    setModal(false)
  }, [setModal])

  if (!isOwnProfile || !personId || !user) {
    return null
  }

  return (
    <ProfileForm title="Languages" onCancel={onCancel} hideCardActions>
      <ModalProfileLanguageAddItem
        personId={personId}
        languageSkills={user.languageSkills}
        allLanguages={allLanguages}
      />

      <Typography sx={{ mt: -2.5, mb: 6, variant: 'body2' }}>
        Languages will be sorted automatically by level of proficiency.
      </Typography>

      <Divider light sx={{ mb: 4 }} />

      {!user.languageSkills.length ? (
        <EmptySection icon={false} text="Start adding your languages." />
      ) : (
        <Stack spacing={4}>
          {user.languageSkills.map(language => (
            <Fragment key={language.id}>
              <ModalProfileLanguageItem
                personId={personId}
                languageSkills={user.languageSkills}
                languageSkill={language}
                allLanguages={allLanguages}
              />
              <Divider light />
            </Fragment>
          ))}
        </Stack>
      )}
    </ProfileForm>
  )
}
