/**
 Component:
 CardLink

 Usage:
 This component should be used for consistent styling of the visual
 effect the CardHeader has on link interaction.
 The CardHeader should have an action icon matching the typo of the title
 and will probably have an avatar.

 Example props:
 action={
    <Typography variant="body2Strong">
      <IconRightArrow />
    </Typography>
  }
 avatar={
    <Box sx={{ position: 'relative' }}>
      <Avatar
        src={avatarUrl ?? undefined}
        alt={getPersonName({ firstName, lastName, id })}
        variant="rounded-small"
      />
    </Box>
  }
 title={
    <Typography variant="body2Strong">
      {getPersonName({ firstName, lastName, id })}
    </Typography>
  }
 */
import { styled } from '@mui/material'

export const CardLink = styled('a')(({ theme }) => ({
  '.MuiAvatar-root > *': {
    transition: theme.transitions.create('transform'),
  },

  '.MuiCardHeader-content': {
    '.MuiTypography-title2, .MuiTypography-body2Strong': {
      transition: theme.transitions.create('color'),
    },
  },

  '.MuiCardHeader-action': {
    alignSelf: 'inherit',
    margin: 0,
    opacity: 0,
    transform: 'translateX(-50%)',
    transition: theme.transitions.create(['opacity', 'transform']),

    svg: {
      color: theme.palette.colors.nemo,
      width: '1.6em',
      height: '1.6em',
      margin: '0.8em 0.3em',
    },
  },

  ':hover, :focus-within': {
    '.MuiAvatar-root > *': {
      transform: 'scale(1.1)',
    },

    '.MuiCardHeader-content': {
      '.MuiTypography-root:first-child': {
        color: theme.palette.colors.nemo,
      },
    },

    '.MuiCardHeader-action': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}))
