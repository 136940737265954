import { styled, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'

import { BrandLogo } from '../../atoms/BrandLogo'
import { TopNavigation } from '../TopNavigation'

const HeaderContainer = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  backgroundColor: theme.palette.colors.foam,

  [theme.breakpoints.up('md')]: {
    paddingRight: 40,
    paddingLeft: 40,
  },
}))

const LogoLink = styled(Link)({
  color: 'inherit',
})

export const Header = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <HeaderContainer>
      <LogoLink to="/">
        <BrandLogo fontSize={32} type={isMobile ? 'icon' : 'withText'} />
      </LogoLink>

      <TopNavigation />
    </HeaderContainer>
  )
}
