import type {} from '@mui/lab/themeAugmentation'
import { Components } from '@mui/material'

import { MuiAutocomplete } from './MuiAutocomplete'
import { MuiAvatar } from './MuiAvatar'
import { MuiBackdrop } from './MuiBackdrop'
import { MuiBottomNavigation } from './MuiBottomNavigation'
import { MuiBottomNavigationAction } from './MuiBottomNavigationAction'
import { MuiButton } from './MuiButton'
import { MuiButtonBase } from './MuiButtonBase'
import { MuiButtonGroup } from './MuiButtonGroup'
import { MuiCard } from './MuiCard'
import { MuiCardActions } from './MuiCardActions'
import { MuiCardContent } from './MuiCardContent'
import { MuiCardHeader } from './MuiCardHeader'
import { MuiCheckbox } from './MuiCheckbox'
import { MuiChip } from './MuiChip'
import { MuiContainer } from './MuiContainer'
import { MuiDialog } from './MuiDialog'
import { MuiDialogActions } from './MuiDialogActions'
import { MuiDialogContent } from './MuiDialogContent'
import { MuiDialogTitle } from './MuiDialogTitle'
import { MuiDivider } from './MuiDivider'
import { MuiDrawer } from './MuiDrawer'
import { MuiFilledInput } from './MuiFilledInput'
import { MuiFormControlLabel } from './MuiFormControlLabel'
import { MuiFormHelperText } from './MuiFormHelperText'
import { MuiFormLabel } from './MuiFormLabel'
import { MuiGrid } from './MuiGrid'
import { MuiIconButton } from './MuiIconButton'
import { MuiInputLabel } from './MuiInputLabel'
import { MuiMenu } from './MuiMenu'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiPaginationItem } from './MuiPaginationItem'
import { MuiPaper } from './MuiPaper'
import { MuiPopover } from './MuiPopover'
import { MuiSelect } from './MuiSelect'
import { MuiSlider } from './MuiSlider'
import { MuiSpeedDial } from './MuiSpeedDial'
import { MuiSpeedDialAction } from './MuiSpeedDialAction'
import { MuiSwitch } from './MuiSwitch'
import { MuiTab } from './MuiTab'
import { MuiTabs } from './MuiTabs'
import { MuiTextField } from './MuiTextField'
import { MuiToggleButton } from './MuiToggleButton'
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup'
import { MuiTooltip } from './MuiTooltip'
import { MuiTypography } from './MuiTypography'

export const components: Partial<Components> = {
  MuiAutocomplete,
  MuiAvatar,
  MuiBackdrop,
  MuiBottomNavigation,
  MuiBottomNavigationAction,
  MuiButton,
  MuiButtonBase,
  MuiButtonGroup,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiDivider,
  MuiDrawer,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiGrid,
  MuiIconButton,
  MuiInputLabel,
  MuiLoadingButton: MuiButton,
  MuiMenu,
  MuiMenuItem,
  MuiPaginationItem,
  MuiPaper,
  MuiPopover,
  MuiSelect,
  MuiSlider,
  MuiSpeedDial,
  MuiSpeedDialAction,
  MuiSwitch,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,
  MuiTypography,
}
