import { Navigate, Outlet } from 'react-router-dom'

import { useUser } from '../../../graphql/person/useUser'
import {
  checkHasAccess,
  mapToAuthorities,
} from '../../../service/auth/role-service'

export const RequirePermissions = ({ roles }: { roles: string[] }) => {
  const { person } = useUser()
  if (!person) {
    return null
  }
  const givenAuthorities = mapToAuthorities(person)
  const hasAccess = checkHasAccess(roles, givenAuthorities)

  if (hasAccess) {
    return <Outlet />
  }

  return <Navigate to="/login" />
}
