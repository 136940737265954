import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../../contexts/AuthContext'
import { colors } from '../../../theme/constants/colors'
import { BrandLogo } from '../../atoms/BrandLogo'

type FormValues = {
  email: string
  password: string
  keepLoggedIn: boolean
}

export const LoginForm = ({ onForget }: { onForget: () => void }) => {
  const { loginUser } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { email: '', password: '', keepLoggedIn: false },
  })

  const { field: fieldEmail, fieldState: fieldEmailState } = useController({
    name: 'email',
    control,
    rules: {
      required: true,
    },
  })

  const {
    field: fieldPassword,
    fieldState: fieldPasswordState,
  } = useController({
    name: 'password',
    control,
    rules: {
      required: true,
    },
  })

  const submit = useCallback(
    (data: FormValues) => {
      setLoading(true)
      loginUser(data.email.trim(), data.password.trim())
        .then(() => navigate('/'))
        .catch(error => {
          toast.error(error.message)
          setLoading(false)
        })
    },
    [loginUser, navigate]
  )

  return (
    <Stack spacing={8} alignItems={{ xs: 'center', lg: 'start' }} width="100%">
      <BrandLogo type="icon" fontSize={40} color={colors.nemo} />
      <Typography variant="h5" component="h1">
        Welcome back!
      </Typography>
      <Typography textAlign={{ xs: 'center', lg: 'left' }}>
        Login to access all features of the neue fische Talent App.
      </Typography>

      <Stack
        spacing={10}
        component="form"
        onSubmit={handleSubmit(submit)}
        width="100%"
      >
        <Stack spacing={4}>
          <TextField
            label="E-Mail"
            {...fieldEmail}
            error={!!fieldEmailState.error}
            helperText={fieldEmailState.error?.message}
            sx={{ width: '100%' }}
            data-test="username"
          />
          <TextField
            label="Password"
            type="password"
            {...fieldPassword}
            error={!!fieldPasswordState.error}
            helperText={fieldPasswordState.error?.message}
            sx={{ width: '100%' }}
            data-test="password"
          />
        </Stack>

        {/* <FormControlLabel
          control={
            <Checkbox
              {...fieldKeepLoggedIn}
              checked={fieldKeepLoggedIn.value}
            />
          }
          label="Keep me logged in"
        /> */}

        <Stack spacing={4} direction={{ xs: 'column', lg: 'row' }}>
          <LoadingButton
            type="submit"
            loading={loading}
            sx={theme => ({
              [theme.breakpoints.up('lg')]: {
                flexGrow: 1,
              },
            })}
            data-test="login"
          >
            Login
          </LoadingButton>

          <Button variant="text" disabled={loading} onClick={onForget}>
            Forgot password?
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
