import { PersonTypeEnum } from '../graphql/graphqlTypes'

export const getPersonTypeName = (personType: PersonTypeEnum) => {
  switch (personType) {
    case PersonTypeEnum.STUDENT:
      return 'Student'
    case PersonTypeEnum.PARTNER_MEMBER:
      return 'Partner Member'
    case PersonTypeEnum.COACH:
      return 'Coach'
    case PersonTypeEnum.STUDENT_SERVICE:
      return 'Student Service Manager'
    case PersonTypeEnum.PARTNER_MANAGEMENT:
      return 'Partner Manager'
    default:
      throw new Error('Unknown person type: ' + personType)
  }
}

export const getPersonTypeSelectOption = (personType: PersonTypeEnum) => {
  return { name: getPersonTypeName(personType), id: personType }
}
