import { CircularProgress, Stack, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  MutationUpdatePartnerArgs,
  Partner,
} from '../../../../graphql/graphqlTypes'
import usePartnerDetails from '../../../../graphql/partner/usePartnerDetails'
import useUpdatePartner from '../../../../graphql/partner/useUpdatePartner'
import { PartnerFormProfileImage } from '../Profile/Forms/PartnerFormProfileAvatar'
import { PartnerFormProfileBanner } from '../Profile/Forms/PartnerFormProfileBanner'
import { ProfileForm } from '../Profile/Forms/ProfileForm'

type FormValues = Pick<
  MutationUpdatePartnerArgs,
  'name' | 'description' | 'homepageUrl'
>

type FormPartnerType = {
  id: string
  onCancel: () => void
  onSuccess: () => void
  isAdmin?: boolean
}

const FormPartnerForm = ({
  id,
  onCancel,
  onSuccess,
  partner,
}: FormPartnerType & {
  partner?: Partner
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: partner?.name || '',
      description: partner?.description || '',
      homepageUrl: partner?.homepageUrl || '',
    },
  })

  const { field: fieldName, fieldState: fieldNameState } = useController({
    name: 'name',
    control,
    rules: {
      required: `Please name your company`,
    },
  })

  const {
    field: fieldDescription,
    fieldState: fieldDescriptionState,
  } = useController({
    name: 'description',
    control,
    rules: {
      maxLength: 500,
    },
  })

  const {
    field: fieldHomepageUrl,
    fieldState: fieldHomepageUrlState,
  } = useController({
    name: 'homepageUrl',
    control,
  })

  const { updatePartner, loading } = useUpdatePartner({
    onError: error => {
      toast.error(`${error.message} 🙀`)
    },
    onCompleted: () => {
      toast.success('Partner successfully updated 🎉')
      onSuccess()
    },
  })

  const handleCancel = () => {
    onCancel()
  }

  const submit = useCallback(
    (data: FormValues) => {
      if (data && partner?.id) {
        updatePartner({ ...partner, ...data })
      }
    },
    [partner, updatePartner]
  )

  return (
    <ProfileForm
      title="Edit partner"
      onCancel={handleCancel}
      onSubmit={handleSubmit(submit)}
      loading={loading}
    >
      <Stack component="form" spacing={8}>
        {id && partner?.id && (
          <PartnerFormProfileImage
            id={partner?.id}
            avatar={partner?.profileImage?.urls.s}
          />
        )}
        <Stack spacing={4}>
          <TextField
            label="Company name"
            {...fieldName}
            error={!!fieldNameState.error}
            helperText={fieldNameState.error?.message}
          />

          <TextField
            label="Description"
            {...fieldDescription}
            error={!!fieldDescriptionState.error}
            helperText={
              fieldDescriptionState.error?.message ||
              `(${fieldDescription.value.length}/500 characters)`
            }
            multiline
            minRows={4}
          />

          <TextField
            label="Website URL"
            {...fieldHomepageUrl}
            error={!!fieldHomepageUrlState.error}
            helperText={fieldHomepageUrlState.error?.message}
          />
        </Stack>

        {id && partner?.id && (
          <PartnerFormProfileBanner
            id={partner?.id}
            banner={partner?.bannerImage?.urls.m}
          />
        )}
      </Stack>
    </ProfileForm>
  )
}

export const FormEditPartnerBasicInfo = ({
  id,
  onCancel,
  onSuccess,
  isAdmin = false,
}: Omit<FormPartnerType, 'onDelete'> & {
  isAdmin: boolean
}) => {
  const { partner, loading: partnerLoading } = usePartnerDetails(id)

  if (id && partnerLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />
  }

  return (
    <FormPartnerForm
      {...{
        id,
        onCancel,
        onSuccess,
        partner,
        isAdmin,
      }}
    />
  )
}
