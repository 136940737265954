import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

export interface ContentListItemProps {
  headline: string
  subline: string
  description?: string
  text?: string
  icon?: ReactNode
}

export const ContentListItem = ({
  headline,
  subline,
  description,
  text,
  icon,
}: ContentListItemProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 5,
        gridTemplateColumns: 'auto 1fr',
      }}
    >
      {icon && <Box sx={{ fontSize: 20, marginTop: 1 }}>{icon}</Box>}

      <div>
        <Typography variant="body2Strong" color="colors.nemo">
          {headline}
        </Typography>

        {subline && <Typography variant="captionStrong">{subline}</Typography>}

        {description && (
          <Typography
            variant="caption"
            color="colors.grey"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            {description}
          </Typography>
        )}

        {text && <Typography variant="body2">{text}</Typography>}
      </div>
    </Box>
  )
}
