import { BaseMutationOptions, gql, useQuery } from '@apollo/client'

import { Event } from '../graphqlTypes'

const GET_EVENT = gql`
  query GetEvent($id: String!) {
    getEvent(id: $id) {
      id
      description
      techStack
      location
      onlineEvent
      bootcamp {
        id
        track {
          name
        }
        teams {
          id
          name
          participants {
            id
          }
        }
        participants {
          id
          role {
            id
            name
          }
          person {
            id
            firstName
            lastName
            email
            teaser
            participates {
              bootcamp {
                id
                track {
                  id
                  name
                }
              }
            }
            jobExperiences {
              id
              title
              companyName
              type
            }
            jobPreferences {
              available
            }
            avatar {
              id
              urls {
                s
              }
            }
          }
          capstoneProject {
            id
            description
            appUrl
            repositoryUrl
            name
            techStack
            device
            image {
              id
              urls {
                l
                xl
              }
            }
            person {
              id
              firstName
              lastName
              avatar {
                urls {
                  m
                }
              }
            }
          }
        }
      }
      date
      time
      token
    }
  }
`

export const useEvent = (options: BaseMutationOptions) => {
  const query = useQuery<{ getEvent: Event }>(GET_EVENT, options)
  const event = query.data?.getEvent
  return { ...query, event }
}
