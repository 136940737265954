import { Components } from '@mui/material'

import { colors } from '../constants/colors'

export const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      minWidth: 'unset',
      textTransform: 'initial',
      color: colors.granite,
      '&.Mui-focusVisible': {
        color: colors.darkNemo,
      },
    },
  },
}
