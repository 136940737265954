import { Person } from '../../graphql/graphqlTypes'

export const getPersonName = (
  person: Partial<Pick<Person, 'firstName' | 'lastName' | 'id'>>
) => {
  if (person.firstName && person.lastName) {
    return `${person.firstName} ${person.lastName}`
  }
  if (person.firstName) {
    return person.firstName
  }
  if (person.lastName) {
    return person.lastName
  }
  return '(empty)'
}

/**
 * Get full name with a line break within
 *
 * @param person
 *
 * @return Element
 */
export const getPersonNameMultiline = (
  person: Partial<Pick<Person, 'firstName' | 'lastName' | 'id'>>
) => {
  if (person.firstName && person.lastName) {
    return (
      <span>
        {person.firstName}
        <br />
        {person.lastName}
      </span>
    )
  }

  if (person.firstName) {
    return <span>{person.firstName}</span>
  }

  if (person.lastName) {
    return <span>{person.lastName}</span>
  }

  return <span>(empty)</span>
}
