import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { GET_PERSON } from './useGetPerson'
import { GET_USER } from './useUser'

const DELETE_PERSON_AVATAR = gql`
  mutation DeletePersonProfileImage {
    deleteOwnPersonAvatar
  }
`

export default function useDeletePersonAvatar(options: BaseMutationOptions) {
  const [deletePersonOwnAvatar, status] = useMutation(
    DELETE_PERSON_AVATAR,
    options
  )

  const deletePersonAvatar = async () => {
    deletePersonOwnAvatar({
      refetchQueries: [GET_USER, GET_PERSON],
    })
  }

  return { deletePersonAvatar, ...status }
}
