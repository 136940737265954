import { BoxProps, styled } from '@mui/material'

import { ImagePictureUploadArrow } from '../../../assets/icons'

export const UploadOverlayContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.colors.foam,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: 8,
  cursor: 'pointer',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),

  ':hover': {
    opacity: 1,
  },

  '::after': {
    content: '""',
    position: 'absolute',
    top: -12,
    right: -12,
    bottom: -12,
    left: -12,
    border: `1px dashed ${theme.palette.colors.lightGrey}`,
    borderRadius: 10,
  },
}))

export const UploadOverlay = ({ sx }: Pick<BoxProps, 'sx'>) => (
  <UploadOverlayContainer sx={sx}>
    <ImagePictureUploadArrow fontSize={40} />
  </UploadOverlayContainer>
)
